import { Gym, GymTypes } from 'fitbeat.models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';
import { currentGymSelector } from '../selector';

interface IProps {
  currentGym: Gym;
}

class OpenStudioWebsite extends Component<IProps> {
  public render() {
    const { currentGym } = this.props;
    const isIndoor = currentGym.type === GymTypes.Indoor;
    const gymUrl = currentGym.landingPage || `https://fitillion.com/location/${currentGym.name}/`;
    return isIndoor ? (
      <a className='open-studio-website-tag-container leading-divider' href={gymUrl} target='_blank' rel='noreferrer'>
        <img
          className='globe-icon'
          src='resources/images/globe-icon.svg'
          style={{ width: '25px', height: '25px' }}
        />
        <p className='studio-website-text'>Studio Website</p>
        <img
          className='newTabIcon'
          src='resources/images/open-in-new-tab-icon.svg'
          style={{ width: '14px', height: '14px' }}
        />
      </a>
    ) : null;
  }
}

const mapStateToProps = (state: IAppStore) => {
  const { gyms } = state.adminSettings.data;
  const currentGym = currentGymSelector(state);
  return { currentGym, gyms };
};

export default connect(mapStateToProps, null)(OpenStudioWebsite);
