import { UserAccountDetail } from 'fitbeat.models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable, { SortingRule } from 'react-table';
import { IAppStore } from '../../rootReducer';
import {
  nextButton,
  previousButton,
} from '../components/reactTablePaginationButtons';
import { paymentColumnData } from '../filters/payments';

interface IProps {
  memberAccountData: UserAccountDetail;
  sortOrder: SortingRule[];
}

class MemberPaymentsOverviewTable extends Component<IProps, any> {
  public reactTable: any;
  constructor(props: IProps) {
    super(props);
    this.reactTable = React.createRef();
  }
  public render() {
    return (
      <ReactTable
      className='member-payments-overview-table'
      ref={this.reactTable}
        minRows={0}
        resizable={true}
        showPageSizeOptions={true}
        filterable
        data={
          this.props.memberAccountData && this.props.memberAccountData.payments
            ? this.props.memberAccountData.payments
            : []
        }
        loadingText='Loading...'
        noDataText={'No data found'}
        columns={paymentColumnData}
        defaultPageSize={50}
        defaultFilterMethod={(filter: any, row: any) =>
          String(row[filter.id]) === filter.value
        }
        defaultSorted={this.props.sortOrder}
        PreviousComponent={previousButton}
        NextComponent={nextButton}
      />
    );
  }
}

const mapStateToProps = (state: IAppStore, props: any) => {
  const { members } = state;
  const { memberAccountData } = members.data;
  return {
    memberAccountData,
  };
};

export default connect(mapStateToProps, null)(MemberPaymentsOverviewTable);
