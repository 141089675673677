import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Checkbox } from './columnCheckbox';

const Column: React.FC<{
  column: any,
  setColumnAsUsed: any,
  showCheckbox?: boolean}> = ({ column, setColumnAsUsed, showCheckbox=true }) => {
  const headerMethod = column.Header();
  const HeaderComponent = headerMethod.type;
  const headerText = headerMethod.props.header;
  return (
    <div className='column-content'>
      <label
        className='column-label'>
        <HeaderComponent showIcons={false} header={headerText}/>

        {showCheckbox && <Checkbox
          name={column.accessor}
          checked={column.used}
          hasFilter={column.hasFilter}
          onChange={(e: any) => {
            setColumnAsUsed(e.target.name);
          }}
        />}
      </label>
    </div>);
};

export const SortableColumn = SortableElement(Column);
