import { useState } from 'react';

interface InitialValues {
  selectedPriceId?: string;
  selectedCouponId?: string;
  selectedBillingStartDate?: string;
}

export const useSpecialOfferFormValues = (initialValues: InitialValues) => {
  const [selectedPriceId, setSelectedPriceId] = useState<string>(initialValues.selectedPriceId || '');
  const [selectedCouponId, setSelectedCouponId] = useState<string>(initialValues.selectedCouponId || '');
  const [selectedBillingStartDate, setSelectedBillingStartDate] = useState<Date | null>(
    initialValues.selectedBillingStartDate ? new Date(initialValues.selectedBillingStartDate) : null);

  return {
    selectedPriceId,
    setSelectedPriceId,
    selectedCouponId,
    setSelectedCouponId,
    selectedBillingStartDate,
    setSelectedBillingStartDate,
  };
};
