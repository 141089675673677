import { Elements, ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements, StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { IPaymentSetupData } from 'fitbeat.models';
import React, { FunctionComponent } from 'react';

interface IProps {
  stripe: Stripe | null;
  paymentOptions: StripePaymentElementOptions;
  setupIntent?: IPaymentSetupData;
  loadPaymentData: () => void;
  renderNotes?: JSX.Element;
  actions: (stripeHook: (Stripe | null), elements: (StripeElements | null)) => JSX.Element;
}

const ManualCardCapture: FunctionComponent<IProps> =
  ({
     setupIntent,
     paymentOptions,
     stripe,
     loadPaymentData,
     renderNotes,
     actions,
   }) => {

    const elementOptions: StripeElementsOptions = {
      clientSecret: setupIntent?.clientSecret || undefined,
    };

    if (!setupIntent) {
      loadPaymentData();
      return actions(null, null);
    }

    return (
      <div>
        <Elements stripe={stripe} options={elementOptions}>
          <ElementsConsumer>
            {({ stripe: stripeHook, elements }) => (
              <div>
                <form id='payment-form'>
                  <PaymentElement
                    id='payment-element'
                    options={paymentOptions}
                  />
                  {renderNotes}
                  <div>
                    {actions(stripeHook, elements)}
                  </div>
                </form>
              </div>)}
          </ElementsConsumer>
        </Elements>
      </div>
    );
  };

export default ManualCardCapture;
