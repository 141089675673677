import { loadStripe, SetupIntent, Stripe, StripeElements } from '@stripe/stripe-js';
import { DiscoverResult, loadStripeTerminal, Reader, Terminal } from '@stripe/terminal-js';
import { UserWorkoutSession } from 'fitbeat.gym-core';
import {
  Gym,
  ICancelSubscriptionResponse,
  IConnectionToken,
  ICustomerPaymentMethod,
  IFreeWeekPhaseInfo,
  IFreezePhaseInfo,
  IInvoice,
  IInvoiceItem,
  IPaymentSetupData,
  IPrice,
  IStripeCouponWrapper,
  IStripeSchedule,
  ISubscriptionPaymentSummary,
  ProductCategory,
  UserAccountDetail,
  UserAccountScheduleActionInfo,
} from 'fitbeat.models';
import moment, { Moment } from 'moment';
import { Dispatch } from 'redux';
import { currentGymSelector } from '../adminSettings/selector';
import { apiConfig } from '../app/apiConfig';
import { config } from '../config';
import { fetchMemberAccountDataSuccess, getMemberDetails } from '../members/actions';
import { MembersService } from '../members/membersService';
import { IAppStore } from '../rootReducer';
import { AccountActionsService } from './accountActionsService';
import {
  getBuyMerchandiseInvoice,
  getMerchandiseNoteSelector,
  shouldGetStartSubscriptionPaymentSummarySelector,
} from './selector';
import { ITerminalStatus } from './storeTypes';

export const MEMBER_SCHEDULE_ACTION_DETAILS_REQUEST = 'MEMBER_SCHEDULE_ACTION_DETAILS_REQUEST';
export const MEMBER_SCHEDULE_ACTION_DETAILS_SUCCESS = 'MEMBER_SCHEDULE_ACTION_DETAILS_SUCCESS';
export const MEMBER_SCHEDULE_ACTION_DETAILS_FAILURE = 'MEMBER_SCHEDULE_ACTION_DETAILS_FAILURE';
export const TOGGLE_ADD_PAUSE_MODAL = 'TOGGLE_ADD_PAUSE_MODAL';
export const PAUSE_SUBSCRIPTION_REQUEST = 'PAUSE_SUBSCRIPTION_REQUEST';
export const PAUSE_SUBSCRIPTION_SUCCESS = 'PAUSE_SUBSCRIPTION_SUCCESS';
export const PAUSE_SUBSCRIPTION_FAILURE = 'PAUSE_SUBSCRIPTION_FAILURE';
export const TOGGLE_CANCEL_SUBSCRIPTION_MODAL = 'TOGGLE_CANCEL_MODAL';
export const CANCEL_PAUSE_REQUEST = 'CANCEL_PAUSE_REQUEST';
export const CANCEL_PAUSE_SUCCESS = 'CANCEL_PAUSE_SUCCESS';
export const CANCEL_PAUSE_FAILURE = 'CANCEL_PAUSE_FAILURE';
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';
export const TOGGLE_CANCEL_PAUSE_MODAL = 'TOGGLE_CANCEL_PAUSE_MODAL';
export const SET_EARLIEST_PAUSE_END = 'SET_EARLIEST_PAUSE_END';
export const UPDATE_CANCELLATION_FEE = 'UPDATE_CANCELLATION_FEE';
export const SET_PAUSE_PLAN_ID = 'SET_PAUSE_PLAN_ID';
export const SET_PAUSE_END_DATE = 'SET_PAUSE_END_DATE';
export const GET_MEMBER_WORKOUT_HISTORY_SUCCESS = 'GET_MEMBER_WORKOUT_HISTORY_SUCCESS';
export const GET_MEMBER_WORKOUT_HISTORY_FAILURE = 'GET_MEMBER_WORKOUT_HISTORY_FAILURE';
export const MEMBER_WORKOUT_HISTORY_REQUEST = 'MEMBER_WORKOUT_HISTORY_REQUEST';
export const TOGGLE_ADD_FREE_WEEKS_MODAL = 'TOGGLE_ADD_FREE_WEEKS_MODAL';
export const FREE_WEEK_SUBSCRIPTION_REQUEST = 'FREE_WEEK_SUBSCRIPTION_REQUEST';
export const FREE_WEEK_SUBSCRIPTION_SUCCESS = 'FREE_WEEK_SUBSCRIPTION_SUCCESS';
export const FREE_WEEK_SUBSCRIPTION_FAILURE = 'FREE_WEEK_SUBSCRIPTION_FAILURE';
export const SET_FREE_WEEK_PLAN_ID = 'SET_FREE_WEEK_PLAN_ID';
export const SET_EARLIEST_FREE_WEEK_END = 'SET_EARLIEST_FREE_WEEK_END';
export const SET_FREE_WEEK_END_DATE = 'SET_FREE_WEEK_END_DATE';
export const TOGGLE_START_SUBSCRIPTION_MODAL = 'TOGGLE_START_SUBSCRIPTION_MODAL';
export const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';
export const PLAN_SELECTED_ON_START_SUBSCRIPTION_REQUEST = 'PLAN_SELECTED_ON_START_SUBSCRIPTION_REQUEST';
export const PLAN_SELECTED_ON_START_SUBSCRIPTION_SUCCESS = 'PLAN_SELECTED_ON_START_SUBSCRIPTION_SUCCESS';
export const PLAN_SELECTED_ON_START_SUBSCRIPTION_FAILURE = 'PLAN_SELECTED_ON_START_SUBSCRIPTION_FAILURE';
export const COUPON_SELECTED_ON_START_SUBSCRIPTION = 'COUPON_SELECTED_ON_START_SUBSCRIPTION';
export const SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_REQUEST = 'SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_REQUEST';
export const SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_SUCCESS = 'SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_SUCCESS';
export const SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_FAILURE = 'SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_FAILURE';
export const APPLY_COUPON_CHECKED_ON_START_SUBSCRIPTION = 'APPLY_COUPON_CHECKED_ON_START_SUBSCRIPTION';
export const CONSENT_CHECKED_ON_START_SUBSCRIPTION = 'CONSENT_CHECKED_ON_START_SUBSCRIPTION';
export const LOAD_START_SUBSCRIPTION_MODAL_REQUEST = 'LOAD_START_SUBSCRIPTION_MODAL_REQUEST';
export const LOAD_START_SUBSCRIPTION_MODAL_SUCCESS = 'LOAD_START_SUBSCRIPTION_MODAL_SUCCESS';
export const LOAD_START_SUBSCRIPTION_MODAL_FAILURE = 'LOAD_START_SUBSCRIPTION_MODAL_FAILURE';
export const CLOSE_START_SUBSCRIPTION_MODAL = 'CLOSE_START_SUBSCRIPTION_MODAL';
export const LOAD_BUY_MERCHANDISE_MODAL_REQUEST = 'LOAD_BUY_MERCHANDISE_MODAL_REQUEST';
export const LOAD_BUY_MERCHANDISE_MODAL_SUCCESS = 'LOAD_BUY_MERCHANDISE_MODAL_SUCCESS';
export const LOAD_BUY_MERCHANDISE_MODAL_FAILURE = 'LOAD_BUY_MERCHANDISE_MODAL_FAILURE';
export const CLOSE_BUY_MERCHANDISE_MODAL = 'CLOSE_BUY_MERCHANDISE_MODAL';
export const CLOSE_CONFIRM_SAVE_DRAFT_MODAL = 'CLOSE_CONFIRM_SAVE_DRAFT_MODAL';
export const CHANGE_MERCHANDISE_NOTE = 'CHANGE_MERCHANDISE_NOTE';
export const UPDATE_MERCHANDISE_INVOICE_REQUEST = 'UPDATE_MERCHANDISE_INVOICE_REQUEST';
export const UPDATE_MERCHANDISE_INVOICE_SUCCESS = 'UPDATE_MERCHANDISE_INVOICE_SUCCESS';
export const UPDATE_MERCHANDISE_INVOICE_FAILURE = 'UPDATE_MERCHANDISE_INVOICE_FAILURE';
export const SELECT_MERCHANDISE_COUPON_REQUEST = 'SELECT_MERCHANDISE_COUPON_REQUEST';
export const SELECT_MERCHANDISE_COUPON_SUCCESS = 'SELECT_MERCHANDISE_COUPON_SUCCESS';
export const SELECT_MERCHANDISE_COUPON_FAILURE = 'SELECT_MERCHANDISE_COUPON_FAILURE';
export const PAY_MERCHANDISE_REQUEST = 'PAY_MERCHANDISE_REQUEST';
export const PAY_MERCHANDISE_SUCCESS = 'PAY_MERCHANDISE_SUCCESS';
export const PAY_MERCHANDISE_FAILURE = 'PAY_MERCHANDISE_FAILURE';
export const LOAD_STRIPE_PAYMENT_DATA_REQUEST = 'LOAD_STRIPE_PAYMENT_DATA_REQUEST';
export const LOAD_STRIPE_PAYMENT_DATA_SUCCESS = 'LOAD_STRIPE_PAYMENT_DATA_SUCCESS';
export const LOAD_STRIPE_PAYMENT_DATA_FAILURE = 'LOAD_STRIPE_PAYMENT_DATA_FAILURE';
export const HANDLE_MERCHANDISE_DRAFT_INVOICE_REQUEST = 'HANDLE_MERCHANDISE_DRAFT_INVOICE_REQUEST';
export const HANDLE_MERCHANDISE_DRAFT_INVOICE_SUCCESS = 'HANDLE_MERCHANDISE_DRAFT_INVOICE_SUCCESS';
export const HANDLE_MERCHANDISE_DRAFT_INVOICE_FAILURE = 'HANDLE_MERCHANDISE_DRAFT_INVOICE_FAILURE';
export const OPEN_CONFIRM_ACTION_INVOICE_MODAL = 'OPEN_CONFIRM_ACTION_INVOICE_MODAL';
export const SETUP_TERMINAL_REQUEST = 'SETUP_TERMINAL_REQUEST';
export const SETUP_TERMINAL_SUCCESS = 'SETUP_TERMINAL_SUCCESS';
export const SETUP_TERMINAL_FAILURE = 'SETUP_TERMINAL_FAILURE';
export const LOAD_TERMINAL_READERS = 'LOAD_TERMINAL_READERS';
export const CONNECT_TO_TERMINAL_READER_REQUEST = 'CONNECT_TO_TERMINAL_READER_REQUEST';
export const CONNECT_TO_TERMINAL_READER_SUCCESS = 'CONNECT_TO_TERMINAL_READER_SUCCESS';
export const CONNECT_TO_TERMINAL_READER_FAILURE = 'CONNECT_TO_TERMINAL_READER_FAILURE';
export const SET_TERMINAL_STATUS = 'SET_TERMINAL_STATUS';
export const HANDLE_TERMINAL_DISCONNECT = 'HANDLE_TERMINAL_DISCONNECT';
export const CLEANUP_TERMINAL = 'CLEANUP_TERMINAL';

interface IGetMemberScheduleDetailsRequestAction {
  type: typeof MEMBER_SCHEDULE_ACTION_DETAILS_REQUEST;
}

interface IGetMemberScheduleDetailsSuccessAction {
  type: typeof MEMBER_SCHEDULE_ACTION_DETAILS_SUCCESS;
  payload: {
    memberAccountSchedule: UserAccountScheduleActionInfo;
    stripeAccountId: string;
    workoutList: UserWorkoutSession[]
  };
}

interface IGetMemberWorkoutHistorySuccessAction {
  type: typeof GET_MEMBER_WORKOUT_HISTORY_SUCCESS;
  payload: {
    workoutList: UserWorkoutSession[];
  };
}

interface IGetMemberWorkoutHistoryFailureAction {
  type: typeof GET_MEMBER_WORKOUT_HISTORY_FAILURE;
  payload: string | null;
}

interface IGetMemberScheduleDetailsFailureAction {
  type: typeof MEMBER_SCHEDULE_ACTION_DETAILS_FAILURE;
  payload: string | null;
}

interface IToggleAddPauseAction {
  type: typeof TOGGLE_ADD_PAUSE_MODAL;
  payload: IFreezePhaseInfo;
}

interface IToggleCancelSubscriptionModalAction {
  type: typeof TOGGLE_CANCEL_SUBSCRIPTION_MODAL;
}

interface IToggleStartSubscriptionModalAction {
  type: typeof TOGGLE_START_SUBSCRIPTION_MODAL;
}

interface IToggleCancelPauseModalAction {
  type: typeof TOGGLE_CANCEL_PAUSE_MODAL;
}

interface IPauseSubscriptionRequestAction {
  type: typeof PAUSE_SUBSCRIPTION_REQUEST;
}

interface IPauseSubscriptionSuccessAction {
  type: typeof PAUSE_SUBSCRIPTION_SUCCESS;
  payload: IStripeSchedule;
}

interface IPauseSubscriptionFailureAction {
  type: typeof PAUSE_SUBSCRIPTION_FAILURE;
  payload: string | null;
}

interface ICancelPauseRequestAction {
  type: typeof CANCEL_PAUSE_REQUEST;
}

interface ICancelPauseSuccessAction {
  type: typeof CANCEL_PAUSE_SUCCESS;
  payload: UserAccountScheduleActionInfo;
}

interface ICancelPauseFailureAction {
  type: typeof CANCEL_PAUSE_FAILURE;
  payload: string | null;
}

interface ICancelSubscriptionRequestAction {
  type: typeof CANCEL_SUBSCRIPTION_REQUEST;
}

interface ICancelSubscriptionSuccessAction {
  type: typeof CANCEL_SUBSCRIPTION_SUCCESS;
  payload: ICancelSubscriptionResponse;
}

interface ICancelSubscriptionFailureAction {
  type: typeof CANCEL_SUBSCRIPTION_FAILURE;
  payload: string | null;
}

interface ISetPauseEarliestEnd {
  type: typeof SET_EARLIEST_PAUSE_END;
  payload: Moment;
}

interface IUpdateCancellationFeeAction {
  type: typeof UPDATE_CANCELLATION_FEE;
  payload: number;
}

interface ISetPausePlanIdAction {
  type: typeof SET_PAUSE_PLAN_ID;
  payload: string;
}

interface ISetPauseEndDateAction {
  type: typeof SET_PAUSE_END_DATE;
  payload: Moment;
}

interface ILoadStartSubscriptionModalSuccess {
  type: typeof LOAD_START_SUBSCRIPTION_MODAL_SUCCESS;
  payload: {paymentIntent: IPaymentSetupData, stripe: Stripe | null};
}

interface ILoadStartSubscriptionModalRequest {
  type: typeof LOAD_START_SUBSCRIPTION_MODAL_REQUEST;
  payload: {paymentIntent: IPaymentSetupData};
}

interface ILoadStartSubscriptionModalFailure {
  type: typeof LOAD_START_SUBSCRIPTION_MODAL_FAILURE;
  payload: {error: string};
}

interface ICloseStartSubscriptionModal {
  type: typeof CLOSE_START_SUBSCRIPTION_MODAL;
}

export type AccountActionTypes =
  IToggleAddPauseAction | IToggleAddFreeWeeksAction | IGetMemberScheduleDetailsRequestAction |
  IGetMemberScheduleDetailsSuccessAction | IGetMemberScheduleDetailsFailureAction | IPauseSubscriptionRequestAction |
  IPauseSubscriptionSuccessAction | IPauseSubscriptionFailureAction | IToggleCancelSubscriptionModalAction |
  ICancelPauseRequestAction | ICancelPauseSuccessAction | ICancelPauseFailureAction | ICancelSubscriptionRequestAction |
  ICancelSubscriptionSuccessAction | ICancelSubscriptionFailureAction | IToggleCancelPauseModalAction |
  ISetPauseEarliestEnd | IUpdateCancellationFeeAction | ISetPausePlanIdAction | ISetPauseEndDateAction |
  IFreeWeekSubscriptionRequestAction | IFreeWeekSubscriptionSuccessAction | IFreeWeekSubscriptionFailureAction |
  ISetFreeWeekPlanIdAction | ISetFreeWeekEarliestEnd | ISetFreeWeekEndDateAction | IToggleStartSubscriptionModalAction |
  ICreateSubscriptionRequestAction | ICreateSubscriptionSuccessAction | ICreateSubscriptionFailureAction |
  IPlanSelectedOnStartSubscriptionRequestAction | IPlanSelectedOnStartSubscriptionSuccessAction |
  IPlanSelectedOnStartSubscriptionFailureAction | ICouponSelectedOnStartSubscriptionAction |
  ILoadStartSubscriptionModalSuccess | ILoadStartSubscriptionModalRequest | ILoadStartSubscriptionModalFailure |
  ICloseStartSubscriptionModal | IApplyCouponCheckedOnStartSubscriptionAction | IConsentCheckedOnStartSubscription |
  ISetStartSubscriptionPaymentSummaryRequestAction | ISetStartSubscriptionPaymentSummarySuccessAction |
  ISetStartSubscriptionPaymentSummaryFailureAction | ILoadBuyMerchandiseModalRequestAction |
  ILoadBuyMerchandiseModalSuccessAction | ILoadBuyMerchandiseModalFailureAction |
  ICloseBuyMerchandiseModalSuccessAction | IUpdateMerchandiseInvoiceRequestAction |
  IUpdateMerchandiseInvoiceSuccessAction | IUpdateMerchandiseInvoiceFailureAction |
  ISelectMerchandiseCouponRequestAction | ISelectMerchandiseCouponSuccessAction |
  ISelectMerchandiseCouponFailureAction | IPayMerchandiseRequestAction | IPayMerchandiseSuccessAction |
  IPayMerchandiseFailureAction | ILoadStripePaymentDataRequestAction | ILoadStripePaymentDataSuccessAction |
  ILoadStripePaymentDataFailureAction | IChangeMerchandiseNoteAction | ICloseConfirmSaveDraftModalAction |
  IHandleMerchandiseDraftInvoiceRequestAction | IHandleMerchandiseDraftInvoiceSuccessAction |
  IHandleMerchandiseDraftInvoiceFailureAction | IOpenConfirmActionInvoiceModalAction |
  ISetupTerminalRequestAction | ISetupTerminalSuccessAction | ISetupTerminalFailureAction |
  IConnectToTerminalReaderRequestAction | IConnectToTerminalReaderSuccessAction |
  IConnectToTerminalReaderFailureAction | ILoadTerminalReadersAction | ISetTerminalStatusAction |
  IHandleTerminalDisconnect | ICleanupTerminal;

export const getMemberWorkoutHistory = (uid: string) => {
  return async (dispatch: Dispatch<AccountActionTypes>) => {
    try {
      dispatch(fetchMemberScheduleRequest());
      const accountActionService = new AccountActionsService(apiConfig);
      const workoutHistory = await accountActionService.getMemberWorkoutHistory(uid);
      if (workoutHistory) {
        dispatch(fetchMemberWorkoutHistorySuccess(workoutHistory));
      } else {
        dispatch(fetchMemberWorkoutHistoryFailure('No data returned'));
      }
    } catch (error) {
      dispatch(fetchMemberWorkoutHistoryFailure(error.message));
    }
  };
};

export const getMemberAccountScheduleAndWorkouts = (uid: string) => {
  return async (dispatch: Dispatch<AccountActionTypes>, getState: () => IAppStore) => {
    try {
      dispatch(fetchMemberScheduleRequest());
      const state = getState();
      const currentGym = currentGymSelector(state);
      const accountActionService = new AccountActionsService(apiConfig);
      const memberSchedule = await accountActionService.getMemberSchedule(uid, currentGym.name);
      let workoutHistory = await accountActionService.getMemberWorkoutHistory(uid);
      if (!workoutHistory) {
        workoutHistory = [];
      }
      if (memberSchedule) {
        dispatch(fetchMemberScheduleSuccess(memberSchedule, currentGym.accountId, workoutHistory));
      } else {
        dispatch(fetchMemberScheduleFailure('No data returned'));
      }
    } catch (error) {
      dispatch(fetchMemberScheduleFailure(error.message));
    }
  };
};

export const pauseSubscription = (
  memberService: MembersService,
  uid: string,
  planId: string,
  start: Moment,
  end: Moment,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    try {
      dispatch(fetchPauseSubscriptionRequest());
      const accountActionService = new AccountActionsService(apiConfig);
      const formattedStart = new Date(moment(start).format('YYYY-MM-DD'));
      const formattedEnd = new Date(moment(end).format('YYYY-MM-DD'));
      const stripeSchedule = await accountActionService.pauseSubscription(uid, planId, formattedStart, formattedEnd);
      if (stripeSchedule) {
        dispatch(fetchPauseSubscriptionSuccess(stripeSchedule));
        dispatch(getMemberAccountScheduleAndWorkouts(uid));
      }
      await reloadMemberAccountData(memberService, uid, dispatch);
    } catch (error) {
      dispatch(fetchPauseSubscriptionFailure(error.message));
    }
  };
};

const reloadMemberAccountData = async (memberService: MembersService, uid: string, dispatch: any) => {
  const rawMembersAccountInfo = await memberService.getMemberDetails(uid);
  const membersAccountInfo = reloadMemberAccount(uid, rawMembersAccountInfo);
  dispatch(fetchMemberAccountDataSuccess(membersAccountInfo));
};

export const cancelSubscription = (
  memberService: MembersService,
  uid: string,
  penaltyAmount: number,
  isImmediateCancel: boolean,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    try {
      const state = getState();
      const currentGym = currentGymSelector(state);
      dispatch(fetchCancelSubscriptionRequest());
      const accountActionService = new AccountActionsService(apiConfig);
      const cancelSubscriptionDetails =
        await accountActionService.cancelSubscription(uid, currentGym.name, penaltyAmount, isImmediateCancel);
      dispatch(fetchCancelSubscriptionSuccess(cancelSubscriptionDetails));
      await reloadMemberAccountData(memberService, uid, dispatch);
      dispatch(getMemberAccountScheduleAndWorkouts(uid));
    } catch (error) {
      dispatch(fetchCancelSubscriptionFailure(error.message));
    }
  };
};

export const cancelPause = (uid: string) => {
  return async (dispatch: Dispatch<AccountActionTypes>) => {
    try {
      dispatch(fetchCancelPauseRequest());
      // const accountActionService = new AccountActionsService(apiConfig);
      // const memberSchedule = await accountActionService.pauseSubscription(uid);
      // dispatch(fetchCancelPauseSuccess(memberSchedule));
    } catch (error) {
      dispatch(fetchCancelPauseFailure(error.message));
    }
  };
};

const reloadMemberAccount = (uid: string, membersAccountInfo: UserAccountDetail) => {
  if (membersAccountInfo.invoices != null) {
    membersAccountInfo.invoices.map((invoice) => {
      invoice.created = moment.unix(invoice.created).format('YYYY-MM-DD');
      const dollars = invoice.total / 100;
      invoice.total = Number(dollars);
      invoice.due = Number(invoice.due / 100);
      invoice.creditNoteTotal = Number(invoice.creditNoteTotal / 100);
    });
  }
  if (membersAccountInfo.payments != null) {
    membersAccountInfo.payments.map((payment) => {
      payment.created = moment.unix(payment.created).format('YYYY-MM-DD');
      const dollars = payment.amount / 100;
      payment.amount = Number(dollars);
    });
  }
  return membersAccountInfo;
};

const fetchMemberScheduleRequest = (): IGetMemberScheduleDetailsRequestAction => {
  return {
    type: MEMBER_SCHEDULE_ACTION_DETAILS_REQUEST,
  };
};

const fetchMemberWorkoutHistorySuccess = (workoutList: UserWorkoutSession[]):
IGetMemberWorkoutHistorySuccessAction => {
  return {
    type: GET_MEMBER_WORKOUT_HISTORY_SUCCESS,
    payload: {
      workoutList,
    },
  };
};

const fetchMemberWorkoutHistoryFailure = (error: string): IGetMemberWorkoutHistoryFailureAction => {
  return {
    type: GET_MEMBER_WORKOUT_HISTORY_FAILURE,
    payload: error,
  };
};

const fetchMemberScheduleSuccess = (
  memberAccountSchedule: UserAccountScheduleActionInfo,
  stripeAccountId: string, workoutList: UserWorkoutSession[]):
IGetMemberScheduleDetailsSuccessAction => {
  return {
    type: MEMBER_SCHEDULE_ACTION_DETAILS_SUCCESS,
    payload: {
      memberAccountSchedule,
      stripeAccountId,
      workoutList,
    },
  };
};

const fetchMemberScheduleFailure = (error: string): IGetMemberScheduleDetailsFailureAction => {
  return {
    type: MEMBER_SCHEDULE_ACTION_DETAILS_FAILURE,
    payload: error,
  };
};

export const toggleAddPauseModal = (freezePhaseInfo: IFreezePhaseInfo) => {
  return async (dispatch: Dispatch<AccountActionTypes>) => {
    dispatch(togglePauseModal(freezePhaseInfo));
  };
};

const togglePauseModal = (freezePhaseInfo: IFreezePhaseInfo): IToggleAddPauseAction => {
  return {
    type: TOGGLE_ADD_PAUSE_MODAL,
    payload: freezePhaseInfo,
  };
};

export const toggleCancelSubscriptionModal = (): IToggleCancelSubscriptionModalAction => {
  return {
    type: TOGGLE_CANCEL_SUBSCRIPTION_MODAL,
  };
};

export const toggleStartSubscriptionModal = (): IToggleStartSubscriptionModalAction => {
  return {
    type: TOGGLE_START_SUBSCRIPTION_MODAL,
  };
};

export const toggleCancelPauseModal = (): IToggleCancelPauseModalAction => {
  return {
    type: TOGGLE_CANCEL_PAUSE_MODAL,
  };
};

const fetchPauseSubscriptionRequest = (): IPauseSubscriptionRequestAction => {
  return {
    type: PAUSE_SUBSCRIPTION_REQUEST,
  };
};

const fetchPauseSubscriptionSuccess = (memberAccountSchedule: IStripeSchedule):
IPauseSubscriptionSuccessAction => {
  return {
    type: PAUSE_SUBSCRIPTION_SUCCESS,
    payload: memberAccountSchedule,
  };
};

const fetchPauseSubscriptionFailure = (error: string): IPauseSubscriptionFailureAction => {
  return {
    type: PAUSE_SUBSCRIPTION_FAILURE,
    payload: error,
  };
};

const fetchCancelPauseRequest = (): ICancelPauseRequestAction => {
  return {
    type: CANCEL_PAUSE_REQUEST,
  };
};

const fetchCancelPauseFailure = (error: string): ICancelPauseFailureAction => {
  return {
    type: CANCEL_PAUSE_FAILURE,
    payload: error,
  };
};

const fetchCancelSubscriptionRequest = (): ICancelSubscriptionRequestAction => {
  return {
    type: CANCEL_SUBSCRIPTION_REQUEST,
  };
};

const fetchCancelSubscriptionSuccess = (cancelSubscriptionDetails: ICancelSubscriptionResponse):
ICancelSubscriptionSuccessAction => {
  return {
    type: CANCEL_SUBSCRIPTION_SUCCESS,
    payload: cancelSubscriptionDetails,
  };
};

const fetchCancelSubscriptionFailure = (error: string): ICancelSubscriptionFailureAction => {
  return {
    type: CANCEL_SUBSCRIPTION_FAILURE,
    payload: error,
  };
};

export const setPauseEarliestStart = (pauseEarliestStart: Moment): ISetPauseEarliestEnd => {
  return {
    type: SET_EARLIEST_PAUSE_END,
    payload: pauseEarliestStart,
  };
};

export const updateCancellationFee = (cancellationFee: number): IUpdateCancellationFeeAction => {
  return {
    type: UPDATE_CANCELLATION_FEE,
    payload: cancellationFee,
  };
};

export const setPausePlanId = (pausePlanId: string): ISetPausePlanIdAction => {
  return {
    type: SET_PAUSE_PLAN_ID,
    payload: pausePlanId,
  };
};

export const setPauseEndDate = (pauseEndDate: Moment): ISetPauseEndDateAction => {
  return {
    type: SET_PAUSE_END_DATE,
    payload: pauseEndDate,
  };
};

interface IFreeWeekSubscriptionRequestAction {
  type: typeof FREE_WEEK_SUBSCRIPTION_REQUEST;
}

interface ICreateSubscriptionRequestAction {
  type: typeof CREATE_SUBSCRIPTION_REQUEST;
}

export interface ICreateSubscriptionSuccessAction {
  type: typeof CREATE_SUBSCRIPTION_SUCCESS;
  payload: {
    membersAccountInfo: UserAccountDetail,
    memberSchedule: UserAccountScheduleActionInfo,
    accountId: string,
  };
}

interface ICreateSubscriptionFailureAction {
  type: typeof CREATE_SUBSCRIPTION_FAILURE;
  payload: {
    error: string | null;
    createNewSetupIntent: boolean;
  };
}

const createSubscriptionRequest = (): ICreateSubscriptionRequestAction => {
  return {
    type: CREATE_SUBSCRIPTION_REQUEST,
  };
};

const createSubscriptionSuccess = (
  membersAccountInfo: UserAccountDetail,
  memberSchedule: UserAccountScheduleActionInfo,
  accountId: string,
):
  ICreateSubscriptionSuccessAction => {
  return {
    type: CREATE_SUBSCRIPTION_SUCCESS,
    payload: {
      membersAccountInfo,
      memberSchedule,
      accountId,
    },
  };
};

const createSubscriptionFailure = (
  error: string | null,
  createNewSetupIntent: boolean = false,
): ICreateSubscriptionFailureAction => {
  return {
    type: CREATE_SUBSCRIPTION_FAILURE,
    payload: {
      error,
      createNewSetupIntent,
    },
  };
};

const fetchFreeWeekSubscriptionRequest = (): IFreeWeekSubscriptionRequestAction => {
  return {
    type: FREE_WEEK_SUBSCRIPTION_REQUEST,
  };
};

interface IPlanSelectedOnStartSubscriptionRequestAction {
  type: typeof PLAN_SELECTED_ON_START_SUBSCRIPTION_REQUEST;
  payload: IPrice;
}

interface IPlanSelectedOnStartSubscriptionSuccessAction {
  type: typeof PLAN_SELECTED_ON_START_SUBSCRIPTION_SUCCESS;
  payload: {
    coupons: IStripeCouponWrapper[],
  };
}

export interface IPlanSelectedOnStartSubscriptionFailureAction {
  type: typeof PLAN_SELECTED_ON_START_SUBSCRIPTION_FAILURE;
  payload: string | null;
}

export interface ICouponSelectedOnStartSubscriptionAction {
  type: typeof COUPON_SELECTED_ON_START_SUBSCRIPTION;
  payload: IStripeCouponWrapper;
}

export interface IApplyCouponCheckedOnStartSubscriptionAction {
  type: typeof APPLY_COUPON_CHECKED_ON_START_SUBSCRIPTION;
}

export interface IConsentCheckedOnStartSubscription {
  type: typeof CONSENT_CHECKED_ON_START_SUBSCRIPTION;
}

const planSelectedOnStartSubscriptionRequest = (plan: IPrice): IPlanSelectedOnStartSubscriptionRequestAction => {
  return {
    type: PLAN_SELECTED_ON_START_SUBSCRIPTION_REQUEST,
    payload: plan,
  };
};

const planSelectedOnStartSubscriptionSuccess =
  (coupons: IStripeCouponWrapper[]): IPlanSelectedOnStartSubscriptionSuccessAction => {
    return {
      type: PLAN_SELECTED_ON_START_SUBSCRIPTION_SUCCESS,
      payload: {
        coupons,
      },
    };
  };

const planSelectedOnStartSubscriptionFailure = (error: string): IPlanSelectedOnStartSubscriptionFailureAction => {
  return {
    type: PLAN_SELECTED_ON_START_SUBSCRIPTION_FAILURE,
    payload: error,
  };
};

const loadStartSubscriptionModalRequest = () => {
  return {
    type: LOAD_START_SUBSCRIPTION_MODAL_REQUEST,
  };
};

const loadBuyMerchandiseModalSuccess = (
  merchandise: IPrice[],
  coupons: IStripeCouponWrapper[],
  stripe: Stripe,
  invoice?: IInvoice,
):
  ILoadBuyMerchandiseModalSuccessAction => {
  return {
    type: LOAD_BUY_MERCHANDISE_MODAL_SUCCESS,
    payload: {
      merchandise,
      coupons,
      stripe,
      invoice,
    },
  };
};

const selectMerchandiseCouponRequest = (coupon: IStripeCouponWrapper): ISelectMerchandiseCouponRequestAction => {
  return {
    type: SELECT_MERCHANDISE_COUPON_REQUEST,
    payload: {
      coupon,
    },
  };
};

const payMerchandiseRequest = (): IPayMerchandiseRequestAction => {
  return {
    type: PAY_MERCHANDISE_REQUEST,
  };
};

const payMerchandiseSuccess = (invoice: IInvoice, member: UserAccountDetail): IPayMerchandiseSuccessAction => {
  return {
    type: PAY_MERCHANDISE_SUCCESS,
    payload: {
      invoice,
      member,
    },
  };
};

const payMerchandiseFailure = (shouldCreateNewSetupIntent?: boolean, error?: string, invoice?: IInvoice,
                               paymentMethod?: ICustomerPaymentMethod | undefined):
  IPayMerchandiseFailureAction => {
  return {
    type: PAY_MERCHANDISE_FAILURE,
    payload: {
      shouldCreateNewSetupIntent,
      error,
      invoice,
      paymentMethod,
    },
  };
};

const selectMerchandiseCouponSuccess = (
  updatedInvoice: IInvoice,
): ISelectMerchandiseCouponSuccessAction => {
  return {
    type: SELECT_MERCHANDISE_COUPON_SUCCESS,
    payload: {
      updatedInvoice,
    },
  };
};

const selectMerchandiseCouponFailure = (error: string): ISelectMerchandiseCouponFailureAction => {
  return {
    type: SELECT_MERCHANDISE_COUPON_FAILURE,
    payload: error,
  };
};

const updateMerchandiseInvoiceRequest = (invoiceItem: IInvoiceItem): IUpdateMerchandiseInvoiceRequestAction => {
  return {
    type: UPDATE_MERCHANDISE_INVOICE_REQUEST,
    payload: {
      invoiceItem,
    },
  };
};

const updateMerchandiseInvoiceSuccess = (invoice: IInvoice): IUpdateMerchandiseInvoiceSuccessAction => {
  return {
    type: UPDATE_MERCHANDISE_INVOICE_SUCCESS,
    payload: {
      invoice,
    },
  };
};

const updateMerchandiseInvoiceFailure = (error: string): IUpdateMerchandiseInvoiceFailureAction => {
  return {
    type: UPDATE_MERCHANDISE_INVOICE_FAILURE,
    payload: error,
  };
};

const loadBuyMerchandiseModalRequest = (): ILoadBuyMerchandiseModalRequestAction => {
  return {
    type: LOAD_BUY_MERCHANDISE_MODAL_REQUEST,
  };
};

const loadBuyMerchandiseModalFailure = (error: string): ILoadBuyMerchandiseModalFailureAction => {
  return {
    type: LOAD_BUY_MERCHANDISE_MODAL_FAILURE,
    payload: error,
  };
};

const loadStartSubscriptionModalSuccess = (paymentIntent: IPaymentSetupData | undefined, stripe: Stripe | null) => {
  return {
    type: LOAD_START_SUBSCRIPTION_MODAL_SUCCESS,
    payload: {
      paymentIntent,
      stripe,
    },
  };
};

const loadStartSubscriptionModalFailure = (error: string) => {
  return {
    type: LOAD_START_SUBSCRIPTION_MODAL_FAILURE,
    payload: {
      error,
    },
  };
};

interface IFreeWeekSubscriptionSuccessAction {
  type: typeof FREE_WEEK_SUBSCRIPTION_SUCCESS;
  payload: IStripeSchedule;
}

const fetchFreeWeekSubscriptionSuccess = (memberAccountSchedule: IStripeSchedule):
IFreeWeekSubscriptionSuccessAction => {
  return {
    type: FREE_WEEK_SUBSCRIPTION_SUCCESS,
    payload: memberAccountSchedule,
  };
};

interface IFreeWeekSubscriptionFailureAction {
  type: typeof FREE_WEEK_SUBSCRIPTION_FAILURE;
  payload: string | null;
}

const fetchFreeWeekSubscriptionFailure = (error: string): IFreeWeekSubscriptionFailureAction => {
  return {
    type: FREE_WEEK_SUBSCRIPTION_FAILURE,
    payload: error,
  };
};

export const applyFreeWeekSubscription = (uid: string, planId: string, start: Moment, end: Moment) => {
  return async (dispatch: any) => {
    try {
      dispatch(fetchFreeWeekSubscriptionRequest());
      const accountActionService = new AccountActionsService(apiConfig);
      const formattedStart = new Date(moment(start).format('YYYY-MM-DD'));
      const formattedEnd = new Date(moment(end).format('YYYY-MM-DD'));
      const stripeSchedule = await accountActionService
        .addFreeWeekSubscription(uid, planId, formattedStart, formattedEnd);
      if (stripeSchedule) {
        dispatch(fetchFreeWeekSubscriptionSuccess(stripeSchedule));
        dispatch(getMemberAccountScheduleAndWorkouts(uid));
      }
    } catch (error) {
      dispatch(fetchFreeWeekSubscriptionFailure(error.message));
    }
  };
};

export const subscribeToPlan = (
  accountActionService: AccountActionsService,
  membersService: MembersService,
  uid: string,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    dispatch(createSubscriptionRequest());
    try {
      const state = getState();
      const currentGym = currentGymSelector(state);
      const startSubscription = state.accountActions.data.startSubscription;
      const formattedDateToStartSubscription = new Date(moment().format('YYYY-MM-DD'));

      if (!startSubscription.plan) {
        throw new Error('No plan selected for start subscription');
      }

      await handleStartSubscription(
        dispatch,
        accountActionService,
        membersService,
        uid,
        startSubscription.plan.priceId,
        formattedDateToStartSubscription,
        currentGym,
        startSubscription.coupon?.id,
      );
    } catch (error) {
      dispatch(createSubscriptionFailure(error.message));
    }
  };
};

const handleStartSubscription = async (
  dispatch: any,
  accountActionService: AccountActionsService,
  membersService: MembersService,
  uid: string,
  planId: string,
  startDate: Date,
  gym: Gym,
  coupon?: string,
  ) => {
  await accountActionService.createSubscription(
    uid,
    planId,
    coupon,
    startDate,
    gym,
  );
  const memberSchedule = await accountActionService.getMemberSchedule(uid, gym.name);
  const rawMembersAccountInfo = await membersService.getMemberDetails(uid);
  const membersAccountInfo = reloadMemberAccount(uid, rawMembersAccountInfo);
  dispatch(createSubscriptionSuccess(membersAccountInfo, memberSchedule, gym.accountId));
};

export const startManualCaptureSubscriptionOnStartSubscription = (
  accountActionService: AccountActionsService,
  membersService: MembersService,
  uid: string,
  stripe: Stripe | null,
  elements: StripeElements | null,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    dispatch(createSubscriptionRequest());
    try {
      const state = getState();
      const currentGym = currentGymSelector(state);
      const { startSubscription } = state.accountActions.data;
      const formattedDateToStartSubscription = new Date(moment().format('YYYY-MM-DD'));

      const setupIntent = await confirmSetupIntent(elements, stripe);
      if (!setupIntent.setupIntent) {
        dispatch(createSubscriptionFailure(setupIntent.errorMessage || null));
        return;
      }

      if (!startSubscription.plan) {
        throw new Error('No plan specified to subscribe to');
      }

      await accountActionService.finaliseSetupIntent(
        currentGym,
        uid,
        setupIntent.setupIntent.id,
        /*saveAsDefaultPaymentMethod*/true,
      );

      await handleStartSubscription(
        dispatch,
        accountActionService,
        membersService,
        uid,
        startSubscription.plan.priceId,
        formattedDateToStartSubscription,
        currentGym,
        startSubscription.coupon?.id,
      );
    } catch (error) {
      dispatch(createSubscriptionFailure(error.message, /*createNewSetupIntent*/true));
    }
  };
};

export const StartSubscriptionWithPOS = (
  accountActionService: AccountActionsService,
  membersService: MembersService,
  uid: string,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    dispatch(createSubscriptionRequest());
    try {
      const state = getState();
      const currentGym = currentGymSelector(state);
      const { startSubscription, terminal } = state.accountActions.data;
      const formattedDateToStartSubscription = new Date(moment().format('YYYY-MM-DD'));

      let setupIntent = state.accountActions.data.setupIntent;

      if (!startSubscription.plan) {
        throw new Error('No plan specified to subscribe to');
      }

      if (!terminal) {
        throw new Error('No terminal configured');
      }

      if (!setupIntent) {
        setupIntent = await accountActionService.createSetupIntent(uid, currentGym.name);
      }

      // @ts-ignore
      const { error: captureCardError, setupIntent: collectedSetupIntent} =
        await terminal.collectSetupIntentPaymentMethod(setupIntent.clientSecret!, true);

      if (captureCardError) {
        throw new Error('Request to capture card failed.');
      }

      // @ts-ignore
      const { error: saveCardError, setupIntent: si } =
        await terminal.confirmSetupIntent(collectedSetupIntent);

      if (saveCardError) {
        throw new Error('Failed to save card.');
      }

      dispatch(setTerminalStatus('processing_card_capture'));

      await accountActionService.setCustomerDefaultPaymentMethod(
        currentGym.name,
        uid,
        si.latest_attempt.payment_method_details.card_present.generated_card,
      );

      dispatch(setTerminalStatus('processing_payment'));

      await handleStartSubscription(
        dispatch,
        accountActionService,
        membersService,
        uid,
        startSubscription.plan.priceId,
        formattedDateToStartSubscription,
        currentGym,
        startSubscription.coupon?.id,
      );
    } catch (error) {
      dispatch(createSubscriptionFailure(error.message, /*createNewSetupIntent*/true));
    }
  };
};

const confirmSetupIntent = async (elements: StripeElements | null, stripe: Stripe | null):
  Promise<{setupIntent: SetupIntent | undefined, errorMessage: string | undefined}> => {
  if (!elements || !stripe) {
    return {
      errorMessage: 'Stripe payment element not setup correctly',
      setupIntent: undefined,
    };
  }

  const { error, setupIntent } = await stripe.confirmSetup({
    elements: elements as any,
    confirmParams: {
      return_url: '',
    },
    redirect: 'if_required',
  });

  if (error) {
    return {
      setupIntent,
      errorMessage: error.message,
    };
  }

  if (!setupIntent) {
    return {
      setupIntent,
      errorMessage: 'Failed to confirm setup intent',
    };
  }

  return {setupIntent, errorMessage: undefined};
};

export const planSelectedOnStartSubscription = (
  accountActionService: AccountActionsService,
  uid: string,
  plan: IPrice,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();
    const currentGym = currentGymSelector(state);
    dispatch(planSelectedOnStartSubscriptionRequest(plan));
    try {
      const coupons = await accountActionService.getCoupons(
        currentGym.name,
        uid,
        plan.priceId,
        [ProductCategory.Services],
      );
      dispatch(planSelectedOnStartSubscriptionSuccess(coupons));
      dispatch(setStartSubscriptionPaymentSummary(accountActionService, uid));
    } catch (error) {
      dispatch(planSelectedOnStartSubscriptionFailure(error.message));
    }
  };
};

export const couponSelectedOnStartSubscription = (
  accountActionService: AccountActionsService,
  uid: string,
  coupon: IStripeCouponWrapper,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();
    const plan = state.accountActions.data.startSubscription.plan;

    if (!plan) {
      throw new Error('Need to select a plan in order to select a coupon');
    }

    dispatch({
      type: COUPON_SELECTED_ON_START_SUBSCRIPTION,
      payload: coupon,
    });
    dispatch(setStartSubscriptionPaymentSummary(accountActionService, uid));
  };
};

export const applyCouponCheckedOnStartSubscription = (accountActionService: AccountActionsService, uid: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: APPLY_COUPON_CHECKED_ON_START_SUBSCRIPTION,
    });
    dispatch(setStartSubscriptionPaymentSummary(accountActionService, uid, /*checkApplyCoupon*/true));
  };
};

export const consentCheckedOnStartSubscription = () => ({
  type: CONSENT_CHECKED_ON_START_SUBSCRIPTION,
});

export const loadStartSubscriptionModal = (
  accountActionService: AccountActionsService,
  uid: string,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();
    const currentGym = currentGymSelector(state);
    dispatch(loadStartSubscriptionModalRequest());
    try {
      const setupIntent = await accountActionService.createSetupIntent(uid,currentGym.name);
      const stripe = await loadStripe(config.stripe.publishableKey, { stripeAccount: currentGym.accountId });
      dispatch(loadStartSubscriptionModalSuccess(setupIntent, stripe));
    } catch (error) {
      dispatch(loadStartSubscriptionModalFailure(error.message));
    }
  };
};

export const loadBuyMerchandiseModal = (
  accountActionService: AccountActionsService,
  uid: string,
  invoiceNo?: string,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();
    const currentGym = currentGymSelector(state);
    dispatch(loadBuyMerchandiseModalRequest());
    try {
      const stripe = await loadStripe(config.stripe.publishableKey, { stripeAccount: currentGym.accountId });

      let invoice: IInvoice | undefined;

      if (invoiceNo) {
        invoice = await accountActionService.getInvoice(currentGym.name, uid, invoiceNo);
      }

      if (!stripe) {
        throw new Error('Failed to load stripe');
      }

      const canEditInvoice = !invoice || invoice?.status === 'draft';

      const merchandise: IPrice[] = canEditInvoice ?
        await accountActionService.getMerchandise(currentGym.name, uid) :
        [];
      const coupons: IStripeCouponWrapper[] = canEditInvoice ?
        await accountActionService.getCoupons(
          currentGym.name,
          uid,
          /*priceId*/ undefined,
          [ProductCategory.Goods],
        ) :
        [];

      dispatch(loadBuyMerchandiseModalSuccess(merchandise, coupons, stripe, invoice));
    } catch (error) {
      dispatch(loadBuyMerchandiseModalFailure(error.message));
    }
  };
};

export const closeBuyMerchandiseModal = () => {
  return async (dispatch: Dispatch<ICloseBuyMerchandiseModalSuccessAction>, getState: () => IAppStore) => {
    const invoice = getBuyMerchandiseInvoice(getState());

    dispatch({
      type: CLOSE_BUY_MERCHANDISE_MODAL,
      payload: {
        showConfirmActionInvoiceModal: invoice?.status === 'draft' || invoice?.status === 'open',
      },
    });
  };
};

export const changeMerchandiseNote = (note: string) => {
  return async (dispatch: Dispatch<IChangeMerchandiseNoteAction>) => {
    dispatch({
      type: CHANGE_MERCHANDISE_NOTE,
      payload: note,
    });
  };
};

export const openConfirmActionInvoiceModal = () => {
  return async (dispatch: Dispatch<IOpenConfirmActionInvoiceModalAction>) => {
    dispatch({
      type: OPEN_CONFIRM_ACTION_INVOICE_MODAL,
    });
  };
};

export const setTerminalStatus = (status: ITerminalStatus) => {
  return async (dispatch: Dispatch<ISetTerminalStatusAction>) => {
    dispatch({
      type: SET_TERMINAL_STATUS,
      payload: status,
    });
  };
};

export const handleTerminalDisconnect = () => {
  return async (dispatch: Dispatch<IHandleTerminalDisconnect>) => {
    dispatch({
      type: HANDLE_TERMINAL_DISCONNECT,
    });
  };
};

export const updateMerchandiseInvoice = (accountActionService: AccountActionsService,
                                         uid: string,
                                         item: IInvoiceItem,
                                         ) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();
    const invoice = getBuyMerchandiseInvoice(state);
    const currentGym = currentGymSelector(state);

    dispatch(updateMerchandiseInvoiceRequest(item));
    try {
      const updatedInvoice =
        await accountActionService.updateInvoiceItem(currentGym.name, uid, item, invoice?.invoiceNo);
      dispatch(updateMerchandiseInvoiceSuccess(updatedInvoice));
    } catch (error) {
      dispatch(updateMerchandiseInvoiceFailure(error.message));
    }
  };
};

export const selectMerchandiseCoupon = (
  accountActionService: AccountActionsService,
  uid: string,
  coupon: IStripeCouponWrapper,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();
    const invoice = getBuyMerchandiseInvoice(state);
    const currentGym = currentGymSelector(state);
    dispatch(selectMerchandiseCouponRequest(coupon));
    try {
      const updatedInvoice =
        await accountActionService.updateInvoiceCoupon(currentGym.name, invoice!.invoiceNo, coupon.id);
      dispatch(selectMerchandiseCouponSuccess(updatedInvoice));
    } catch (error) {
      dispatch(selectMerchandiseCouponFailure(error.message));
    }
  };
};

export const payMerchandise = (
  uid: string,
  accountActionService: AccountActionsService,
  membersService: MembersService,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();
    const invoice = getBuyMerchandiseInvoice(state);
    const currentGym = currentGymSelector(state);
    const note = getMerchandiseNoteSelector(state);

    dispatch(payMerchandiseRequest());
    try {
      const finalisedInvoice =
        await accountActionService.finaliseAndPayInvoice(currentGym.name, invoice!.invoiceNo, undefined, note);
      const membersAccountInfo = await getMemberDetails(membersService, uid);
      dispatch(payMerchandiseSuccess(finalisedInvoice, membersAccountInfo));
    } catch (error) {
      const invoiceInFailedState = await accountActionService.getInvoice(currentGym.name, uid, invoice!.invoiceNo);
      dispatch(payMerchandiseFailure(false, error.message, invoiceInFailedState));
    }
  };
};

export const payMerchandiseWithManualCard = (
  accountActionService: AccountActionsService,
  membersService: MembersService,
  uid: string,
  stripe: Stripe | null,
  elements: StripeElements | null,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();
    const note = getMerchandiseNoteSelector(state);
    const invoice = getBuyMerchandiseInvoice(state);
    const currentGym = currentGymSelector(state);

    dispatch(payMerchandiseRequest());
    try {
      const setupIntent = await confirmSetupIntent(elements, stripe);
      if (!setupIntent.setupIntent) {
        dispatch(payMerchandiseFailure(false, setupIntent.errorMessage));
        return;
      }

      const finalisedSetupIntent: IPaymentSetupData =
        await accountActionService.finaliseSetupIntent(currentGym, uid, setupIntent.setupIntent.id);

      if (!finalisedSetupIntent.paymentMethod) {
        throw new Error(`No payment method attached to finalised setup intent
         ${finalisedSetupIntent.setupIntentId}`);
      }

      const finalisedInvoice =
        await accountActionService.finaliseAndPayInvoice(
          currentGym.name,
          invoice!.invoiceNo,
          finalisedSetupIntent.paymentMethod,
          note,
        );
      const membersAccountInfo = await getMemberDetails(membersService, uid);
      dispatch(payMerchandiseSuccess(finalisedInvoice, membersAccountInfo));
    } catch (error) {
      await handleMerchandisePaymentFailure(
        dispatch,
        accountActionService,
        membersService,
        currentGym.name,
        uid,
        invoice!.invoiceNo,
        error,
      );
    }
  };
};

const handleMerchandisePaymentFailure = async (
  dispatch: Dispatch<IPayMerchandiseFailureAction>,
  accountActionService: AccountActionsService,
  membersService: MembersService,
  gymName: string,
  uid: string,
  invoiceNo: string,
  error: {message: string | undefined},
) => {
  let invoiceInFailedState: IInvoice | undefined;

  try {
    invoiceInFailedState = await accountActionService.getInvoice(gymName, uid, invoiceNo);
    const membersAccountInfo = await getMemberDetails(membersService, uid);
    dispatch(payMerchandiseFailure(true, error.message,
      invoiceInFailedState, membersAccountInfo.userInfo.defaultPaymentMethod));
  } catch (e) {
    dispatch(payMerchandiseFailure(false, error.message));
  }
};

export const loadStripePaymentData = (uid: string, accountActionService: AccountActionsService) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();

    dispatch({
      type: LOAD_STRIPE_PAYMENT_DATA_REQUEST,
    } as ILoadStripePaymentDataRequestAction);
    try {
      const currentGym = currentGymSelector(state);
      const setupIntent = await accountActionService.createSetupIntent(uid,currentGym.name);

      dispatch({
        type: LOAD_STRIPE_PAYMENT_DATA_SUCCESS,
        payload: {
          setupIntent,
        },
      } as ILoadStripePaymentDataSuccessAction);
    } catch (error) {
      dispatch({
        type: LOAD_STRIPE_PAYMENT_DATA_FAILURE,
        payload: error,
      } as ILoadStripePaymentDataFailureAction);
    }
  };
};

export const setupTerminal = (uid: string, accountActionService: AccountActionsService) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();

    dispatch({
      type: SETUP_TERMINAL_REQUEST,
    } as ISetupTerminalRequestAction);
    try {
      const currentGym = currentGymSelector(state);
      let setupIntent = state.accountActions.data.setupIntent;

      dispatch(setTerminalStatus('setting_up'));
      if (!setupIntent) {
        setupIntent = await accountActionService.createSetupIntent(uid,currentGym.name);
      }

      let terminal: Terminal | null | undefined = state.accountActions.data.terminal;

      if (!terminal) {
        const terminalSdk = await loadStripeTerminal();

        const statusMap =
          new Map<string, ITerminalStatus>([
            ['ready', 'ready'],
            ['not_ready', 'disconnected'],
            ['waiting_for_input', 'capturing'],
            ['processing', 'processing_card_capture'],
            ['connecting', 'connecting'],
            ['connected', 'ready'],
            ['not_connected', 'disconnected'],
          ]);

        terminal = terminalSdk?.create({
          onConnectionStatusChange: (e) => dispatch(setTerminalStatus(statusMap.get(e.status)!)),
          onFetchConnectionToken: () =>
            accountActionService.fetchConnectionToken(currentGym).then((token) => token.secret),
          onUnexpectedReaderDisconnect: (e) => dispatch(handleTerminalDisconnect()),
          onPaymentStatusChange: (e) => {
            dispatch(setTerminalStatus(statusMap.get(e.status)!));
          },
          readerBehavior: { allowCustomerCancel: true },
        });
      }

      if (!terminal) {
        throw Error('Failed to create terminal');
      }

      dispatch(setTerminalStatus('scanning'));
      if (!terminal.getConnectedReader()) {
        const readers = await terminal.discoverReaders({ simulated: false }) as DiscoverResult;
        if (readers.discoveredReaders.length === 0) {
          throw new Error('No readers found');
        }

        // @ts-ignore
        const { error } = await terminal.connectReader(readers.discoveredReaders[0]);
        if (error) {
          throw new Error('Failed to connect to reader, make sure the reader is online and on the same network');
        }
      }

      const connectedReader = terminal.getConnectedReader();

      dispatch({
        type: SETUP_TERMINAL_SUCCESS,
        payload: {
          setupIntent,
          terminal,
          connectedReader,
        },
      } as ISetupTerminalSuccessAction);
    } catch (error) {
      dispatch({
        type: SETUP_TERMINAL_FAILURE,
        payload: error.message,
      } as ISetupTerminalFailureAction);
    }
  };
};

export const discardMerchandiseInvoice = (
  uid: string,
  membersService: MembersService,
  accountActionService: AccountActionsService,
) => {

  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();
    const currentGym = currentGymSelector(state);
    const invoice = getBuyMerchandiseInvoice(state);

    dispatch({
      type: HANDLE_MERCHANDISE_DRAFT_INVOICE_REQUEST,
    } as IHandleMerchandiseDraftInvoiceRequestAction);

    try {
      if (!invoice) {
        throw new Error('No invoice found');
      }

      if (invoice.status === 'draft') {
        await accountActionService.deleteInvoice(currentGym.name, invoice.invoiceNo);
      }

      if (invoice.status === 'open') {
        await accountActionService.deleteInvoice(currentGym.name, invoice.invoiceNo);
      }

      const membersAccountInfo = await getMemberDetails(membersService, uid);

      dispatch({
        type: HANDLE_MERCHANDISE_DRAFT_INVOICE_SUCCESS,
        payload: {
          member: membersAccountInfo,
        },
      } as IHandleMerchandiseDraftInvoiceSuccessAction);
    } catch (error) {
      dispatch({
        type: HANDLE_MERCHANDISE_DRAFT_INVOICE_FAILURE,
        payload: error,
      } as IHandleMerchandiseDraftInvoiceFailureAction);
    }
  };
};

export const keepMerchandiseInvoice = (
  uid: string,
  membersService: MembersService,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();
    const invoice = getBuyMerchandiseInvoice(state);

    dispatch({
      type: HANDLE_MERCHANDISE_DRAFT_INVOICE_REQUEST,
    } as IHandleMerchandiseDraftInvoiceRequestAction);

    try {
      if (!invoice) {
        throw new Error('No invoice found');
      }

      const membersAccountInfo = await getMemberDetails(membersService, uid);

      dispatch({
        type: HANDLE_MERCHANDISE_DRAFT_INVOICE_SUCCESS,
        payload: {
          member: membersAccountInfo,
        },
      } as IHandleMerchandiseDraftInvoiceSuccessAction);
    } catch (error) {
      dispatch({
        type: HANDLE_MERCHANDISE_DRAFT_INVOICE_FAILURE,
        payload: error,
      } as IHandleMerchandiseDraftInvoiceFailureAction);
    }
  };
};

export const cleanupTerminal = () => {
  return async (dispatch: Dispatch<ICleanupTerminal>, getState: () => IAppStore) => {
    const state = getState();
    const terminal = state.accountActions.data.terminal;

    await terminal?.disconnectReader();
    await terminal?.clearCachedCredentials();
    await terminal?.cancelCollectSetupIntentPaymentMethod();

    dispatch({
      type: CLEANUP_TERMINAL,
    } as ICleanupTerminal);
  };
};

export const cancelCardCapture = () => {
  return async (dispatch: Dispatch<ICleanupTerminal>, getState: () => IAppStore) => {
    const state = getState();
    const terminal = state.accountActions.data.terminal;

    await terminal?.cancelCollectSetupIntentPaymentMethod();
  };
};

export const closeStartSubscriptionModal = () => ({
  type: CLOSE_START_SUBSCRIPTION_MODAL,
});

interface IToggleAddFreeWeeksAction {
  type: typeof TOGGLE_ADD_FREE_WEEKS_MODAL;
  payload: IFreeWeekPhaseInfo;
}

interface ILoadStripePaymentDataRequestAction {
  type: typeof LOAD_STRIPE_PAYMENT_DATA_REQUEST;
}

interface ILoadStripePaymentDataSuccessAction {
  type: typeof LOAD_STRIPE_PAYMENT_DATA_SUCCESS;
  payload: {
    setupIntent: IPaymentSetupData,
    connectionToken?: IConnectionToken,
  };
}

interface ILoadStripePaymentDataFailureAction {
  type: typeof LOAD_STRIPE_PAYMENT_DATA_FAILURE;
  payload: string;
}

interface ISetupTerminalRequestAction {
  type: typeof SETUP_TERMINAL_REQUEST;
  payload: string;
}

interface ILoadTerminalReadersAction {
  type: typeof LOAD_TERMINAL_READERS;
  payload: Reader[];
}

interface ISetupTerminalSuccessAction {
  type: typeof SETUP_TERMINAL_SUCCESS;
  payload: {
    setupIntent: IPaymentSetupData,
    terminal: Terminal,
    connectedReader: Reader;
  };
}

interface ISetupTerminalFailureAction {
  type: typeof SETUP_TERMINAL_FAILURE;
  payload: string;
}

interface IConnectToTerminalReaderRequestAction {
  type: typeof CONNECT_TO_TERMINAL_READER_REQUEST;
  payload: string;
}

interface IConnectToTerminalReaderSuccessAction {
  type: typeof CONNECT_TO_TERMINAL_READER_SUCCESS;
}

interface IConnectToTerminalReaderFailureAction {
  type: typeof CONNECT_TO_TERMINAL_READER_FAILURE;
  payload: string;
}

interface IHandleMerchandiseDraftInvoiceRequestAction {
  type: typeof HANDLE_MERCHANDISE_DRAFT_INVOICE_REQUEST;
}

export interface IHandleMerchandiseDraftInvoiceSuccessAction {
  type: typeof HANDLE_MERCHANDISE_DRAFT_INVOICE_SUCCESS;
  payload: {
    member: UserAccountDetail,
  };
}

interface IHandleMerchandiseDraftInvoiceFailureAction {
  type: typeof HANDLE_MERCHANDISE_DRAFT_INVOICE_FAILURE;
  payload: string;
}

interface ICleanupTerminal {
  type: typeof CLEANUP_TERMINAL;
}

export const toggleAddFreeWeeksModal = (freeWeeksPhaseInfo?: IFreeWeekPhaseInfo) => {
  return async (dispatch: Dispatch<AccountActionTypes>) => {
    dispatch({
      type: TOGGLE_ADD_FREE_WEEKS_MODAL,
      payload: freeWeeksPhaseInfo,
    });
  };
};

interface ISetFreeWeekPlanIdAction {
  type: typeof SET_FREE_WEEK_PLAN_ID;
  payload: string;
}

export const setFreeWeekPlanId = (freeWeekPlanId: string): ISetFreeWeekPlanIdAction => {
  return {
    type: SET_FREE_WEEK_PLAN_ID,
    payload: freeWeekPlanId,
  };
};

interface ISetFreeWeekEarliestEnd {
  type: typeof SET_EARLIEST_FREE_WEEK_END;
  payload: Moment;
}

export const setFreeWeekEarliestStart = (earliestStart: Moment): ISetFreeWeekEarliestEnd => {
  return {
    type: SET_EARLIEST_FREE_WEEK_END,
    payload: earliestStart,
  };
};

interface ISetFreeWeekEndDateAction {
  type: typeof SET_FREE_WEEK_END_DATE;
  payload: Moment;
}

export const setFreeWeekEndDate = (endDate: Moment): ISetFreeWeekEndDateAction => {
  return {
    type: SET_FREE_WEEK_END_DATE,
    payload: endDate,
  };
};

const setStartSubscriptionPaymentSummary = (
  accountActionsService: AccountActionsService,
  uid: string,
  applyCouponClicked?: boolean,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    dispatch(setStartSubscriptionPaymentSummaryRequest());
    const state = getState();
    const { plan, coupon, couponChecked } = state.accountActions.data.startSubscription;

    try {
      let paymentSummary: ISubscriptionPaymentSummary | undefined;
      if (shouldGetStartSubscriptionPaymentSummarySelector(state)) {
        paymentSummary = await accountActionsService
           .getPaymentSummary(uid, plan!.priceId, coupon?.id);

        const shouldAutomaticallyApplyCoupon = !applyCouponClicked && !couponChecked && !paymentSummary.coupon;
        if (shouldAutomaticallyApplyCoupon) {
          dispatch({
            type: APPLY_COUPON_CHECKED_ON_START_SUBSCRIPTION,
          });
          return;
        }
      }

      dispatch(setStartSubscriptionPaymentSummarySuccess(paymentSummary));
      return;
    } catch (error) {
      dispatch(setStartSubscriptionPaymentSummaryFailure());
    }
  };
};

export const setStartSubscriptionPaymentSummaryRequest = (): ISetStartSubscriptionPaymentSummaryRequestAction => {
  return {
    type: SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_REQUEST,
  };
};

export const setStartSubscriptionPaymentSummarySuccess = (
  paymentSummary: ISubscriptionPaymentSummary | undefined,
): ISetStartSubscriptionPaymentSummarySuccessAction => {
  return {
    type: SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_SUCCESS,
    payload: paymentSummary,
  };
};

export const closeConfirmSaveDraftModal = () => {
  return async (dispatch: Dispatch<ICloseConfirmSaveDraftModalAction>) => {
    dispatch({
      type: CLOSE_CONFIRM_SAVE_DRAFT_MODAL,
    });
  };
};

export const setStartSubscriptionPaymentSummaryFailure = (): ISetStartSubscriptionPaymentSummaryFailureAction => {
  return {
    type: SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_FAILURE,
  };
};

export const payMerchandiseWithPos = (
  accountActionsService: AccountActionsService,
  membersService: MembersService,
  uid: string,
) => {
  return async (dispatch: any, getState: () => IAppStore) => {
    const state = getState();
    const currentGym = currentGymSelector(state);
    const originalInvoice = getBuyMerchandiseInvoice(getState());
    const terminal = state.accountActions.data.terminal;
    try {
      dispatch(payMerchandiseRequest());

      if (!terminal) {
        throw new Error('No terminal configured');
      }

      dispatch(setTerminalStatus('processing_payment'));
      const {
        invoice,
        paymentIntent,
      } = await accountActionsService.finaliseInvoice(currentGym.name,
        originalInvoice!.invoiceNo, '') as any;

      if (!paymentIntent) {
        throw new Error('Failed to correctly finalise the invoice');
      }

      const {
        error: collectError,
        paymentIntent: collectPaymentIntent,
      } = await terminal.collectPaymentMethod(paymentIntent.client_secret) as any;
      if (collectError) {
        throw new Error(`Failed to start payment process`);
      }

      const {
        error: paymentError,
      } = await terminal.processPayment(collectPaymentIntent) as any;
      if (paymentError) {
        throw new Error(`Failed to collect payment: ${paymentError.message}`);
      }

      const paidInvoice =
        await accountActionsService.markInvoiceAsPaidOutOfBand(currentGym.name, invoice.invoiceNo) as any;
      const membersAccountInfo = await getMemberDetails(membersService, uid);
      dispatch(payMerchandiseSuccess(paidInvoice, membersAccountInfo));
    } catch (error) {
      const invoiceInFailedState = await accountActionsService.getInvoice(
        currentGym.name, uid, originalInvoice!.invoiceNo);
      dispatch(payMerchandiseFailure(false, error.message, invoiceInFailedState));
    }
  };
};

interface ISetStartSubscriptionPaymentSummaryRequestAction {
  type: typeof SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_REQUEST;
}

interface ISetStartSubscriptionPaymentSummarySuccessAction {
  type: typeof SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_SUCCESS;
  payload: ISubscriptionPaymentSummary | undefined;
}

interface ISetStartSubscriptionPaymentSummaryFailureAction {
  type: typeof SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_FAILURE;
}

interface ILoadBuyMerchandiseModalRequestAction {
  type: typeof LOAD_BUY_MERCHANDISE_MODAL_REQUEST;
}

interface ILoadBuyMerchandiseModalSuccessAction {
  type: typeof LOAD_BUY_MERCHANDISE_MODAL_SUCCESS;
  payload: {
    merchandise: IPrice[];
    coupons: IStripeCouponWrapper[];
    stripe: Stripe,
    invoice?: IInvoice,
  };
}

interface ICloseBuyMerchandiseModalSuccessAction {
  type: typeof CLOSE_BUY_MERCHANDISE_MODAL;
  payload: {
    showConfirmActionInvoiceModal: boolean,
  };
}

interface IChangeMerchandiseNoteAction {
  type: typeof CHANGE_MERCHANDISE_NOTE;
  payload: string;
}

interface IOpenConfirmActionInvoiceModalAction {
  type: typeof OPEN_CONFIRM_ACTION_INVOICE_MODAL;
}

interface ISetTerminalStatusAction {
  type: typeof  SET_TERMINAL_STATUS;
  payload: ITerminalStatus;
}

interface IHandleTerminalDisconnect {
  type: typeof  HANDLE_TERMINAL_DISCONNECT;
}

interface ICloseConfirmSaveDraftModalAction {
  type: typeof CLOSE_CONFIRM_SAVE_DRAFT_MODAL;
}

interface ILoadBuyMerchandiseModalFailureAction {
  type: typeof LOAD_BUY_MERCHANDISE_MODAL_FAILURE;
  payload: string;
}

interface IUpdateMerchandiseInvoiceRequestAction {
  type: typeof UPDATE_MERCHANDISE_INVOICE_REQUEST;
  payload: {
    invoiceItem: IInvoiceItem,
  };
}

interface IUpdateMerchandiseInvoiceSuccessAction {
  type: typeof UPDATE_MERCHANDISE_INVOICE_SUCCESS;
  payload: {
    invoice: IInvoice,
  };
}

interface IUpdateMerchandiseInvoiceFailureAction {
  type: typeof UPDATE_MERCHANDISE_INVOICE_FAILURE;
  payload: string;
}

interface IPayMerchandiseRequestAction {
  type: typeof PAY_MERCHANDISE_REQUEST;
}

export interface IPayMerchandiseSuccessAction {
  type: typeof PAY_MERCHANDISE_SUCCESS;
  payload: {
    invoice: IInvoice,
    member: UserAccountDetail,
  };
}

export interface IPayMerchandiseFailureAction {
  type: typeof PAY_MERCHANDISE_FAILURE;
  payload: {
    shouldCreateNewSetupIntent?: boolean;
    error?: string;
    invoice?: IInvoice;
    paymentMethod?: ICustomerPaymentMethod;
  };
}

interface ISelectMerchandiseCouponRequestAction {
  type: typeof SELECT_MERCHANDISE_COUPON_REQUEST;
  payload: {
    coupon: IStripeCouponWrapper,
  };
}

interface ISelectMerchandiseCouponSuccessAction {
  type: typeof SELECT_MERCHANDISE_COUPON_SUCCESS;
  payload: {
    updatedInvoice: IInvoice,
  };
}

interface ISelectMerchandiseCouponFailureAction {
  type: typeof SELECT_MERCHANDISE_COUPON_FAILURE;
  payload: string;
}
