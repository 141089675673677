import React from 'react';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';
import DatePickerFilter from './datePickerComponent';

class DateOfBirthFilter extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <DatePickerFilter startDate={this.props.dateOfBirthFilterDates.startDate}
      endDate={this.props.dateOfBirthFilterDates.endDate} onChange={this.props.onChange}
      type={'DOB'}/>
    );
  }
}
const mapDispatchToProps = {
};

const mapStateToProps = (state: IAppStore) => {
  const { members } = state;
  const { filtered } = members.view;
  const dateOfBirthDates = filtered.find((filter) => filter.id === 'dateOfBirth');
  return {
    filtered,
    dateOfBirthFilterDates: dateOfBirthDates ? dateOfBirthDates.value : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateOfBirthFilter);
