import { IClientSideLogger } from 'fitbeat.gym-core';
// TODO: this file is shared between zone screens and web-admin, it should move to a shared client-side lib
/* tslint:disable no-console */
/* eslint-disable no-console */
export class DebugLogger implements IClientSideLogger {
  constructor(private name: string) {
    this.trace(`created DebugLogger(${name})`);
  }

  public info(msg: string) {
    console.info(`${this.name} ${msg}`);
  }

  public error(e: Error, customErrorMessage?: string): void {
    console.error(`${this.name} ${e?.message}`, customErrorMessage);
  }

  public warn(msg: string): void {
    console.warn(`${this.name} ${msg}`);
  }

  public trace(msg: string): void {
    console.log(`${this.name} ${msg}`);
  }
}
