import { Stripe } from '@stripe/stripe-js';
import { Reader, Terminal } from '@stripe/terminal-js';
import { UserWorkoutSession } from 'fitbeat.gym-core';
import {
  ICancelSubscriptionResponse,
  IInvoice,
  IPaymentSetupData,
  IPhaseActionInfo,
  IPrice,
  IStripeCouponWrapper,
  ISubscriptionPaymentSummary,
  UserAccountScheduleActionInfo,
} from 'fitbeat.models';
import moment, { Moment } from 'moment';

export interface IStartSubscription {
  plan: IPrice | undefined;
  couponsForPlan: IStripeCouponWrapper[];
  coupon: IStripeCouponWrapper | undefined;
  couponChecked: boolean;
  consentChecked: boolean;
  stripe: Stripe | null;
  couponPlaceholder: string;
  isLoadingCoupons: boolean;
  paymentSummary: ISubscriptionPaymentSummary | undefined;
}

export type ITerminalStatus = 'setting_up' | 'scanning' | 'connecting' | 'disconnected' | 'ready' |
  'capturing' | 'processing_card_capture' | 'processing_payment';

export interface IBuyMerchandise {
  merchandise: IPrice[];
  invoice: IInvoice | undefined;
  error: string | null;
  isLoadingInvoice: boolean;
  coupons: IStripeCouponWrapper[] | undefined;
  selectedCoupon: IStripeCouponWrapper | undefined;
  isProcessingPayment: boolean;
  success: string | undefined;
  stripe: Stripe | null;
  note: string | undefined;
  showConfirmActionInvoiceModal: boolean;
  showMerchandiseSuccessModal: boolean;
}
export interface IMemberAccountActionsData {
    memberAccountShedule: UserAccountScheduleActionInfo;
    cancelSubscriptionDetail?: ICancelSubscriptionResponse;
    memberWorkoutHistory: UserWorkoutSession[];
    startSubscription: IStartSubscription;
    buyMerchandise: IBuyMerchandise;
    setupIntent: IPaymentSetupData | undefined;
    terminal: Terminal | null;
    readers: Reader[];
    connectedReader: Reader | null;
}
export interface IMemberAccountActionsState {
    data: IMemberAccountActionsData;
    view: IMemberAccountActionsUIView;
}

export interface IMemberAccountActionsUIView {
    terminalStatus: ITerminalStatus;
    error: null | string;
    isFetching: boolean;
    isLoadingTerminal: boolean;
    terminalError: string  | null;
    showAddPauseModal: boolean;
    showAddFreeWeeksModal: boolean;
    addFreezeInfo?: IPhaseActionInfo;
    addFreeWeekInfo?: IPhaseActionInfo;
    showCancelSubscriptionModal: boolean;
    showStartSubscriptionModal: boolean;
    showStartSubscriptionSuccessModal: boolean;
    showBuyMerchandiseModal: boolean;
    isLoadingBuyMerchandiseModal: boolean;
    validationError: null | string;
    successMessage: null | string;
    showCancelPauseModal: boolean;
    pauseEarliestEnd: Moment;
    pausePlanId: string;
    pauseStartDate: Moment;
    pauseEndDate: Moment;
    allowedPlanIds?: Array<{name: string | null, price: string, accountId: string}>;
    allowedFreeWeekPlanIds?: Array<{name: string, price: string, accountId: string}>;
    allowedStartSubscriptionPlans?: IPrice[];
    isLoadingCancelSubscription: boolean;
    isLoadingStartSubscription: boolean;
    cancelSubscriptionError: null | string;
    startSubscriptionError: null | string;
    freeWeekEarliestEnd: Moment;
    freeWeekPlanId: string;
    freeWeekStartDate: Moment;
    freeWeekEndDate: Moment;
}

export const initialState: IMemberAccountActionsState = {
    data: {
      terminal: null,
      readers: [],
      connectedReader: null,
        memberAccountShedule: {
            cancelSubscriptionInfo: {
                date: new Date(),
                enabled: false,
                defaultPenaltyAmount: 0,
                message: 'Are you sure you want to cancel?',
                enabledCancellationFeeOverride: false,
            },
            pauseSubscriptionInfo: {
                allowedPlanIds: [],
                enabled: false,
            },
            freeWeekSubscriptionInfo: {
                allowedPlanIds: [],
                enabled: false,
            },
            startSubscriptionInfo: {
              enabled: false,
              message: '',
              allowedPlans: [],
            },
            subscribeEnabled: false,
            subscriptionSchedule: undefined,
            upgradeEnabled: false,
        },
      setupIntent: undefined,
        memberWorkoutHistory: [],
        startSubscription: {
          plan: undefined,
          stripe: null,
          couponChecked: false,
          coupon: undefined,
          couponsForPlan: [],
          couponPlaceholder: '',
          isLoadingCoupons: false,
          consentChecked: false,
          paymentSummary: undefined,
        },
        buyMerchandise: {
          merchandise: [],
          error: null,
          invoice: undefined,
          isLoadingInvoice: false,
          coupons: [],
          selectedCoupon: undefined,
          isProcessingPayment: false,
          success: undefined,
          stripe: null,
          note: undefined,
          showConfirmActionInvoiceModal: false,
          showMerchandiseSuccessModal: false,
        },
    },
    view: {
        terminalStatus: 'disconnected',
        terminalError: null,
        error: null,
        isFetching: false,
        isLoadingTerminal: false,
        showAddPauseModal: false,
        showAddFreeWeeksModal: false,
        showCancelSubscriptionModal: false,
        showCancelPauseModal: false,
        showStartSubscriptionModal: false,
        validationError: null,
        successMessage: null,
        pauseEarliestEnd: moment(),
        pausePlanId: '',
        pauseStartDate: moment(),
        pauseEndDate: moment(),
        isLoadingCancelSubscription: false,
        isLoadingStartSubscription: false,
        cancelSubscriptionError: null,
        startSubscriptionError: null,
        freeWeekEarliestEnd: moment(),
        freeWeekPlanId: '',
        freeWeekStartDate: moment(),
        freeWeekEndDate: moment(),
        allowedStartSubscriptionPlans: [],
        showBuyMerchandiseModal: false,
        showStartSubscriptionSuccessModal: false,
        isLoadingBuyMerchandiseModal: false,
    },
};
