import React, { Component } from 'react';

interface IProps {
  onClick: () => void;
  isChecked: boolean;
  labelText: string | undefined;
  labelClass: string;
  disabled?: boolean;
}

export default class Checkbox extends Component<IProps> {
  public render() {
    return (
      <div style={{ display: 'flex' }}>
        <div
          onClick={!this.props.disabled ? this.props.onClick : undefined}
          className={`checkbox-item${this.props.disabled ? ' disabled' : ''}`}
        >
          <span className={this.props.isChecked ? 'checked' : 'unchecked'}/>
        </div>
        {this.props.labelText &&
          <p className={this.props.labelClass}>
            {this.props.labelText}
          </p>
        }
      </div>
    );
  }
}
