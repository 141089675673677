import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import { IAppStore } from '../../rootReducer';
import Dropdown from '../../shared/components/Dropdown';

class GreaterThanLessThanFilter extends Component<any, any> {
  public render() {
    const options = [
      { label: 'Greater Than >', value: 'greaterThan' },
      { label: 'Less Than <', value: 'lessThan' },
      { label: 'Equals =', value: 'equals' },
    ];
    const { onChange, aggregateFilters } = this.props;
    const operatorValue = options.filter((item) => item.value === aggregateFilters.operator);
    return (
      <div className='modal-content-columns'>
        <Dropdown
          onChange={(option: any) => {
            const updateFilter = {
              ...aggregateFilters,
              operator: option.value,
            };
            return onChange(updateFilter);
          }}
          options={options}
          value={aggregateFilters.operator ? operatorValue : []}
          className='report-header-single-select select-container'
          classNamePrefix='select'
          placeholder='Select...'
          isSearchable={false}
        />
        <Input
          style={{ height: 29, marginLeft: 5 }}
          value={aggregateFilters.operatorValue}
          type={'number'}
          onChange={(e) => {
            const searchText = e.target.value;
            const updateFilter = {
              ...aggregateFilters,
              operatorValue: searchText,
            };
            return onChange(updateFilter);
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state: IAppStore, props: any) => {
  const { members } = state;
  const { filtered } = members.view;
  const filters = filtered.find((filter) => filter.id === props.columnId);
  return {
    aggregateFilters: filters ? filters.value : {},
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GreaterThanLessThanFilter);
