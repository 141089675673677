import React from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { IAppStore } from '../../rootReducer';
import {
  saveReport,
  setReportName,
  toggleConfirmationModal,
  toggleReportModal,
} from '../actions';
class SaveReportModal extends React.Component<any, any> {
  public render() {
    return (
      <div>
        <Modal
          isOpen={this.props.showSaveReportModal}
          className='modal-content'
          overlayClassName='modal-overlay'
          ariaHideApp={false}
          contentLabel='Columns'>
          <div className='column-label content-margin'>
            <h2
              className='column-header'>
              {!this.props.isClone ? 'Save Report' : 'Save as Report'}
            </h2>
            <button
              className='modal-close-button'
              onClick={this.props.toggleReportModal}>
              X
                </button>
          </div>
          <React.Fragment>
            <div className='modal-content-columns'>
              <InputGroup>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>Report Name</InputGroupText>
                </InputGroupAddon>
                <Input value={this.props.tempReportNameStore}
                  placeholder='Report name'
                  onChange={this.props.setReportName} />
              </InputGroup>
            </div>
            {this.props.isFetching ?
            <div style={{ textAlign: 'center' }}>
              <Loader
                type='ThreeDots'
                color='#FF621D'
                height={50}
                width={50}
                timeout={3000}
              />
            </div> :
              <div className='float-right'>
                <button className='jr-btn-default' style={{ float: 'right', width: '20%', marginTop: 10 }}
                  onClick={this.props.isNewReport || this.props.isClone ?
                    () => this.props.saveReport(this.props.tempReportNameStore, this.props.memberTableColumns,
                      this.props.filtered, this.props.sortOrder, this.props.reportType) :
                    () => this.props.toggleConfirmationModal(false)}
                  disabled={this.props.tempReportNameStore ? false : true}>Save</button>
                {this.props.error &&
                  <label style={{ color: 'red', float: 'left' }}>{this.props.error}</label>
                }
              </div>}
            </React.Fragment>
        </Modal>
      </div>
    );
  }
}
const mapDispatchToProps = {
  saveReport,
  toggleReportModal,
  setReportName,
  toggleConfirmationModal,
};
const mapStateToProps = (state: IAppStore) => {
  const { members } = state;
  const { memberColumnData, reportName, reportId, reportType } = members.data.activeReport;
  const { showSaveReportModal, isNewReport, showConfirmationModal, isClone, isFetching, error,
    tempReportNameStore } = members.view;
  const userProfiles = members.data.memberDetailsData.map((member) => member.userSettings);
  const filtered = members.view.filtered;
  const sortOrder = members.view.sortOrder;
  return {
    members: userProfiles,
    memberTableColumns: memberColumnData,
    filtered,
    sortOrder,
    showSaveReportModal,
    reportName,
    isNewReport,
    showConfirmationModal,
    isClone,
    reportId,
    isFetching,
    error,
    tempReportNameStore,
    reportType,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveReportModal);
