import { BaseApiClient, IApiInterface } from 'fitbeat.api-clients';
import { ILeaderboardConfig, ILeaderboardDataViewModel, ILeaderboardInstance } from 'fitbeat.models';
import fetch from 'isomorphic-fetch';

export class LeaderboardsServices extends BaseApiClient {
  public constructor(config: IApiInterface) {
    super(config, fetch, undefined);
    this.fetch = fetch;
  }

  public async getGymLeaderboardConfigs(gymName: string): Promise<ILeaderboardConfig[]> {
    const request = await this.buildGetRequest(`business-tools/gyms/${gymName}/leaderboards`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async updateGymLeaderboardConfigRanking(
    gymName: string,
    leaderboardConfigs: ILeaderboardConfig[],
  ): Promise<ILeaderboardConfig[]> {
    const request = await this.buildPostRequest(`business-tools/gyms/${gymName}/leaderboards/rankings`, {
      leaderboardConfigs,
    }, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async createGymLeaderboard(
    gymName: string,
    newLeaderboardConfig: ILeaderboardConfig,
  ): Promise<void> {
    const request = await this.buildPostRequest(
      `business-tools/gyms/${gymName}/leaderboards`,
      { newLeaderboardConfig },
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    await this.assertHttp200AndGetJsonFromResult(result);
  }

  public async updateGymLeaderboard(
    gymName: string,
    updatedLeaderboardConfig: ILeaderboardConfig,
  ): Promise<void> {
    const request = await this.buildPostRequest(
      `business-tools/gyms/${gymName}/leaderboards/${updatedLeaderboardConfig._id}`,
      { updatedLeaderboardConfig },
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    await this.assertHttp200AndGetJsonFromResult(result);
  }

  public async getGymLeaderboardInstances(
    gymName: string,
    leaderboardConfigId: string,
  ): Promise<ILeaderboardInstance[]> {
    // the gym name is used for auth checks, to validate the request
    const request = await this.buildGetRequest(
      `business-tools/gyms/${gymName}/leaderboards/${leaderboardConfigId}/instances`,
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getGymLeaderboardInstanceData(
    gymName: string,
    leaderboardConfigId: string,
    startDate: string,
    endDate: string,
    team?: string,
  ): Promise<ILeaderboardDataViewModel> {
    // the gym name is used for auth checks, to validate the request
    const query = new URLSearchParams();
    if (startDate) {
      query.append('startDate', startDate);
    }
    if (endDate) {
      query.append('endDate', endDate);
    }
    if (team) {
      query.append('team', team);
    }
    const queryString = query.size ? `?${query.toString()}` : '';

    const request = await this.buildGetRequest(
      `business-tools/gyms/${gymName}/leaderboards/${leaderboardConfigId}/instances/info${queryString}`,
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async userLeaderboardTeamChange(
    gymName: string,
    userProfileId: string,
    leaderboardConfigId: string,
    team: string,
  ): Promise<void> {
    const request = await this.buildPostRequest(
      `business-tools/gyms/${gymName}/leaderboards/${leaderboardConfigId}/team`,
      {
        userProfileId,
        team,
      },
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    await this.assertHttp200AndGetJsonFromResult(result);
  }
}
