import {
  ILeaderboardConfig,
  ILeaderboardDataViewModel,
  ILeaderboardInstance,
  ILeaderboardRanking,
} from 'fitbeat.models';
import moment from 'moment';
import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { IAppStore } from '../rootReducer';
import { Error } from '../shared/error';
import { Loading } from '../shared/loading';
import ArrangeConfigOrder from './components/ArrangeConfigOrder';
import LeaderboardConfigForm from './components/LeaderboardConfigForm';
import LeaderboardInstanceSelectors from './components/LeaderboardInstanceSelectors';
import LeaderboardInstanceStats from './components/LeaderboardInstanceStats';
import LeaderboardsInstanceTable from './components/LeaderboardInstanceTable';
import LeaderboardsTable from './components/LeaderboardsTable';
import {
  setSelectedInstance,
  setSelectedTeam,
  setupInitialLeaderboard,
} from './leaderboardsSlice';

export interface IInstanceRankingRow {
  position: number,
  name: string,
  score: number,
}
interface IProps {
  errorMessage: string | null;
  isFetching: boolean;
  selectedGymName: string | null;
  isFetchingInstanceDetails: boolean;
  leaderboardConfigs: ILeaderboardConfig[];
  selectedLeaderboardConfig: ILeaderboardConfig | null;
  instanceDetails: ILeaderboardDataViewModel | null;
  exportData: IInstanceRankingRow[] | ILeaderboardRanking[];
  enableDataExport: boolean;
  selectedLeaderboardInstance: ILeaderboardInstance | null;
  selectedLeaderboardTeam: string | null;
  loadLeaderboardConfigsList(selectedGymName: string): void;
  setSelectedInstance(instanceDetails: {
    gym: string | null,
    leaderboardInstance: ILeaderboardInstance,
  }): void;
  setSelectedTeam(teamDetails: {
    gym: string | null,
    leaderboardTeam: string | null,
    selectedLeaderboardInstance: ILeaderboardInstance,
  }): void;
}
class Leaderboards extends Component<IProps> {
  public componentDidMount() {
    const { leaderboardConfigs, selectedGymName, loadLeaderboardConfigsList } = this.props;
    if (leaderboardConfigs.length === 0 && selectedGymName) {
      loadLeaderboardConfigsList(selectedGymName);
    }
  }

  public goBackToAllTeams = () => {
    const {
      selectedLeaderboardInstance,
      selectedGymName,
    } = this.props;
    if (selectedLeaderboardInstance) {
      this.props.setSelectedInstance({
        gym: selectedGymName,
        leaderboardInstance: selectedLeaderboardInstance,
      });
      this.props.setSelectedTeam({
        gym: selectedGymName,
        leaderboardTeam: null,
        selectedLeaderboardInstance,
      });
    }
  };

  public render() {
    const {
      errorMessage,
      isFetching,
      exportData,
      instanceDetails,
      enableDataExport,
      leaderboardConfigs,
      selectedLeaderboardTeam,
      isFetchingInstanceDetails,
      selectedLeaderboardConfig,
    } = this.props;
    const isTeamConfig = (selectedLeaderboardConfig && selectedLeaderboardConfig?.teams);
    const isTeamSelected = isTeamConfig && selectedLeaderboardTeam;
    if (errorMessage && !isFetching) {
      return (
        <Error
          errorMessage={errorMessage || undefined}
        />
      );
    }
    return (
      <div className='leaderboard-container' style={{ display: 'flex', flexDirection: 'row' }}>
        <div className='leaderboard-left-section'>
          <div className='leaderboard-left-header-container'>
            <h2 className='dashboard-heading leaderboard-left-heading'>Leaderboards</h2>
            <div className='leaderboard-actions-wrapper'>
              {leaderboardConfigs.length && !isFetching
                ? (
                  <div className='leaderboard-actions-container row align-items-center'>
                    <ArrangeConfigOrder />
                  </div>
                ) : null}
              {!isFetching
                ? (
                  <div className='leaderboard-actions-container row align-items-center'>
                    <LeaderboardConfigForm
                      formType='create'
                    />
                  </div>
                ) : null}
            </div>
          </div>
          <div>
            {isFetching && isFetchingInstanceDetails
              ? (
                <div className='loader-container'>
                  <Loading />
                </div>
              )
              : <LeaderboardsTable leaderboardConfigs={leaderboardConfigs} />}
          </div>
        </div>
        <div className='leaderboard-right-section'>
          {(selectedLeaderboardConfig && !isFetching)
            && (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 className='dashboard-heading leaderboard-right-heading'>
                  {selectedLeaderboardConfig ? selectedLeaderboardConfig.name : null}
                </h2>
                <div className='leaderboard-actions-container row align-items-center'>
                  <LeaderboardConfigForm
                    formType='update'
                  />
                </div>
              </div>
              <LeaderboardInstanceSelectors />
              {!isFetchingInstanceDetails && <LeaderboardInstanceStats />}
              {enableDataExport && !isFetchingInstanceDetails
                && (
                  <div className={`challenge-instance-csv ${isTeamSelected ? 'justifySpaceBetween' : ''}`}>
                    {isTeamSelected && (
                    <button
                      className='jr-btn-no-border black-color-text'
                      onClick={this.goBackToAllTeams}
                    >
                      <img className='btnImg' src='resources/images/chevron-left.svg' />
                      Back
                    </button>
                    )}
                    <CSVLink
                      data={exportData || instanceDetails?.rankings}
                      asyncOnClick
                      filename={`${selectedLeaderboardConfig.name}-${
                        moment(instanceDetails?.startDate).toISOString()
                      }-${isTeamSelected ? selectedLeaderboardTeam : ''}-table.csv`}
                      target='_blank'
                    >
                      <button className='jr-btn-no-border black-color-text'>
                        <img className='btnImg csvExportIconContainer' src='resources/images/csv-export-icon.svg' />
                        CSV Export
                      </button>
                    </CSVLink>
                  </div>
                )}
              <LeaderboardsInstanceTable />
            </div>
            )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  loadLeaderboardConfigsList: (selectedGymName: string) => dispatch(setupInitialLeaderboard(selectedGymName)),
  setSelectedInstance: (instanceDetails: {
    gym: string,
    leaderboardInstance: ILeaderboardInstance,
  }) => dispatch(setSelectedInstance({
    gym: instanceDetails.gym,
    leaderboardInstance: instanceDetails.leaderboardInstance,
  })),
  setSelectedTeam: (teamDetails: {
    gym: string,
    leaderboardTeam: string,
    selectedLeaderboardInstance: ILeaderboardInstance,
  }) => dispatch(setSelectedTeam({
    gym: teamDetails.gym,
    leaderboardTeam: teamDetails.leaderboardTeam,
    selectedLeaderboardInstance: teamDetails.selectedLeaderboardInstance,
  })),
});
const mapStateToProps = (state: IAppStore) => {
  const { leaderboards, adminSettings } = state;
  const { selectedGymName } = adminSettings.data;
  const { exportData, enableDataExport } = adminSettings.data;
  const {
    leaderboardConfigs,
    selectedLeaderboardConfig,
    selectedLeaderboardTeam,
    selectedLeaderboardInstanceDetails,
    selectedLeaderboardInstance,
  } = leaderboards.data;
  const {
    isFetching,
    isFetchingInstanceDetails,
    errorMessage,
  } = leaderboards.view;
  return {
    errorMessage,
    exportData,
    isFetching,
    selectedGymName,
    enableDataExport,
    leaderboardConfigs,
    isFetchingInstanceDetails,
    selectedLeaderboardConfig,
    selectedLeaderboardInstance,
    instanceDetails: selectedLeaderboardInstanceDetails,
    selectedLeaderboardTeam,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboards);
