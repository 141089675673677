import { UserServiceClient } from 'fitbeat.api-clients';
import fetch from 'isomorphic-fetch';
import React from 'react';
import { connect } from 'react-redux';
import { FormGroup } from 'rsuite';
import { loadInitialStateForApp } from './adminSettings/actions';
import { apiConfig } from './app/apiConfig';
import { IAppStore } from './rootReducer';
import { Loading } from './shared/loading';

interface IBasicAuthDetails {
  username: string;
  password: string;
}

interface IProps {
  isLoading: boolean;
  error: string | null;
  login: () => void;
}

function basicAuthLoginOnApiConfig(auth: IBasicAuthDetails) {
  // todo: move all auth access into auth/ dir
  apiConfig.LOCAL_SERVER_USERNAME = auth.username;
  apiConfig.LOCAL_SERVER_PASSWORD = auth.password;
  localStorage.setItem('auth', JSON.stringify(auth));
}

function reauthenticate() {
  const savedAuth = localStorage.getItem('auth');
  if (!savedAuth) {
    return false;
  }

  try {
    const auth = JSON.parse(savedAuth);
    basicAuthLoginOnApiConfig(auth);
    return true;
  } catch (e) {
    localStorage.removeItem('auth');
    return false;
  }
}

class Login extends React.Component<IProps> {
  public componentDidMount() {
    const { login } = this.props;
    if (reauthenticate()) {
      login();
    }
  }

  public handleLoginSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { login } = this.props;

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    basicAuthLoginOnApiConfig({
      username: formData.get('username')?.toString() || '',
      password: formData.get('password')?.toString() || '',
    });
    login();
  };

  public render() {
    const { error, isLoading } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className='login-container'>
        <div className='login'>
          <h2>Login to Business Tools</h2>

          {error && <p className='error'>{error}</p>}

          <form method='post' onSubmit={this.handleLoginSubmit}>
            <FormGroup>
              <label htmlFor='username'>Username:</label>
              <div>
                <input type='username' name='username' id='username' required />
              </div>
            </FormGroup>

            <FormGroup>
              <label htmlFor='password'>Password:</label>
              <div>
                <input type='password' name='password' id='password' required />
              </div>
            </FormGroup>

            <div className='button-container'>
              <button className='jr-btn-default' type='submit'>Login</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppStore): Partial<IProps> => ({
  isLoading: state.adminSettings.view.appInitialising,
  error: state.adminSettings.view.appInitialisationError,
});

const mapDispatchToProps = (dispatch: any): Partial<IProps> => {
  const userServiceClient = new UserServiceClient(apiConfig, fetch, null, undefined);
  return {
    login: () => dispatch(loadInitialStateForApp(userServiceClient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
