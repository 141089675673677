import moment, { Moment } from 'moment';
import React from 'react';
import { SingleDatePicker } from 'react-dates';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { IAppStore } from '../../rootReducer';
import {
  applyFreeWeekSubscription,
  setFreeWeekEarliestStart,
  setFreeWeekEndDate,
  setFreeWeekPlanId,
  toggleAddFreeWeeksModal,
} from '../actions';
import { getMemberAccountScheduleAndWorkouts } from '../actions';

class AddFreeWeeks extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      endFocused: false,
      focused: false,
    };
  }

  public blockedStartDates = (momentDate: Moment) => {
    return momentDate.isBefore(moment(this.props.addFreeWeekInfo.earliestStart).startOf('day'))
      || momentDate.isAfter(moment(this.props.addFreeWeekInfo.latestStart).endOf('day'));
  }

  public blockedEndDates = (momentDate: Moment) => {
    return momentDate.isBefore(moment(this.props.freeWeekStartDate).endOf('day')) ||
      momentDate.isAfter(moment(this.props.addFreeWeekInfo.latestEnd).endOf('day'));
  }

  public handleEndDateChange = (endDate: Moment) => {
    this.setState({ endDate });
  }

  public setFocus = (focused: boolean) => {
    this.setState({ focused });
  }

  public setEndFocus = (endFocused: boolean) => {
    this.setState({ endFocused });
  }

  public applyFreeWeek = async () => {
    this.props.applyFreeWeekSubscription(
      this.props.memberDetail.uid,
      this.props.freeWeekPlanId,
      this.props.freeWeekStartDate,
      this.props.freeWeekEndDate,
    );
  }

  public render() {
    return (
      <div>
        <Modal
          isOpen={this.props.showAddFreeWeeksModal}
          className='modal-content'
          overlayClassName='modal-overlay'
          ariaHideApp={false}
          contentLabel='Columns'
        >
          <div className='column-label content-margin'>
            <h2 className='column-header'>{`Add Free Days`}</h2>
            <button
              className='modal-close-button'
              onClick={this.props.toggleAddFreeWeeksModal}
            >
              X
            </button>
          </div>
          {this.props.addFreeWeekInfo && (
            <div>
              <Table size='sm'>
                <thead>
                  <tr>
                    <th>Plan</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <select
                        className='pause-plan-select'
                        onChange={(val) =>
                          this.props.setFreeWeekPlanId(val.target.value)
                        }
                      >
                        <option value={undefined} key={0}>
                          Select a Free Plan
                        </option>
                        {this.props.allowedFreeWeekPlanIds &&
                          this.props.allowedFreeWeekPlanIds.map((plan: any) => {
                            return (
                              <option value={plan.price} key={plan.name}>
                                {plan.name}
                              </option>
                            );
                          })}
                      </select>
                    </td>
                    <td>
                      <SingleDatePicker
                        date={moment(this.props.freeWeekStartDate)}
                        onDateChange={this.props.setFreeWeekEarliestStart}
                        focused={this.state.focused}
                        displayFormat='YYYY-MM-DD'
                        onFocusChange={() => this.setFocus(!this.state.focused)}
                        isDayBlocked={this.blockedStartDates}
                        id='subscription-date-picker'
                        block={true}
                        small={true}
                        daySize={40}
                        hideKeyboardShortcutsPanel={true}
                        appendToBody={true}
                        numberOfMonths={1}
                        readOnly={true}
                      />
                    </td>
                    <td>
                      <SingleDatePicker
                        date={moment(this.props.freeWeekEndDate)}
                        onDateChange={this.props.setFreeWeekEndDate}
                        focused={this.state.endFocused}
                        displayFormat='YYYY-MM-DD'
                        onFocusChange={() =>
                          this.setEndFocus(!this.state.endFocused)
                        }
                        isDayBlocked={this.blockedEndDates}
                        id='end-subscription-date-picker'
                        block={true}
                        small={true}
                        daySize={40}
                        hideKeyboardShortcutsPanel={true}
                        appendToBody={true}
                        numberOfMonths={1}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {this.props.error && (
            <div style={{ textAlign: 'right' }}>
              <p style={{ color: 'red' }}>{this.props.error}</p>
            </div>
          )}
          {this.props.isFetching ? (
            <div style={{ textAlign: 'right' }}>
              <Loader
                type='ThreeDots'
                color='#FF621D'
                height={50}
                width={50}
                timeout={3000}
              />
            </div>
          ) : (
            <div style={{ textAlign: 'right' }}>
              <button
                className='jr-btn-default'
                style={{ width: '20%', marginTop: 10 }}
                onClick={this.applyFreeWeek}
                disabled={!this.props.freeWeekPlanId}
              >
                Apply Free Week
              </button>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}
const mapDispatchToProps = {
  setFreeWeekEarliestStart,
  setFreeWeekPlanId,
  setFreeWeekEndDate,
  getMemberAccountScheduleAndWorkouts,
  applyFreeWeekSubscription,
  toggleAddFreeWeeksModal,
};
const mapStateToProps = (state: IAppStore) => {
  const { accountActions } = state;
  const {
    showAddFreeWeeksModal,
    isFetching,
    error,
    freeWeekEarliestEnd,
    freeWeekPlanId,
    freeWeekStartDate,
    freeWeekEndDate,
  } = accountActions.view;
  const { allowedFreeWeekPlanIds, addFreeWeekInfo } = accountActions.view;

  return {
    showAddFreeWeeksModal,
    isFetching,
    error,
    allowedFreeWeekPlanIds,
    freeWeekEarliestEnd,
    freeWeekPlanId,
    freeWeekStartDate,
    freeWeekEndDate,
    addFreeWeekInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFreeWeeks);
