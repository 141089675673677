import { UserWorkoutSession } from 'fitbeat.gym-core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable, { SortingRule } from 'react-table';
import { currentGymSelector } from '../../adminSettings/selector';
import { IAppStore } from '../../rootReducer';
import { Loading } from '../../shared/loading';
import {
  nextButton,
  previousButton,
} from '../components/reactTablePaginationButtons';
import { buildWorkoutConfigColumnsFromAccessor } from '../filters/workoutColumnConfigFilter';

interface IProps {
  memberWorkoutHistory: UserWorkoutSession[];
  sortOrder: SortingRule[];
  loading: boolean;
  workoutColumns: any;
}

class WorkoutHistoryOverviewTable extends Component<IProps, any> {
  public reactTable: any;
  constructor(props: IProps) {
    super(props);
    this.reactTable = React.createRef();
  }

  public render() {
    return (this.props.loading ?
      <div className={'loader-container'}>
        <Loading />
      </div> :
      <ReactTable
        className='workout-history-overview-table'
        ref={this.reactTable}
        minRows={0}
        resizable={true}
        showPageSizeOptions={true}
        filterable
        data={
          this.props.memberWorkoutHistory ? this.props.memberWorkoutHistory : []
        }
        loadingText='Loading...'
        noDataText={'No data found'}
        columns={this.props.workoutColumns}
        defaultPageSize={10}
        defaultFilterMethod={(filter: any, row: any) =>
          String(row[filter.id]) === filter.value
        }
        defaultSorted={this.props.sortOrder}
        PreviousComponent={previousButton}
        NextComponent={nextButton}
      />
    );
  }
}

const mapStateToProps = (state: IAppStore, props: any) => {
  const { accountActions } = state;
  const { memberWorkoutHistory } = accountActions.data;
  const { isFetching } = accountActions.view;
  const currentGym = currentGymSelector(state);
  const workoutColumns = buildWorkoutConfigColumnsFromAccessor(currentGym);
  return {
    loading: isFetching,
    memberWorkoutHistory,
    workoutColumns,
  };
};

export default connect(mapStateToProps, null)(WorkoutHistoryOverviewTable);
