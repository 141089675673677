import moment from 'moment';
import React from 'react';
import {
  filterBooleanStatus,
  filterInvoiceLink,
  filterPaymentTableStatus,
  invoiceStatusFilters, invoiceStatusMap,
} from '../components/activeFilter';
import DateOfBirthFilter from '../components/dateOfBirthFilter';
import { invoiceTotal } from '../components/invoiceTotal';
import { invoiceLink } from '../components/invoiceURL';
import { MemberTableHeader } from '../components/memberTableHeader';
import { exactTextMatchFilter, filterMethodContainsOrDoesntContain } from '../filters/filterHelpers';

const createdDateColumnData = {
  filterMethod: (filter: any, row: any) => {
    const isEmpty = !row[filter.id];
    if (filter.value.startDate === undefined &&
      moment(row[filter.id]).isSameOrBefore(moment(filter.value.endDate)) && !isEmpty) {
      return true;
    }
    if (filter.value.endDate === undefined &&
      moment(row[filter.id]).isSameOrAfter(moment(filter.value.startDate)) && !isEmpty) {
      return true;
    }
    if (filter.value.startDate === undefined && filter.value.endDate === undefined) {
      return true;
    }
    if (moment(row[filter.id]).isSameOrAfter(moment(filter.value.startDate).format('YYYY-MM-DD')) &&
      moment(row[filter.id]).isSameOrBefore(moment(filter.value.endDate).format('YYYY-MM-DD')) && !isEmpty) {
      return true;
    }
    return false;
  },
  Filter: ({ filter, onChange }: any) => <DateOfBirthFilter onChange={onChange}></DateOfBirthFilter>,
};

const invoiceLinkColumnData = {
  filterMethod: (filters: any, row: any) => {
    let hasFoundMatch = false;
    const isEmpty = !row[filters.id];
    const hasLink = row[filters.id];
    for (const filter of filters.value) {
      if (filter === 'None' && isEmpty) {
        hasFoundMatch = true;
      }
      if (filter === 'Continue' && hasLink) {
        hasFoundMatch = true;
      }
    }
    return hasFoundMatch;
  },
  Filter: ({ filter, onChange }: any) => filterInvoiceLink(filter, onChange),
  Cell: ({ original }: any) => (invoiceLink(original)),
};

export const invoiceColumnData = [
  {
    Header: () => <MemberTableHeader header='Total' />,
    accessor: 'total',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'total'),
    filterAll: true,
    filterable: true,
    width: 100,
    sortable: false,
  },
  {
    Header: () => <MemberTableHeader header='Paid' />,
    id: 'paid',
    accessor: (data: any) => data.paid.toString(),
    used: true,
    headerClassName: 'container-overflow-visible',
    filterMethod: exactTextMatchFilter,
    Filter: ({ filter, onChange }: any) => filterBooleanStatus(filter, onChange),
    filterable: true,
    sortable: true,
    width: 100,
  },
  {
    Header: () => <MemberTableHeader header='Outstanding' />,
    accessor: 'due',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'due'),
    filterAll: true,
    filterable: true,
    sortable: true,
    width: 110,
  },
  {
    Header: () => <MemberTableHeader header='Credit Note Total' />,
    accessor: 'creditNoteTotal',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'creditNoteTotal'),
    filterAll: true,
    filterable: true,
    sortable: true,
    width: 130,
  },
  {
    Header: () => <MemberTableHeader header='Invoice Number' />,
    accessor: 'invoiceNo',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'invoiceNo'),
    headerClassName: 'container-overflow-visible',
    filterable: true,
    filterAll: true,
    sortable: true,
    width: 270,
  },
  {
    Header: () => <MemberTableHeader header='Created' />,
    accessor: 'created',
    headerClassName: 'container-overflow-visible',
    used: true,
    filterable: true,
    sortable: true,
    width: 100,
    ...createdDateColumnData,
  },
  {
    Header: () => <MemberTableHeader header='Description' />,
    accessor: 'invoiceLinesSummary',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'invoiceLinesSummary'),
    filterAll: true,
    filterable: true,
    sortable: true,
    width: 150,
  },
  {
    Header: () => <MemberTableHeader header='Invoice URL' />,
    accessor: 'invoiceUrl',
    used: true,
    width: 150,
    headerClassName: 'container-overflow-visible',
    filterable: true,
    sortable: true,
    ...invoiceLinkColumnData,
  },
];

export const merchandiseInvoiceColumnData = [
  {
    Header: () => <MemberTableHeader header='Created' />,
    accessor: 'created',
    headerClassName: 'container-overflow-visible',
    used: true,
    filterable: true,
    sortable: true,
    width: 220,
    ...createdDateColumnData,
  },
  {
    Header: () => <MemberTableHeader header='Status' />,
    accessor: 'status',
    used: true,
    filterable: true,
    sortable: true,
    width: 155,
    headerClassName: 'container-overflow-visible',
    Filter: ({ filter, onChange }: any) => invoiceStatusFilters(filter, onChange),
    filterMethod: exactTextMatchFilter,
    Cell: ({ original }: any) => {
      let status = original.status;

      if (status === 'open') { status = invoiceStatusMap.get('open'); }
      if (status === 'void') { status = invoiceStatusMap.get('void'); }

      return <p style={{margin: 0}}>{status}</p>;
    },
  },
  {
    Header: () => <MemberTableHeader header='Description' />,
    accessor: 'invoiceLinesSummary',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'invoiceLinesSummary'),
    filterAll: true,
    filterable: true,
    sortable: true,
    width: 220,
  },
  {
    Header: () => <MemberTableHeader header='Coupon' />,
    accessor: 'couponCode',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'couponCode'),
    filterAll: true,
    filterable: true,
    sortable: true,
    width: 150,
  },
  {
    Header: () => <MemberTableHeader header='Total' />,
    accessor: 'total',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'total'),
    filterAll: true,
    filterable: true,
    width: 80,
    sortable: false,
    Cell: ({ original }: any) => invoiceTotal(original),
  },
  {
    Header: () => <MemberTableHeader header='Invoice URL' />,
    accessor: 'invoiceUrl',
    used: true,
    width: 120,
    headerClassName: 'container-overflow-visible',
    filterable: true,
    sortable: true,
    ...invoiceLinkColumnData,
  },
  {
    Header: () => <MemberTableHeader header='Note' />,
    accessor: 'footer',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'footer'),
    filterAll: true,
    filterable: true,
    sortable: true,
    width: 400,
  },
];

export const paymentColumnData = [
  {
    Header: () => <MemberTableHeader header='Total' />,
    accessor: 'amount',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'amount'),
    filterAll: true,
    filterable: true,
    sortable: true,
    width: 100,
  },
  {

    Header: () => <MemberTableHeader header='Status' />,
    used: true,
    width: 100,
    accessor: 'status',
    headerClassName: 'container-overflow-visible',
    filterMethod: exactTextMatchFilter,
    filterable: true,
    sortable: true,
    Filter: ({ filter, onChange }: any) => filterPaymentTableStatus(filter, onChange),
  },
  {
    Header: () => <MemberTableHeader header='Description' />,
    accessor: 'description',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'description'),
    filterAll: true,
    filterable: true,
    sortable: true,
    width: 150,
  },
  {
    Header: () => <MemberTableHeader header='Paid' />,
    accessor: (data: any) => data.paid.toString(),
    id: 'paid',
    used: true,
    headerClassName: 'container-overflow-visible',
    filterMethod: exactTextMatchFilter,
    Filter: ({ filter, onChange }: any) => filterBooleanStatus(filter, onChange),
    filterable: true,
    sortable: true,
    width: 100,
  },
  {
    Header: () => <MemberTableHeader header='Refunded' />,
    accessor: (data: any) => data.refunded.toString(),
    id: 'refunded',
    used: true,
    headerClassName: 'container-overflow-visible',
    filterMethod: exactTextMatchFilter,
    Filter: ({ filter, onChange }: any) => filterBooleanStatus(filter, onChange),
    filterable: true,
    sortable: true,
    width: 100,
  },
  {
    Header: () => <MemberTableHeader header='Invoice Number' />,
    accessor: 'invoiceNo',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'description'),
    headerClassName: 'container-overflow-visible',
    filterable: true,
    filterAll: true,
    sortable: true,
    width: 270,
  },
  {
    Header: () => <MemberTableHeader header='Created1' />,
    accessor: 'created',
    headerClassName: 'container-overflow-visible',
    Filter: ({ filter, onChange }: any) => <DateOfBirthFilter onChange={onChange}></DateOfBirthFilter>,
    used: true,
    filterable: true,
    sortable: true,
  }];
