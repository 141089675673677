import { IInvoice, IPrice, IStripeCouponWrapper } from 'fitbeat.models';
import { createSelector } from 'reselect';
import { getInvoiceTotalDueIsZero, getMemberHasValidDefaultPaymentMethod } from '../members';
import { IAppStore } from '../rootReducer';
import { getCouponDescription } from '../shared/helpers/formatHelper';
import { ITerminalStatus } from './storeTypes';

export const getStartSubscriptionPlanDetailsProvided = (state: IAppStore) => {
  const { plan, coupon, couponChecked } = state.accountActions.data.startSubscription;

  return !(!plan || (couponChecked && !coupon));
};

export const getStartSubscriptionConsentGiven = (state: IAppStore) => {
  const {  consentChecked } = state.accountActions.data.startSubscription;
  return consentChecked;
};

export const getIsStartSubscriptionTotalZero = (state: IAppStore): boolean => {
  const total = state.accountActions.data.startSubscription?.paymentSummary?.total;
  if (total) {
    return Number(total) === 0;
  }

  return false;
};

export const getStartSubscriptionPaymentSummary = (state: IAppStore) => {
  const { paymentSummary } = state.accountActions.data.startSubscription;
  return paymentSummary;
};

export const isReaderConnected = (state: IAppStore) => {
  const status = state.accountActions.view.terminalStatus;
  return status === 'ready';
};

export const canStartSubscriptionWithDefaultPaymentMethodSelector = createSelector(
  [
    getStartSubscriptionPlanDetailsProvided,
    getStartSubscriptionConsentGiven,
    getMemberHasValidDefaultPaymentMethod,
    getIsStartSubscriptionTotalZero,
  ],
  (minimumDetailsProvided, consentGiven, hasDefaultValidDefaultPaymentMethod, totalIsZero) =>
    minimumDetailsProvided && consentGiven && (hasDefaultValidDefaultPaymentMethod || totalIsZero));

export const canStartSubscriptionWithNewPaymentMethodSelector = createSelector(
  [getStartSubscriptionPlanDetailsProvided, getStartSubscriptionConsentGiven],
  (minimumDetailsProvided, consentGiven) => minimumDetailsProvided && consentGiven);

export const canStartSubscriptionWithPosSelector = createSelector(
  [getStartSubscriptionPlanDetailsProvided, getStartSubscriptionConsentGiven, isReaderConnected],
  (minimumDetailsProvided, consentGiven, isConnectedToReader) =>
    minimumDetailsProvided && consentGiven && isConnectedToReader);

export const getStartSubscriptionPlanDetailsProvidedSelector = createSelector(
  [getStartSubscriptionPlanDetailsProvided, getStartSubscriptionConsentGiven],
  (minimumDetailsProvided) => minimumDetailsProvided);

export const getStartSubscriptionPaymentSummarySelector = createSelector(
  [getStartSubscriptionPaymentSummary],
  (startSubscriptionPaymentSummary) => startSubscriptionPaymentSummary);

export const shouldGetStartSubscriptionPaymentSummarySelector = createSelector(
  [getStartSubscriptionPlanDetailsProvided],
  (shouldGetPaymentSummary) => shouldGetPaymentSummary);

export const getCustomerCouponIfApplicable = (state: IAppStore) => {
  const { paymentSummary, couponChecked } = state.accountActions.data.startSubscription;

  if (!paymentSummary) {
    return;
  }

  if (!couponChecked && paymentSummary.coupon) {
    return paymentSummary.coupon;
  }

  return undefined;
};

export const getCustomerCouponIfApplicableSelector = createSelector(
  [getCustomerCouponIfApplicable],
  (customerCoupon) => customerCoupon);

export const getStartSubscriptionCurrency = createSelector(
  [
    (state: IAppStore) => state.accountActions.data.startSubscription.plan,
    (state: IAppStore) => state.accountActions.data.startSubscription,
  ],
  (plan: IPrice | undefined) => {
    return plan?.currency;
  },
);

export const getBuyMerchandiseInvoice = createSelector(
  [(state: IAppStore) => state.accountActions.data.buyMerchandise.invoice],
  (invoice: IInvoice | undefined) => invoice,
);

export const getMerchandiseNoteSelector = createSelector(
  [(state: IAppStore) => state.accountActions.data.buyMerchandise.note],
  (note: string | undefined) => note,
);

export const getMerchandiseCouponsSelector = createSelector(
  [(state: IAppStore) => state.accountActions.data.buyMerchandise.coupons],
  (coupons: IStripeCouponWrapper[] | undefined): {label: string, value: IStripeCouponWrapper | undefined}[] => {
    if (!coupons) {
      return [];
    }
    return coupons?.map((coupon: IStripeCouponWrapper) => {
      return {
        label: getCouponDescription(coupon),
        value: coupon,
      };
    });
  },
);

export const getSelectedMerchandiseCoupon = createSelector(
  [(state: IAppStore) => state.accountActions.data.buyMerchandise.selectedCoupon],
  (coupon: IStripeCouponWrapper | undefined): IStripeCouponWrapper | undefined => {
    return coupon;
  },
);

export const isMerchandiseInvoiceEditableSelector = createSelector(
  [(state: IAppStore) => state.accountActions.data.buyMerchandise.invoice],
  (invoice: IInvoice | undefined): boolean => {
    return !invoice || invoice?.status === 'draft';
  },
);

export const canConfirmAndPayMerchandiseSelector = createSelector(
  [(state: IAppStore) => !!state.accountActions.data.buyMerchandise.invoice,
    (state: IAppStore) => state.accountActions.data.buyMerchandise.isLoadingInvoice,
    getMemberHasValidDefaultPaymentMethod, getInvoiceTotalDueIsZero],
  (
    invoice: boolean,
    isLoadingInvoice: boolean,
    hasValidPaymentMethod: boolean,
    invoiceTotalDueIsZero,
    ):
  boolean => {
    return invoice && !isLoadingInvoice && (hasValidPaymentMethod || invoiceTotalDueIsZero);
  },
);

export const canConfirmAndPayMerchandiseWithManualCaptureSelector = createSelector(
  [(state: IAppStore) => !!state.accountActions.data.buyMerchandise.invoice,
    (state: IAppStore) => state.accountActions.data.buyMerchandise.isLoadingInvoice,
  ],
  (invoice: boolean, isLoadingInvoice: boolean): boolean => {
    return invoice && !isLoadingInvoice;
  },
);

export const canConfirmAndPayMerchandiseWithPosSelector = createSelector(
  [(state: IAppStore) => !!state.accountActions.data.buyMerchandise.invoice,
    (state: IAppStore) => state.accountActions.data.buyMerchandise.isLoadingInvoice,
    (state: IAppStore) => state.accountActions.data.buyMerchandise.note,
    isMerchandiseInvoiceEditableSelector,
    isReaderConnected,
  ],
  (invoice: boolean, isLoadingInvoice: boolean, note: string | undefined,
   isEditable: boolean, isConnectedToReader: boolean): boolean => {
    return invoice && !isLoadingInvoice && isConnectedToReader;
  },
);

export const shouldLoadConfirmAndPayMerchandiseSelector = createSelector(
  [(state: IAppStore) => state.accountActions.data.buyMerchandise.isLoadingInvoice,
    (state: IAppStore) => state.accountActions.data.buyMerchandise.isProcessingPayment],
  (isLoadingInvoice: boolean, isProcessingPayment: boolean): boolean => {
    return isLoadingInvoice || isProcessingPayment;
  },
);

export const canPayForMerchandiseSelector = createSelector(
  [(state: IAppStore) => state.accountActions.data.buyMerchandise.invoice,
    isMerchandiseInvoiceEditableSelector,
  ],
  (invoice: IInvoice | undefined, canEditInvoice: boolean): boolean => {
    return !invoice || (invoice?.status === 'open' || canEditInvoice);
  },
);

export const canDiscardInvoiceSelector = createSelector(
  [(state: IAppStore) => state.accountActions.data.buyMerchandise.invoice?.status],
  (status: string | undefined | null): boolean => {
    return status === 'draft' || status === 'open';
  },
);

export const showProcessingPaymentWithPosModalSelector = createSelector(
  [(state: IAppStore) => state.accountActions.view.terminalStatus],
  (status: ITerminalStatus): boolean => {
    return status === 'capturing' || status === 'processing_card_capture' || status === 'processing_payment';
  },
);
