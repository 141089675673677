import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { IAppStore } from '../../rootReducer';
import { invoiceColumnData } from '../filters/payments';
import { invoicesSelector } from '../selector';
import { nextButton, previousButton } from './reactTablePaginationButtons';

class Invoices extends Component<any, any> {
  public reactTable: any;
  constructor(props: any) {
    super(props);
    this.reactTable = React.createRef();
  }

  public render() {
    return (
      <ReactTable
        ref={this.reactTable}
        className='member-invoices-overview-table'
        minRows={0}
        resizable={true}
        showPageSizeOptions={true}
        filterable
        data={this.props.invoices}
        loadingText='Loading...'
        noDataText={'No data found'}
        columns={invoiceColumnData}
        defaultPageSize={50}
        defaultFilterMethod={(filter: any, row: any) =>
          String(row[filter.id]) === filter.value
        }
        defaultSorted={this.props.sortOrder}
        PreviousComponent={previousButton}
        NextComponent={nextButton}
      />
    );
  }
}

const mapStateToProps = (state: IAppStore, props: any) => {
  const { accountActions, members } = state;
  const { isFetching, error } = members.view;
  const { memberAccountShedule, memberWorkoutHistory } = accountActions.data;
  const { memberAccountData } = members.data;
  const memberDetail = memberAccountData && memberAccountData.userInfo.profile;
  const invoices = invoicesSelector(state.members.data.memberAccountData);
  return {
    isFetching,
    error,
    memberAccountShedule,
    memberAccountData,
    memberDetail,
    memberWorkoutHistory,
    invoices,
  };
};

export default connect(mapStateToProps)(Invoices);
