import moment, { Moment } from 'moment';
import React from 'react';
import { SingleDatePicker } from 'react-dates';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { apiConfig } from '../../app/apiConfig';
import { MembersService } from '../../members/membersService';
import { IAppStore } from '../../rootReducer';
import {
  pauseSubscription, setPauseEarliestStart, setPauseEndDate, setPausePlanId,
  toggleAddPauseModal,
} from '../actions';
import { getMemberAccountScheduleAndWorkouts } from '../actions';

class AddPause extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      endFocused: false,
      focused: false,
    };
  }

  public blockedStartDates = (momentDate: Moment) => {
    return momentDate.isBefore(moment(this.props.addFreezeInfo.earliestStart).startOf('day'))
      || momentDate.isAfter(moment(this.props.addFreezeInfo.latestStart).endOf('day'));
  }

  public blockedEndDates = (momentDate: Moment) => {
    return momentDate.isBefore(moment(this.props.pauseStartDate).endOf('day')) ||
      momentDate.isAfter(moment(this.props.addFreezeInfo.latestEnd).endOf('day'));
  }

  public handleEndDateChange = (endDate: Moment) => {
    this.setState({ endDate });
  }

  public setFocus = (focused: boolean) => {
    this.setState({ focused });
  }

  public setEndFocus = (endFocused: boolean) => {
    this.setState({ endFocused });
  }

  public closePauseModal = () => {
    this.props.toggleAddPauseModal();
  }

  public applyPause = async () => {
    this.props.pauseSubscription(this.props.memberDetail.uid, this.props.pausePlanId,
      this.props.pauseStartDate, this.props.pauseEndDate);
  }

  public render() {
    return (
      <div>
        <Modal
          isOpen={this.props.showAddPauseModal}
          className='modal-content'
          overlayClassName='modal-overlay'
          ariaHideApp={false}
          contentLabel='Columns'>
          <div className='column-label content-margin'>
            <h2
              className='column-header'>
              {`Add Freeze`}
            </h2>
            <button
              className='modal-close-button'
              onClick={this.closePauseModal}>
              X
                </button>
          </div>
          {this.props.addFreezeInfo &&
            <div>
              <Table size='sm'>
                <thead>
                  <tr>
                    <th>Plan</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <select className='pause-plan-select'
                        onChange={(val) => this.props.setPausePlanId(val.target.value)}>
                        <option value={undefined} key={0}>Select a Freeze Plan</option>
                        {this.props.allowedPlanIds &&
                          this.props.allowedPlanIds.map((plan: any) => {
                            return (
                              <option value={plan.price} key={plan.name}>{plan.name}</option>
                            );
                          })}
                      </select></td>
                    <td>
                      <SingleDatePicker
                        date={moment(this.props.pauseStartDate)}
                        onDateChange={this.props.setPauseEarliestStart}
                        focused={this.state.focused}
                        displayFormat='YYYY-MM-DD'
                        onFocusChange={() => this.setFocus(!this.state.focused)}
                        isDayBlocked={this.blockedStartDates}
                        id='subscription-date-picker'
                        block={true}
                        small={true}
                        daySize={40}
                        hideKeyboardShortcutsPanel={true}
                        appendToBody={true}
                        numberOfMonths={1}
                        readOnly={true}
                      />
                    </td>
                    <td>
                      <SingleDatePicker
                        date={moment(this.props.pauseEndDate)}
                        onDateChange={this.props.setPauseEndDate}
                        focused={this.state.endFocused}
                        displayFormat='YYYY-MM-DD'
                        onFocusChange={() => this.setEndFocus(!this.state.endFocused)}
                        isDayBlocked={this.blockedEndDates}
                        id='end-subscription-date-picker'
                        block={true}
                        small={true}
                        daySize={40}
                        hideKeyboardShortcutsPanel={true}
                        appendToBody={true}
                        numberOfMonths={1}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          }
          {this.props.error &&
            <div style={{ textAlign: 'right' }}>
              <p style={{ color: 'red' }}>{this.props.error}</p>
            </div>
          }
          {this.props.isFetching ?
            <div style={{ textAlign: 'right' }}>
              <Loader
                type='ThreeDots'
                color='#FF621D'
                height={50}
                width={50}
                timeout={3000}
              />
            </div> :
            <div style={{ textAlign: 'right' }}>
              <button className='jr-btn-default' style={{ width: '20%', marginTop: 10 }}
                onClick={this.applyPause} disabled={!this.props.pausePlanId}>Apply Freeze</button>
            </div>
          }
        </Modal>
      </div>
    );
  }
}
const mapDispatchToProps = {
  toggleAddPauseModal,
  setPauseEarliestStart,
  pauseSubscription: (uid: string, pausePlanId: string, pauseStartDate: Moment, pauseEndDate: Moment) =>
    pauseSubscription(new MembersService(apiConfig), uid, pausePlanId, pauseStartDate, pauseEndDate),
  setPausePlanId,
  setPauseEndDate,
  getMemberAccountScheduleAndWorkouts,
};

const mapStateToProps = (state: IAppStore) => {
  const { accountActions } = state;
  const { showAddPauseModal, isFetching, error, pauseEarliestEnd, pausePlanId,
    pauseStartDate, pauseEndDate } = accountActions.view;
  const { allowedPlanIds, addFreezeInfo } = accountActions.view;
  return {
    showAddPauseModal,
    isFetching,
    error,
    pauseEarliestEnd,
    allowedPlanIds,
    pausePlanId,
    pauseStartDate,
    pauseEndDate,
    addFreezeInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPause);
