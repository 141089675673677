import { Dispatch } from 'redux';

export function ReduxHelpers(stateTransformations: any) {
  function makeApiRequestAction<T extends (...args: any[]) => any>(
    type: string,
    originalAction: T,
    successTransformation: (draft: any, action: any) => any,
    requestTransformation: (draft: any, action: any) => any = (draft: any, action: any) => {
      draft.view.error = null;
      draft.view.isFetching = true;
      return draft;
    },
    failureTransformation: (draft: any, action: any) => any = (draft: any, action: any) => {
      draft.view.error = action.payload.message;
      draft.view.isFetching = false;
      return draft;
    },
    ): (...funcArgs: Parameters<T>) => any {

    const requestType = `${type}_REQUEST`;
    const successType = `${type}_SUCCESS`;
    const failureType = `${type}_FAILURE`;

    if (!stateTransformations[requestType]) {
      stateTransformations[requestType] = requestTransformation;
    }

    if (!stateTransformations[successType]) {
      stateTransformations[successType] = successTransformation;
    }

    if (!stateTransformations[failureType]) {
      stateTransformations[failureType] = failureTransformation;
    }

    return (...args: Parameters<T>): any => {
      return async (dispatch: Dispatch<any>) => {
        try {
          dispatch({
            type: requestType,
          });
          const result = await originalAction(...args, dispatch);
          dispatch({
            type: successType,
            payload: result,
          });
        } catch (error) {
          dispatch({
            type: failureType,
            payload: error,
          });
        }
      };
    };
  }

  return {
    makeApiRequestAction,
  };
}
