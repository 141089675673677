import { Level } from 'fitbeat.gym-core';
import { BaseUserProfile, Gender, ProgramType } from 'fitbeat.models';
import moment from 'moment';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { formatPhoneNumberIntl, isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import { connect } from 'react-redux';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { IAppStore } from '../../rootReducer';
import { toggleEditMemberModal, updateMemberDetails } from '../actions';
import { getProgramTypeTranslation, getRegionsForCountry, getSkillLevelMapping } from '../config';

class EditMemberDetailsModal extends React.Component<any, any> {
  public DOB: any;
  constructor(props: any) {
    super(props);
    const { memberDetail } = this.props;
    const country = memberDetail.country ? memberDetail.country : 'Australia';
    const regions = getRegionsForCountry(country);
    this.state = {
      regions,
      editMemberDetails: {
        mobileNumber: memberDetail.mobileNumber ? memberDetail.mobileNumber : undefined,
        email: memberDetail.email ? memberDetail.email : undefined,
        skillLevel: memberDetail.skillLevel ? memberDetail.skillLevel : undefined,
        longTermGoal: memberDetail.longTermGoal ? memberDetail.longTermGoal : undefined,
        heartRateMonitorIdentifier: memberDetail.heartRateMonitorIdentifier ?
          memberDetail.heartRateMonitorIdentifier : undefined,
        dateOfBirth: memberDetail.dateOfBirth ? memberDetail.dateOfBirth : undefined,
        gender: memberDetail.gender ? memberDetail.gender : undefined,
        addressLine1: memberDetail.addressLine1 ? memberDetail.addressLine1 : undefined,
        addressLine2: memberDetail.addressLine2 ? memberDetail.addressLine2 : undefined,
        postCode: memberDetail.postCode ? memberDetail.postCode : undefined,
        suburb: memberDetail.suburb ? memberDetail.suburb : undefined,
        unit: memberDetail.unit ? memberDetail.unit : undefined,
        state: memberDetail.state ? memberDetail.state : undefined,
        weight: memberDetail.weight ? memberDetail.weight : undefined,
        height: memberDetail.height ? memberDetail.height : undefined,
        nickname: memberDetail.nickname ? memberDetail.nickname : undefined,
        firstname: memberDetail.firstname ? memberDetail.firstname : undefined,
        lastname: memberDetail.lastname ? memberDetail.lastname : undefined,
        discount:  this.getMemberDiscountFromCoupon(memberDetail.influencerSettings?.coupon) ,
        source: memberDetail.influencerSettings?.utmTags?.source,
        isStreamingUser: memberDetail.isStreamingUser,
        bio: memberDetail.bio,
        attributionSourceId: memberDetail.signupSource?.attributionSourceId,
        signupSource: memberDetail.signupSource?.source,
      },
      hasInvalidValue: false,
    };
  }

  public formatDate = (date: Date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return formattedDate;
  }

  public handleDetailChange = (value: any, key: any) => {
    const tempDetails = {...this.state.editMemberDetails};
    let isRequiredField = false;
    if (key === 'dateOfBirth' ||
      key === 'mobileNumber') {
      tempDetails[key] = value;
      isRequiredField = true;
    } else if (key === 'isStreamingUser') {
      tempDetails[key] = value.currentTarget.value === 'true';
    } else {
      tempDetails[key] = value.currentTarget.value;
      isRequiredField = value.currentTarget.required;
    }
    this.setState({
      editMemberDetails: tempDetails,
      hasInvalidValue: !tempDetails[key] && isRequiredField,
    });
  }

  public updateDetails = async (uid: string, editMemberDetails: any) => {
    if (editMemberDetails.dateOfBirth !== undefined) {
      editMemberDetails.dateOfBirth = moment(editMemberDetails.dateOfBirth).format('YYYY-MM-DD');
    }
    await this.props.updateMemberDetails(uid, editMemberDetails);
    if (!this.props.error) {
      this.resetEditingDetails();
    }
  }

  public resetEditingDetails = () => {
    const { memberDetail } = this.props;
    this.setState({
      editMemberDetails: {
        mobileNumber: memberDetail.mobileNumber ? memberDetail.mobileNumber : undefined,
        email: memberDetail.email ? memberDetail.email : undefined,
        skillLevel: memberDetail.skillLevel ? memberDetail.skillLevel : undefined,
        longTermGoal: memberDetail.longTermGoal ? memberDetail.longTermGoal : undefined,
        heartRateMonitorIdentifier: memberDetail.heartRateMonitorIdentifier ?
          memberDetail.heartRateMonitorIdentifier : undefined,
        dateOfBirth: memberDetail.dateOfBirth ? memberDetail.dateOfBirth : undefined,
        gender: memberDetail.gender ? memberDetail.gender : undefined,
        addressLine1: memberDetail.addressLine1 ? memberDetail.addressLine1 : undefined,
        addressLine2: memberDetail.addressLine2 ? memberDetail.addressLine2 : undefined,
        postCode: memberDetail.postCode ? memberDetail.postCode : undefined,
        suburb: memberDetail.suburb ? memberDetail.suburb : undefined,
        unit: memberDetail.unit ? memberDetail.unit : undefined,
        state: memberDetail.state ? memberDetail.state : undefined,
        weight: memberDetail.weight ? memberDetail.weight : undefined,
        height: memberDetail.height ? memberDetail.height : undefined,
        nickname: memberDetail.nickname ? memberDetail.nickname : undefined,
        firstname: memberDetail.firstname ? memberDetail.firstname : undefined,
        lastname: memberDetail.lastname ? memberDetail.lastname : undefined,
        discount: this.getMemberDiscountFromCoupon(memberDetail.influencerSettings?.coupon) ,
        source: memberDetail.influencerSettings?.utmTags?.source,
        isStreamingUser: memberDetail.isStreamingUser,
        bio: memberDetail.bio,
        attributionSourceId: memberDetail.signupSource?.attributionSourceId,
        signupSource: memberDetail.signupSource?.source,
      },
    });
  }

  public closeEditModal = () => {
    this.props.toggleEditMemberModal();
    this.resetEditingDetails();
  }

  public render() {
    const inputGroupAddonStyle: React.CSSProperties = { width: '20%'};
    const { editMemberDetails, hasInvalidValue } = this.state;
    const { memberDetail } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.showEditMemberDetails}
          className='modal-content'
          overlayClassName='modal-overlay'
          ariaHideApp={false}
          contentLabel='Columns'>
          <div className='column-label content-margin'>
            <h2
              className='column-header'>
              {`Edit Details`}
            </h2>
            <button
              className='modal-close-button'
              onClick={this.closeEditModal}>
              X
                </button>
          </div>
          <div style={{overflow: 'scroll'}}>
          {hasInvalidValue && <p>Required fields can not be cleared, only updated.</p>}
          <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>First Name *</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.firstname}
                required
                className={`${editMemberDetails.firstname ? 'validInputValue': 'invalidInputValue'}`}
                placeholder='First Name' onChange={(value) => this.handleDetailChange(value, 'firstname')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Last Name *</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.lastname}
                required
                className={`${editMemberDetails.lastname ? 'validInputValue': 'invalidInputValue'}`}
                placeholder='Last Name' onChange={(value) => this.handleDetailChange(value, 'lastname')}
              />
            </InputGroup>
          <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Nickname</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.nickname}
                className={`${editMemberDetails.nickname ? 'validInputValue' : 'invalidInputValue'}`}
                placeholder='Nickname' onChange={(value) => this.handleDetailChange(value, 'nickname')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Mobile Number</InputGroupText>
              </InputGroupAddon>

              <PhoneInput
                style={{border: 'none', paddingLeft: 15}}
                value={editMemberDetails.mobileNumber}
                rules={{ validate: [isPossiblePhoneNumber, formatPhoneNumberIntl, parsePhoneNumber] }}
                className={`${editMemberDetails.mobileNumber ? 'validInputValue' : 'invalidInputValue'}`}
                placeholder='Mobile Number' onChange={(value) => this.handleDetailChange(value, 'mobileNumber')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Email *</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.email} onChange={(value) => this.handleDetailChange(value, 'email')}
                disabled
                type='email'
                className={`${editMemberDetails.email ? 'validInputValue' : 'invalidInputValue'}`}
                placeholder='Email'
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>HR Monitor</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.heartRateMonitorIdentifier} placeholder='HR Monitor'
                onChange={(value) => this.handleDetailChange(value, 'heartRateMonitorIdentifier')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText re style={{ width: '100%' }}>Date of Birth *</InputGroupText>
              </InputGroupAddon>
              <span
                className={`member-detail-dropdown ${
                  editMemberDetails.dateOfBirth ? 'validInputValue' : 'invalidInputValue'
                }`}
                contentEditable='false'>
                <DayPickerInput
                  value={editMemberDetails.dateOfBirth}
                  placeholder='YYYY-MM-DD'
                  format='LL'
                  formatDate={this.formatDate}
                  dayPickerProps={{
                    numberOfMonths: 1,
                    onDayClick: (val) => this.handleDetailChange(val, 'dateOfBirth'),
                  }}
                  onDayChange={(val) => this.handleDetailChange(val, 'dateOfBirth')}
                /></span>
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Gender *</InputGroupText>
              </InputGroupAddon>
              <select className='member-detail-dropdown' onChange={(value) => this.handleDetailChange(value, 'gender')}>
                {!editMemberDetails.gender &&
                  <option value={undefined} key={0}>Please select an option</option>}
                {Object.values(Gender).map((value: any) => {
                  if (value === editMemberDetails.gender) {
                    return <option selected value={value} key={value}>{value}</option>;
                  } else {
                    return <option value={value} key={value}>{value}</option>;
                  }
                })}
              </select>
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Skill Level *</InputGroupText>
              </InputGroupAddon>
              <select className='member-detail-dropdown'
                onChange={(value) => this.handleDetailChange(value, 'skillLevel')}>
                {!editMemberDetails.skillLevel &&
                  <option value={undefined} key={0}>Please select an option</option>}
                {Object.values(Level).map((value: any) => {
                  if (value !== Level.Expert) {
                    if (value === editMemberDetails.skillLevel) {
                      return <option selected value={value} key={value}>{getSkillLevelMapping(value)}</option>;
                    } else {
                      return <option value={value} key={value}>{getSkillLevelMapping(value)}</option>;
                    }
                  } else {
                    return undefined;
                  }
                })}
              </select>
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Fitness Goal *</InputGroupText>
              </InputGroupAddon>
              <select className='member-detail-dropdown'
                onChange={(value) => this.handleDetailChange(value, 'longTermGoal')}>
                {!editMemberDetails.longTermGoal &&
                  <option value={undefined} key={0}>Please select an option</option>}
                {Object.values(ProgramType).map((value: any) => {
                  if (value === editMemberDetails.longTermGoal) {
                    return <option selected value={value} key={value}>{getProgramTypeTranslation(value)}</option>;
                  } else {
                    return <option value={value} key={value}>{getProgramTypeTranslation(value)}</option>;
                  }
                })}
              </select>
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Unit</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.unit}
                placeholder='Unit' onChange={(value) => this.handleDetailChange(value, 'unit')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Address Line 1</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.addressLine1}
                placeholder='Address Line 1' onChange={(value) => this.handleDetailChange(value, 'addressLine1')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Address Line 2</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.addressLine2}
                placeholder='Address Line 2' onChange={(value) => this.handleDetailChange(value, 'addressLine2')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Suburb</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.suburb}
                placeholder='Suburb' onChange={(value) => this.handleDetailChange(value, 'suburb')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Post Code</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.postCode}
                placeholder='Post Code' onChange={(value) => this.handleDetailChange(value, 'postCode')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>State</InputGroupText>
              </InputGroupAddon>
              <select className='member-detail-dropdown' onChange={(value) => this.handleDetailChange(value, 'state')}>
                {!editMemberDetails.state &&
                  <option value={undefined} key={0}>Please select an option</option>}
                {Object.values(this.state.regions).map((value: any) => {
                  if (value.regionName === editMemberDetails.state) {
                    return <option selected value={value.regionName} key={value.regionName}>
                      {value.regionName}</option>;
                  }
                  return <option value={value.regionName} key={value.regionName}>{value.regionName}</option>;
                })}
              </select>
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Weight (Kg)</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.weight} type='number'
                placeholder='weight' onChange={(value) => this.handleDetailChange(value, 'weight')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Height (cm)</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.height} type='number'
                placeholder='height' onChange={(value) => this.handleDetailChange(value, 'height')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Attribution ID</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.attributionSourceId}
                     placeholder='Attribution ID' onChange={(value) =>
                this.handleDetailChange(value, 'attributionSourceId')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Sign up Source</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.signupSource}
                     placeholder='Sign up Source' onChange={(value) =>
                this.handleDetailChange(value, 'signupSource')}
              />
            </InputGroup>
          <div className='content-margin'>
            <h2  className='column-header-margin'>Ambassador Settings</h2>
          </div>
          {/* disable influencer settings temporary */}
          {/* <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Discount (%)</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.discount} type='number'
                placeholder='discount' onChange={(value) => this.handleDetailChange(value, 'discount')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
                <InputGroupText style={{ width: '100%' }}>Source</InputGroupText>
              </InputGroupAddon>
              <Input value={editMemberDetails.source} type='text'
                placeholder='source' onChange={(value) => this.handleDetailChange(value, 'source')}
              />
            </InputGroup> */}
          <InputGroup>
            <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
              <InputGroupText style={{ width: '100%' }}>Enable live stream</InputGroupText>
            </InputGroupAddon>
            <select
              className='member-detail-dropdown'
              onChange={(value) => this.handleDetailChange(value, 'isStreamingUser')}>
                {(!editMemberDetails.isStreamingUser && editMemberDetails.isStreamingUser !== false) &&
                  <option value={undefined} key={0}>Please select an option</option>}
                {['No', 'Yes'].map((value: any) => {
                  const selectedOptionValue = editMemberDetails.isStreamingUser ? 'Yes': 'No';
                  if ((typeof editMemberDetails.isStreamingUser === 'boolean') &&
                    selectedOptionValue === value) {
                    return <option
                      selected
                      value={`${editMemberDetails.isStreamingUser}`}
                      key={value}>{selectedOptionValue}</option>;
                  }
                  return <option value={`${value === 'Yes'}`} key={value}>{value}</option>;
                })}
            </select>
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType='prepend' style={inputGroupAddonStyle}>
              <InputGroupText style={{ width: '100%' }}>Bio</InputGroupText>
            </InputGroupAddon>
            <Input value={editMemberDetails.bio} type='textarea' maxlength={`${BaseUserProfile.maxLengths.bio}`}
              onChange={(value) => this.handleDetailChange(value, 'bio')}
            />
          </InputGroup>

          </div>
          {this.props.error &&
            <div style={{ textAlign: 'right' }}>
              <p style={{ color: 'red' }}>{this.props.error}</p>
            </div>
          }
          {this.props.isSaving ?
            <div style={{ textAlign: 'right' }}>
              <Loader
                type='ThreeDots'
                color='#FF621D'
                height={50}
                width={50}
                timeout={3000}
              />
            </div> :
            <div style={{ textAlign: 'right' }}>
              <button className='jr-btn-default' style={{ width: '20%', marginTop: 10 }}
                disabled={hasInvalidValue}
                onClick={() => this.updateDetails(memberDetail.uid, editMemberDetails)}>Save</button>
            </div>
          }
        </Modal>
      </div>
    );
  }

  private getMemberDiscountFromCoupon(coupon: string): string | undefined {
    const discount = coupon ? coupon.match(/[0-9]+$/)?.join('') : undefined;
    return discount;
  }
}
const mapDispatchToProps = {
  toggleEditMemberModal,
  updateMemberDetails,
};
const mapStateToProps = (state: IAppStore) => {
  const { members } = state;
  const { showEditMemberDetails, isFetching, error, isSaving } = members.view;
  return {
    showEditMemberDetails,
    isFetching,
    error,
    isSaving,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMemberDetailsModal);
