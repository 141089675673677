import React, { FunctionComponent } from 'react';
import Loader from 'react-loader-spinner';
import { ITerminalStatus } from '../../accountActions';

interface IProps {
  status: ITerminalStatus;
  cancelCardCapture: () => void;
}

const doNotCloseText = 'Please don’t close this popup or disconnect Card Reader until  complete';
const getStatusText = (status: ITerminalStatus) => {
  switch (status) {
    case 'capturing':
      return { header: 'Capturing card on reader...',
        subHeader: 'Please follow the instructions on the Card Reader' };
    case 'processing_card_capture':
      return { header: 'Processing card capture...', subHeader: doNotCloseText};
    case 'processing_payment':
      return { header: 'Processing payment...', subHeader: doNotCloseText};
  }

  return { header: undefined, subHeader: undefined };
};

const PosStatus: FunctionComponent<IProps> = ({status, cancelCardCapture}) => {
  return (
    <div className='terminal-processing-payment-status-container'>
      <h1 className='header'>{getStatusText(status).header}</h1>
      <Loader
        type='ThreeDots'
        color='#FF621D'
        height={50}
        width={50}
        timeout={3000}
      />
      <h1 className='sub-header'>{getStatusText(status).subHeader}</h1>
      {status === 'capturing' && <button
        className='jr-btn-default-outlined cancel-capture-card-button'
        onClick={cancelCardCapture}
      >Cancel Card Capture
      </button>}
    </div>
  );
};

export default PosStatus;
