import React from 'react';
import { logout } from '../../auth';

function LogoutButton() {
  return (
    <div className='logout-container leading-divider'>
      <button className='jr-btn-no-border row-button' onClick={logout}>
        <img
          className='btnImg'
          src='resources/images/user.svg'
        />
        <p className='studio-website-text'>Logout</p>
      </button>
    </div>
  );
}

export default LogoutButton;
