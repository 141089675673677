import {
  Gym,
  ILeaderboardConfig,
} from 'fitbeat.models';
import React, { Component, CSSProperties } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';
import getLogger from '../../util/logging';
import {
  createLeaderboardConfig,
  getLeaderboardConfigs,
  toggleCreateLeaderboardConfigModal,
  toggleUpdateLeaderboardConfigModal,
  updateLeaderboardConfig,
} from '../leaderboardsSlice';
import { setLeaderboardConfig, transformLeaderboardConfig } from './helpers';
import LeaderboardConfigAdvancedOptions from './LeaderboardConfigAdvancedOptions';
import LeaderboardConfigBasicOptions from './LeaderboardConfigBasicOptions';
import LeaderboardConfigTeams from './LeaderboardConfigTeams';

interface IProps {
  selectedGymName: string;
  isFetching: boolean;
  gyms: Gym[];
  isOrgAdmin: boolean;
  showCreateLeaderboardConfigModal: boolean;
  showUpdateLeaderboardConfigModal: boolean;
  formType: 'update' | 'create';
  selectedLeaderboardConfig: ILeaderboardConfig;
  createLeaderboardConfig: (
    selectedGymName: string,
    leaderboardConfig: ILeaderboardConfig,
  ) => void;
  updateLeaderboardConfig: (
    selectedGymName: string,
    leaderboardConfig: ILeaderboardConfig,
  ) => void;
  refreshGymLeaderboardConfigs: (gymName: string) => void;
  toggleCreateLeaderboardConfigModal: (modalState: boolean) => void;
  toggleUpdateLeaderboardConfigModal: (modalState: boolean) => void;
}

interface IState {
  showModal: boolean;
  formData: ILeaderboardConfig;
  allGyms: boolean;
  teams: string[];
  newTeamName: string;
  allGymsInOrg: string;
  hasUpdated: boolean;
}

const initialFormData: ILeaderboardConfig = {
  _id: '',
  name: '',
  order: 1,
  startDate: new Date(),
  endDate: undefined,
  frequency: 'one-time',
  organisation: '',
  gymName: '',
  competitionType: 'individual',
  metric: 'effort-points',
  teams: [],
  timezone: 'Australia/Sydney',
  showInStudio: false,
  requireOptIn: false,
  countZeroScores: false,
};

class LeaderboardConfigForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showModal: false,
      formData: initialFormData,
      allGyms: false,
      teams: [],
      newTeamName: '',
      allGymsInOrg: 'All Gyms in Organisation',
      hasUpdated: false,
    };
    if (props.formType === 'create') {
      const selectedGym = this.props.gyms.find((gym) => gym.name === this.props.selectedGymName);
      const selectedTimezone = selectedGym ? selectedGym.timezone : 'Australia/Sydney';

      initialFormData.gymName = this.props.isOrgAdmin ? this.state.allGymsInOrg : this.props.selectedGymName;
      initialFormData.timezone = selectedTimezone;
    }
  }

  public componentWillUnmount() {
    this.setState({
      showModal: false,
    });
  };

  public createLeaderboardConfig = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    transformLeaderboardConfig(
      this.state.formData,
      this.state.allGymsInOrg,
      this.state.teams,
      this.props.showUpdateLeaderboardConfigModal,
    );
    const logger = getLogger('leaderboard:submit');
    try {
      if (this.props.showUpdateLeaderboardConfigModal) {
        await this.props.updateLeaderboardConfig(
          this.props.selectedGymName,
          this.state.formData,
        );
      } else {
        await this.props.createLeaderboardConfig(
          this.props.selectedGymName,
          this.state.formData,
        );
      }

      this.setState({
        formData: initialFormData,
        allGyms: false,
        teams: [],
      });
      this.props.refreshGymLeaderboardConfigs(this.props.selectedGymName);
      this.props.toggleCreateLeaderboardConfigModal(false);
      this.props.toggleUpdateLeaderboardConfigModal(false);
    } catch (error) {
      logger.error(error, 'Failed to create leaderboard config');
    }
  };

  public discardLeaderboardConfig = async (
    e: React.MouseEvent<HTMLButtonElement>,
    discardUpdated: boolean = false,
  ) => {
    e.preventDefault();
    if (!discardUpdated) {
      await this.props.toggleUpdateLeaderboardConfigModal(false);
    }
    this.setState({
      formData: discardUpdated
        ? this.props.selectedLeaderboardConfig
        : initialFormData,
      allGyms: false,
      teams: discardUpdated
        ? this.props.selectedLeaderboardConfig.teams ?? []
        : [],
      hasUpdated: false,
    });
  };

  public render() {
    const { selectedGymName, gyms } = this.props;
    const { orderedGyms, timezones } = setLeaderboardConfig(
      gyms,
      selectedGymName,
      this.props.isOrgAdmin,
      this.state.allGymsInOrg,
    );

    return (
      <div>
        <div
          className='jr-btn-no-border pointer'
          onClick={() => this.modalSetup()}
        >
          { this.props.formType === 'update' ? 'Update ' : 'Create New ' }
          Leaderboard
        </div>
        <Modal
          isOpen={this.props.showCreateLeaderboardConfigModal}
          className='modal-content leaderboard-create-container'
          contentLabel='Columns'
          ariaHideApp={false}
          overlayClassName='modal-overlay'
        >
          <div>
            <div style={styles.header}>
              <h2 style={styles.headerTitle}>
                { this.props.showUpdateLeaderboardConfigModal ? 'Update ' : 'Create ' }
                Leaderboard
              </h2>
              <button
                className='modal-close-button'
                onClick={async (e) => {
                  this.props.toggleCreateLeaderboardConfigModal(false);
                  if (this.props.showUpdateLeaderboardConfigModal) {
                    await this.discardLeaderboardConfig(e);
                  }
                }}
                style={styles.headerButton}
              >
                X
              </button>
            </div>
            <form onSubmit={this.createLeaderboardConfig}>
              <div style={styles.formRow}>
                <LeaderboardConfigBasicOptions
                  formData={this.state.formData}
                  orderedGyms={orderedGyms}
                  timezones={timezones}
                  isOrgAdmin={this.props.isOrgAdmin}
                  allGyms={this.state.allGyms}
                  handleInputChange={this.handleInputChange}
                  handleSelectChange={this.handleSelectChange}
                />
                <LeaderboardConfigTeams
                  competitionType={this.state.formData.competitionType}
                  teams={this.state.teams}
                  newTeamName={this.state.newTeamName}
                  handleDeleteTeam={this.handleDeleteTeam}
                  handleTeamNameChange={this.handleTeamNameChange}
                  handleAddTeam={this.handleAddTeam}
                />
                <LeaderboardConfigAdvancedOptions
                  formData={this.state.formData}
                  handleCheckboxChange={this.handleCheckboxChange}
                />
              </div>
              <div style={styles.buttonRow}>
                <button
                  type='button'
                  className='jr-btn-default-outlined'
                  onClick={async (e) => {
                    if (this.props.showUpdateLeaderboardConfigModal) {
                      await this.discardLeaderboardConfig(e, true);
                    } else {
                      await this.discardLeaderboardConfig(e);
                    }
                  }}
                  disabled={
                    !this.state.hasUpdated
                    && this.props.showUpdateLeaderboardConfigModal
                  }
                >
                  Discard
                </button>
                <button
                  type='submit'
                  className='jr-btn-default'
                  disabled={!this.state.hasUpdated}
                >
                  { this.props.showUpdateLeaderboardConfigModal
                    ? 'Update'
                    : 'Create' }
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }

  /// Input Related Functions
  private handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: checked,
      },
      hasUpdated: true,
    }));
  };

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    if (type === 'date') {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value ? new Date(value) : undefined,
        },
        hasUpdated: true,
      }));
    } else {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
        hasUpdated: true,
      }));
    }
  };

  private handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
      hasUpdated: true,
    }));
  };

  /// Team Related Functions
  private handleAddTeam = () => {
    const teamName = this.state.newTeamName.trim();
    if (teamName) {
      this.setState((prevState) => ({
        teams: [...prevState.teams, teamName],
        newTeamName: '',
        hasUpdated: true,
      }));
    }
  };

  private handleDeleteTeam = (index: number) => {
    this.setState((prevState) => ({
      teams: prevState.teams.filter((_, i) => i !== index),
    }));
  };

  private handleTeamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newTeamName: e.target.value });
  };

  private modalSetup = () => {
    if (this.props.formType === 'update') {
      this.props.toggleUpdateLeaderboardConfigModal(true);
      if (this.props.selectedLeaderboardConfig) {
        const { startDate, endDate } = this.props.selectedLeaderboardConfig;

        this.setState(() => ({
          formData: {
            ...this.props.selectedLeaderboardConfig,
            startDate: new Date(startDate),
            endDate: endDate ? new Date(endDate) : undefined,
          },
        }));

        this.setState((prevState) => ({
          teams: [
            ...prevState.teams,
            ...this.props.selectedLeaderboardConfig.teams ?? [],
          ],
          newTeamName: '',
        }));

        this.props.toggleCreateLeaderboardConfigModal(true);
      }
    } else {
      this.props.toggleCreateLeaderboardConfigModal(true);
    }
  };
}

const styles: { [key: string]: CSSProperties } = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  headerTitle: {
    flex: 1,
    margin: 0,
  },
  headerButton: {
    marginLeft: 'auto',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    fontSize: '1.5rem',
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '6px',
    gap: '20px',
  },
  buttonRow: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px',
  },
};

const mapDispatchToProps = (dispatch: any) => ({
  toggleCreateLeaderboardConfigModal: (modalState: boolean) => dispatch(toggleCreateLeaderboardConfigModal(modalState)),
  toggleUpdateLeaderboardConfigModal: (modalState: boolean) => dispatch(toggleUpdateLeaderboardConfigModal(modalState)),
  createLeaderboardConfig: (
    selectGymName: string,
    leaderboardConfig: ILeaderboardConfig,
  ) => dispatch(createLeaderboardConfig({
    gym: selectGymName,
    leaderboardConfig,
  })),
  updateLeaderboardConfig: (
    selectGymName: string,
    leaderboardConfig: ILeaderboardConfig,
  ) => dispatch(updateLeaderboardConfig({
    gym: selectGymName,
    leaderboardConfig,
  })),
  refreshGymLeaderboardConfigs: (gymName: string) => dispatch(getLeaderboardConfigs(gymName)),
});

const mapStateToProps = (state: IAppStore) => {
  const { leaderboards, adminSettings } = state;
  const { selectedGymName, gyms } = adminSettings.data;
  const { isOrgAdmin } = adminSettings;
  const { isFetching } = state.leaderboards.view;
  const {
    showCreateLeaderboardConfigModal,
    showUpdateLeaderboardConfigModal,
  } = leaderboards.view;
  const {
    selectedLeaderboardConfig,
  } = leaderboards.data;

  return {
    selectedGymName,
    selectedLeaderboardConfig,
    showCreateLeaderboardConfigModal,
    showUpdateLeaderboardConfigModal,
    isOrgAdmin,
    gyms,
    isFetching,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardConfigForm);
