import React, { Component } from 'react';

interface IProps {
  close: () => void;
  header: string;
  subHeader?: string;
}

class Success extends Component<IProps> {
  public render() {
    const { close, header, subHeader } = this.props;

    return (
      <div>
        <div className='d-flex justify-content-end overflow-scroll'>
          <button
            className='modal-close-button'
            onClick={close}
          >
            X
          </button>
        </div>
        <div className='merchandise-post-purchase-container'>
          <h1 className='header'>{header}</h1>
          <img
            className='btnImg close-icon-icon'
            src='resources/icons/check-mark-outline.svg'
          />
          {subHeader && <div className='text-container success'>
            <p className='text'>{subHeader}</p>
          </div>}
        </div>
      </div>
    );
  }
}

export default Success;
