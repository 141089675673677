import { IMemberDetailsData} from 'fitbeat.gym-core';
import { Gym, IMemberColumnData, IUserWorkoutSessionMemberReport, MembersReportData, ReportType } from 'fitbeat.models';
import moment from 'moment';
import * as React from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { Filter, SortingRule } from 'react-table';
import { getSettings } from '../adminSettings/actions';
import { currentGymSelector } from '../adminSettings/selector';
import ColumnSelector from '../members/components/columnSelector';
import ConfirmationModal from '../members/components/confirmationModal';
import SaveReportModal from '../members/components/saveReportModal';
import { deleteConfirmationActions, updateConfirmationActions } from '../members/config';
import { convertDataToViews } from '../members/storeTypes';
import { IAppStore } from '../rootReducer';
import { Loading } from '../shared/loading';
import {
  clearFilterData, deleteReport, getAllData,
  getSavedReports, toggleConfirmationModal,
  toggleIsReportClone, toggleReportModal, updateReport,
} from './actions';
import { List } from './components/memberList';
import MemberReportHeaderReportSelection from './components/MemberReportHeaderReportSelection';
import ReportActionsDropdown from './components/ReportActionsDropdown';

interface IProps {
  memberDetailsData: IMemberDetailsData[];
  allWorkouts: IUserWorkoutSessionMemberReport[];
  excludedEmails: string;
  reportName: string;
  reportId: string;
  activeReport: MembersReportData;
  memberReportData: MembersReportData[];
  isNewReport: boolean;
  isDeleteConfirmation: boolean;
  memberColumnData: IMemberColumnData[];
  filtered: Filter[];
  sortOrder: SortingRule[];
  exportData: any[];
  isFetching: boolean;
  currentGym: Gym;
  enableDataExport: boolean;
  lastViewedWorkoutsReport: string;
  showConfirmationModal: boolean;
  canSaveReports: boolean;
  error: string | null;
  getSavedReports(reportType: ReportType): void;
  clearFilterData(): void;
  getSettings(): void;
  getAllData(excludedEmails: string, gymName: string): void;
  toggleIsReportClone(): void;
  toggleReportModal(): void;
  toggleConfirmationModal(show: boolean): void;
  deleteReport(reportId: string): void;
  updateReport(): void;
}
class Workouts extends React.Component<IProps, any> {
  constructor(props: any) {
    super(props);
  }

  public componentDidMount = async () => {
    if (this.props.memberDetailsData.length === 0) {
      this.props.getSavedReports(ReportType.workouts);
      this.props.getAllData(this.props.excludedEmails, this.props.currentGym.name);
    }
  }

  public render() {
    const { canSaveReports } = this.props;
    const deleteTitles = deleteConfirmationActions(this.props.reportName);
    const updateTitles = updateConfirmationActions(this.props.reportName);
    return (
      <div className='app-wrapper'>
        <div className='table-action-header text-center'>
          <div className='row align-items-center report-top-section '>
            <div className='col'>
              <MemberReportHeaderReportSelection reportType={ReportType.workouts}/>
            </div>
            <div className='row'>
            {this.props.enableDataExport &&
                <div className='settings'>
                  <CSVLink
                    data={this.props.exportData || this.props.allWorkouts}
                    asyncOnClick={true}
                    filename={`${moment(new Date()).toISOString()}-workout-table.csv`}
                    target='_blank'>
                    <button className='jr-btn-no-border black-color-text'>
                      <img className='btnImg csvExportIconContainer' src='resources/images/csv-export-icon.svg' />
                          CSV Export
                        </button>
                  </CSVLink>
                </div>
              }
              <ColumnSelector />
              {canSaveReports && (
                <>
                  <ReportActionsDropdown />
                  <SaveReportModal />
                </>
              )}
            </div>
          </div>
          {this.props.isDeleteConfirmation ?
            <ConfirmationModal
              error={this.props.error}
              isFetching={this.props.isFetching}
              showConfirmationModal={this.props.showConfirmationModal}
              titles={deleteTitles}
              onCancel={() => this.props.toggleConfirmationModal(false)}
              onClose={() => this.props.toggleConfirmationModal(false)}
              onYesClick={() => this.props.deleteReport(this.props.reportId)} /> :
            <ConfirmationModal
              error={this.props.error}
              isFetching={this.props.isFetching}
              showConfirmationModal={this.props.showConfirmationModal}
              titles={updateTitles}
              onCancel={() => this.props.toggleConfirmationModal(false)}
              onClose={() => this.props.toggleConfirmationModal(false)}
              onYesClick={() => this.props.updateReport()} />}
          {this.props.memberDetailsData.length === 0 || this.props.isFetching ?
            <div className={'loader-container'}>
              <Loading />
            </div>
            :
            <List
              report={this.props.activeReport}
              reportType={ReportType.workouts}
            />
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getAllData,
  clearFilterData,
  toggleConfirmationModal,
  toggleReportModal,
  toggleIsReportClone,
  deleteReport,
  updateReport,
  getSettings,
  getSavedReports,
};
const mapStateToProps = (state: IAppStore) => {
  const { members, adminSettings } = state;
  const { canSaveReports } = state.adminSettings;
  const { isNewReport, isClone, filtered, sortOrder, isDeleteConfirmation,
    error, isFetching, showConfirmationModal } = members.view;
  const { exportData, enableDataExport } = adminSettings.data;
  const {
    activeReport,
    memberDetailsData,
    allWorkouts,
    lastViewedWorkoutsReport,
    memberReportData,
   } = members.data;
  const { memberColumnData, reportName, reportId } = members.data.activeReport;
  const currentGym = currentGymSelector(state);
  const memberColumnDataView = convertDataToViews(
    memberColumnData,
    state.members.data.filterDataFromMembersData, currentGym);
  const { excludedEmails } = adminSettings.data;
  return {
    memberTableColumns: memberColumnDataView.filter((column) => column.used),
    exportData,
    reportName,
    isNewReport,
    reportId,
    isClone,
    filtered,
    sortOrder,
    isDeleteConfirmation,
    error,
    isFetching,
    activeReport,
    memberColumnData,
    memberDetailsData,
    allWorkouts,
    excludedEmails,
    currentGym,
    enableDataExport,
    lastViewedWorkoutsReport,
    memberReportData,
    showConfirmationModal,
    canSaveReports,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Workouts);
