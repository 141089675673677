import React from 'react';
import 'react-dates/initialize';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { logout } from './auth';
import App from './containers/App';
import Login from './Login';
import { IAppStore } from './rootReducer';
import { history } from './store';

interface IProps {
  isAuthenticated: boolean;
}

class Main extends React.Component<IProps> {
  public render() {
    if (location.pathname === '/logout') {
      logout();
      return (<div>Logging you out</div>);
    }
    if (!this.props.isAuthenticated) {
      return (
        <Login />
      );
    }

    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Route path='/' component={App} />
        </Switch>
      </ConnectedRouter>
    );
  }
}

const mapStateToProps = (state: IAppStore): Partial<IProps> => ({
  isAuthenticated: state.adminSettings.isAuthenticated,
});

export default connect(mapStateToProps, null)(Main);
