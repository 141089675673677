import {
  Gym,
  GymEnvironment,
  GymTypes,
  ILeaderboardConfig,
  ILeaderboardDataViewModel,
  ILeaderboardInstance,
  Organisation,
} from 'fitbeat.models';

import moment from 'moment';

export function mapLeaderboardInstanceToOption(
  leaderboardInstance: ILeaderboardInstance,
) {
  return {
    value: leaderboardInstance,
    label: `${moment(leaderboardInstance.startDate).format(
      'D MMM YYYY',
    )} - ${leaderboardInstance.endDate !== 'undefined'
      ? moment(leaderboardInstance.endDate).format('D MMM YYYY')
      : 'Recurring'}`,
  };
}

export function mapLeaderboardTeamsToOption(
  teamName: string,
) {
  return {
    value: teamName,
    label: teamName,
  };
}

export function mapTeamsLeaderboardAverageMinutesToSeconds(
  leaderboardInstanceDetails: ILeaderboardDataViewModel,
): ILeaderboardDataViewModel {
  const updatedRankings = leaderboardInstanceDetails?.rankings.map((leaderboard) => ({
    ...leaderboard,
    value: leaderboard.value * 60,
  }));

  const teamInstanceDetails = {
    ...leaderboardInstanceDetails,
    rankings: updatedRankings,
  };
  return teamInstanceDetails;
}

export function setLeaderboardConfig(
  gyms: Gym[],
  selectedGymName: string,
  isOrgAdmin: boolean,
  allGymsInOrg: string,
) {
  const timezones = moment.tz.names();

  const orderedGyms = [...gyms].sort((a, b) => {
    if (a.name === selectedGymName) {
      return -1;
    }
    if (b.name === selectedGymName) {
      return 1;
    }
    return 0;
  });

  if (isOrgAdmin) {
    orderedGyms.unshift({
      name: allGymsInOrg,
      displayName: 'All Gyms',
      created: new Date(),
      type: GymTypes.Indoor,
      numOfZones: 0,
      timezone: 'Australia/Sydney',
      iso2Country: '',
      zoneMapping: [],
      publicHolidays: [],
      cancelledDays: [],
      environment: GymEnvironment.None,
      allowedOnProductIds: [],
      autoCheckin: false,
      enableStudioAppCheckIn: true,
      allowedCapacityAsPercentOfFullGym: 1,
      enabled: true,
      accountId: '',
      websiteSlug: '',
      singleStationPerZoneGym: false,
      shouldUseInAppPurchaseForMembership: false,
      organisation: Organisation.Test,
      specificTimeSlotTypes: [],
    });
  }
  return { orderedGyms, timezones };
}

export function transformLeaderboardConfig(
  formData: ILeaderboardConfig,
  allGymsName: string,
  teams: string[],
  updating: boolean,
) {
  if (!updating) {
    const allGymsInOrg = formData.gymName === allGymsName;
    formData.startDate = moment.tz(formData.startDate, formData.timezone).startOf('day').toDate();

    if (allGymsInOrg) {
      formData.gymName = undefined;
    }
  }
  if (formData.competitionType === 'inter-team') {
    formData.teams = teams;
  } else {
    formData.teams = undefined;
  }
  if (formData.endDate) {
    formData.endDate = moment.tz(formData.endDate, formData.timezone).endOf('day').toDate();
  }
}
