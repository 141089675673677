import * as React from 'react';
import { paymentLink } from '../config';

export const paymentButton = (row: any) => {
    return (
        <div>
            {row.continueSignupToken &&
            <button style={{ backgroundColor: 'transparent', color: '#FF621D' }}
            onClick={() => goTo(row.continueSignupToken)}>
               Continue Signup
            </button>}
        </div>
    );
};

const goTo = (token: string) => {
    window.open(paymentLink + token);
};
