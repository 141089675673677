import { BaseUserProfile } from 'fitbeat.models';
import React, { RefObject } from 'react';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';
import { uploadAvatar } from '../actions';
import { memberAvatarUrlSelector } from '../selector';

interface IProps {
  UID: string;
  memberDetail: BaseUserProfile;
  isUploadingAvatar: boolean;
  avatarUrl?: string;
  uploadAvatarImage(uid: string, base64: string): void;
}

class MemberDetailsAvatarComponent extends React.Component<IProps> {
  private avatrInputRef: RefObject<HTMLInputElement>;
  constructor(props: any) {
    super(props);
    this.avatrInputRef = React.createRef() as RefObject<HTMLInputElement>;
  }

  public convertBase64 = (file: Blob): any => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  public handleAvatarUpload = async (e: any) => {
    const { UID, uploadAvatarImage } = this.props;
    const file = e.target.files[0];
    const base64 = await this.convertBase64(file);
    const formattedBase64 = base64.split(';base64')[1];
    uploadAvatarImage(UID, formattedBase64);
  }

  public handAvatarClicked = () => {
    if (this.avatrInputRef && this.avatrInputRef.current) {
      this.avatrInputRef.current.click();
    }
  }

  public render() {
    const { avatarUrl, isUploadingAvatar } = this.props;
    const overlayStyles = `overlay ${(isUploadingAvatar && 'show-overlay') ?? ''}`;
    return (
      <div
        className='member-details-avatar-container'
        onClick={this.handAvatarClicked}
      >
        <div className={overlayStyles}>
          {isUploadingAvatar && (
            <Loader
              type='ThreeDots'
              color='#FF621D'
              height={50}
              width={50}
              timeout={3000}
            />
          )}
        </div>
        <input
          ref={this.avatrInputRef}
          type='file'
          accept='image/*'
          onChange={this.handleAvatarUpload}
        />
        <p>
          Change
          <br />
          Profile <br />
          Photo
        </p>
        <img
          alt='Avatar'
          src={avatarUrl}
          onError={this.addDefaultSrc}
          className='member-detail-avatar'
        />
      </div>
    );
  }
  private addDefaultSrc(ev: any) {
    ev.target.src = `resources/images/user.svg`;
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  uploadAvatarImage: (uid: string, base64: string) => {
    dispatch(uploadAvatar(uid, base64));
  },
});

const mapStateToProps = (state: IAppStore, ownProps: any) => {
  return {
    isUploadingAvatar: state.members.view.isUploadingAvatar,
    avatarUrl: memberAvatarUrlSelector(state, ownProps.UID),
  };
};

export const MemberDetailsAvatar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberDetailsAvatarComponent);
