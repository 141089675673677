import { BaseApiClient, IApiInterface } from 'fitbeat.api-clients';
import { IMemberDetailsData } from 'fitbeat.gym-core';
import {
  IAccountComment,
  ICreateSpecialOfferParams,
  IOrganisationConfig,
  ISpecialOfferDisplay,
  IUserWorkoutSessionMemberReport,
  LeadStatus,
  MembersReportData,
  UserAccountDetail,
  UserProfile,
} from 'fitbeat.models';
import fetch from 'isomorphic-fetch';

export class MembersService extends BaseApiClient {
  private logger: any;

  public constructor(config: IApiInterface) {
    super(config, fetch, undefined);
    this.logger = (() => {
      // empty
    });
    this.fetch = fetch;
  }

  public async getOrginisationConfig(gymName: string): Promise<IOrganisationConfig> {
    this.logger('[BusinessToolsClient] getting org gym');
    const request = await this.buildGetRequest(`business-tools/gyms/${gymName}/organisation`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getMembers(
    { emailExclusions, gymName }
    : { emailExclusions?: string; gymName: string; },
    workoutsDateRange?: { workoutsFromDate: Date, workoutsToDate?: Date },
  ): Promise<IMemberDetailsData[]> {
    this.logger('[BusinessToolsClient] getting members for gym');

    const querys = [
      ...emailExclusions ? [`emailExclusions=${emailExclusions && encodeURIComponent(emailExclusions)}`] : [],
      ...workoutsDateRange?.workoutsFromDate
        ? [`workoutsFromDate=${workoutsDateRange.workoutsFromDate.toISOString()}`]
        : [],
      ...workoutsDateRange?.workoutsToDate
        ? [`workoutsToDate=${workoutsDateRange.workoutsToDate.toISOString()}`]
        : [],
    ];

    let queryString = '';
    querys.map((query, inx) => {
      if (inx === 0) {
        queryString = '?';
      }
      if (inx !== 0) {
        queryString += '&';
      }
      queryString += query;
    });

    const request = await this.buildGetRequest(`business-tools/gyms/${gymName}/members${queryString}`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getMemberDetails(uid: string): Promise<UserAccountDetail> {
    this.logger(`[BusinessToolsClient] getting member detail for ${uid}`);
    const request = await this.buildGetRequest(`business-tools/members/${uid}`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async updateMember(uid: string, updatedMember: any): Promise<UserProfile> {
    this.logger(`[BusinessToolsClient] update member detail for ${uid}`);
    const request = await this.buildPutRequest(`business-tools/members/${uid}`, updatedMember, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getMemberComments(uid: string): Promise<IAccountComment[]> {
    this.logger(`[BusinessToolsClient] getting member comments for ${uid}`);
    const request = await this.buildGetRequest(`business-tools/members/${uid}/comments`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data.comment;
  }

  public async updateLeadStatus(uid: string, leadStatus: LeadStatus): Promise<string> {
    this.logger(`[BusinessToolsClient] updating lead status ${uid}`);
    const request = await this.buildPutRequest(`business-tools/members/${uid}/leadStatus`, {
      leadStatus,
    }, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async updateTeam(uid: string, teamid: string): Promise<string> {
    this.logger(`[BusinessToolsClient] updating team for user ${uid}`);
    const request = await this.buildPutRequest(`business-tools/member/${uid}/team`, {
      teamid,
    }, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async updateComments(uid: string, comment: string): Promise<IAccountComment[]> {
    this.logger(`[BusinessToolsClient] update member comments for ${uid}`);
    const request = await this.buildPutRequest(`business-tools/members/${uid}/comments`, { comment }, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async saveFollowUpDate(uid: string, followUpDate: string): Promise<{ updatedFollowUpDate: Date | undefined }> {
    this.logger(`[BusinessToolsClient] update member follow up date for ${uid}`);
    const request = await this.buildPutRequest(`business-tools/members/${uid}/followUpDate`, { followUpDate }, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getFollowUpDate(uid: string): Promise<{ followUpDate: Date | undefined }> {
    this.logger(`[BusinessToolsClient] update member follow up date for ${uid}`);
    const request = await this.buildGetRequest(`business-tools/members/${uid}/followUpDate`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async removeFollowUpDate(uid: string) {
    this.logger(`[BusinessToolsClient] update member follow up date for ${uid}`);
    const request = await this.buildDeleteRequest(`business-tools/members/${uid}/followUpDate`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async createMemberReport(report: MembersReportData) {
    this.logger(`[BusinessToolsClient] create new member report: ${report.reportName}`);
    const request = await this.buildPostRequest('business-tools/memberReport', report, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getAllMemberReports() {
    this.logger('[BusinessToolsClient] getting all member reports');
    const request = await this.buildGetRequest('business-tools/memberReports', true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async updateMemberReport(reportId: string, report: MembersReportData) {
    this.logger(`[BusinessToolsClient] updating member report: ${report.reportName}`);
    const request = await this.buildPutRequest(`business-tools/memberReport/${reportId}`, report, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getDashboardConfig() {
    this.logger('[BusinessToolsClient] getting all member reports');
    const request = await this.buildGetRequest('business-tools/dashboardConfig', true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async updateReportOrderList(reportId: string, moveDown: boolean) {
    this.logger(`[BusinessToolsClient] updating member report: ${reportId}`);
    const request = await this.buildPutRequest('business-tools/dashboardConfig', { reportId, moveDown }, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async deleteMemberReport(reportId: string) {
    this.logger(`[BusinessToolsClient] deleting member report: ${reportId}`);
    const request = await this.buildDeleteRequest(`business-tools/memberReport/${reportId}`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getAllWorkoutsForGym(gymName: string): Promise<IUserWorkoutSessionMemberReport[]> {
    this.logger(`[BusinessToolsClient] getting al workouts for ${gymName}`);
    const request = await this.buildGetRequest(`business-tools/gyms/${gymName}/workouts`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async uploadAvatar(uid: string, base64Image: string) {
    this.logger(`[BusinessToolsClient] updating avatar for user with id ${uid}`);
    const request = await this.buildPostRequest(`user/${uid}/avatar`, {
      base64Image,
    }, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async createSpecialOffer(
    uid: string,
    specialOffer: ICreateSpecialOfferParams,
  ): Promise<ISpecialOfferDisplay> {
    this.logger(`[BusinessToolsClient] creating special offer for: ${uid}`);
    const request = await this.buildPostRequest(
      `business-tools/members/${uid}/special-offers`,
      specialOffer,
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    return this.assertHttp200AndGetJsonFromResult(result);
  }

  public async updateSpecialOffer(
    uid: string,
    specialOfferId: string,
    specialOffer: ICreateSpecialOfferParams,
  ): Promise<ISpecialOfferDisplay> {
    this.logger(`[BusinessToolsClient] updating special offer for: ${uid}`);
    const request = await this.buildPutRequest(
      `business-tools/members/${uid}/special-offers/${specialOfferId}`,
      specialOffer,
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    return this.assertHttp200AndGetJsonFromResult(result);
  }
}
