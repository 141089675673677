import { IMemberDetailsData } from 'fitbeat.gym-core';
import {
  Gym,
  ILeaderboardRanking,
  IUserWorkoutSessionMemberReport,
} from 'fitbeat.models';
import { IInstanceRankingRow } from '../leaderboards/Leaderboards';

export interface IAdminSettingsData {
  excludedEmails: string;
  gyms: Gym [];
  selectedGymName: string | null;
  exportData: IInstanceRankingRow[]
  | ILeaderboardRanking[]
  | IMemberDetailsData[]
  | IUserWorkoutSessionMemberReport[];
  enableDataExport: boolean;
}
export interface IAdminSettingsState {
  isAuthenticated: boolean;
  canSaveReports: boolean;
  canViewSubscriptionDetails: boolean;
  isOrgAdmin: boolean;
  data: IAdminSettingsData;
  view: IAdminSettingsUIView;
}

export interface IAdminSettingsUIView {
  error: null | string;
  successMessage: null | string;
  isFetching: boolean;
  appInitialising: boolean;
  appInitialisationError: string | null;
}

export const initialState: IAdminSettingsState = {
  isAuthenticated: false,
  canSaveReports: false,
  canViewSubscriptionDetails: false,
  isOrgAdmin: false,
  data: {
    gyms: [],
    excludedEmails: localStorage.getItem('emailExclusion') || '****',
    selectedGymName: localStorage.getItem('selectedGymName'),
    exportData: [],
    enableDataExport: false,
  },
  view: {
    error: null,
    isFetching: false,
    successMessage: null,
    appInitialising: false,
    appInitialisationError: null,
  },
};
