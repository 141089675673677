import * as React from 'react';

export const invoiceLink = (row: any) => {
    return (
        <div>
            {row.invoiceUrl &&
            <button style={{ backgroundColor: 'transparent', color: '#FF621D' }}
            onClick={(event) => {
              event.stopPropagation();
              goTo(row.invoiceUrl);
            }}>
               {row.invoiceUrl}
            </button>}
        </div>
    );
};

const goTo = (invoiceURL: string) => {
    window.open(invoiceURL);
};
