import React, { useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { currentGymSelector } from '../../adminSettings/selector';
import { IAppStore } from '../../rootReducer';
import { resetTempColumnStore, saveColumnUsed, sortMemberTableColumn, toggleMemberTableColumnIsUsed } from '../actions';
import { convertDataToViews, ISortColumn } from '../storeTypes';
import { SelectableColumns } from './Columns';
const ColumnSelector: React.FC<{
  usedColumns: any[], unUsedColumns: any[], setColumnAsUsed: any, sortColumn: any, save: any, reportName: string,
  resetColumns: any,
}> = ({ usedColumns, unUsedColumns, setColumnAsUsed, sortColumn, save, reportName, resetColumns }) => {
  const [showModal, setModal] = useState<boolean>(false);
  const toggleModal = () => {setModal(!showModal); resetColumns(); };
  const saveColumnData = () => { save(); toggleModal(); };
  return (
    <div style={{ display: 'inline-block' }}>
      <button className='jr-btn-no-border black-color-text'
        onClick={toggleModal}>
        <img className='btnImg showOrHideColumnIconContainer' src='resources/images/hide-or-show-column-icon.svg'/>
        Show/Hide Columns</button>
      <Modal
        isOpen={showModal}
        className='modal-content'
        overlayClassName='modal-overlay'
        ariaHideApp={false}
        contentLabel='Columns'>
        <div className='column-label content-margin'>
          <h2
            className='column-header'>
            Show/Hide Columns
                </h2>
          <button
            className='modal-close-button'
            onClick={toggleModal}>
            X
                </button>
        </div>
        <div className='modal-content-columns'>
          <SelectableColumns
            header='Visible'
            onSortEnd={sortColumn}
            columns={usedColumns}
            setColumnAsUsed={setColumnAsUsed}
          />
          <SelectableColumns
            header='Hidden (filters still apply)'
            emptyMessage='You have no columns that are not used.'
            onSortEnd={sortColumn}
            columns={unUsedColumns}
            setColumnAsUsed={setColumnAsUsed}
          />
        </div>
        <div className='float-right'>
          <button className='jr-btn-default' style={{ float: 'right', width: '20%', marginRight: 15 }}
            onClick={saveColumnData}>Apply</button>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IAppStore) => {
  const currentGym = currentGymSelector(state);
  const memberColumns = convertDataToViews(
    state.members.view.tempColumnStore, state.members.data.filterDataFromMembersData,
    currentGym);
  const { reportName } = state.members.data.activeReport;
  const { filtered } = state.members.view;
  memberColumns.forEach((col: any) => {
    filtered.forEach((filter: any) => {
      if (filter.id === col.accessor) {
        col.hasFilter = true;
      }
    });
  });
  const usedColumns = memberColumns.filter((column) => column.used);
  const unUsedColumns = memberColumns.filter((column) => !column.used);
  return {
    usedColumns,
    unUsedColumns,
    reportName,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setColumnAsUsed: (name: string) => dispatch(toggleMemberTableColumnIsUsed(name)),
    sortColumn: ({ oldIndex, newIndex }: ISortColumn) => {
      return dispatch(sortMemberTableColumn(oldIndex, newIndex));
    },
    save: () => dispatch(saveColumnUsed()),
    resetColumns: () => dispatch(resetTempColumnStore()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnSelector);
