import { ILeaderboardConfig, ILeaderboardInstance, IMemberColumnData } from 'fitbeat.models';
import React, { Component, createRef, LegacyRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { currentGymSelector } from '../../adminSettings/selector';
import { membersColumnsSelector } from '../../members/selector';
import { IAppStore } from '../../rootReducer';
import { Loading } from '../../shared/loading';
import { availableLeaderboardsAccessors } from '../leaderboardsFilters';
import { getLeaderboardInstances, setSelectedInstance } from '../leaderboardsSlice';

interface IProps {
  isFetching: boolean;
  selectedGymName: string;
  membersColumns: IMemberColumnData[];
  leaderboards: ILeaderboardConfig[];
  selectedLeaderboardConfig: ILeaderboardConfig | null;
  loadLeaderboardInstances(instanceDetails: { gym: string, leaderboardConfigId: string }): void;
}
class LeaderboardsMainTable extends Component<IProps> {
  public reactTable: LegacyRef<ReactTable<ILeaderboardConfig>> | undefined;

  constructor(props: any) {
    super(props);
    this.reactTable = createRef<ReactTable<ILeaderboardConfig>>();
  }

  public render() {
    const {
      isFetching,
      leaderboards,
      selectedGymName,
      selectedLeaderboardConfig,
      loadLeaderboardInstances,
    } = this.props;
    const configListIsEmpty = leaderboards.length === 0;
    return (isFetching && configListIsEmpty)
      ? (
        <div className='loader-container'>
          <Loading />
        </div>
      )
      : (
        <div className='leaderboards-table leaderboard-configs'>
          <ReactTable
            className='clickable-row'
            ref={this.reactTable}
            minRows={0}
            resizable
            data={leaderboards}
            showPagination={false}
            defaultPageSize={1000}
            loadingText='Loading...'
            noDataText='No data found'
            columns={this.props.membersColumns}
            TrGroupComponent={(props: any) => (
              <div
                onClick={() => {
                  if (props.configId) {
                    loadLeaderboardInstances({ gym: selectedGymName, leaderboardConfigId: props.configId });
                  }
                }}
                className={`rt-tr-group ${selectedLeaderboardConfig?._id === props.configId
                  ? 'selected-config-row' : ''}`}
                role='rowgroup'
              >
                {props.children}
              </div>
            )}
            getTrGroupProps={(_: any, props: any) => ({ configId: props.original._id })}
          />
        </div>
      );
  }
}

const mapStateToProps = (state: IAppStore) => {
  const { adminSettings, leaderboards } = state;
  const {
    isFetching,
  } = leaderboards.view;
  const { selectedLeaderboardConfig } = leaderboards.data;
  const { selectedGymName } = adminSettings.data;
  const currentGym = currentGymSelector(state);

  const leaderboardsTableDetails = {
    reportId: 'default leaderboards list',
    reportName: 'Leaderboards Table',
    dateCreated: new Date(),
    memberColumnData: [...availableLeaderboardsAccessors],
    selected: true,
  };
  return {
    isFetching,
    selectedGymName,
    membersColumns: membersColumnsSelector(state, leaderboardsTableDetails, currentGym),
    selectedLeaderboardConfig,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  loadLeaderboardInstances: (instanceDetails: {
    gym: string,
    leaderboardConfigId: string,
  }) => dispatch(getLeaderboardInstances({
    gym: instanceDetails.gym,
    leaderboardConfigId: instanceDetails.leaderboardConfigId,
  }))
    .unwrap()
    .then((result: { leaderboardConfigId: string, leaderboardInstances: ILeaderboardInstance[] }) => {
      const { leaderboardInstances } = result;
      dispatch(setSelectedInstance({ gym: instanceDetails.gym, leaderboardInstance: leaderboardInstances[0] }));
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LeaderboardsMainTable));
