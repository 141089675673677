import { calculateTimeLeftText, isCompleted } from 'fitbeat.gym-core';
import { ILeaderboardConfig, ILeaderboardDataViewModel } from 'fitbeat.models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';

interface IProps {
  instanceDetails: ILeaderboardDataViewModel | null;
  selectedLeaderboardConfig: ILeaderboardConfig | null;
  selectedLeaderboardTeam: string | null;
}
class LeaderboardInstanceStats extends Component<IProps> {
  public render() {
    const {
      instanceDetails,
      selectedLeaderboardConfig,
      selectedLeaderboardTeam,
    } = this.props;
    const isTeamConfig = (selectedLeaderboardConfig && selectedLeaderboardConfig?.teams);
    const isTeamSelected = selectedLeaderboardTeam;
    const daysLeft = instanceDetails ? calculateTimeLeftText(
      instanceDetails.startDate,
      instanceDetails.endDate,
    ) : { unit: 0, timeUnit: 'Days', isUpcoming: false };
    const participants = instanceDetails ? instanceDetails.rankings.length : 0;
    const isComplete = !!instanceDetails && isCompleted(new Date(instanceDetails.endDate));

    return (
      <div className='leaderboard-instance-stats-container'>
        <div className='leaderboard-instance-single-stat-container'>
          <p className='leaderboard-instance-single-stat-value'>{participants}</p>
          <p className='leaderboard-instance-single-stat-label'>
            { (isTeamConfig && !isTeamSelected) ? 'Teams' : 'Participants' }
          </p>
        </div>
        <div className='leaderboard-instance-single-stat-divider' />
        <div className='leaderboard-instance-single-stat-container'>
          {!isComplete && (
            <div>
              <p className='leaderboard-instance-single-stat-value'>{daysLeft.unit}</p>
              <p className='leaderboard-instance-single-stat-label'>
                { daysLeft.timeUnit }
                { daysLeft.isUpcoming ? ' until start' : ' left' }
              </p>
            </div>
          )}
          {isComplete && (
            <p className='leaderboard-instance-single-stat-value'>Completed</p>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};
const mapStateToProps = (state: IAppStore) => {
  const { leaderboards } = state;

  const {
    selectedLeaderboardInstanceDetails,
    selectedLeaderboardConfig,
    selectedLeaderboardTeam,
  } = leaderboards.data;

  return {
    selectedLeaderboardConfig,
    selectedLeaderboardTeam,
    instanceDetails: selectedLeaderboardInstanceDetails,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardInstanceStats);
