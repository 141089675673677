import { Gym, ISpecialOfferDisplay, UserAccountDetail } from 'fitbeat.models';
import moment from 'moment-timezone';
import React, { FunctionComponent } from 'react';
import QRCode from 'react-qr-code';
import {
  buildSMSLinkForSpecialOffer,
  getEmailLink,
} from '../../../util/helpers';
import { getCouponAmountOffDisplay } from '../formElements/plan-coupon-select-helper-functions';
import { TextCopyToClipBoard } from '../TextCopyToClipBoard';
import { ILineItem, SpecialOfferLineItem } from './SpecialOfferLineItem';

interface IProps {
  specialOffer: ISpecialOfferDisplay;
  userAccountDetails: UserAccountDetail;
  currentGym: Gym;
  borderBottom?: boolean;
}

export const SpecialOfferCard: FunctionComponent<IProps> = (
  {
    specialOffer,
    userAccountDetails,
    currentGym,
  },
) => {
  const emailLink = getEmailLink(currentGym, userAccountDetails.userInfo.profile, specialOffer.shortLink);
  const smsLink = buildSMSLinkForSpecialOffer(userAccountDetails.userInfo.profile, specialOffer.shortLink, currentGym);

  const couponAmountOff = getCouponAmountOffDisplay(specialOffer);
  let couponData;
  if (couponAmountOff) {
    couponData = {
      heading: 'Coupon:',
      description: specialOffer.couponDescription,
      rightContainerValue: {
        valueSymbol: specialOffer.couponPercentOff ? '%' : specialOffer.currencySymbol,
        value: couponAmountOff,
      },
    };
  }

  const lineItems: ILineItem[] = [
    {
      heading: 'Plan:',
      description: specialOffer.planDescription,
      rightContainerValue: {
        valueSymbol: specialOffer.currencySymbol,
        value: specialOffer.totalValueBeforeDiscount.toString(),
      },
    },
      ...(couponData ? [couponData] : [] ),
    {
      heading: `Total per ${specialOffer.interval}`,
      rightContainerValue: {
        valueSymbol: specialOffer.currencySymbol,
        value: specialOffer.totalChargedToCustomer.toString(),
      },
    },
    {
      heading: 'Billing will start on',
      rightContainerValue: {
        date: moment(specialOffer.billingStartDate).format('DD MMM YYYY'),
      },
    },
  ];

  return (
    <div className='card border-0 shadow-lg'>
      <div className='card-body p-3'>

        <h3 className='member-details-section-sub-header'>
          Special Membership Offer
        </h3>

        <div className='row'>
          <div className='col-8'>
            {lineItems.map((item, index) => <SpecialOfferLineItem {...item} key={index} />)}
          </div>

          <div className='col-4'>
            <QRCode
              value={specialOffer.shortLink}
              size={140}
              preserveAspectRatio='xMidYMid meet'
            />
          </div>

        </div>

        <div className='row align-items-center mt-3'>

          <div className='col-5'>
            <div className='row no-gutters'>
              <div className='col d-flex justify-content-center'>
                <a type='button' className='btn btn-link btn-lg link-btn-text p-0' href={emailLink} target='_blank'>
                  Email Offer
                </a>
              </div>
              <div className='col d-flex justify-content-center'>
                <a type='button' className='btn btn-link btn-lg link-btn-text p-0' href={smsLink} target='_blank'>
                  SMS Offer
                </a>
              </div>
            </div>
          </div>

          <div className='col-7'>
            <TextCopyToClipBoard text={specialOffer.shortLink} id={'std-membership-plan-link'} />
          </div>

        </div>
      </div>
    </div>
  );
};
