import { Gym } from 'fitbeat.models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';
import Dropdown from '../../shared/components/Dropdown';
import { selectGym } from '../actions';
import { currentGymSelector } from '../selector';

interface IProps {
  gyms: Gym[];
  currentGym: Gym;
  handleOnGymSelect: (gym: Gym) => void;
}

class GymDropDown extends Component<IProps> {
  public render() {
    const options = this.props.gyms.map((gym) => {
      return {
        label: gym.displayName,
        value: gym,
      };
    });

    return (
      <div className='navbar-gym-dropdown'>
        <Dropdown
            onChange={(selected: { label: string; value: Gym; }) => this.props.handleOnGymSelect(selected.value)}
            options={options}
            value={this.props.currentGym && {
              label: this.props.currentGym.displayName,
              value: this.props.currentGym,
            }}
            placeholder='Select Gym'
            className='gym select-container'
            classNamePrefix='gym select'
            isSearchable={false}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IAppStore) => {
  const { gyms } = state.adminSettings.data;
  const currentGym = currentGymSelector(state);
  return { currentGym, gyms };
};

const mapDispatchToProps = (dispatch: any) => ({
  handleOnGymSelect: (gym: Gym) => dispatch(selectGym(gym)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GymDropDown);
