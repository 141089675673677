import { UnauthorizedErrorMessage, UserServiceClient } from 'fitbeat.api-clients';
import { IMemberDetailsData } from 'fitbeat.gym-core';
import {
  Gym,
  IBasicAuthenticationUser,
  ILeaderboardRanking,
  IUserWorkoutSessionMemberReport,
} from 'fitbeat.models';
import ReactTable, { Instance } from 'react-table';
import { IInstanceRankingRow } from '../leaderboards/Leaderboards';
import {
  getLeaderboardConfigs,
} from '../leaderboards/leaderboardsSlice';
import { getAllData } from '../members/actions';
import { IAppStore } from '../rootReducer';
import getLogger from '../util/logging';
import { updateZendeskWidget } from './zendesk';

export const GET_SETTINGS = 'GET_SETTINGS';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_EMAIL_EXCLUSION = 'SET_EMAIL_EXCLUSION';
export const SET_PLAN_INCLUSION = 'SET_PLAN_INCLUSION';
export const LOAD_INITIAL_APP_STATE_REQUEST = 'LOAD_INITIAL_APP_STATE_REQUEST';
export const LOAD_INITIAL_APP_STATE_SUCCESS = 'LOAD_INITIAL_APP_STATE_SUCCESS';
export const LOAD_INITIAL_APP_STATE_FAILURE = 'LOAD_INITIAL_APP_STATE_FAILURE';
export const LOAD_INITIAL_APP_STATE_UNAUTHORIZED = 'LOAD_INITIAL_APP_STATE_UNAUTHORIZED';
export const GYM_SELECTED = 'GYM_SELECTED';
export const GET_EXPORT_DATA = 'GET_EXPORT_DATA';

interface IGetSettings {
  type: typeof GET_SETTINGS;
  payload: any;
}

interface ISetSettings {
  type: typeof SET_SETTINGS;
  payload: any;
}

interface ISetEmailExclusion {
  type: typeof SET_EMAIL_EXCLUSION;
  payload: string;
}

interface ISetPlanInclusion {
  type: typeof SET_PLAN_INCLUSION;
  payload: string;
}

interface ILoadInitialAppStateRequest {
  type: typeof LOAD_INITIAL_APP_STATE_REQUEST;
}

interface ILoadInitialAppStateSuccess {
  type: typeof LOAD_INITIAL_APP_STATE_SUCCESS;
  payload: {
    user: IBasicAuthenticationUser,
  };
}

interface ILoadInitialAppStateFailure {
  type: typeof LOAD_INITIAL_APP_STATE_FAILURE;
}

interface ILoadInitialAppStateUnauthorized {
  type: typeof LOAD_INITIAL_APP_STATE_UNAUTHORIZED;
}

interface ISetGym {
  type: typeof GYM_SELECTED;
  payload: {
    gym: Gym,
  };
}

interface IGetExportData {
  type: typeof GET_EXPORT_DATA;
  payload: {
    exportData: IInstanceRankingRow[]
    | ILeaderboardRanking[]
    | IMemberDetailsData[]
    | IUserWorkoutSessionMemberReport[],
    enableDataExport: boolean,
  };
}

export type AdminSettingsActionTypes =
  IGetSettings
  | ISetSettings
  | ISetEmailExclusion
  | ISetPlanInclusion
  | ILoadInitialAppStateRequest
  | ILoadInitialAppStateSuccess
  | ILoadInitialAppStateFailure
  | ILoadInitialAppStateUnauthorized
  | ISetGym
  | IGetExportData;

const fetchExportData = (exportData: IInstanceRankingRow[]
| ILeaderboardRanking[]
| IMemberDetailsData[]
| IUserWorkoutSessionMemberReport[]): IGetExportData => ({
  type: GET_EXPORT_DATA,
  payload: {
    exportData,
    enableDataExport: true,
  },
});

export const exportDataToCSV = (
  reactTable: { current: ReactTable<any> & Instance<any> },
) => async (dispatch: any) => {
  const tableData = reactTable.current;
  const exportData = extractDataToCsvFormat(tableData);
  if (exportData && exportData.length) {
    exportData.map((obj: any) => {
      delete obj._original;
      delete obj._index;
      delete obj._nestingLevel;
      delete obj._subRows;
      delete obj.select;
      delete obj.continueSignupToken;
    });
  }
  dispatch(fetchExportData(exportData));
  return exportData;
};

const extractDataToCsvFormat = (tableData: Instance<any>): IInstanceRankingRow[]
| ILeaderboardRanking[]
| IMemberDetailsData[]
| IUserWorkoutSessionMemberReport[] => {
  const resolvedTableData = tableData?.getResolvedState();
  const exportData: any[] = resolvedTableData ? [...resolvedTableData.sortedData] : [];
  if (tableData) {
    const mappedOutData = exportData.map((singleUserData: any) => {
      const toCsvData = {};
      if (tableData.props.columns) {
        const { columns } = tableData.props;
        columns.map((singleColumnData: any) => {
          let singleColumn = { ...singleColumnData };
          const columnHeader = singleColumn.Header()?.props?.header;
          switch (singleColumn.accessor) {
            case 'uniqueReference':
              singleColumn = null;
              return;
            case 'workoutSelectionId':
              singleColumn = null;
              return;
            case 'isNew':
              singleColumn = null;
              return;
            case 'memberDetails':
              singleColumn = null;
              return;
            case 'member':
              singleColumn = null;
              return;
            case 'continueSignupToken':
              singleColumn = null;
              return;
            case 'leadStatus':
              delete singleColumn.Cell;
            default:
              break;
          }
          const columnData = singleColumn.Cell
            ? singleColumn.Cell({ original: singleUserData._original })
            : singleUserData._original[singleColumn.accessor];
          toCsvData[columnHeader] = columnData ?? '';
          return singleColumn;
        });
      }
      return toCsvData;
    });
    return mappedOutData;
  }
  return tableData;
};

export const getSettings = (): IGetSettings => {
  const emailsToExclude = String(localStorage.getItem('emailExclusion'));
  const stripeAccountId = String(localStorage.getItem('stripeAccountId'));
  return {
    type: GET_SETTINGS,
    payload: {
      emailsToExclude,
      stripeAccountId,
    },
  };
};

export const setEmailExclusion = (emailExclusion: string): ISetEmailExclusion => {
  localStorage.setItem('emailExclusion', emailExclusion);
  return {
    type: SET_EMAIL_EXCLUSION,
    payload: emailExclusion,
  };
};

export const setGym = (gym: Gym): ISetGym => ({
  type: GYM_SELECTED,
  payload: {
    gym,
  },
});

export const selectGym = (gym: Gym) => async (dispatch: any, getState: () => IAppStore) => {
  const { adminSettings } = getState();
  dispatch(setGym(gym));
  updateZendeskWidget(gym);
  dispatch(getLeaderboardConfigs(gym.name));
  dispatch(getAllData(adminSettings.data.excludedEmails, gym.name));
};

const LoadInitialAppStateRequest = (): ILoadInitialAppStateRequest => ({
  type: LOAD_INITIAL_APP_STATE_REQUEST,
});

const LoadInitialAppStateSuccess = (user: IBasicAuthenticationUser): ILoadInitialAppStateSuccess => ({
  type: LOAD_INITIAL_APP_STATE_SUCCESS,
  payload: {
    user,
  },
});

const LoadInitialAppStateFailure = (): ILoadInitialAppStateFailure => ({
  type: LOAD_INITIAL_APP_STATE_FAILURE,
});

const LoadInitialAppStateUnauthorized = (): ILoadInitialAppStateUnauthorized => ({
  type: LOAD_INITIAL_APP_STATE_UNAUTHORIZED,
});

export function loadInitialStateForApp(userServiceClient: UserServiceClient) {
  const logger = getLogger('admin:actions');
  return async (dispatch: any) => {
    dispatch(LoadInitialAppStateRequest());
    try {
      const response = await userServiceClient.getBasicAuthUserAndRoles();
      logger.trace('successful getBasicAuthUserAndRoles response');
      dispatch(LoadInitialAppStateSuccess(response.user));
      logger.trace('loadInitialStateForApp done');
    } catch (error) {
      if (error.message === UnauthorizedErrorMessage) {
        dispatch(LoadInitialAppStateUnauthorized());
      } else {
        logger.error(error, 'app initialization failed');
        dispatch(LoadInitialAppStateFailure());
      }
    }
  };
}
