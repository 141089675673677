import produce from 'immer';
import {
  ADD_NEW_PHASE, ADD_PAUSE_PHASES, CLOSE_STRIPE_KEY_MODAL,
  DELETE_PHASE, GET_REQUIRED_STRIPE_DATA_FAILURE, GET_REQUIRED_STRIPE_DATA_REQUEST,
  GET_REQUIRED_STRIPE_DATA_SUCCESS, SAVE_STRIPE_KEY_FAILURE, SAVE_STRIPE_KEY_SUCCESS, SET_ERROR_MESSAGE,
  SET_STRIPE_KEY, SET_SUBSCRIPTION_COUPON, SET_SUBSCRIPTION_PHASE_END_DATE, SET_SUBSCRIPTION_PLAN, StripeActionTypes,
  stripeStateTransformations, TOGGLE_STRIPE_ACTIONS, TOGGLE_STRIPE_KEY_MODAL, TOGGLE_STRIPE_SUBSCRIPTIONS_MODAL,
} from './actions';
import { IMemberStripeState, initialState } from './storeTypes';
import { StripeService } from './stripeService';

export const stripeReducer = produce((draft: IMemberStripeState = initialState, action: StripeActionTypes):
  IMemberStripeState => {

  const mappedTransformationForType = stripeStateTransformations[action.type];
  if (mappedTransformationForType) {
    return mappedTransformationForType(draft, action);
  }

  switch (action.type) {
    case TOGGLE_STRIPE_KEY_MODAL:
      if (draft.view.showStripeKeyModal === false && draft.view.showStripeActions === false) {
        draft.view.showStripeKeyModal = true;
      }
      if (draft.view.showStripeActions === true) {
        draft.view.showStripeKeyModal = false;
        draft.view.showStripeActions = false;
      }
      return draft;
    case CLOSE_STRIPE_KEY_MODAL:
      draft.view.showStripeKeyModal = false;
      return draft;
    case SET_STRIPE_KEY:
      draft.view.tempStripeKeyStore = action.payload;
      return draft;
    case SAVE_STRIPE_KEY_SUCCESS:
      draft.data.stripeKey = action.payload;
      draft.view.showStripeKeyModal = false;
      draft.view.error = null;
      draft.view.tempStripeKeyStore = '';
      return draft;
    case SAVE_STRIPE_KEY_FAILURE:
      draft.view.error = action.payload;
      return draft;
    case TOGGLE_STRIPE_ACTIONS:
      draft.view.showStripeActions = !draft.view.showStripeActions;
      return draft;
    case GET_REQUIRED_STRIPE_DATA_REQUEST:
      draft.data.plans = [];
      draft.data.customer = null;
      draft.view.isFetching = true;
      draft.view.error = null;
      return draft;
    case GET_REQUIRED_STRIPE_DATA_SUCCESS:
      draft.data.plans = action.payload.plans;
      draft.data.pausePlansWithPaidFirst = draft.data.plans
        .filter((plan) => plan.product.name.includes(StripeService.pauseProductNameContains))
        .sort((a, b) => b.amount - a.amount);
      draft.data.customer = action.payload.customer;
      draft.view.isFetching = false;
      return draft;
    case GET_REQUIRED_STRIPE_DATA_FAILURE:
      draft.view.error = action.payload;
      draft.view.isFetching = false;
      return draft;
    case TOGGLE_STRIPE_SUBSCRIPTIONS_MODAL:
      draft.view.showSubscriptionsModal = !draft.view.showSubscriptionsModal;
      return draft;
    case SET_SUBSCRIPTION_PHASE_END_DATE:
      draft.data.customer.subscriptions.data.forEach((subscription: any) => {
        if (subscription.id === action.payload.subscriptionId) {
          subscription.schedule.phases[action.payload.phaseIndex].end_date = action.payload.newDate;
        }
      });
      return draft;
    case SET_SUBSCRIPTION_PLAN:
      draft.data.customer.subscriptions.data.forEach((subscription: any) => {
        if (subscription.id === action.payload.subscriptionId) {
          subscription.schedule.phases[action.payload.phaseIndex].plans[0].plan = action.payload.planId;
        }
      });
      return draft;
    case SET_SUBSCRIPTION_COUPON:
        draft.data.customer.subscriptions.data.forEach((subscription: any) => {
          if (subscription.id === action.payload.subscriptionId) {
            subscription.schedule.phases[action.payload.phaseIndex].coupon = action.payload.couponId;
          }
        });
        return draft;
    case ADD_NEW_PHASE:
      draft.view.error = null;
      draft.data.customer.subscriptions.data.forEach((subscription: any) => {
        if (subscription.id === action.payload) {
          const numberOfPhases = subscription.schedule.phases.length;
          const lastPhase = subscription.schedule.phases[numberOfPhases - 1];
          const lastPhaseEndDate = new Date(lastPhase.end_date * 1000);
          lastPhaseEndDate.setDate(lastPhaseEndDate.getDate() + 7);
          const lastPhaseCoupon = lastPhase.coupon;
          subscription.schedule.phases.push({
            end_date: Math.floor(lastPhaseEndDate.getTime() / 1000),
            coupon: lastPhaseCoupon,
            plans: [{
              billing_thresholds: null,
              plan: null,
              price: null,
              quantity: 1,
              tax_rates: [],
            }],
          });
        }
      });
      return draft;
    case ADD_PAUSE_PHASES:
      draft.view.error = null;
      draft.data.customer.subscriptions.data.forEach((subscription: any) => {
        if (subscription.id === action.payload) {
          const numberOfPhases = subscription.schedule.phases.length;
          if (numberOfPhases > 1) {
            draft.view.error =
            'Cannot create a pause schedule if there is another schedule. Release the other schedule first.';
            return;
          }

          // TODO hide first and last phase so that only pause phase can change

          const originalPhase = subscription.schedule.phases[numberOfPhases - 1];
          const originalPhaseEndDate = new Date(originalPhase.end_date * 1000);
          const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;
          const originalPhaseEndDatePlusOneWeek = new Date(originalPhaseEndDate.getTime() + oneWeekInMs);
          const originalPhaseEndDatePlusOneWeekAndASecond = new Date(originalPhaseEndDatePlusOneWeek.getTime() + 1000);
          const originalPhaseEndDatePlusOneWeekAndTwoSeconds
           = new Date(originalPhaseEndDatePlusOneWeek.getTime() + 2000);
          const originalPhaseCoupon = originalPhase.coupon;
          const originalPhasePlans = originalPhase.plans;

          // push pause phase
          subscription.schedule.phases.push({
            end_date: Math.floor(originalPhaseEndDatePlusOneWeekAndASecond.getTime() / 1000),
            coupon: null, // assume no coupon applies to pause
            plans: [{
              billing_thresholds: null,
              plan: draft.data.pausePlansWithPaidFirst[0].id, // default pause plan id
              price: null,
              quantity: 1,
              tax_rates: [],
            }],
          });

          // push original plan and coupon as last phase
          subscription.schedule.phases.push({
            end_date:
              Math.floor(originalPhaseEndDatePlusOneWeekAndTwoSeconds.getTime() / 1000), // release schedule immediately
            coupon: originalPhaseCoupon,
            plans: originalPhasePlans,
          });

        }
      });

      // this breaks stuff
      // draft.data.plans = draft.data.pausePlansWithPaidFirst; // assume we will only change the pause plan
      return draft;
    case SET_ERROR_MESSAGE:
      draft.view.error = action.payload;
      return draft;
    case DELETE_PHASE:
      draft.data.customer.subscriptions.data.forEach((subscription: any) => {
        if (subscription.id === action.payload.subscriptionId) {
          subscription.schedule.phases.splice(action.payload.phaseIndex, 1);
        }
      });
      return draft;
    default:
      return draft;
  }
});
