import { ILeaderboardConfig } from 'fitbeat.models';
import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { MemberTableHeader } from '../../members/components/memberTableHeader';

const Column: React.FC<{
  singleLeaderboardConfig: ILeaderboardConfig,
  indexNumber: number,
}> = ({
  singleLeaderboardConfig,
  indexNumber,
}) => {
  const headerText = singleLeaderboardConfig.name;

  return (
    <div className='column-content leaderboard-ranking-tile'>
      <label
        className='column-label'
        style={{
          maxWidth: '70%',
          minWidth: '40%',
        }}
      >
        <MemberTableHeader
          showIcons={false}
          header={`${indexNumber + 1}. ${headerText}`}
        />
      </label>
      <label
        className='column-label'
        style={{
          minWidth: '20%',
        }}
      >
        <MemberTableHeader
          showIcons={false}
          header={`
            ${singleLeaderboardConfig.frequency}
          `}
        />
      </label>
      <img
        className='table-column-header-arrow-icons'
        src='resources/images/drag-icon.svg'
      />
    </div>
  );
};

export const SortableRow = SortableElement(Column);
