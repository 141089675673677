import { Gym } from 'fitbeat.models';
import moment from 'moment';
import React from 'react';
import { MemberTableHeader } from '../members/components/memberTableHeader';

export const buildLeaderboardsColumnsFromAccessor = (
  accessor: string,
  currentGym: Gym,
) => {
  switch (accessor) {
    case 'order':
      return {
        Header: () => <MemberTableHeader header='Rank' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
      };
    case 'name':
      return {
        Header: () => <MemberTableHeader header='name' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
      };
    case 'status':
      return {
        Header: () => <MemberTableHeader header='status' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
      };
    case 'startDate':
      return {
        Header: () => <MemberTableHeader header='Start Date' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
        Cell: ({ original }: any) => moment.tz(original.startDate, original.timezone).format('YYYY-MM-DD'),
      };
    case 'endDate':
      return {
        Header: () => <MemberTableHeader header='End Date' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
        Cell: ({ original }: any) => (original.frequency === 'one-time'
          ? moment.tz(original.endDate, original.timezone).format('YYYY-MM-DD') : 'Recurring'),
      };
    case 'frequency':
      return {
        Header: () => <MemberTableHeader header='Frequency' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
      };
    case 'competitionType':
      return {
        Header: () => <MemberTableHeader header='Competition' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
      };
    case 'metric':
      return {
        Header: () => <MemberTableHeader header='metric' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
      };
    default:
      return {};
  }
};

export const availableLeaderboardsAccessors = [
  {
    accessor: 'order',
    used: true,
    sortable: true,
    width: 100,
  },
  {
    accessor: 'name',
    used: true,
    sortable: true,
    width: 200,
  }, {
    accessor: 'status',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'startDate',
    used: true,
    sortable: true,
    width: 120,
  }, {
    accessor: 'endDate',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'frequency',
    used: true,
    sortable: true,
    width: 120,
  }, {
    accessor: 'competitionType',
    used: true,
    sortable: true,
    width: 120,
  }, {
    accessor: 'metric',
    used: true,
    sortable: true,
    width: 120,
  },
];
