import { UserServiceClient } from 'fitbeat.api-clients';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'react-big-calendar/lib/less/styles.less';
import 'react-toggle-switch/dist/css/switch.min.css';
import 'rc-drawer/assets/index.css';
import 'styles/bootstrap.scss';
import 'styles/app.scss';
import 'styles/app-rtl.scss';
import AppLocale from '../lngProvider';
import MainApp from 'app/index';
import { apiConfig } from '../app/apiConfig';
import fetch from 'isomorphic-fetch';
import { loadInitialStateForApp } from '../adminSettings/actions';
import { Error } from '../shared/error';
import { Loading } from '../shared/loading';
import NotFoundPage from '../shared/components/Error404';

class App extends Component {
  render() {
    const {
      match, location, locale, isDirectionRTL, appInitialising, appInitialisationError,
    } = this.props;

    if (appInitialising) {
      return (<Loading />);
    }

    if (appInitialisationError) {
      const styles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
      };
      return (
        <Error
          containerStyle={styles}
          reload={this.props.loadInitialStateForApp}
          errorMessage={appInitialisationError}
        />
      );
    }

    if (location.pathname === '/') {
      return (<Redirect to='/app/members' />);
    }

    // for RTL Support
    if (isDirectionRTL) {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <div className='app-main'>
          <Switch>
            <Route path={`${match.url}app`} component={MainApp} />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.settings.locale,
  isDirectionRTL: state.settings.isDirectionRTL,
  appInitialising: state.adminSettings.view.appInitialising,
  appInitialisationError: state.adminSettings.view.appInitialisationError,
});

export default connect(mapStateToProps)(App);
