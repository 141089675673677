import { LeadStatus } from 'fitbeat.models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import Dropdown from '../shared/components/Dropdown';
import { leadStatusSelected } from './actions';
interface IProps {
  uid: string;
  activeLeadStatus: LeadStatus;
  leadStatusSelected: (
    uid: string,
    currentLeadStatus: LeadStatus,
    newLeadStatus: LeadStatus) => void;
}

export const leadStatusDropdownValues = Object.values(LeadStatus).map((value) => ({
  label: value,
  value,
}));

class LeadStatusDropdownComponent extends Component<IProps> {
  public render() {
    return (
      <Dropdown
        options={leadStatusDropdownValues}
        value={leadStatusDropdownValues.find((option) => option.value === this.props.activeLeadStatus)}
        menuPortalTarget={document.body}
        placeholder={'Select Lead Status'}
        className={`lead-status select-container ${this.props.activeLeadStatus}`}
        classNamePrefix={`lead-status ${this.props.activeLeadStatus} select`}
        components={{ Option: this.customOption, Control: this.customControl }}
      />
      );
    }

  private ControlOnClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }

  private customControl = ({...props }) => {
   return (
    <div onClick={this.ControlOnClick}>
      <components.Control {...props}>
        {props.children}
      </components.Control>
    </div>);
  }

  private customOption = (optionProps: any) => {
    const HandleOptionClick = () => {
      optionProps.selectOption(optionProps.data.value);
      this.props.leadStatusSelected(
        this.props.uid,
        this.props.activeLeadStatus,
        optionProps.data.value);
    };
    return (
      <div
        {...optionProps.data.innerProps}
        className={`row jr-btn-no-border lead-status-dropdown-option
        ${optionProps.data.value}`}
        onClick={HandleOptionClick}
      >
        <div className={`lead-status-option-color-block ${optionProps.data.value}`}></div>
        <div className='lead-status-option-text'>{optionProps.data.value}</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    leadStatusSelected: (
      uid: string,
      currentLeadStatus: LeadStatus,
      newLeadStatus: LeadStatus,
    ) => dispatch(leadStatusSelected(uid, currentLeadStatus, newLeadStatus)),
  };
};

export const LeadStatusDropdown = connect(null, mapDispatchToProps)(LeadStatusDropdownComponent);
