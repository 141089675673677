
import React from 'react';

interface IProps {
    header: string;
    showIcons?: boolean;
}
export class MemberTableHeader extends React.Component<IProps> {

    public render() {
        const { header, showIcons = true} = this.props;
        return (
            <div className='table-column-header-container'>
                <p className='table-column-header-text'>{header}</p>
                {showIcons &&
                    <img
                        className='table-column-header-arrow-icons'
                        src='resources/images/arrows-icon.svg'>
                    </img>
                }
            </div>
    );
    }
}
