import { ILeaderboardConfig, ILeaderboardInstance } from 'fitbeat.models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';
import Dropdown from '../../shared/components/Dropdown';
import { setSelectedInstance, setSelectedTeam } from '../leaderboardsSlice';
import { mapLeaderboardInstanceToOption, mapLeaderboardTeamsToOption } from './helpers';

interface IProps {
  mappedSelectedLeaderboardInstance: {
    value: ILeaderboardInstance,
    label: string,
  } | null;
  leaderboardInstancesToListOptions: {
    value: ILeaderboardInstance,
    label: string,
  }[];
  leaderboardTeamsToListOptions: { value: string, label: string }[];
  selectedGymName: string | null;
  selectedLeaderboardInstance: ILeaderboardInstance | null;
  selectedLeaderboardConfig: ILeaderboardConfig | null;
  mappedSelectedLeaderboardTeam: { value: string, label: string } | null;
  setSelectedInstance(instanceDetails: { gym: string | null, leaderboardInstance: ILeaderboardInstance }): void;
  setSelectedTeam(teamDetails: {
    gym: string | null,
    leaderboardTeam: string | null,
    selectedLeaderboardInstance: ILeaderboardInstance,
  }): void;
}

class LeaderboardInstanceSelectors extends Component<IProps> {
  public render() {
    const {
      selectedGymName,
      selectedLeaderboardConfig,
      selectedLeaderboardInstance,
      leaderboardTeamsToListOptions,
      mappedSelectedLeaderboardTeam,
      leaderboardInstancesToListOptions,
      mappedSelectedLeaderboardInstance,
    } = this.props;
    const enableTeamsOptions = (mappedSelectedLeaderboardInstance && selectedLeaderboardConfig?.teams);
    return (
      <div className='leaderboard-right-date-team-container'>
        <div className='leaderboard-select-container'>
          <h4 className='leaderboard-select-title'>Date</h4>
          <Dropdown
            onChange={(selected: { label: string; value: ILeaderboardInstance }) => {
              this.props.setSelectedInstance({ gym: selectedGymName, leaderboardInstance: selected.value });
            }}
            options={leaderboardInstancesToListOptions}
            value={mappedSelectedLeaderboardInstance}
            placeholder='Select'
            className='leaderboard-select'
            classNamePrefix='select'
          />
        </div>
        {enableTeamsOptions && (
          <div className='leaderboard-select-container'>
            <h4 className='leaderboard-select-title'>Team(s)</h4>
            <Dropdown
              onChange={(selected: { label: string; value: string; }) => {
                if (selectedLeaderboardInstance) {
                  this.props.setSelectedInstance({
                    gym: selectedGymName,
                    leaderboardInstance: selectedLeaderboardInstance,
                  });
                  this.props.setSelectedTeam({
                    gym: selectedGymName,
                    leaderboardTeam: selected.value === 'All teams' ? null : selected.value,
                    selectedLeaderboardInstance,
                  });
                }
              }}
              options={leaderboardTeamsToListOptions}
              value={mappedSelectedLeaderboardTeam}
              placeholder='Select'
              className='leaderboard-select'
              classNamePrefix='select'
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  setSelectedInstance,
  setSelectedTeam,
};
const mapStateToProps = (state: IAppStore) => {
  const { leaderboards, adminSettings } = state;
  const { selectedGymName } = adminSettings.data;
  const {
    leaderboardInstances,
    selectedLeaderboardConfig,
    selectedLeaderboardInstance,
    selectedLeaderboardTeam,
  } = leaderboards.data;

  const leaderboardInstancesToListOptions = leaderboardInstances.map((
    singleItem: ILeaderboardInstance,
  ) => mapLeaderboardInstanceToOption(singleItem));
  const leaderboardTeamsToListOptions = selectedLeaderboardConfig?.teams ? [
    ...selectedLeaderboardConfig.teams,
    'All teams',
  ].map((
    singleItem: string,
  ) => mapLeaderboardTeamsToOption(singleItem)) : [];
  const mappedSelectedLeaderboardInstance = selectedLeaderboardInstance
    ? mapLeaderboardInstanceToOption(selectedLeaderboardInstance)
    : null;
  const mappedSelectedLeaderboardTeam = selectedLeaderboardTeam
    ? mapLeaderboardTeamsToOption(selectedLeaderboardTeam)
    : {
      value: 'All teams',
      label: 'All teams',
    };

  return {
    selectedGymName,
    selectedLeaderboardConfig,
    selectedLeaderboardInstance,
    leaderboardTeamsToListOptions,
    mappedSelectedLeaderboardTeam,
    mappedSelectedLeaderboardInstance,
    leaderboardInstancesToListOptions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardInstanceSelectors);
