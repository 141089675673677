import { Reader } from '@stripe/terminal-js';
import React, { FunctionComponent, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import {
  ITerminalStatus,
  showProcessingPaymentWithPosModalSelector,
} from '../../accountActions';
import { AccountActionsService } from '../../accountActions/accountActionsService';
import {
  cancelCardCapture as cancelCardCaptureAction,
  cleanupTerminal,
  setupTerminal as setupTerminalAction,
} from '../../accountActions/actions';
import { apiConfig } from '../../app/apiConfig';
import { config } from '../../config';
import { IAppStore } from '../../rootReducer';
import POSStatus from './POSStatus';

interface IProps {
  uid: string;
  setupTerminal: (uid: string) => void;
  actions: () => JSX.Element;
  readers: Reader[];
  connectedReader: Reader | null;
  isLoadingTerminal: boolean;
  terminalError: string |  null;
  terminalStatus: ITerminalStatus;
  destroyTerminal: () => void;
  renderNotes?: JSX.Element;
  showProcessingPaymentWithPosModal: boolean;
  cancelCardCapture: () => void;
  actionText: string;
}

const Pos: FunctionComponent<IProps> = ({
                                          setupTerminal,
                                          uid,
                                          actions,
                                          readers,
                                          isLoadingTerminal,
                                          terminalError,
                                          terminalStatus,
                                          destroyTerminal,
                                          renderNotes,
                                          connectedReader,
                                          showProcessingPaymentWithPosModal,
                                          cancelCardCapture,
                                          actionText,
                                        }) => {
  {
    useEffect(() => {
      setupTerminal(uid);
      return () => {
        destroyTerminal();
      };
    }, []);

    return (
      <div>
        {(terminalStatus === 'connecting' || terminalStatus === 'scanning' || terminalStatus === 'setting_up') &&
          <div className='terminal-loading-container'>
          {isLoadingTerminal && <div style={{ textAlign: 'center' }}>
            <Loader
              type='ThreeDots'
              color='#FF621D'
              height={50}
              width={50}
              timeout={3000}
            />
            {terminalStatus === 'setting_up' && <h1>Setting up terminal...</h1>}
            {terminalStatus === 'scanning' && <h1>Scanning for available card readers...</h1>}
            {terminalStatus === 'connecting' && <h1>Connecting to first available card reader... </h1>}
          </div>}
        </div>}

        {(terminalStatus === 'ready' || terminalStatus === 'disconnected') &&
          <div className='terminal-container'>
            {!isLoadingTerminal && (terminalStatus === 'disconnected' || !connectedReader) &&
              <div>
                <div className='terminal-status-container'>
                  <div className='terminal-disconnected-container'>
                    <div className='text-container'>
                      <img
                        className='btnImg error-icon'
                        src='resources/icons/exclamation-mark-outline.svg'
                      />
                      <h1 className='text-container-text'>No card readers connected</h1>
                    </div>
                    <div className='retry-connection-button-container'>
                      <button
                        className='jr-btn-default retry-connection-button'
                        onClick={() => setupTerminal(uid)}
                      >Retry Connection
                      </button>
                    </div>
                  </div>
                </div>
                <div className='terminal-disconnected-info-container'>
                  <p>To connect, check that the Card Reader is:</p>
                  <li>
                    <a className='link' target='_blank' href={config.links.terminalPower}>Switched on</a>
                  </li>

                  <li>
                    <a className='link' target='_blank' href={config.links.terminalConnection}>
                      Connected</a> to the Internet <a href={config.links.terminalTroubleShoot}
                                                       className='link' target='_blank'>(troubleshoot)</a>
                  </li>

                  <li>
                    <a
                      className='link'
                      href={config.links.terminalConnection}
                      target='_blank'>On same Wi-Fi </a> as this laptop <a href={config.links.terminalTroubleShoot}
                                      className='link' target='_blank'>(troubleshoot)</a>
                  </li>

                  <li>
                    The only active <a target='_blank' className='link' href={config.links.terminalSetup}>
                    BBPOS WisePOS E</a> reader on your Stripe account
                  </li>
                </div>
              </div>
            }

            {!isLoadingTerminal && terminalStatus === 'ready' && connectedReader &&
              <div>
                <div className='terminal-status-container'>
                  <div className='terminal-disconnected-container'>
                    <div className='text-container'>
                      <img
                        className='btnImg error-icon'
                        src='resources/icons/connect-outline.svg'
                      />
                      <div className='terminal-connected-info-container'>
                        <h1>Reader connected & ready</h1>
                        <p className='terminal-info-text'>Connection info: {connectedReader?.serial_number ?? '-'}
                          / IP {connectedReader?.ip_address ?? '-'}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='terminal-connected-text-container'>
                  <p className='header'>Next Step: {actionText}</p>
                  <p className='sub-header' style={{ maxWidth: 600 }}>
                    Click {actionText} after the customer gives consent
                    to capture and save card, and then follow the instructions on the Card Reader.</p>
                </div>
              </div>
            }

            {terminalError && (
              <div style={{ textAlign: 'left', marginTop: 20 }}>
                <p className='error'>{terminalError}</p>
              </div>
            )}
          </div>}
        {renderNotes}
        {actions()}

        <Modal
          isOpen={showProcessingPaymentWithPosModal}
          className='modal-content'
          overlayClassName='modal-overlay'
          ariaHideApp={false}
        >
          <POSStatus cancelCardCapture={cancelCardCapture} status={terminalStatus} />
        </Modal>
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch: any) => {
  const accountActionService = new AccountActionsService(apiConfig);

  return {
    setupTerminal: (uid: string) => dispatch(setupTerminalAction(uid, accountActionService)),
    destroyTerminal: () => dispatch(cleanupTerminal()),
    cancelCardCapture: () => dispatch(cancelCardCaptureAction()),
  };
};

const mapStateToProps = (state: IAppStore) => {
  const { accountActions } = state;
  const showProcessingPaymentWithPosModal = showProcessingPaymentWithPosModalSelector(state);

  const {
    showBuyMerchandiseModal,
    isLoadingTerminal,
    terminalError,
    terminalStatus,
  } = accountActions.view;

  const {
    readers,
    connectedReader,
  } = accountActions.data;

  return {
    showBuyMerchandiseModal,
    readers,
    isLoadingTerminal,
    terminalError,
    terminalStatus,
    connectedReader,
    showProcessingPaymentWithPosModal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pos);
