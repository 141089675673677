import { BusinessToolsPermissions } from 'fitbeat.models';
import produce from 'immer';
import { GymResolver } from '../util/GymResolver';
import { setUserDetails as setUserDetailsOnLogging } from '../util/logging';
import {
  AdminSettingsActionTypes,
  GET_EXPORT_DATA,
  GET_SETTINGS,
  GYM_SELECTED,
  LOAD_INITIAL_APP_STATE_FAILURE,
  LOAD_INITIAL_APP_STATE_REQUEST,
  LOAD_INITIAL_APP_STATE_SUCCESS,
  LOAD_INITIAL_APP_STATE_UNAUTHORIZED,
  SET_EMAIL_EXCLUSION,
} from './actions';
import { IAdminSettingsState, initialState } from './storeTypes';
import { setupZendeskWidget } from './zendesk';

export const admingSettingsReducer = produce((
  draft: IAdminSettingsState = initialState,
  action: AdminSettingsActionTypes,
):
IAdminSettingsState => {
  const initialAppStateFailure = (message: string) => {
    draft.view.appInitialisationError = message;
    draft.view.appInitialising = false;
  };

  switch (action.type) {
    case GET_SETTINGS:
      draft.data.excludedEmails = action.payload.emailsToExclude !== 'null'
        ? action.payload.emailsToExclude : initialState.data.excludedEmails;
      return draft;
    case SET_EMAIL_EXCLUSION:
      draft.data.excludedEmails = action.payload;
      return draft;
    case LOAD_INITIAL_APP_STATE_REQUEST:
      draft.view.appInitialising = true;
      draft.view.appInitialisationError = null;
      return draft;
    case LOAD_INITIAL_APP_STATE_SUCCESS:
      const { user } = action.payload;

      if (!user.gymDetails || user.gymDetails.length === 0) {
        initialAppStateFailure('User does not have access to any gyms. Please contact support.');
        return draft;
      }

      draft.data.gyms = user.gymDetails;
      GymResolver.prefill(draft.data.gyms);

      if (!draft.data.selectedGymName) {
        const validSelectedGym = draft.data.gyms.find((selectedGym) => selectedGym.name === draft.data.selectedGymName);
        const gymName = validSelectedGym ? validSelectedGym.name : user.gymDetails[0].name;
        draft.data.selectedGymName = gymName;
        localStorage.setItem('selectedGymName', gymName);
      }

      const selectedGymDetails = draft.data.gyms.find((i) => i.name === draft.data.selectedGymName);
      setupZendeskWidget(user, selectedGymDetails || draft.data.gyms[0]);

      draft.view.appInitialising = false;
      const userToolPermissions = user.businessToolsPermissions;
      draft.canSaveReports = userToolPermissions.indexOf(BusinessToolsPermissions.UpdateReport) > -1;
      draft.canViewSubscriptionDetails = userToolPermissions.indexOf(
        BusinessToolsPermissions.ViewSubscriptionDetails,
      ) > -1;
      draft.isOrgAdmin = userToolPermissions.indexOf(BusinessToolsPermissions.OrgAdmin) > -1;
      draft.isAuthenticated = true;
      setUserDetailsOnLogging(user.name);
      return draft;
    case LOAD_INITIAL_APP_STATE_FAILURE:
      initialAppStateFailure('Failed to load app please refresh the page or contact support if the problem persists.');
      return draft;
    case LOAD_INITIAL_APP_STATE_UNAUTHORIZED:
      initialAppStateFailure('The login details you entered are incorrect. Please try again or contact support.');
      return draft;
    case GYM_SELECTED:
      const { gym } = action.payload;
      draft.data.selectedGymName = gym.name;
      localStorage.setItem('selectedGymName', gym.name);
      draft.data.enableDataExport = false;
      return draft;
    case GET_EXPORT_DATA:
      draft.data.exportData = action.payload.exportData;
      draft.data.enableDataExport = true;
      return draft;
    default:
      return draft;
  }
});
