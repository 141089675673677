import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import 'core-js/stable';
import { config } from './config';
import getLogger, { createReactErrorBoundry, initializeLogging } from './util/logging';

initializeLogging();
const logger = getLogger('BT startup');
const { environmentDetails } = config;
logger.info(`Starting Business Tools for ${environmentDetails.nameForLogging} (${environmentDetails.environmentName})`);

const initialState = {};
export const store = configureStore(initialState);

const rootEl = document.getElementById('app-site');
const ErrorBoundary = createReactErrorBoundry(React);

// Create a reusable render method that we can call more than once
const render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').default;

  if (ErrorBoundary) {
    ReactDOM.render(
      <ErrorBoundary>
        <Provider store={store}>
          <MainApp />
        </Provider>
      </ErrorBoundary>,
      rootEl,
    );
    return;
  }

  ReactDOM.render(
    <Provider store={store}>
      <MainApp />
    </Provider>,
    rootEl,
  );
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const NextApp = require('./MainApp').default;
    render(
      <Provider store={store}>
        <NextApp />
      </Provider>,
      rootEl,
    );
  });
}

render();
