import { IMemberDetailsData } from 'fitbeat.gym-core';
import { Gym, IMemberColumnData, MembersReportData, ReportType } from 'fitbeat.models';
import moment from 'moment';
import * as React from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { Filter, SortingRule } from 'react-table';
import { getSettings } from '../adminSettings/actions';
import { currentGymSelector } from '../adminSettings/selector';
import ColumnSelector from '../members/components/columnSelector';
import ConfirmationModal from '../members/components/confirmationModal';
import CopyReportSmsEmails from '../members/components/CopyReportSmsEmails';
import SaveReportModal from '../members/components/saveReportModal';
import { deleteConfirmationActions, updateConfirmationActions } from '../members/config';
import { convertDataToViews } from '../members/storeTypes';
import { IAppStore } from '../rootReducer';
import { Loading } from '../shared/loading';
import {
  clearFilterData,
  deleteReport,
  getAllData,
  getSavedReports,
  IWorkoutAggregateFilter,
  toggleConfirmationModal,
  toggleIsReportClone,
  toggleReportModal,
  updateReport,
  workoutAggregateDateChange,
} from './actions';
import DatePickerPreDefinedDays from './components/datePickerPreDefinedDays';
import { List } from './components/memberList';
import MemberReportHeaderReportSelection from './components/MemberReportHeaderReportSelection';
import ReportActionsDropdown from './components/ReportActionsDropdown';

interface IProps {
  memberDetailsData: IMemberDetailsData[];
  excludedEmails: string;
  reportName: string;
  reportId: string;
  activeReport: MembersReportData;
  isNewReport: boolean;
  isDeleteConfirmation: boolean;
  memberColumnData: IMemberColumnData[];
  filtered: Filter[];
  sortOrder: SortingRule[];
  exportData: any[];
  isFetching: boolean;
  currentGym: Gym;
  enableDataExport: boolean;
  lastViewedMemberReport: string;
  canSaveReports: boolean;
  error: string | null;
  showConfirmationModal: boolean;
  workoutAggregateFilter: IWorkoutAggregateFilter;
  clearFilterData(): void;
  getSettings(): void;
  getAllData(
    excludedEmails: string,
    gymName: string,
    includeWorkouts?: boolean,
  ): void;
  toggleIsReportClone(): void;
  toggleReportModal(): void;
  toggleConfirmationModal(show: boolean): void;
  deleteReport(reportId: string): void;
  updateReport(): void;
  getSavedReports(reportType: ReportType): void;
  workoutAggregateDateChange(data: any, gymName: string): void;
}
class Members extends React.Component<IProps, any> {
  constructor(props: any) {
    super(props);
  }

  public componentDidMount = async () => {
    if (this.props.memberDetailsData.length === 0) {
      this.props.getSavedReports(ReportType.members);
      this.props.getAllData(this.props.excludedEmails, this.props.currentGym.name);
    }
  }

  public render() {
    const { canSaveReports } = this.props;
    const deleteTitles = deleteConfirmationActions(this.props.reportName);
    const updateTitles = updateConfirmationActions(this.props.reportName);
    return (
      <div className='app-wrapper'>
        <div className='table-action-header text-center'>
          <div className='row align-items-center report-top-section '>
            <div className='col'>
              <MemberReportHeaderReportSelection reportType={ReportType.members}/>
            </div>
            <div className='row' style={{alignItems: 'center'}}>
            {this.props.enableDataExport && <div>
              <CopyReportSmsEmails
                  isSmsCopy={false}
                  idToUse='emails'
                  buttonText='Copy Emails'
                />
                <CopyReportSmsEmails
                  isSmsCopy={true}
                  idToUse='sms'
                  buttonText='Copy SMS Emails'/>
              </div>}
              {this.props.enableDataExport &&
                <div className='settings'>
                  <CSVLink
                    data={this.props.exportData || this.props.memberDetailsData}
                    asyncOnClick={true}
                    filename={`${moment(new Date()).toISOString()}-member-table.csv`}
                    target='_blank'>
                    <button className='jr-btn-no-border black-color-text'>
                      <img className='btnImg csvExportIconContainer' src='resources/images/csv-export-icon.svg' />
                          CSV Export
                        </button>
                  </CSVLink>
                </div>
              }
              <div>
                <ColumnSelector />
              </div>
              <div className='workoutAggregateContainer'>
                <div className='jr-btn-no-border black-color-text' style={{display: 'flex'}}>
                  <img className='btnImg showOrHideColumnIconContainer' src='resources/icons/calendar.svg' />
                  <div className='workoutAggregateTextContainer'>
                    <div>Workouts Stats</div>
                    {this.props.workoutAggregateFilter?.label &&
                      <div style={{fontSize: 10}}>
                        {this.props.workoutAggregateFilter.label}
                      </div>
                    }
                  </div>
                </div>
                <div style={{alignItems: 'center', display: 'flex', marginRight: '20px'}}>
                  <DatePickerPreDefinedDays
                    placeHolderFormat='MMM d YYYY'
                    type='aggregateDate'
                    onChange={(data: IWorkoutAggregateFilter) => {
                      this.props.workoutAggregateDateChange(data, this.props.currentGym.name);
                      this.props.getAllData(this.props.excludedEmails, this.props.currentGym.name, /* including
                       workouts */ false);
                    }}
                    custom={this.props.workoutAggregateFilter}
                    showLeft={true}
                    maxRangeDays={365}
                  />
                </div>
              </div>

              {canSaveReports && (
                <>
                  <ReportActionsDropdown />
                  <SaveReportModal />
                </>
              )}
            </div>
          </div>
          {this.props.isDeleteConfirmation ?
            <ConfirmationModal
              error={this.props.error}
              isFetching={this.props.isFetching}
              showConfirmationModal={this.props.showConfirmationModal}
              titles={deleteTitles}
              onCancel={() => this.props.toggleConfirmationModal(false)}
              onClose={() => this.props.toggleConfirmationModal(false)}
              onYesClick={() => this.props.deleteReport(this.props.reportId)} /> :
            <ConfirmationModal
              error={this.props.error}
              isFetching={this.props.isFetching}
              showConfirmationModal={this.props.showConfirmationModal}
              titles={updateTitles}
              onCancel={() => this.props.toggleConfirmationModal(false)}
              onClose={() => this.props.toggleConfirmationModal(false)}
              onYesClick={() => this.props.updateReport()} />}
          {this.props.memberDetailsData.length === 0 || this.props.isFetching ?
            <div className={'loader-container'}>
              <Loading />
            </div>
            :
            <List
              report={this.props.activeReport}
              reportType={ReportType.members}
            />
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getAllData,
  clearFilterData,
  toggleConfirmationModal,
  toggleReportModal,
  toggleIsReportClone,
  deleteReport,
  updateReport,
  getSettings,
  getSavedReports,
  workoutAggregateDateChange,
};
const mapStateToProps = (state: IAppStore) => {
  const { members, adminSettings } = state;
  const { canSaveReports } = state.adminSettings;
  const { isNewReport, isClone, filtered, sortOrder, isDeleteConfirmation,
    error, isFetching, showConfirmationModal, workoutAggregateFilter } = members.view;
  const { activeReport, memberDetailsData, lastViewedMemberReport } = members.data;
  const { exportData, enableDataExport } = adminSettings.data;
  const { memberColumnData, reportName, reportId } = members.data.activeReport;
  const currentGym = currentGymSelector(state);
  const memberColumnDataView = convertDataToViews(
    memberColumnData,
    state.members.data.filterDataFromMembersData,
    currentGym);
  const { excludedEmails } = adminSettings.data;
  return {
    memberTableColumns: memberColumnDataView.filter((column) => column.used),
    exportData,
    reportName,
    isNewReport,
    reportId,
    isClone,
    filtered,
    sortOrder,
    isDeleteConfirmation,
    error,
    isFetching,
    activeReport,
    memberColumnData,
    memberDetailsData,
    excludedEmails,
    currentGym,
    enableDataExport,
    lastViewedMemberReport,
    showConfirmationModal,
    workoutAggregateFilter,
    canSaveReports,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Members);
