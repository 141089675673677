import { MembersReportData, ReportType } from 'fitbeat.models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleActiveReportChange, loadActiveReport } from '../../../members/actions';
import Dropdown from '../Dropdown';

interface IProps {
  loadActiveReport: (reportList: MembersReportData[], reportType: ReportType) => void;
  handleActiveReportChange: (report: MembersReportData) => void;
  isFetching: boolean;
  reportType: ReportType;
  memberReportData: MembersReportData[];
  lastViewedMemberReport: MembersReportData;
  activeReport: MembersReportData;
  lastViewedWorkoutsReport: MembersReportData;
}

class DropdownReport extends Component<IProps> {
  public componentDidMount() {
    this.props.loadActiveReport(this.props.memberReportData, this.props.reportType);
  }

  public render() {
    const { memberReportData, activeReport } = this.props;

    const filtered = memberReportData
      .filter((singleReport: MembersReportData) => singleReport.reportType === this.props.reportType);
    const options = filtered.map((singleReport: MembersReportData) => ({
      ...singleReport,
      label: singleReport.reportName,
      value: singleReport.reportId,
    }));

    const report = {
      ...activeReport,
      label: activeReport.reportName,
      value: activeReport.reportId,
    };
    const isLoading = options.length === 0;

    return (
      <Dropdown
        onChange={this.props.handleActiveReportChange}
        options={options}
        value={report}
        isLoading={isLoading}
        placeholder='Select Report'
        className='report select-container'
        classNamePrefix='report select'
        isSearchable
      />
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  loadActiveReport: (
    reportList: MembersReportData[],
    reportType: ReportType,
  ) => dispatch(loadActiveReport(reportList, reportType)),
  handleActiveReportChange: (report: MembersReportData) => dispatch(handleActiveReportChange(report)),
});

const mapStateToProps = (state: any) => {
  const { members } = state;
  const { memberReportData } = members.data;
  const {
    activeReport,
    lastViewedMemberReport,
    lastViewedWorkoutsReport,
  } = members.data;
  const { initialLoadDone, isFetching } = members.view;
  return {
    memberReportData,
    activeReport,
    initialLoadDone,
    lastViewedMemberReport,
    lastViewedWorkoutsReport,
    isFetching,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownReport);
