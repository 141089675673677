import { Gym, ICreateSpecialOfferParams, ISpecialOfferDisplay } from 'fitbeat.models';
import React, { FunctionComponent } from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { IAppStore } from '../../../rootReducer';
import IntlMessages from '../../../util/IntlMessages';
import { dateString } from '../formElements/billing-start-date-helpers';
import BillingStartDateSelect from '../formElements/BillingStartDateSelect';
import PlanAndCouponSelect from '../formElements/PlanAndCouponSelect';
import { useSpecialOfferFormValues } from './specialOfferFormValuesHook';
import {
  closeMembershipOfferModalPressed,
  createMembershipOfferInModalPressed,
  updateMembershipOfferInModalPressed,
} from './subscription-offers-actions';

interface IProps {
  activeSpecialOffer?: ISpecialOfferDisplay;
  modalOpen: boolean;
  closeModal: () => void;
  loading: boolean;
  createSpecialOfferPressed: (specialOffer: ICreateSpecialOfferParams) => void;
  updateSpecialOfferPressed: (specialOfferId: string, specialOffer: ICreateSpecialOfferParams) => void;
  currentGym: Gym;
  uid: string;
  errorMessage?: string;
}

const SpecialOfferModal: FunctionComponent<IProps> = (
  {
    modalOpen,
    closeModal,
    activeSpecialOffer,
    createSpecialOfferPressed,
    updateSpecialOfferPressed,
    errorMessage,
    loading,
  },
) => {
  const editingExistingSpecialOffer = activeSpecialOffer?.id;

  const formValues = useSpecialOfferFormValues({
    selectedPriceId: activeSpecialOffer?.contactPlanId,
    selectedCouponId: activeSpecialOffer?.contractCoupon,
    selectedBillingStartDate: activeSpecialOffer?.billingStartDate,
  });

  const submitDisabled = !(
    formValues.selectedPriceId && formValues.selectedBillingStartDate
  );

  const submit = () => {
    if (submitDisabled) {
      return;
    }
    const billingStart = dateString(formValues.selectedBillingStartDate!);

    const offer: ICreateSpecialOfferParams = {
      contactPlanId: formValues.selectedPriceId,
      ...(formValues.selectedCouponId && { contractCoupon: formValues.selectedCouponId }),
      billingStartDate: billingStart,
    };
    if (editingExistingSpecialOffer) {
      updateSpecialOfferPressed(activeSpecialOffer!.id, offer);
    } else {
      createSpecialOfferPressed(offer);
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      className='modal-content'
      overlayClassName='modal-overlay'
      ariaHideApp={false}
      contentLabel='Columns'
    >
      <div className='column-label border-bottom'>
        <h2 className='column-header'>
          {editingExistingSpecialOffer ?
            <IntlMessages id='member.editSpecialOffer' /> : <IntlMessages id='member.createSpecialOffer' />
          }
        </h2>
        <button
          className='modal-close-button'
          onClick={closeModal}>
          X
        </button>
      </div>

      <PlanAndCouponSelect {...{ parentProps: formValues }} />

      <BillingStartDateSelect {...{ parentProps: formValues }} />

      <div className='border-top d-flex justify-content-between pt-3'>
        <div>
          {errorMessage &&
            <p className='text-danger'>{errorMessage}</p>
          }
        </div>

        {loading &&
          <div className='mr-4'>
            <Loader
              type='ThreeDots'
              color='#FF621D'
              height={50}
              width={50}
            />
          </div>
        }
        {!loading &&
          <button
            className='jr-btn-default start-subscription'
            disabled={submitDisabled}
            onClick={submit}
          >
            {editingExistingSpecialOffer && <IntlMessages id='member.updateSpecialOfferButton' />}
            {!editingExistingSpecialOffer && <IntlMessages id='member.createSpecialOfferButton' />}
          </button>
        }
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: IAppStore): Partial<IProps> => ({
  modalOpen: state.members.view.specialOfferModalVisible,
  activeSpecialOffer: state.members.data.memberAccountData?.userInfo?.activeSpecialOffer,
  loading: state.members.view.specialOfferSaving,
  errorMessage: state.members.view.specialOfferFailureMessage,
});

const mapDispatchToProps = {
  closeModal: closeMembershipOfferModalPressed,
  createSpecialOfferPressed: createMembershipOfferInModalPressed,
  updateSpecialOfferPressed: updateMembershipOfferInModalPressed,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialOfferModal);
