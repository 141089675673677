import { Gym, ILeaderboardConfig } from 'fitbeat.models';
import moment from 'moment';
import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';

interface IProps {
  formData: ILeaderboardConfig;
  orderedGyms: Gym[];
  timezones: string[];
  isOrgAdmin: boolean;
  allGyms: boolean;
  showUpdateLeaderboardConfigModal: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void;
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const LeaderboardConfigBasicOptions: React.FC<IProps> = ({
  formData,
  orderedGyms,
  timezones,
  isOrgAdmin,
  allGyms,
  showUpdateLeaderboardConfigModal,
  handleInputChange,
  handleSelectChange,
}) => (
  <div style={styles.formColumn}>
    <h3 style={styles.columnHeader} className='leaderboard-create-headers'>
      Basic Options
    </h3>
    <label style={styles.inputLabel}>
      Leaderboard Name:
      <input
        type='text'
        name='name'
        value={formData.name}
        onChange={handleInputChange}
        required
        style={styles.inputField}
      />
    </label>
    {isOrgAdmin && !allGyms && (
      <label style={styles.inputLabel}>
        Applies to members of Gym:
        <select
          name='gymName'
          value={formData.gymName}
          onChange={handleSelectChange}
          required
          style={styles.inputField}
          disabled={showUpdateLeaderboardConfigModal}
        >
          {orderedGyms.map((gym) => (
            <option key={gym.name} value={gym.name}>
              {gym.name}
            </option>
          ))}
        </select>
      </label>
    )}
    <label style={styles.inputLabel}>
      Frequency:
      <select
        name='frequency'
        value={formData.frequency}
        onChange={handleSelectChange}
        style={styles.inputField}
        disabled={showUpdateLeaderboardConfigModal}
      >
        <option value='one-time'>One-time</option>
        <option value='daily'>Daily</option>
        <option value='weekly'>Weekly</option>
        <option value='monthly'>Monthly</option>
      </select>
    </label>
    <label style={styles.inputLabel}>
      Start Date:
      <input
        type='date'
        name='startDate'
        value={moment(formData.startDate).tz(formData.timezone).format('YYYY-MM-DD')}
        onChange={handleInputChange}
        required
        style={styles.inputField}
        disabled={showUpdateLeaderboardConfigModal}
      />
    </label>
    {formData.frequency === 'one-time' && (
      <label style={styles.inputLabel}>
        End Date:
        <input
          type='date'
          name='endDate'
          value={formData.endDate ? moment(formData.endDate).tz(formData.timezone).format('YYYY-MM-DD') : ''}
          onChange={handleInputChange}
          style={styles.inputField}
        />
      </label>
    )}
    <label style={styles.inputLabel}>
      Competition Type:
      <select
        name='competitionType'
        value={formData.competitionType}
        onChange={handleSelectChange}
        style={styles.inputField}
        disabled={showUpdateLeaderboardConfigModal}
      >
        <option value='individual'>Individual</option>
        <option value='inter-team'>Inter-team</option>
      </select>
    </label>
    <label style={styles.inputLabel}>
      Metric:
      <select
        name='metric'
        value={formData.metric}
        onChange={handleSelectChange}
        style={styles.inputField}
        disabled={showUpdateLeaderboardConfigModal}
      >
        <option value='effort-points'>Effort Points</option>
        <option value='total-minutes'>Total Minutes</option>
        <option value='average-minutes'>Average Minutes</option>
        <option value='team-weighted-minutes-score'>Weighted Total Minutes</option>
        <option value='team-weighted-points-score'>Weighted Effort Points</option>
      </select>
    </label>
    <label style={styles.inputLabel}>
      Timezone:
      <select
        name='timezone'
        value={formData.timezone}
        onChange={handleInputChange}
        required
        style={styles.inputField}
        disabled={showUpdateLeaderboardConfigModal}
      >
        {timezones.map((timezone) => (
          <option key={timezone} value={timezone}>
            {timezone}
          </option>
        ))}
      </select>
    </label>
  </div>
);

const styles: { [key: string]: CSSProperties } = {
  formColumn: {
    marginRight: '16px',
  },
  columnHeader: {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  inputLabel: {
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  inputField: {
    width: '100%',
    padding: '8px',
    marginBottom: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
};

const mapStateToProps = (state: IAppStore) => {
  const { leaderboards } = state;
  const {
    showUpdateLeaderboardConfigModal,
  } = leaderboards.view;

  return {
    showUpdateLeaderboardConfigModal,
  };
};

export default connect(mapStateToProps)(LeaderboardConfigBasicOptions);
