import { IMemberDetailsData } from 'fitbeat.gym-core';
import { MembersReportData } from 'fitbeat.models';
import { Gym } from 'fitbeat.models';
import * as React from 'react';
import { connect } from 'react-redux';
import { currentGymSelector } from '../adminSettings/selector';
import { IAppStore } from '../rootReducer';
import { Loading } from '../shared/loading';
import {
  clearFilterData, deleteReport, getAllData, getSavedReports,
  toggleConfirmationModal,
  toggleIsReportClone, toggleReportModal,
} from './actions';
import { MemberTableError } from './components';
import { DashboardReport } from './components/dashboardReport';
import { DashboardReportTile } from './components/DashboardReportTile';

interface IProps {
  memberReportData: MembersReportData[];
  excludedEmails: string;
  error: string | null;
  isFetching: boolean;
  currentGym: Gym;
  activeDashboardActiveReport: MembersReportData;
  memberDetailsData: IMemberDetailsData[];
  dashboardMemberReportData: MembersReportData[];
  getAllData(excludedEmails: string, gymName: string): void;
  getSavedReports(): void;
}

class Dashboard extends React.Component<IProps, any> {
  constructor(props: any) {
    super(props);
  }

  public componentDidMount = async () => {
    if (this.props.dashboardMemberReportData.length === 0) {
      this.props.getAllData(this.props.excludedEmails, this.props.currentGym.name);
      this.props.getSavedReports();
    }
  }

  public render() {
    const {
      error,
      isFetching,
      excludedEmails,
      currentGym,
      activeDashboardActiveReport,
      dashboardMemberReportData,
      memberDetailsData,
      } = this.props;

    const memberDetailsIsEmpty = dashboardMemberReportData.length === 0 || memberDetailsData.length === 0;
    const isLoading = !error && (isFetching || memberDetailsIsEmpty);
    return (
      <div className='app-wrapper'>
         {error && (
          <MemberTableError reload={()=> this.props.getAllData(excludedEmails, currentGym.name)} />
        ) }
        {(isLoading) &&
          <div className={'loader-container'}>
            <Loading />
          </div>
        }
        {(!isLoading) &&
          <h2 className='dashboard-heading'>Dashboard</h2>
        }
          <div className='dashbaord-repo'>
          {(!isLoading) &&
            dashboardMemberReportData.map((report: MembersReportData) =>  (
              <DashboardReportTile report={report} />
            ))}
        </div>
        {!isLoading &&
          <DashboardReport
            {...this.props}
            report={activeDashboardActiveReport}
           />
        }
      </div>
    );
  }
}

const mapDispatchToProps = {
  reload: getAllData,
  getAllData,
  clearFilterData,
  toggleConfirmationModal,
  toggleReportModal,
  toggleIsReportClone,
  deleteReport,
  getSavedReports,
};
const mapStateToProps = (state: IAppStore) => {
  const { members } = state;
  const { adminSettings } = state;
  const { excludedEmails } = adminSettings.data;
  const {
    memberReportData,
    memberDetailsData,
    activeDashboardActiveReport,
    dashboardMemberReportData,
  } = members.data;
  const { isFetching, error } = members.view;
  const currentGym = currentGymSelector(state);
  return {
    memberReportData,
    memberDetailsData,
    error,
    isFetching,
    excludedEmails,
    currentGym,
    activeDashboardActiveReport,
    dashboardMemberReportData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
