import { BaseUserProfile, Gym } from 'fitbeat.models';

// this file is shared (symlinked) in web-admin as well
// TODO: generalize the setup to not need to symlink it

export class GymResolver {
  public static prefill(gyms: Gym[]) {
    GymResolver.gyms = gyms;
  }

  public static getByName(gymName: string): Gym {
    const gym = GymResolver.getByNameOrNull(gymName);
    if (!gym) {
      throw new Error(`No gym found for ${gymName}`);
    }
    return gym;
  }

  public static getByNameOrNull(gymName: string): Gym | null {
    const gym = GymResolver.gyms.find((g: Gym) => g.name === gymName);
    if (!gym) {
      return null;
    }
    return gym;
  }

  public static getGymFromUserProfile(userProfile: BaseUserProfile): Gym | null {
    if (!userProfile.gymName) {
      return null;
    }
    return this.getByNameOrNull(userProfile.gymName);
  }

  public static clearGyms(): void {
    GymResolver.gyms = [];
  }

  public static getPersonalisedHeader(gymName: string): string {
    const gym = GymResolver.getByName(gymName);

    return gym.personalisedHeader || 'Fitillion';
  }

  public static getPersonalisedHeaderOrDisplayName(gymName: string): string {
    const gym = GymResolver.getByName(gymName);
    return gym.personalisedHeader || gym.displayName;
  }

  private static gyms: Gym[];
}
