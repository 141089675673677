import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'shared/components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'shared/components/Footer';

import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import TopNav from 'shared/components/TopNav';
import Members from '../members/members';
import Workouts from '../members/workouts';
import Dashboard from '../members/dashboard';
import Leaderboards from '../leaderboards/Leaderboards';
import Details from '../members/memberDetails';
import NotFoundPage from '../shared/components/Error404';
class App extends React.Component {

  render() {
    const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }
    return (
      <div className={`app-container`}>
        <div className='app-main-container'>
          <div className='app-header'>
            <TopNav styleName='app-top-header' />
          </div>

          <main className='app-main-content-wrapper'>
            <div className='app-main-content'>
              <Switch>
                <Route
                  exact
                  path={`${match.url}/members`}
                  component={Members}
                />
                <Route
                  exact
                  path={`${match.url}/workouts`}
                  component={Workouts}
                />
                <Route
                  exact
                  path={`${match.url}/dashboard`}
                  component={Dashboard}
                />
                <Route
                  path={`${match.url}/memberDetails/:uid`}
                  component={Details}
                />
                <Route
                  exact
                  path={`${match.url}/leaderboards`}
                  component={Leaderboards}
                />
                <Route
                  component={NotFoundPage}
                />
              </Switch>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
