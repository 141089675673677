import {
  CustomCheckboxContainer,
  CustomCheckboxInput,
} from '@reach/checkbox';
import '@reach/checkbox/styles.css';
import React from 'react';

const CheckboxContainer = (props: any) => {
  return (
    <div>
      {props.hasFilter &&
        <img style={{marginRight: 5, marginTop: -5}} src='resources/images/filter.svg'></img>
      }
        <CustomCheckboxContainer
          checked={props.checked}
          onChange={props.onChange}
          className='column-checkbox-container'>
          <CustomCheckboxInput {...props} />
          <span
            aria-hidden
            className={`${props.checked ? 'column-checkbox-normal column-checkbox-checked' : 'column-checkbox-normal'}`}
          />
        </CustomCheckboxContainer>
    </div>

  );
};

export const Checkbox: React.FC<{
  name: string,
  checked: boolean,
  onChange: any,
  hasFilter: boolean,
}> = ({
  name,
  checked,
  onChange,
  hasFilter,
}) => (
      <div>
        <label className='column-checkbox'>
          <CheckboxContainer name={name} checked={checked} onChange={onChange} hasFilter={hasFilter}/>
        </label>
      </div>
    );
