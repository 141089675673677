import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { IAppStore } from '../rootReducer';
import { editMemberComment, loadMemberComments, saveMemberComments} from './actions';

interface IProps {
  UID: string;
  memberCommentOnEdit: string;
  saveMemberComments(uid: string, comment: string): void;
  editMemberComment(comment: string): void;
  loadMemberComments(uid: string): void;
}

interface IState {
  commentOnEdit: string;
}

class MemberComments extends Component<IProps, IState> {
  public handleCommentEdit = (even: any) => {
    this.props.editMemberComment(even.target.value);
  }

  public componentDidMount() {
    this.props.loadMemberComments(this.props.UID);
  }

  public handleCommentSave = () => {
    this.props.saveMemberComments(this.props.UID, this.props.memberCommentOnEdit);
  }
  public onEnterKeyPress = (event: any) => {
    if (event.which === 13) {
      this.handleCommentSave();
    }
  }

  public render() {
  return (
      <InputGroup className='comment-section-container'>
        <InputGroupAddon addonType='prepend'>
        </InputGroupAddon>
        <Input
          className='member-detail-p comment-input-text'
          type='text'
          value={this.props.memberCommentOnEdit}
          placeholder='Type comment here...'
          onChange={this.handleCommentEdit}
          onKeyPress={this.onEnterKeyPress} />
        <Button className='jr-btn-default saveComments' onClick={this.handleCommentSave}>
          <img
            className='btnImg paper-plane-icon'
            src='resources/images/paper-plane-outline.svg'
          />
        </Button>
      </InputGroup>
      );
  }
}

const mapStateToProps = (state: IAppStore) => {
  const { memberCommentOnEdit } = state.members.data;
  return { memberCommentOnEdit };
};

const mapDispatchToProps = { saveMemberComments, editMemberComment, loadMemberComments };

export default connect(mapStateToProps, mapDispatchToProps)(MemberComments);
