import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import { IClientSideLogger } from 'fitbeat.gym-core';
import React from 'react';
import { config } from '../../config';
import { BugsnagLogger } from './symlinked-bugsnagLogger';

export function initializeBugsnag(apiKey: string) {
  Bugsnag.start({
    apiKey,
    appVersion: config.appVersion,
    releaseStage: config.environmentDetails.nameForLogging,
    plugins: [new BugsnagPluginReact()],
  });
  BugsnagPerformance.start({ apiKey });
}

export function createBugsnagLogger(): IClientSideLogger {
  return new BugsnagLogger(Bugsnag);
}

export function createReactErrorBoundry(react?: typeof React): BugsnagErrorBoundary | undefined {
  return Bugsnag.getPlugin('react')?.createErrorBoundary(react);
}

export function setActiveUser(userId: string | null) {
  if (userId) {
    Bugsnag.setUser(userId);
  } else {
    Bugsnag.setUser(undefined);
  }
}
