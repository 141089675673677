import { MembersReportData, ReportType } from 'fitbeat.models';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import 'react-table/react-table.css';
import {
  handleActiveReportChange,
} from '../actions';
import { MemberListTable } from './memberListTable';

interface IProps {
  report: MembersReportData;
  handleActiveReportChange: (report: MembersReportData) => void;
}

export class List extends React.Component<IProps, any> {
  public reactTable: any;
  constructor(props: any) {
    super(props);
    this.reactTable = React.createRef();
  }

  public loadReport = (report: MembersReportData) => {
    this.props.handleActiveReportChange(report);
  }

  public render() {
    const isMembersReport =  this.props.report.reportType === ReportType.members;
    return (
      <div>
        <div className='dashboard-report-heading-button-container'>
          <h2 className='dashboard-report-heading-text'>{this.props.report.reportName}</h2>
          <NavLink to={`${isMembersReport ? 'members' :  'workouts'}`}>
            <button style={{ display: 'inline-block', marginRight: 15 }}
              onClick={() => this.loadReport(this.props.report)}
              className='jr-btn-default-outlined'>Go to Report</button>
          </NavLink>
        </div>
        <div>
          <MemberListTable
            report={this.props.report}
            isDashboardReport={true}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  handleActiveReportChange,
};

export const DashboardReport = connect(null, mapDispatchToProps)(List);
