import { ILeaderboardConfig } from 'fitbeat.models';
import React from 'react';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import LeaderboardsMainTable from './LeaderboardsMainTable';

interface IProps {
  leaderboardConfigs: ILeaderboardConfig[];
}
class LeaderboardsTable extends React.Component<IProps> {
  public render() {
    return (
      <LeaderboardsMainTable
        leaderboards={this.props.leaderboardConfigs}
      />
    );
  }
}

export default connect(null, null)(LeaderboardsTable);
