import { BaseApiClient, IApiInterface } from 'fitbeat.api-clients';
import { UserWorkoutSession } from 'fitbeat.gym-core';
import {
  Gym,
  ICancelSubscriptionResponse,
  IInvoice,
  IInvoiceItem,
  IPaymentSetupData,
  IStartSubscriptionResponse,
  IStripeCouponWrapper,
  IStripeSchedule,
  ISubscriptionPaymentSummary,
  ProductCategory,
  UserAccountScheduleActionInfo,
} from 'fitbeat.models';
import fetch from 'isomorphic-fetch';

export class AccountActionsService extends BaseApiClient {
  public static pauseProductNameContains = 'Pause';

  private logger: any;

  public constructor(config: IApiInterface) {
    super(config, fetch, undefined);
    this.logger = (() => {
      // empty
    });
    this.fetch = fetch;
  }

  public async getMemberSchedule(uid: string, gymName: string): Promise<UserAccountScheduleActionInfo> {
    this.logger(`[BusinessToolsClient] getting member schedule for ${uid}`);
    const request = await this.buildGetRequest(`business-tools/${gymName}/memberActions/schedule/${uid}`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getMemberWorkoutHistory(uid: string): Promise<UserWorkoutSession[]> {
    this.logger(`[BusinessToolsClient] getting member schedule for ${uid}`);
    const request = await this.buildGetRequest(`business-tools/memberActions/workoutHistory/${uid}`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getCoupons(
    gymName: string,
    uid: string,
    priceId?: string,
    productCategories?: ProductCategory[],
  ): Promise<IStripeCouponWrapper[]> {
    this.logger(`[BusinessToolsClient] getting coupons for price: ${priceId}`);

    const searchParams = new URLSearchParams({
      ...priceId ? { priceId } : {},
      ...productCategories ? { productCategories: productCategories.toString() } : {},
    });

    const request = await this.buildGetRequest(`business-tools/${gymName}/coupons/${uid}?${searchParams}`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data: IStripeCouponWrapper[] = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async updateInvoiceCoupon(
    gymName: string,
    invoiceId: string,
    couponCode?: string,
  ): Promise<IInvoice> {
    this.logger(`[BusinessToolsClient] updating invoice ${invoiceId} with coupon ${couponCode}`);

    const request = await this.buildPostRequest(
      `business-tools/${gymName}/invoices/${invoiceId}/coupon`,
      couponCode ? { couponCode } : {},
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data: IInvoice = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getPaymentSummary(uid: string, priceId: string, couponCode?: string):
  Promise<ISubscriptionPaymentSummary> {
    this.logger(`[BusinessToolsClient] getting payment summary for user: ${uid}
      and price ${priceId} and coupon ${couponCode ?? 'none'}`);
    const couponQuery = `&couponCode=${couponCode}`;
    const request = await this.buildGetRequest(
      `business-tools/paymentSummary?uid=${uid}&priceId=${priceId}${couponQuery}`,
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data: ISubscriptionPaymentSummary = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async pauseSubscription(uid: string, planId: string, start: Date, end: Date)
    : Promise<IStripeSchedule | undefined> {
    this.logger(`[BusinessToolsClient] pause subscription for ${uid}`);
    const request = await this.buildPutRequest(`business-tools/memberActions/pauseSubscription/${uid}`, {
      planId,
      start,
      end,
    }, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async cancelSubscription(uid: string, gymName: string, penaltyAmount: number, isImmediateCancel: boolean):
  Promise<ICancelSubscriptionResponse> {
    this.logger(`[BusinessToolsClient] cancel subscription for ${uid}, amount ${penaltyAmount}`);
    const request = await this.buildPutRequest(`business-tools/${gymName}/memberActions/cancelSubscription/${uid}`, {
      penaltyAmount,
      isImmediateCancel,
    }, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async createSubscription(
    uid: string,
    planId: string,
    couponCode: string | undefined,
    startDate: Date,
    gym: Gym,
  ): Promise<IStartSubscriptionResponse> {
    this.logger(`[BusinessToolsClient] start subscription for ${uid}, planId: ${planId} start date: ${startDate}`);
    const request = await this.buildPostRequest(`business-tools/memberActions/createSubscription/${uid}`, {
      planId,
      startDate,
      couponCode,
      gym,
    }, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async addFreeWeekSubscription(uid: string, planId: string, start: Date, end: Date)
    : Promise<IStripeSchedule | undefined> {
    this.logger(`[BusinessToolsClient] add free week subscription for ${uid}`);
    const request = await this.buildPutRequest(`business-tools/memberActions/freeWeekSubscription/${uid}`, {
      planId,
      start,
      end,
    }, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async createSetupIntent(uid: string, gymName: string): Promise<IPaymentSetupData> {
    const request = await this.buildPostRequest(
      `business-tools/${gymName}/setupIntent/${uid}`,
      {},
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async setCustomerDefaultPaymentMethod(
    gymName: string,
    uid: string,
    paymentMethodId: string,
  ): Promise<IPaymentSetupData> {
    const request = await this.buildPostRequest(
      `business-tools/${gymName}/paymentMethod/${uid}`,
      { paymentMethodId },
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async finaliseSetupIntent(
    gym: Gym,
    uid: string,
    setupIntentId: string,
    saveAsDefaultPaymentMethod = false,
  ) {
    const request = await this.buildPostRequest(
      `business-tools/${gym.name}/finaliseSetupIntent/${uid}?`
        + `setupIntentId=${setupIntentId}&saveAsDefaultPaymentMethod=${saveAsDefaultPaymentMethod}`,
      {},
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getMerchandise(gymName: string, uid: string) {
    const request = await this.buildGetRequest(`business-tools/${gymName}/merchandise/${uid}`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async updateInvoiceItem(gymName: string, uid: string, item: IInvoiceItem, invoiceId?: string):
  Promise<IInvoice> {
    const invoiceQuery = invoiceId ? `?invoiceId=${invoiceId}` : '';
    const request = await this.buildPostRequest(
      `business-tools/${gymName}/memberActions/invoiceItem/${uid}${invoiceQuery}`,
      item,
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async getInvoice(gymName: string, uid: string, invoiceId: string):
  Promise<IInvoice> {
    const request = await this.buildGetRequest(`business-tools/${gymName}/invoices/${invoiceId}`, true);
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async updateInvoice(gymName: string, uid: string, invoiceId?: string):
  Promise<IInvoice> {
    const invoiceQuery = invoiceId ? `?invoiceId=${invoiceId}` : '';
    const request = await this.buildPostRequest(
      `business-tools/memberActions/invoice/${gymName}/${uid}${invoiceQuery}`,
      {},
      true,
    );
    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async finaliseAndPayInvoice(gymName: string, invoiceId: string, paymentMethodId?: string, note?: string):
  Promise<IInvoice> {
    const request = await this.buildPostRequest(
      `business-tools/${gymName}/invoices/${invoiceId}/finaliseAndPay`,
      { note, paymentMethodId },
      true,
    );
    this.logger(`[BusinessToolsClient] finalising invoice: ${invoiceId} at gym ${gymName}`);

    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async finaliseInvoice(gymName: string, invoiceId: string, note?: string):
  Promise<{
    invoice: IInvoice,
    paymentIntent: any,
  }> {
    const request = await this.buildPostRequest(
      `business-tools/${gymName}/invoices/${invoiceId}/finalise`,
      { note },
      true,
    );
    this.logger(`[BusinessToolsClient] finalising invoice: ${invoiceId} at gym ${gymName}`);

    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async markInvoiceAsPaidOutOfBand(gymName: string, invoiceId: string):
  Promise<IInvoice> {
    const request = await this.buildPostRequest(
      `business-tools/${gymName}/invoices/${invoiceId}/markInvoiceAsPaidOutOfBand`,
      {},
      true,
    );
    this.logger(`[BusinessToolsClient] mark invoice as paid: ${invoiceId} at gym ${gymName}`);

    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async deleteInvoice(gymName: string, invoiceId: string):
  Promise<void> {
    const request = await this.buildDeleteRequest(`business-tools/${gymName}/invoices/${invoiceId}`, true);
    this.logger(`[BusinessToolsClient] deleting invoice: ${invoiceId} at gym ${gymName}`);

    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }

  public async fetchConnectionToken(gym: Gym) {
    const request = await this.buildPostRequest(`business-tools/${gym.name}/connectionToken`, {}, true);
    this.logger(`Fetching connection token for gym: ${gym.name}`);

    const result = await this.fetch(request.url, this.buildFetchOptionsWithUserProvidedAuth(request.options));
    const data = await this.assertHttp200AndGetJsonFromResult(result);
    return data;
  }
}
