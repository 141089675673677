import { IInvoice } from 'fitbeat.models';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { AccountActionsService } from '../../accountActions/accountActionsService';
import {
  closeBuyMerchandiseModal,
  discardMerchandiseInvoice,
  loadBuyMerchandiseModal,
} from '../../accountActions/actions';
import { apiConfig } from '../../app/apiConfig';
import { config } from '../../config';
import { IAppStore } from '../../rootReducer';
import { MembersService } from '../membersService';
import MerchandiseInvoices from './MerchandiseInvoices';
import MerchandiseModal from './MerchandiseModal';
import Success from './Success';

interface IProps {
  showBuyMerchandiseModal: boolean;
  uid: string;
  openBuyMerchandise: (uid: string) => void;
  closeMerchandiseModal: () => void;
  invoice: IInvoice | undefined;
  cancelInvoice: (uid: string) => void;
  showMerchandiseSuccessModal: boolean;
}

class Merchandise extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { invoice, showMerchandiseSuccessModal } = this.props;
    const eliteEcommerceUrl = config.links.eliteEcommercePage;

    return(
      <div>
        <div className='member-details-overview-sub-heading-container' style={{paddingTop: 15}}>
          <h2 className='member-details-section-heading-text'>
            Merchandise
          </h2>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <button
              className='account-action'
              onClick={() => this.props.openBuyMerchandise(this.props.uid)}
            >Buy Merchandise</button>
            <a className='open-elite-ecommerce-website-tag-container' href={eliteEcommerceUrl} target={'_blank'}>
              <button className='account-action elite-ecommerce'>Elite Ecommerce</button>
              <img
                className='newTabIcon'
                src={'resources/images/open-in-new-tab-icon.svg'}
                style={{ width: '14px', height: '14px' }}
              />
            </a>
          </div>
        </div>
        <MerchandiseInvoices uid={this.props.uid} />

        {this.props.showBuyMerchandiseModal &&
            <Modal
              isOpen={this.props.showBuyMerchandiseModal}
              className='modal-content'
              overlayClassName='modal-overlay'
              ariaHideApp={false}
            >
              {!showMerchandiseSuccessModal &&
                <MerchandiseModal uid={this.props.uid}/>}
              {(showMerchandiseSuccessModal && invoice?.status === 'paid') &&
                <Success
                  header={'Invoice and payment successful!'}
                  subHeader={'Next step: Customer can collect their goods now!'}
                  close={this.props.closeMerchandiseModal}
                />}
            </Modal>
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  const accountActionService = new AccountActionsService(apiConfig);
  const membersService = new MembersService(apiConfig);

  return {
    openBuyMerchandise: (uid: string) => dispatch(loadBuyMerchandiseModal(accountActionService, uid)),
    closeMerchandiseModal: () => dispatch(closeBuyMerchandiseModal()),
    cancelInvoice: (uid: string) => dispatch(discardMerchandiseInvoice(uid, membersService, accountActionService)),
  };
};

const mapStateToProps = (state: IAppStore) => {
  const { accountActions } = state;

  const {
    invoice,
    showMerchandiseSuccessModal,
  } = accountActions.data.buyMerchandise;

  const {
    showBuyMerchandiseModal,
  } = accountActions.view;

  return {
    showMerchandiseSuccessModal,
    showBuyMerchandiseModal,
    invoice,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Merchandise);
