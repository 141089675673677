import { CardioOrResistance, IMemberDetailsData, Level } from 'fitbeat.gym-core';
import {
  IMemberColumnData, IUserWorkoutSessionMemberReport,
  MembersReportData, ProgramType, ReportType,
} from 'fitbeat.models';
import { apiConfig } from '../app/apiConfig';
import CountryRegionData from './CountryRegionData';

export const paymentLink = process.env.NODE_ENV === 'staging'
  ? 'https://stage.gyrovi.com/fitbeat/membership-plans/?continueSignupToken='
  : 'https://www.fitbeat.com/membership-plans/?continueSignupToken=';

export const userAvatarSrc = (uid: string): string => {
  const url = new URL(`${apiConfig.LOCAL_SERVER_BASE_URL}/user/${uid}/pngAvatar2?t=${Date.now()}`);
  url.searchParams.append('username', apiConfig.LOCAL_SERVER_USERNAME);
  url.searchParams.append('password', apiConfig.LOCAL_SERVER_PASSWORD);
  return url.href;
};

export const getProgramTypeTranslation = (goal: string) => {
  if (goal === ProgramType.GetLeanAndFitGoal) {
    return 'Lean & Fit';
  }
  if (goal === ProgramType.GainMuscleAndStrengthGoal) {
    return 'Muscle & Strength';
  }
  if (goal === ProgramType.MobilityAndTone) {
    return 'Mobility & Tone';
  }
  if (goal === ProgramType.GetStrongAndFitGoal) {
    return 'All-rounder';
  }
  if (goal === ProgramType.MuscleAndShred) {
    return 'Muscle & Shred';
  }
  return 'Not Set';
};

export const getCardioOrResistanceMapping = (workoutType: string) => {
  if (workoutType === CardioOrResistance.Cardio) {
    return 'Burn';
  }
  if (workoutType === CardioOrResistance.Resistance) {
    return 'Build';
  }
  if (workoutType === CardioOrResistance.Balance) {
    return 'Balance';
  }
  return '';
};

export const getSkillLevelMapping = (skillLevel: string) => {
  if (skillLevel === Level.Beginner) {
    return 'Basic';
  }
  if (skillLevel === Level.Basic) {
    return 'Intermediate';
  }
  if (skillLevel === Level.Intermediate) {
    return 'Advanced';
  }
  return '';
};

export const defaultReportName = 'Members Database *';
export const defaultReportId = 'initialState';

export const deleteConfirmationActions = (reportName: string) => ({
  titleAction: 'Delete Report',
  questionAction: `delete ${reportName} `,
});

export const updateConfirmationActions = (reportName: string) => ({
  titleAction: 'Update Report',
  questionAction: `update ${reportName} `,
});

export const cancelPauseConfirmationActions = () => ({
  titleAction: 'Cancel Pause',
  questionAction: 'cancel your subscription pause',
});

export const cancelSubscriptionConfirmationActions = () => ({
  titleAction: 'Cancel Subscription',
  questionAction: 'cancel your subscription',
});

export const getRegionsForCountry = (country: string) => {
  if (!country) {
    return [];
  }

  let regions: any[] = [];
  CountryRegionData.forEach((i) => {
    if (i[0] === country) {
      regions = i;
    }
  });

  if (regions.length >= 3) {
    return regions[2].split('|').map((regionPair: any) => {
      const [regionName] = regionPair.split('~');
      return { regionName };
    });
  }

  return [];
};

export const addMissingColumns = (initialReport: IMemberColumnData[], activeReport: IMemberColumnData[]) => {
  initialReport.forEach((column: IMemberColumnData) => {
    const found = activeReport.find((activeCol: any) => activeCol.accessor === column.accessor);
    if (!found) {
      const newCol = JSON.parse(JSON.stringify(column));
      newCol.used = false;
      activeReport.push(newCol);
    }
  });
  return activeReport;
};

export const flattenDataForReport = (
  memberData: IMemberDetailsData[],
  allWorkouts: IUserWorkoutSessionMemberReport[],
  report: MembersReportData,
): any => {
  const members = flattedMemberData(memberData);
  if (report.reportType === ReportType.members) {
    return members;
  }

  if (report.reportType === ReportType.workouts) {
    return allWorkouts.map((workoutSession: IUserWorkoutSessionMemberReport) => ({
      ...workoutSession,
      ...workoutSession.settings,
      ...members.find((member) => member.uid === workoutSession.userProfileId),
      gymName: workoutSession.gymName,
    }));
  }
  return [];
};

function flattedMemberData(memberData: IMemberDetailsData[]) {
  return memberData.map((member) => {
    let delinquentString = '';
    if (member.userAccount != null && member.userAccount.delinquent != null) {
      delinquentString = member.userAccount.delinquent.toString();
    }
    const userData = {
      ...member.userSettings,
      ...member.userWorkoutAggregate,
      ...member.userAccount,
      delinquent: delinquentString,
    };

    if (userData.signupSource) {
      return { ...userData, ...userData.signupSource };
    }
    return userData;
  });
}
