import {
  ILeaderboardConfig,
  ILeaderboardDataViewModel,
  ILeaderboardInstance,
  IMemberColumnData,
} from 'fitbeat.models';
import React, { Component, createRef, LegacyRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { exportDataToCSV } from '../../adminSettings/actions';
import { currentGymSelector } from '../../adminSettings/selector';
import { membersColumnsSelector } from '../../members/selector';
import { IAppStore } from '../../rootReducer';
import { Loading } from '../../shared/loading';
import { leaderboardInstanceAccessors } from '../leaderboardInstanceFilters';
import { setSelectedTeam } from '../leaderboardsSlice';
import { mapTeamsLeaderboardAverageMinutesToSeconds } from './helpers';

interface IProps {
  selectedGymName: string | null;
  membersColumns: IMemberColumnData[];
  instanceDetails: ILeaderboardDataViewModel | null;
  isFetchingInstanceDetails: boolean;
  selectedLeaderboardInstance: ILeaderboardInstance | null;
  enableTeamsOptions: boolean;
  exportCSV: (table: LegacyRef<ReactTable<ILeaderboardConfig>> | undefined) => void;
  setSelectedTeam(teamDetails: {
    gym: string | null,
    leaderboardTeam: string | null,
    selectedLeaderboardInstance: ILeaderboardInstance,
  }): void;
}
class LeaderboardInstanceTable extends Component<IProps> {
  public reactTable: LegacyRef<ReactTable<ILeaderboardConfig>> | undefined;

  constructor(props: IProps) {
    super(props);
    this.reactTable = createRef<ReactTable<ILeaderboardConfig>>();
    this.props.exportCSV(this.reactTable);
  }

  public async componentDidMount() {
    await this.props.exportCSV(this.reactTable);
  }

  public async componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps?.instanceDetails?.startDate !== this.props?.instanceDetails?.startDate
      || prevProps?.instanceDetails?.rankings !== this.props?.instanceDetails?.rankings) {
      await this.props.exportCSV(this.reactTable);
    }
  }

  public render() {
    const {
      selectedGymName,
      instanceDetails,
      isFetchingInstanceDetails,
      selectedLeaderboardInstance,
      enableTeamsOptions,
    } = this.props;
    const instanceRanking = instanceDetails?.rankings || [];
    return isFetchingInstanceDetails
      ? (
        <div className='loader-container'>
          <Loading />
        </div>
      )
      : (
        <div className='leaderboards-table instance-table'>
          <ReactTable
            className='clickable-row'
            ref={this.reactTable}
            minRows={0}
            resizable
            filterable
            data={instanceRanking}
            showPagination={false}
            defaultPageSize={1000}
            loadingText='Loading...'
            noDataText='No data found'
            columns={this.props.membersColumns}
            TrGroupComponent={(props) => (
              <div
                className={`rt-tr-group ${enableTeamsOptions ? 'pointer' : 'not-allowed-pointer'}`}
                role='rowgroup'
                onClick={() => {
                  if (enableTeamsOptions && props.originalData.name) {
                    this.props.setSelectedTeam({
                      gym: selectedGymName,
                      leaderboardTeam: props.originalData.name,
                      selectedLeaderboardInstance,
                    });
                  }
                }}
              >
                {props.children}
              </div>
            )}
            getTrGroupProps={(_: any, props: any) => ({ originalData: props.original })}
          />
        </div>
      );
  }
}

const mapStateToProps = (state: IAppStore) => {
  const { leaderboards, adminSettings } = state;
  const currentGym = currentGymSelector(state);
  const { selectedGymName } = adminSettings.data;
  const {
    selectedLeaderboardInstanceDetails,
    selectedLeaderboardInstance,
    selectedLeaderboardTeam,
  } = leaderboards.data;
  const { isFetchingInstanceDetails } = leaderboards.view;

  const leaderboardInstanceTableDetails = {
    reportId: 'leaderboards instance',
    reportName: 'Leaderboards instance Table',
    dateCreated: new Date(),
    memberColumnData: [...leaderboardInstanceAccessors],
    selected: true,
  };

  const enableTeamsOptions = (
    selectedLeaderboardInstance
    && selectedLeaderboardInstanceDetails?.teams
    && !selectedLeaderboardTeam
  );

  return {
    selectedGymName,
    instanceDetails: selectedLeaderboardInstanceDetails,
    selectedLeaderboardInstance,
    membersColumns: membersColumnsSelector(state, leaderboardInstanceTableDetails, currentGym),
    isFetchingInstanceDetails,
    enableTeamsOptions,
  };
};

const mapDispatchToProps = {
  exportCSV: exportDataToCSV,
  setSelectedTeam,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LeaderboardInstanceTable));
