import { Gym, UserAccountDetail } from 'fitbeat.models';
import React, { FunctionComponent } from 'react';
import QRCode from 'react-qr-code';
import {
  buildSMSLinkForStandardPlans,
  getEmailLink,
} from '../../../util/helpers';
import { TextCopyToClipBoard } from '../TextCopyToClipBoard';

interface IProps {
  userAccountDetails: UserAccountDetail;
  currentGym: Gym;
  standardMembershipPlansLink?: string;
}

export const StandardMembershipCard: FunctionComponent<IProps> = (
  {
    userAccountDetails,
    currentGym,
    standardMembershipPlansLink,
  },
) => {

  if (!standardMembershipPlansLink) {
    return <></>;
  }

  const emailLink = getEmailLink(currentGym, userAccountDetails.userInfo.profile, standardMembershipPlansLink);
  const smsLink = buildSMSLinkForStandardPlans(userAccountDetails.userInfo.profile, standardMembershipPlansLink,
    currentGym);

  return (
    <div className='card border-0 shadow-lg'>
      <div className='card-body p-3'>

        <h3 className='member-details-section-sub-header'>
          Standard Membership Plans
        </h3>

        <div className='row'>
          <div className='col-7'>

            <TextCopyToClipBoard text={standardMembershipPlansLink} id={'std-membership-plan-link'} />

            <div className='row mt-4'>
              <div className='col d-flex justify-content-center'>
                <a type='button' className='btn btn-link btn-lg link-btn-text' href={emailLink} target='_blank'>
                  Email Link
                </a>
              </div>
              <div className='col d-flex justify-content-center'>
                <a type='button' className='btn btn-link btn-lg link-btn-text' href={smsLink} target='_blank'>
                  SMS Link
                </a>
              </div>
            </div>

          </div>
          <div className='col-5'>
            <QRCode
              value={standardMembershipPlansLink}
              size={150}
              preserveAspectRatio='xMidYMid meet'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
