import { UserProfile } from 'fitbeat.gym-core';
import {
  Gym,
  UserAccountDetail,
  UserAccountScheduleActionInfo,
} from 'fitbeat.models';
import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  toggleCancelSubscriptionModal, toggleStartSubscriptionModal,
} from '../../accountActions/actions';
import AddFreeWeeks from '../../accountActions/components/addFreeWeeksModal';
import AddPause from '../../accountActions/components/addPauseModal';
import { currentGymSelector } from '../../adminSettings/selector';
import { IAppStore } from '../../rootReducer';
import { toggleStripeKeyModal } from '../../stripe/actions';
import { toggleEditMemberModal } from '../actions';
import {
  getMemberAddressDetailsSelector,
  getMemberSubscriptionDetailsSelector,
} from '../selector';
import MemberDetailsSingleField, { ISingleField } from './MemberDetailsSingleField';
import {
  createMembershipOfferPressed as createMembershipOfferPressedAction,
  editMembershipOfferPressed as editMembershipOfferPressedAction,
} from './subscriptionOffers/subscription-offers-actions';
import { subscriptionOfferComponentSelector } from './subscriptionOffers/subscription-offers-selectors';
import SubscriptionSchedule from './SubscriptionSchedule';

interface IProps {
  UID: string;
  memberDetail: UserProfile;
  memberAccountData: UserAccountDetail;
  stripeAccountId: string;
  memberAccountShedule: UserAccountScheduleActionInfo | undefined;
  currentGym: Gym;
  accountActionsIsFetching: boolean;
  accountActionsError: string | null;
  accountActionsSuccessMessage: string | null;
  showStartSubscriptionModal: boolean;
  memberAddressDetails: { label: string, value: string }[];
  memberPersonalDetails: { leftList: { label: string, value: string }[],
    rightList: { label: string, value: string | undefined }[] };
  memberSubscriptionDetails: { label: string, value: string }[];

  toggleStripeKeyModal(): void;
  toggleEditMemberModal(): void;
  toggleStartSubscriptionModal(): void;
  toggleCancelSubscriptionModal(): void;
}

class Subscriptions extends Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
  }

  public emailSmsHistory = () => {
    const userEmail = encodeURIComponent(this.props.memberDetail.email);
    const userMobileNumber = this.props.memberDetail.mobileNumber;
    const gymEmail = this.props.currentGym.email;
    window.open(
      `https://mail.google.com/mail/u/${gymEmail}/?tab=rm&ogbl#search/${userMobileNumber}+OR+${userEmail}`,
      'mygmailtab',
    );
  };

  public render() {
    const {
      memberAccountData,
      memberAccountShedule,
      memberDetail,
      stripeAccountId,
      memberSubscriptionDetails,
    } = this.props;
    const userAccountInfo = memberAccountData && memberAccountData.userInfo;
    return (
      <Container className='member-details-overview-container'>
        <Container className='subscription-details-container'>
          <Row className='member-details-overview-sub-heading-container'>
            <h2 className='member-details-section-heading-text'>
              Subscription Details
            </h2>

          </Row>
          <Row className='member-details-overview-content-container'>
            <Col className='member-plan-details'>
              {memberSubscriptionDetails.map((singleData: ISingleField, index) => (
                <MemberDetailsSingleField
                  field={{
                    label: singleData.label,
                    value: singleData.value ? singleData.value : '-',
                  }}
                  key={index}
                />
              ))}
            </Col>
            <Col>
              <h3 className='member-details-section-sub-header'>
                Subscription Schedule
              </h3>
              {this.props.accountActionsIsFetching
                ? (
                  <Loader
                    type='ThreeDots'
                    color='#FF621D'
                    height={50}
                    width={50}
                    timeout={3000}
                  />
                )
                : (
                  <SubscriptionSchedule
                    memberAccountShedule={memberAccountShedule}
                    userAccountInfo={userAccountInfo}
                    userProfile={memberDetail}
                  />
                )}
            </Col>
          </Row>
        </Container>
        <AddFreeWeeks
          memberDetail={memberDetail}
          stripeAccountId={stripeAccountId}
        />
        <AddPause
          memberDetail={memberDetail}
          stripeAccountId={stripeAccountId}
        />
        <Row />
      </Container>
    );
  }
}

const mapStateToProps = (state: IAppStore, props: any) => {
  const { accountActions, members } = state;
  const { memberAccountData } = members.data;
  const memberDetail = memberAccountData && memberAccountData.userInfo.profile;
  const {
    isFetching, error, successMessage, showStartSubscriptionModal,
  } = accountActions.view;
  const { memberAccountShedule } = accountActions.data;
  const currentGym = currentGymSelector(state);
  const memberAddressDetails: { label: string, value: string }[] = getMemberAddressDetailsSelector(state);

  const memberSubscriptionDetails: {
    label: string,
    value: string | undefined,
  }[] = getMemberSubscriptionDetailsSelector(state, false);

  return {
    memberDetail,
    memberAccountData,
    currentGym,
    memberAccountShedule,
    accountActionsIsFetching: isFetching,
    accountActionsError: error,
    accountActionsSuccessMessage: successMessage,
    showStartSubscriptionModal,
    memberAddressDetails,
    memberSubscriptionDetails,
    subscriptionOfferDisplayed: subscriptionOfferComponentSelector(state),
  };
};

const mapDispatchToProps = {
  toggleStripeKeyModal,
  toggleEditMemberModal,
  toggleStartSubscriptionModal,
  toggleCancelSubscriptionModal,
  createMembershipOfferPressed: createMembershipOfferPressedAction,
  editMembershipOfferPressed: editMembershipOfferPressedAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Subscriptions);
