import { configureStore } from '@reduxjs/toolkit';
import createHistory from 'history/createHashHistory';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import rootReducer from '../rootReducer';

const history = createHistory();
const routeMiddleware = routerMiddleware(history);

const store = () => configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      thunk,
    }).concat(routeMiddleware)
    return middleware
  },
})

export default store;
export { history };
