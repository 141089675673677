import { IApiInterface } from 'fitbeat.api-clients';
export const apiConfig: IApiInterface = {
  API_BASE_URL: '',
  PLATFORM: 'business-tools',
  BUILD_NUMBER: '',
  VERSION_NUMBER: '',
  LOCAL_SERVER_BASE_URL: process.env.CLOUD_GYM_DOMAIN || '',
  LOCAL_SERVER_USERNAME: '',
  LOCAL_SERVER_PASSWORD: '',
  PT_USERNAME: '',
  fallbackUrl: '',
};
