import React, { FunctionComponent, useState } from 'react';
import { Tooltip } from 'reactstrap';

interface IProps {
  text: string;
  id: string;
}

const defaultToolTip = 'Copy to clipboard';

export const TextCopyToClipBoard: FunctionComponent<IProps> = ({ text, id }) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [toolTipContent, setToolTipContent] = useState<string>(defaultToolTip);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const copyPressed = () => {
    navigator.clipboard.writeText(text)
      .then(() => setToolTipContent('Copied!'))
      .catch((err) => setToolTipContent('Failed to copy!'));
  };

  const mouseEnter = () => {
    setTooltipOpen(true);
  };

  const mouseLeave = () => {
    setTooltipOpen(false);
    setToolTipContent(defaultToolTip);
  };

  return (
    <div className='card my-2 pl-2 pr-3 py-1'>
      <div className='row'>
        <div className='col-10 text-wrap body-copy-reg muted'>
          {text}
        </div>
        <div className='col-2 align-self-center justify-content-center is-link-text' id={'Tooltip-' + id}
             onMouseEnter={mouseEnter}
             onMouseLeave={mouseLeave}
             onClick={copyPressed}
        >
          <img src='resources/images/copy-outline.svg' />
        </div>
        <Tooltip
          placement={'top'}
          isOpen={tooltipOpen}
          target={'Tooltip-' + id}
          toggle={toggle}
        >
          {toolTipContent}
        </Tooltip>
      </div>
    </div>
  );
};
