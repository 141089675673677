import arrayMove from 'array-move';
import { IMemberDetailsData } from 'fitbeat.gym-core';
import { IInvoice, MembersReportData, ReportType } from 'fitbeat.models';
import produce from 'immer';
import {
  CREATE_SUBSCRIPTION_SUCCESS,
  HANDLE_MERCHANDISE_DRAFT_INVOICE_SUCCESS,
  PAY_MERCHANDISE_FAILURE,
  PAY_MERCHANDISE_SUCCESS,
} from '../accountActions/actions';
import {
  CHANGE_REPORT_NAME, CLEAR_FILTER_DATA, DASHBOARD_RETURN_TO_INITIAL_STATE,
  DELETE_REPORT_REQUEST, DELETE_REPORT_SUCCESS, EDIT_MEMBER_COMMENTS,
  FOLLOW_UP_DATE_SERVICE_FAILURE_ACTION,
  FOLLOW_UP_DATE_SERVICE_SUCCESS_ACTION,
  GET_ACTIVE_REPORT, GET_ALL_DATA_FAILURE, GET_ALL_DATA_REQUEST,
  GET_ALL_DATA_SUCCESS,
  GET_DASHBOARD_ACTIVE_REPORT,
  GET_MEMBERACCOUNT_FAILURE,
  GET_MEMBERACCOUNT_REQUEST,
  GET_MEMBERACCOUNT_SUCCESS,
  GET_SAVED_REPORTS_FAILURE,
  GET_SAVED_REPORTS_REQUEST, GET_SAVED_REPORTS_SUCCESS,
  LOAD_FOLLOW_UP_DATE_ACTION,
  LOAD_MEMBER_COMMENTS,
  MEMBER_COMMENTS_SERVICE_FAILURE,
  MEMBER_COMMENTS_SERVICE_SUCCESS, MEMBER_LEAD_STATUS_UPDATE_FAILURE, MEMBER_LEAD_STATUS_UPDATE_REQUEST,
  MEMBER_LEAD_STATUS_UPDATE_SUCCESS, MemberActionTypes, MOVE_REPORT_DOWN, MOVE_REPORT_UP,
  RESET_TABLE_FILTERS,
  RESET_TEMP_COLUMN_STORE,
  RETURN_TO_INITIAL_STATE,
  SAVE_COLUMN_WIDTH, SAVE_FOLLOW_UP_DATE_ACTION, SAVE_MEMBER_COMMENTS_REQUEST, SAVE_REPORT_FAILURE,
  SAVE_REPORT_REQUEST, SAVE_REPORT_SUCCESS, SAVE_USED_COLUMNS,
  SELECT_TABLE_ROW, SET_FILTER_DATA, SET_SORTING_ORDER,
  SORT_COLUMN,
  TOGGLE_COLUMN_IS_USED,
  TOGGLE_CONFIRMATION_MODAL,
  TOGGLE_EDIT_MEMBER_DETAILS_MODAL,
  TOGGLE_IS_CLONE,
  TOGGLE_SAVE_REPORT_MODAL,
  UPDATE_MEMBER_DETAILS_FAILURE,
  UPDATE_MEMBER_DETAILS_REQUEST,
  UPDATE_MEMBER_DETAILS_SUCCESS,
  UPLOAD_AVATAR_FAILURE,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS, WORKOUT_AGGREGATE_DATE_CHANGED,
} from './actions';
import {
  ICreateMembershipOfferInModalPressedSuccess,
  IOfferPlanSelectedSuccess, IUpdateMembershipOfferInModalPressedSuccess,
  SpecialOfferActions,
  types as SpecialOfferActionsTypes,
} from './components/subscriptionOffers/subscription-offers-actions';
import { addMissingColumns, userAvatarSrc } from './config';
import { availableMemberDataAccessors } from './filters/members';
import { availableWorkoutAccessors } from './filters/workouts';
import { invoicesSelector } from './selector';
import {
  IFilterDataFromMembersData, IMemberColumn, IMembersState, initialState,
} from './storeTypes';

export const membersReducer = produce((
  draft: IMembersState = initialState,
  action: MemberActionTypes & SpecialOfferActions,
): IMembersState => {
  switch (action.type) {
    case GET_ALL_DATA_REQUEST:
      draft.view.isFetching = true;
      draft.view.workoutAggregateFilter = action.payload;
      return draft;
    case GET_ALL_DATA_SUCCESS:
      try {
        draft.data.organisationConfig = action.payload.organisationConfig;
        draft.data.filterDataFromMembersData = buildPredefinedFilterData(action.payload.members);
        draft.data.memberDetailsData = action.payload.members;
        draft.view.error = null;
        let shouldBeActiveReport: MembersReportData | undefined = draft.data.activeReport;
        if (action.payload.reportToUpdate === ReportType.members) {
          shouldBeActiveReport = draft.data.memberReportData
            .find((item: any) => item.reportType === draft.data.lastViewedMemberReport);
        } else if (action.payload.reportToUpdate === ReportType.workouts) {
          shouldBeActiveReport = draft.data.memberReportData
            .find((item: any) => item.reportType === draft.data.lastViewedWorkoutsReport);
        }
        draft.data.activeReport = shouldBeActiveReport || draft.data.activeReport;
        draft.view.tempColumnStore = draft.data.activeReport.memberColumnData.filter((column) => column.used);
        draft.view.isFetching = false;
        draft.data.allWorkouts = action.payload.workouts;
      } catch (e) { alert(e); }

      return draft;
    case GET_ALL_DATA_FAILURE:
      draft.view.error = action.payload;
      draft.view.isFetching = false;
      return draft;
    case WORKOUT_AGGREGATE_DATE_CHANGED:
      draft.view.workoutAggregateFilter = action.payload;
      return draft;
    case TOGGLE_COLUMN_IS_USED:
      const columnName = action.payload;
      const memberColumns = draft.view.tempColumnStore.map((column) => {
        if (column.accessor === columnName) {
          column.used = !column.used;
        }
        return column;
      });
      draft.view.tempColumnStore = memberColumns;
      draft.view.error = null;
      return draft;
    case SORT_COLUMN:
      const usedColumns = draft.view.tempColumnStore.filter((column) => column.used && column.sortable);
      const unUsedColumns = draft.view.tempColumnStore.filter((column) => !column.used && column.sortable);
      const sortedMemberColumns = arrayMove(
        usedColumns,
        action.payload.oldIndex,
        action.payload.newIndex,
      );
      draft.view.tempColumnStore = [...sortedMemberColumns, ...unUsedColumns];
      draft.view.error = null;
      return draft;
    case SET_FILTER_DATA:
      draft.view.filtered = action.payload;
      draft.view.error = null;
      return draft;
    case CLEAR_FILTER_DATA:
      draft.view.filtered = action.payload;
      draft.data.activeReport.filters = [];
      draft.view.error = null;
      return draft;
    case SAVE_REPORT_REQUEST:
      draft.view.isFetching = true;
      draft.view.error = null;
      return draft;
    case EDIT_MEMBER_COMMENTS:
      draft.data.memberCommentOnEdit = action.payload;
      return draft;
    case SAVE_MEMBER_COMMENTS_REQUEST:
      draft.view.memberCommentUpdateEnabled = false;
      draft.view.memberCommentServiceMessage = 'Saving...';
      return draft;
    case LOAD_MEMBER_COMMENTS:
      draft.view.memberCommentUpdateEnabled = false;
      draft.view.memberCommentServiceMessage = 'Loading...';
      return draft;
    case MEMBER_COMMENTS_SERVICE_SUCCESS:
      draft.view.memberCommentUpdateEnabled = true;
      draft.view.memberCommentServiceMessage = '';
      draft.data.memberCommentOnEdit = '';
      draft.data.memberComments = action.payload;
      return draft;
    case MEMBER_COMMENTS_SERVICE_FAILURE:
      draft.view.memberCommentUpdateEnabled = true;
      draft.view.memberCommentServiceMessage = action.payload;
      return draft;
    case SAVE_REPORT_SUCCESS:
      const existing = draft.data.memberReportData.some((x) => action.payload.activeReport
        && x.reportId === action.payload.activeReport.reportId);
      if (!existing) {
        draft.data.memberReportData = [...draft.data.memberReportData, action.payload.activeReport];
      } else {
        draft.data.memberReportData = draft.data.memberReportData.map((report) => {
          if (report.reportId === action.payload.activeReport.reportId) {
            return {
              ...report,
              ...action.payload.activeReport,
            };
          }
          return report;
        });
      }
      draft.data.activeReport = action.payload.activeReport;
      draft.view.tempReportNameStore = '';
      draft.view.showConfirmationModal = false;
      draft.view.showSaveReportModal = false;
      draft.view.error = null;
      draft.view.isFetching = false;
      draft.view.isClone = false;
      draft.view.isNewReport = false;
      return draft;
    case DELETE_REPORT_REQUEST:
      draft.view.isFetching = true;
      return draft;
    case DELETE_REPORT_SUCCESS:
      draft.data.memberReportData = draft.data.memberReportData.filter((x) => x.reportId !== action.payload);
      draft.data.activeReport = initialState.data.activeReport;
      draft.view.tempReportNameStore = '';
      draft.view.showConfirmationModal = false;
      draft.view.showSaveReportModal = false;
      draft.view.error = null;
      draft.view.isFetching = false;
      draft.view.isClone = false;
      draft.view.isNewReport = false;
      return draft;
    case SAVE_REPORT_FAILURE:
      draft.view.error = action.payload;
      draft.view.isFetching = false;
      return draft;
    case SET_SORTING_ORDER:
      draft.view.sortOrder = action.payload;
      draft.view.error = null;
      return draft;
    case TOGGLE_SAVE_REPORT_MODAL:
      draft.view.showSaveReportModal = !draft.view.showSaveReportModal;
      draft.view.error = null;
      return draft;
    case CHANGE_REPORT_NAME:
      draft.view.tempReportNameStore = action.payload;
      draft.view.error = null;
      return draft;
    case GET_SAVED_REPORTS_REQUEST:
      draft.data.memberReportData = [];
      draft.data.lastViewedMemberReport = 'initialState';
      draft.view.error = null;
      draft.view.isFetching = true;
      return draft;
    case GET_SAVED_REPORTS_SUCCESS:
      const initialReportSet = action.payload.reports
        .some((report: MembersReportData) => report.reportId === initialState.data.activeReport.reportId);
      if (!initialReportSet) {
        action.payload.reports.splice(0, 0, initialState.data.activeReport);
      }
      const allReports = [...action.payload.reports];
      draft.data.memberReportData = allReports;
      draft.view.showConfirmationModal = false;
      draft.view.isFetching = false;
      draft.view.error = null;
      // For Members
      const lastViewedReportFromReportList = allReports
        .find((item) => item.reportId === action.payload.lastViewedMemberReport);
      if (lastViewedReportFromReportList) {
        draft.data.lastViewedMemberReport = action.payload.lastViewedMemberReport;
      }
      //  For Workout
      const lastViewedWorkoutReportFromReportList = allReports
        .find((item) => item.reportId === action.payload.lastViewedWorkoutsReport);

      if (lastViewedWorkoutReportFromReportList) {
        draft.data.lastViewedWorkoutsReport = action.payload.lastViewedWorkoutsReport;
      }
      // For Dashboard
      const dashboardReports = updateDashboardReportListUsingReportIds(
        action.payload.reports,
        action.payload.dashboardReportIds,
      );
      draft.data.dashboardMemberReportData = dashboardReports;
      const lastActiveDashboardReportFromReportList = dashboardReports
        .find((item) => item.reportId === action.payload.lastActiveDashboardReport);
      if (lastActiveDashboardReportFromReportList) {
        draft.data.lastDashboardActiveReport = action.payload.lastActiveDashboardReport;
        setActiveDashboardReportDraftState(draft, action.payload.lastActiveDashboardReport);
      }
      if (!lastActiveDashboardReportFromReportList && dashboardReports[0].reportId) {
        setActiveDashboardReportDraftState(draft, dashboardReports[0].reportId);
      }
      return draft;
    case GET_DASHBOARD_ACTIVE_REPORT:
      const dashboardReportId = action.payload;
      setActiveDashboardReportDraftState(draft, dashboardReportId);
      return draft;
    case DASHBOARD_RETURN_TO_INITIAL_STATE:
      draft.data.activeDashboardActiveReport = initialState.data.activeReport;
      draft.data.lastDashboardActiveReport = initialState.data.lastViewedMemberReport;
      return draft;
    case GET_SAVED_REPORTS_FAILURE:
      draft.view.error = action.payload;
      draft.view.isFetching = false;
      return draft;
    case GET_ACTIVE_REPORT:
      const reportId = action.payload;
      setActiveReportDraftState(draft, reportId);
      return draft;
    case RETURN_TO_INITIAL_STATE:
      draft.data.activeReport = initialState.data.activeReport;
      draft.data.lastViewedMemberReport = initialState.data.lastViewedMemberReport;
      draft.data.lastViewedWorkoutsReport = initialState.data.lastViewedWorkoutsReport;
      draft.view.tempColumnStore = initialState.data.activeReport.memberColumnData;
      draft.view.filtered = [];
      draft.view.sortOrder = initialState.view.sortOrder;
      draft.view.isNewReport = true;
      draft.view.error = null;
      return draft;
    case RESET_TABLE_FILTERS:
      draft.view.filtered = [];
      draft.view.sortOrder = initialState.view.sortOrder;
      return draft;
    case TOGGLE_CONFIRMATION_MODAL:
      draft.view.showConfirmationModal = !draft.view.showConfirmationModal;
      draft.view.isDeleteConfirmation = action.payload;
      draft.view.showSaveReportModal = false;
      draft.view.error = null;
      return draft;
    case TOGGLE_IS_CLONE:
      draft.view.showSaveReportModal = !draft.view.showSaveReportModal;
      draft.view.isClone = true;
      draft.view.error = null;
      return draft;
    case SELECT_TABLE_ROW:
      const selectedRows = JSON.parse(JSON.stringify(draft.view.selectedRows));
      const rowIndex = selectedRows.findIndex((row: any) => row.uid === action.payload.uid);
      if (rowIndex > -1) {
        selectedRows.splice(rowIndex, 1);
      } else {
        selectedRows.push(action.payload);
      }
      draft.view.selectedRows = selectedRows;
      draft.view.error = null;
      return draft;
    case SAVE_USED_COLUMNS:
      draft.data.activeReport.memberColumnData = JSON.parse(JSON.stringify(draft.view.tempColumnStore));
      draft.view.error = null;
      return draft;
    case SAVE_COLUMN_WIDTH:
      const memberColumnCopy = JSON.parse(JSON.stringify(draft.data.activeReport.memberColumnData));
      memberColumnCopy.forEach((column: IMemberColumn) => {
        action.payload.forEach((resizeData: any) => {
          if (resizeData.id === column.accessor) {
            column.width = resizeData.value;
          }
        });
        return column;
      });
      const widthUpdatedReportList = [...draft.data.memberReportData];
      widthUpdatedReportList.map((report: MembersReportData) => {
        if (report.reportName === draft.data.activeReport.reportName) {
          report.memberColumnData = memberColumnCopy;
        }
        return report;
      });
      draft.data.memberReportData = widthUpdatedReportList;
      draft.data.activeReport.memberColumnData = memberColumnCopy;
      draft.view.error = null;
      return draft;
    case RESET_TEMP_COLUMN_STORE:
      draft.view.tempColumnStore = draft.data.activeReport.memberColumnData;
      draft.view.error = null;
      return draft;
    case GET_MEMBERACCOUNT_REQUEST:
      draft.view.isFetching = true;
      return draft;
    case GET_MEMBERACCOUNT_SUCCESS:
      draft.data.memberAccountData = action.payload;
      draft.view.isFetching = false;
      draft.view.error = null;
      draft.view.totalInvoiceOutstandingAmount = getTotalInvoiceOutstandingAmount(draft);
      return draft;
    case HANDLE_MERCHANDISE_DRAFT_INVOICE_SUCCESS:
    case PAY_MERCHANDISE_SUCCESS:
      if (draft.data.memberAccountData) {
        draft.data.memberAccountData = action.payload.member;
      }
      draft.view.totalInvoiceOutstandingAmount = getTotalInvoiceOutstandingAmount(draft);

      return draft;
    case PAY_MERCHANDISE_FAILURE:
      if (action.payload.paymentMethod && draft.data.memberAccountData?.userInfo) {
        draft.data.memberAccountData.userInfo.defaultPaymentMethod = action.payload.paymentMethod;
      }
      return draft;
    case GET_MEMBERACCOUNT_FAILURE:
      draft.data.memberAccountData = undefined;
      draft.view.error = action.payload;
      draft.view.isFetching = false;
      return draft;
    case TOGGLE_EDIT_MEMBER_DETAILS_MODAL:
      draft.view.showEditMemberDetails = !draft.view.showEditMemberDetails;
      return draft;
    case LOAD_FOLLOW_UP_DATE_ACTION:
      draft.view.followUpDateLoading = true;
      return draft;
    case SAVE_FOLLOW_UP_DATE_ACTION:
      draft.view.followUpDateLoading = true;
      return draft;
    case FOLLOW_UP_DATE_SERVICE_SUCCESS_ACTION:
      draft.view.followUpDateLoading = false;
      draft.view.followUpDateMessage = '';
      draft.data.memberFollowUpDate = action.payload ? action.payload : null;
      return draft;
    case FOLLOW_UP_DATE_SERVICE_FAILURE_ACTION:
      draft.data.memberFollowUpDate = null;
      draft.view.followUpDateLoading = false;
      draft.view.followUpDateMessage = action.payload;
      return draft;
    case UPDATE_MEMBER_DETAILS_REQUEST:
      draft.view.isSaving = true;
      return draft;
    case UPDATE_MEMBER_DETAILS_SUCCESS:
      if (draft.data.memberAccountData != null) {
        draft.data.memberAccountData.userInfo.profile = action.payload;
      }
      draft.view.showEditMemberDetails = false;
      draft.view.isSaving = false;
      draft.view.error = null;
      return draft;
    case UPDATE_MEMBER_DETAILS_FAILURE:
      draft.view.error = action.payload;
      draft.view.isSaving = false;
      return draft;
    case MOVE_REPORT_DOWN:
      const dashboardIdList: MembersReportData[] = [];
      action.payload.forEach((item: string) => {
        const itemFound = draft.data.memberReportData.find((item1: any) => item1.reportId === item);
        if (itemFound) {
          dashboardIdList.push(itemFound);
        }
      });
      draft.view.isFetching = false;
      draft.data.memberReportData = dashboardIdList;
      return draft;
    case MOVE_REPORT_UP:
      const reportIdList: MembersReportData[] = [];
      action.payload.forEach((item: string) => {
        const itemFound = draft.data.memberReportData.find((item1: any) => item1.reportId === item);
        if (itemFound) {
          reportIdList.push(itemFound);
        }
      });
      draft.view.isFetching = false;
      draft.data.memberReportData = reportIdList;
      return draft;
    case CREATE_SUBSCRIPTION_SUCCESS:
      draft.data.memberAccountData = action.payload.membersAccountInfo;
      draft.view.totalInvoiceOutstandingAmount = getTotalInvoiceOutstandingAmount(draft);
      return draft;
    case MEMBER_LEAD_STATUS_UPDATE_SUCCESS:
    case MEMBER_LEAD_STATUS_UPDATE_REQUEST:
    case MEMBER_LEAD_STATUS_UPDATE_FAILURE:
      const { uid, leadStatus } = action.payload;
      const member = draft.data.memberDetailsData
        .find((memberData: IMemberDetailsData) => memberData.userSettings.uid === uid);
      if (member) {
        member.userSettings.leadStatus = leadStatus;
      }

      if (draft.data.memberAccountData
        && draft.data.memberAccountData.userInfo.profile.uid === uid) {
        draft.data.memberAccountData.userInfo.profile.leadStatus = leadStatus;
      }
      return draft;
    case UPLOAD_AVATAR_REQUEST: {
      draft.view.isUploadingAvatar = true;
      return draft;
    }
    case UPLOAD_AVATAR_FAILURE:
      draft.view.isUploadingAvatar = false;
    case UPLOAD_AVATAR_SUCCESS: {
      draft.view.isUploadingAvatar = false;
      draft.data.memberDetailsData = draft.data.memberDetailsData.map((mem: IMemberDetailsData) => {
        if (mem.userSettings.uid === action.payload.uid) {
          mem.userAvatarUrl = `${userAvatarSrc(action.payload.uid)}`;
        }
        return mem;
      });
      return draft;
    }
    case SpecialOfferActionsTypes.UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_REQUEST:
      delete draft.view.specialOfferFailureMessage;
      draft.view.specialOfferSaving = true;
      break;
    case SpecialOfferActionsTypes.CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_REQUEST:
      delete draft.view.specialOfferFailureMessage;
      draft.view.specialOfferSaving = true;
      break;
    case SpecialOfferActionsTypes.UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_SUCCESS:
      const updateAction = action as IUpdateMembershipOfferInModalPressedSuccess;
      draft.data.memberAccountData!.userInfo.activeSpecialOffer = updateAction.payload.specialOffer;
      draft.view.specialOfferSaving = false;
      draft.view.specialOfferModalVisible = false;
      break;
    case SpecialOfferActionsTypes.CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_SUCCESS:
      const successAction = action as ICreateMembershipOfferInModalPressedSuccess;
      draft.data.memberAccountData!.userInfo.activeSpecialOffer = successAction.payload.specialOffer;
      draft.view.specialOfferSaving = false;
      draft.view.specialOfferModalVisible = false;
      break;
    case SpecialOfferActionsTypes.CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_FAILURE:
      draft.view.specialOfferFailureMessage = 'Failed to create special offer. Please try again';
      draft.view.specialOfferSaving = false;
      break;
    case SpecialOfferActionsTypes.UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_FAILURE:
      draft.view.specialOfferFailureMessage = 'Failed to update special offer. Please try again';
      draft.view.specialOfferSaving = false;
      break;
    case SpecialOfferActionsTypes.CREATE_MEMBERSHIP_OFFER_PRESSED:
      draft.view.specialOfferModalVisible = true;
      break;
    case SpecialOfferActionsTypes.EDIT_MEMBERSHIP_OFFER_PRESSED:
      draft.view.specialOfferModalVisible = true;
      break;
    case SpecialOfferActionsTypes.CLOSE_MEMBERSHIP_OFFER_MODAL_PRESSED:
      draft.view.specialOfferModalVisible = false;
      break;
    case SpecialOfferActionsTypes.OFFER_PLAN_SELECTED_REQUEST:
      draft.view.offerCouponsLoading = true;
      break;
    case SpecialOfferActionsTypes.OFFER_PLAN_SELECTED_SUCCESS:
      draft.data.offerCoupons = (action as IOfferPlanSelectedSuccess).payload.coupons;
      draft.view.offerCouponsLoading = false;
      break;
    case SpecialOfferActionsTypes.OFFER_PLAN_SELECTED_FAILURE:
      // TODO: handel the error case
      break;
    default:
      return draft;
  }
  return draft;
});
function setActiveReportDraftState(draft: IMembersState, reportId: string) {
  const activeReport = draft.data.memberReportData.find((x) => x.reportId === reportId)!;
  let allAvailableFields = availableMemberDataAccessors;
  if (activeReport.reportType === ReportType.workouts) {
    draft.data.lastViewedWorkoutsReport = reportId;
    allAvailableFields = [
      ...availableMemberDataAccessors,
      ...availableWorkoutAccessors,
    ];
  } else {
    draft.data.lastViewedMemberReport = reportId;
  }

  const updatedActiveReport = addMissingColumns(
    allAvailableFields,
    activeReport.memberColumnData,
  );
  draft.view.tempColumnStore = updatedActiveReport;
  draft.view.filtered = activeReport.filters;
  draft.view.sortOrder = activeReport.sortOrder;
  draft.data.activeReport = activeReport;
  draft.view.isNewReport = false;
  draft.view.isClone = false;
  draft.view.error = null;
}

function setActiveDashboardReportDraftState(draft: IMembersState, reportId: string) {
  const dashboardActiveReport = draft.data.dashboardMemberReportData.find((x) => x.reportId === reportId);
  if (dashboardActiveReport) {
    draft.data.activeDashboardActiveReport = dashboardActiveReport;
    draft.data.lastDashboardActiveReport = reportId;
    draft.view.filtered = dashboardActiveReport.filters;
    draft.view.sortOrder = dashboardActiveReport.sortOrder;
  }
}

function updateDashboardReportListUsingReportIds(
  allReports: MembersReportData[],
  reportIds: string[],
) {
  const dashboardReports: MembersReportData[] = [];
  if (reportIds) {
    reportIds.forEach((item: string) => {
      const itemFound = allReports.find((item1: any) => item1.reportId === item);
      if (itemFound) {
        dashboardReports.push(itemFound);
      }
    });
  }
  return dashboardReports;
}

function buildPredefinedFilterData(memberDetailsData: IMemberDetailsData[]): IFilterDataFromMembersData {
  return memberDetailsData.reduce((filterData: any, memberData: IMemberDetailsData) => {
    if (memberData.userSettings && memberData.userSettings.signupSource) {
      const {
        medium,
        campaign,
        source,
      } = memberData.userSettings.signupSource;
      if (medium && filterData.mediums.indexOf(medium) === -1) {
        filterData.mediums.push(medium);
      }
      if (source && filterData.sources.indexOf(source) === -1) {
        filterData.sources.push(source);
      }
      if (campaign && filterData.mediums.indexOf(campaign) === -1) {
        filterData.campaigns.push(campaign);
      }
    }
    return filterData;
  }, {
    mediums: [],
    sources: [],
    campaigns: [],
  });
}

const getTotalInvoiceOutstandingAmount = (state: IMembersState) => {
  const invoices = invoicesSelector(state.data.memberAccountData);
  let totalDue = 0;
  invoices.forEach((invoice: IInvoice) => {
    totalDue += invoice.due;
  });

  return totalDue;
};
