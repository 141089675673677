import { IPrice, IStripeCouponWrapper } from 'fitbeat.models';
import {
  ICouponSelectOption,
  IPriceSelectOption,
} from '../../members/components/subscriptionOffers/subscription-offers-selectors';

export const formatDecimalPlaces = (value: number, fractionDigits: number = 2) => {
  return value.toFixed(fractionDigits);
};

export const getFormattedCurrency = (currency: string | null | undefined) => {
  switch (currency) {
    case 'usd':
      return '$';
    case 'aud':
      return 'A$';
    default:
      return undefined;
  }
};

export const getFormattedAmount = (amount: number, currency: string | null | undefined): string => {
  return (getFormattedCurrency(currency) ?? '') + formatDecimalPlaces(amount);
};

export const getPlanDescription = (plan: IPrice): string => {
  let typeDescription: string = '';
  const productName = plan.product.name;

  switch (plan.type) {
    case 'one_time':
      typeDescription = 'Once';
      break;
    case 'recurring':
      typeDescription = `per ${plan.recurring?.interval}`;
      break;
  }

  const amount = `(${plan.unitAmount / 100} ${plan.currency.toUpperCase()} ${typeDescription})`;

  if (plan.description) {
    return `${productName}\n${plan.description} ${amount}`;
  }

  return `${productName} ${amount}`;
};

export const mapPriceToSelectOption = (plan: IPrice): IPriceSelectOption => {
  return { label: getPlanDescription(plan), value: plan };
};

export const mapCouponToSelectOption = (coupon: IStripeCouponWrapper): ICouponSelectOption => {
  return { label: getCouponDescription(coupon), value: coupon };
};

export const getCouponDescription = (coupon: IStripeCouponWrapper): string => {
  if (coupon.percent_off) {
    return `${coupon.name} (${coupon.percent_off}% off)`;
  }

  if (coupon.amount_off && coupon.currency) {
    return `${coupon.name} (${coupon.amount_off / 100} ${coupon.currency.toUpperCase()} off)`;
  }

  return coupon.name ?? '';
};
