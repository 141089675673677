import { ReportType } from 'fitbeat.models';
import React from 'react';
import DropdownReport from '../../shared/components/Header/DropdownReports';

interface IProps {
  reportType: ReportType;
}

class MemberReportHeaderReportSelection extends React.Component<IProps> {
  public render() {
    return (
        <div className='report-selection-with-sub-header-container'>
          <h4 className='report-sub-title'>{this.props.reportType === ReportType.members ?
            'Member Reports' : 'Workout Reports'}</h4>
          <DropdownReport reportType={this.props.reportType}/>
        </div>
    );
  }
}

export default MemberReportHeaderReportSelection;
