import { Stripe, StripeElements, StripePaymentElementOptions } from '@stripe/stripe-js';
import { IInvoice, IInvoiceItem, IPaymentSetupData, IPrice, IStripeCouponWrapper } from 'fitbeat.models';
import React from 'react';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import {
  canConfirmAndPayMerchandiseSelector,
  canConfirmAndPayMerchandiseWithManualCaptureSelector,
  canConfirmAndPayMerchandiseWithPosSelector,
  canDiscardInvoiceSelector,
  canPayForMerchandiseSelector,
  getMerchandiseCouponsSelector,
  getMerchandiseNoteSelector,
  getSelectedMerchandiseCoupon,
  isMerchandiseInvoiceEditableSelector,
  shouldLoadConfirmAndPayMerchandiseSelector,
} from '../../accountActions';
import { AccountActionsService } from '../../accountActions/accountActionsService';
import {
  changeMerchandiseNote,
  closeBuyMerchandiseModal,
  closeConfirmSaveDraftModal,
  discardMerchandiseInvoice,
  keepMerchandiseInvoice,
  loadStripePaymentData, openConfirmActionInvoiceModal,
  payMerchandise,
  payMerchandiseWithManualCard, payMerchandiseWithPos,
  selectMerchandiseCoupon,
  updateMerchandiseInvoice,
} from '../../accountActions/actions';
import { apiConfig } from '../../app/apiConfig';
import { IAppStore } from '../../rootReducer';
import Dropdown from '../../shared/components/Dropdown';
import TabDisplay from '../../shared/components/TabDisplay/TabDisplay';
import { getCouponDescription, getFormattedAmount, getPlanDescription } from '../../shared/helpers/formatHelper';
import { MembersService } from '../membersService';
import { defaultPaymentMethodDisplayTextSelector } from '../selector';
import ConfirmationModal from './confirmationModal';
import ManualCardCapture from './ManualCardCapture';
import Pos from './POS';

interface IProps {
  showBuyMerchandiseModal: boolean;
  isLoadingBuyMerchandiseModal: boolean;
  close: () => void;
  uid: string;
  merchandise: IPrice[];
  invoice: IInvoice | undefined;
  error: string | null;
  update: (uid: string, item: IInvoiceItem) => void;
  isLoadingInvoice: boolean;
  coupons: {label: string, value: IStripeCouponWrapper | undefined}[];
  selectedCoupon: IStripeCouponWrapper | undefined;
  selectCoupon: (uid: string, coupon: IStripeCouponWrapper) => void;
  isLoadingBuyMerchandiseButton: boolean;
  defaultPaymentMethodDisplayText: string | undefined;
  pay: (uid: string) => void;
  success: string | undefined;
  canConfirmAndPayMerchandise: boolean;
  stripe: Stripe | null;
  setupIntent: IPaymentSetupData | undefined;
  payWithManualMethod: (uid: string, stripe: Stripe | null, elements: StripeElements | null) => void;
  payWithPos: (uid: string) => void;
  loadPaymentMethodData: (uid: string) => void;
  canConfirmAndPayMerchandiseWithManualCapture: boolean;
  note: string | undefined;
  handleNoteChange: (note: string) => void;
  isEditable: boolean;
  canPayForMerchandise: boolean;
  cancelClose: () => void;
  keepInvoice: (uid: string) => void;
  discardInvoice: (uid: string) => void;
  shouldShowConfirmActionInvoiceModal: boolean;
  openConfirmActionInvoiceModal: () => void;
  canDiscardInvoice: boolean;
  canConfirmAndPayMerchandiseWithPos: boolean;
}

class MerchandiseModal extends React.Component<IProps> {
  public generateTabDisplay = ():
    {tabTitle: string, tabContents: JSX.Element | undefined}[] => {

    const {
      success,
      error,
      canConfirmAndPayMerchandise,
      canConfirmAndPayMerchandiseWithManualCapture,
      canConfirmAndPayMerchandiseWithPos,
      isLoadingBuyMerchandiseButton,
      uid,
      pay,
      payWithPos,
    } = this.props;

    const paymentOptions: StripePaymentElementOptions = {
      terms: {
        auBecsDebit: 'never',
        card: 'never',
      },
    };

    return [
      {
        tabTitle: 'Default Payment Method',
        tabContents:
          <div className='default-payment-method-container'>
            <div className='default-payment-method-text-container'>
              <p className='default-payment-method-text'>
                {this.props.defaultPaymentMethodDisplayText ??
                  'No default payment method'}</p>
            </div>
            {this.renderNotes(this.props.note)}
            <div>
              {this.renderMerchandiseActionButtons(
                () => pay(uid),
                isLoadingBuyMerchandiseButton,
                canConfirmAndPayMerchandise,
                success,
                error,
                'Confirm and Pay',
              )}
            </div>
          </div>,
      },
      {
        tabTitle: 'Card Reader',
        tabContents:
          <div>
            <Pos
              actionText={'Capture Card & Pay'}
              uid={this.props.uid}
              renderNotes={this.renderNotes(this.props.note)}
              actions={() => this.renderMerchandiseActionButtons(
                () => payWithPos(uid),
                isLoadingBuyMerchandiseButton,
                canConfirmAndPayMerchandiseWithPos,
                success,
                error,
                'Capture Card & Pay',
              )}/>
          </div>,
      },
      {
        tabTitle: 'Manual Capture',
        tabContents:
          <div>
            <ManualCardCapture paymentOptions={paymentOptions}
                               stripe={this.props.stripe}
                               setupIntent={this.props.setupIntent}
                               loadPaymentData={this.loadPaymentData}
                               renderNotes={this.renderNotes(this.props.note)}
                               actions={(stripe: Stripe | null, elements: StripeElements | null) =>
                                 this.renderMerchandiseActionButtons(
                                 () => this.handleManualCaptureSubmit(stripe, elements),
                                 isLoadingBuyMerchandiseButton,
                                 canConfirmAndPayMerchandiseWithManualCapture,
                                 success,
                                 error,
                                'Confirm and Pay',
                               )}
            />
          </div>,
      },
    ];
  }

  public render() {
    const {
      uid,
      isLoadingBuyMerchandiseModal,
      isLoadingBuyMerchandiseButton,
      close,
      update,
      selectCoupon,
      merchandise,
      invoice,
      isLoadingInvoice,
      coupons,
      selectedCoupon,
      isEditable,
      canPayForMerchandise,
      cancelClose,
      keepInvoice,
      discardInvoice,
      shouldShowConfirmActionInvoiceModal,
    } = this.props;
    const planOptions: { label: string, value: IInvoiceItem }[]
      = merchandise.map((plan: IPrice) => {
      return {
        label: getPlanDescription(plan),
        value: {
          id: undefined,
          price: plan,
          quantity: 1,
          total: 0,
          unitAmount: 0,
          description: null,
        },
      };
    });
    const quantityRange = Array.from({length: 20}, (_, i) => i + 1);

    return (
      <div>
          <div className='d-flex justify-content-between overflow-scroll'>
            <h1>Merchandise Invoice {invoice?.status ? `(${invoice.status})` : ''}</h1>
            <button
              className='modal-close-button'
              onClick={close}
            >
              X
            </button>
          </div>

          <div className='start-subscription-separator'></div>

          {isLoadingBuyMerchandiseModal && (
            <div style={{ textAlign: 'center' }}>
              <Loader
                type='ThreeDots'
                color='#FF621D'
                height={50}
                width={50}
                timeout={3000}
              />
            </div>
          )}

          {!isLoadingBuyMerchandiseModal && (
            <div className='merchandise-invoice-container'>

              <div className='line-items' style={{flexDirection: 'row', display: 'flex'}}>
                <h2 className='product-column'>Product</h2>
                <h2 className='quantity-column'>Amount</h2>
                <h2 className='total-column'>Price</h2>
                <h2 className='total-column'>Total</h2>
                <span className='remove-item-column'></span>
              </div>

              {invoice && (
                <div>
                  {invoice.invoiceLines.map((item: IInvoiceItem) => {
                    const invoicePlanOptions: { label: string, value: IInvoiceItem }[]
                      = merchandise.map((plan: IPrice) => {
                      return {
                        label: getPlanDescription(plan),
                        value: {...item, price: plan},
                      };
                    });

                    const invoiceQuantityOptions = this.getQuantityOptions(quantityRange, item);
                    return (<div className='merchandise-invoice-item' style={{flexDirection: 'row', display: 'flex'}}>
                        {isEditable ? <Dropdown
                          value={invoicePlanOptions.find((it) =>
                            it.value.price?.priceId === item.price?.priceId)}
                          options={invoicePlanOptions}
                          placeholder={'Select a product'}
                          className='merchandise select-container product-column'
                          classNamePrefix='plan select'
                          isSearchable={true}
                          onChange={(selectedValue) => update(uid, selectedValue.value)}
                        /> : (
                          <div className='product-description-column'>
                            <h2 className='product-description-value'>{item.description}</h2>
                          </div>
                        )}
                      {isEditable ? <Dropdown
                          value={invoiceQuantityOptions.find((opt) => opt.value.quantity === item.quantity)}
                          options={invoiceQuantityOptions}
                          placeholder={'Select a product'}
                          className='merchandise select-container quantity-column'
                          classNamePrefix='plan select'
                          isSearchable={true}
                          onChange={(selectedValue) => update(uid, selectedValue.value)}
                        /> :
                        (
                          <div className='quantity-column'>
                            <h2 className='quantity-description-value'>{item.quantity}</h2>
                          </div>
                        )}
                      <h2 className='total-column total'>
                        {!isLoadingInvoice ? getFormattedAmount(item.unitAmount / 100, invoice.currency) :
                          <Loader
                            type='ThreeDots'
                            color='#FF621D'
                            height={15}
                            width={50}
                            timeout={3000}
                          />
                        }
                      </h2>
                      <h2 className='total-column total'>
                        {!isLoadingInvoice ? getFormattedAmount(item.total / 100, invoice.currency) :
                          <Loader
                            type='ThreeDots'
                            color='#FF621D'
                            height={15}
                            width={50}
                            timeout={3000}
                          />
                        }
                      </h2>
                      {(!(isLoadingInvoice || isLoadingBuyMerchandiseButton) && isEditable) && (
                        <img
                          className='btnImg close-icon-icon'
                          src='resources/icons/close-circle-outlineclose-circle-outline.svg'
                          onClick={() => update(uid, {...item, quantity: 0})}
                          style={{cursor: 'pointer'}}
                        />
                      )}
                    </div>);})}
                </div>
              )}

              {isEditable && <div className='merchandise-invoice-item-new'>
                <div style={{flexDirection: 'row', display: 'flex'}}>
                  <Dropdown
                    value={null}
                    options={planOptions}
                    placeholder={'Select a product'}
                    className='merchandise select-container product-column'
                    classNamePrefix='plan select'
                    isSearchable={true}
                    onChange={(selectedValue) => update(uid, selectedValue.value)}
                  />
                  <Dropdown
                    // Quantity will always be 1 for now
                    isDisabled={true}
                    value={{label: 1, value: 1}}
                    options={quantityRange.map((quantity: number) =>
                      ({label: quantity, value: quantity}))}
                    placeholder={'Select a quantity'}
                    className='merchandise select-container quantity-column'
                    classNamePrefix='plan select'
                  />
                  <h2 className='total-column placeholder'>-</h2>
                  <h2 className='total-column placeholder'>-</h2>
                  <div className='remove-item-column'></div>
                </div>
              </div>}

              <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                <h2 className='merchandise-total'>Subtotal</h2>
                <div className='total-container'>
                  <h2 className={!invoice ? 'total-column placeholder' : 'total-column total'}>
                    {invoice && !isLoadingInvoice && getFormattedAmount(invoice.subtotal / 100, invoice.currency)}
                    {invoice && isLoadingInvoice &&
                      <Loader
                        type='ThreeDots'
                        color='#FF621D'
                        height={15}
                        width={50}
                      />
                    }
                    {!invoice && '-'}
                  </h2>
                  <span className='remove-item-column'></span>
                </div>
              </div>

              <div className='start-subscription-separator'></div>

              <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                <div style={{flexDirection: 'row', display: 'flex'}}>
                  <h2 className='coupon-header'>Coupon</h2>
                  {isEditable ? <Dropdown
                    onChange={(option) => selectCoupon(uid, option.value)}
                    options={coupons}
                    value={selectedCoupon ? {
                      label: getCouponDescription(selectedCoupon),
                      value: selectedCoupon,
                    } : -1}
                    placeholder={invoice ? 'Select a coupon' : 'First select a product'}
                    className='start-subscription select-container coupon-column'
                    classNamePrefix='coupon select'
                    isSearchable={true}
                    isDisabled={!invoice || isLoadingInvoice}
                    menuPortalTarget={document.body}
                  /> :
                    (
                      <h2 className='coupon-description-value'>{invoice?.couponCode || ''}</h2>
                    )}
                </div>
                <div className='total-container'>
                  <h2 className={(isEditable && !selectedCoupon) ? 'total-column placeholder' : 'total-column total'}>
                    {isEditable && !selectedCoupon && '-'}
                    {(isEditable && invoice && isLoadingInvoice && selectedCoupon) &&
                      <Loader
                        type='ThreeDots'
                        color='#FF621D'
                        height={15}
                        width={50}
                      />
                    }
                    {(isEditable && invoice && !isLoadingInvoice && selectedCoupon) &&
                      `-${getFormattedAmount(invoice.discount / 100, invoice.currency)}`}
                    {(!isEditable && invoice) && `-${getFormattedAmount(invoice.discount / 100, invoice.currency)}`}
                  </h2>
                  <span className='remove-item-column'></span>
                </div>
              </div>

              <div className='start-subscription-separator'></div>

              <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                <h2 className='merchandise-total'>Total</h2>
                <div className='total-container'>
                  <h2 className={!invoice ? 'total-column placeholder' : 'total-column total'}>
                    {!invoice && '-'}
                    {invoice && isLoadingInvoice &&
                      <Loader
                        type='ThreeDots'
                        color='#FF621D'
                        height={15}
                        width={50}
                      />
                    }
                    {invoice && !isLoadingInvoice && getFormattedAmount(invoice.total / 100, invoice.currency)}
                  </h2>
                  <div className='remove-item-column'></div>
                </div>
              </div>

              {canPayForMerchandise ? <div className='start-subscription-separator'></div> : this.renderNotes()}

              {canPayForMerchandise && <div>
                <h1>Payment method</h1>
                <div>
                  <TabDisplay
                    tabHeaderClass='nav payment-method-nav-tabs'
                    tabContentClass='tab-content-container'
                    tabItemsWithDisplay={this.generateTabDisplay()}
                  />
                </div>
              </div>}

            </div>
          )}
        {invoice?.status === 'draft' &&
          <ConfirmationModal titles={{
            titleAction: 'Discard draft',
            questionAction: `discard the draft invoice`,
          }}
                             showConfirmationModal={shouldShowConfirmActionInvoiceModal}
                             onClose={cancelClose}
                             onYesClick={() => discardInvoice(this.props.uid)}
                             onCancel={() => keepInvoice(this.props.uid)}
                             primaryActionText='No, save draft'
                             secondaryActionText='Yes, discard draft'
                             isFetching={isLoadingBuyMerchandiseModal}
          />}

        {invoice?.status === 'open' &&
          <ConfirmationModal titles={{
            titleAction: 'Discard (void) invoice',
            questionAction: `discard (void) the unpaid invoice`,
          }}
                             showConfirmationModal={shouldShowConfirmActionInvoiceModal}
                             onClose={cancelClose}
                             onYesClick={() => discardInvoice(this.props.uid)}
                             onCancel={() => keepInvoice(this.props.uid)}
                             primaryActionText='No, customer will pay later'
                             secondaryActionText='Yes, discard (void) invoice'
                             isFetching={isLoadingBuyMerchandiseModal}
          />}
      </div>
    );
  }

  private getQuantityOptions = (range: number[], item: IInvoiceItem) => {
    return range.map((quantity: number) =>
      ({label: quantity === 0 ? '0 (remove)' : quantity, value: {...item, quantity}}));
  }

  private handleManualCaptureSubmit = (stripe: Stripe | null, elements: any | null) => {
    const { uid, payWithManualMethod} = this.props;

    payWithManualMethod(
      uid,
      stripe,
      elements,
    );
  }

  private loadPaymentData = () => {
    this.props.loadPaymentMethodData(this.props.uid);
  }

  private renderNotes = (note?: string | undefined) => {
    return (
      <div>
        <div className='start-subscription-separator'></div>

        <h2 className='note-header'>Note</h2>
        {this.props.isEditable &&
          <Input
            className='note-input'
            type='textarea'
            value={note}
            placeholder='Type a note'
            onChange={(event) => this.props.handleNoteChange(event.target.value)}/>
        }

        {!this.props.isEditable &&
          <h2 className='note-description-value'>{this.props.invoice?.footer}</h2>
        }
      </div>
    );
  }

  private renderMerchandiseActionButtons = (
    submit: () => void,
    isLoading: boolean,
    enabled: boolean,
    success: string | undefined,
    error: string | null,
    buttonText: string,
  ) => {
    return (
      <div>
        <div className='start-subscription-separator'></div>
        {success &&
          <div style={{ textAlign: 'right' }}>
            <p className='success'>{success}</p>
          </div>
        }
        {error && (
          <div style={{ textAlign: 'right' }}>
            <p className='error'>{error}</p>
          </div>
        )}
        {isLoading ? (
          <div style={{ textAlign: 'right', marginRight: 50 }}>
            <Loader
              type='ThreeDots'
              color='#FF621D'
              height={50}
              width={50}
            />
          </div>
        ) : (
          <div>
            <div style={{ textAlign: 'right' }}>
              {this.props.invoice && <button
                className='jr-btn-default-outlined pay-merchandise'
                onClick={this.props.openConfirmActionInvoiceModal}
                style={{marginRight: 20}}
              >Discard</button>}
              <button
                className='jr-btn-default pay-merchandise'
                disabled={!enabled}
                onClick={submit}
              >{buttonText}</button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  const accountActionService = new AccountActionsService(apiConfig);
  const membersService = new MembersService(apiConfig);

  return {
    close: () => dispatch(closeBuyMerchandiseModal()),
    update: (uid: string, item: IInvoiceItem) => dispatch(updateMerchandiseInvoice(accountActionService, uid, item)),
    selectCoupon: (uid: string, coupon: IStripeCouponWrapper) =>
      dispatch(selectMerchandiseCoupon(accountActionService, uid, coupon)),
    pay: (uid: string) => dispatch(payMerchandise(uid, accountActionService, membersService)),
    payWithManualMethod: (uid: string, stripe: Stripe | null, elements: StripeElements | null) =>
      dispatch(payMerchandiseWithManualCard(
        accountActionService,
        membersService,
        uid,
        stripe,
        elements,
      )),
    payWithPos: (uid: string) => dispatch(payMerchandiseWithPos(accountActionService, membersService, uid)),
    loadPaymentMethodData: (uid: string) => dispatch(loadStripePaymentData(uid, accountActionService)),
    handleNoteChange: (note: string) => dispatch(changeMerchandiseNote(note)),
    openConfirmActionInvoiceModal: () => dispatch(openConfirmActionInvoiceModal()),
    cancelClose: () => dispatch(closeConfirmSaveDraftModal()),
    keepInvoice: (uid: string) =>
      dispatch(keepMerchandiseInvoice(uid, membersService)),
    discardInvoice: (uid: string) =>
      dispatch(discardMerchandiseInvoice(uid, membersService, accountActionService)),
  };
};

const mapStateToProps = (state: IAppStore) => {
  const { accountActions } = state;

  const {
    showBuyMerchandiseModal,
    isLoadingBuyMerchandiseModal,
  } = accountActions.view;

  const {
    setupIntent,
  } = accountActions.data;

  const defaultPaymentMethodDisplayText = defaultPaymentMethodDisplayTextSelector(state);
  const isLoadingBuyMerchandiseButton = shouldLoadConfirmAndPayMerchandiseSelector(state);

  const {
    merchandise,
    error,
    invoice,
    isLoadingInvoice,
    success,
    stripe,
    showConfirmActionInvoiceModal,
  } = accountActions.data.buyMerchandise;

  const coupons = getMerchandiseCouponsSelector(state);
  const selectedCoupon = getSelectedMerchandiseCoupon(state);
  const canConfirmAndPayMerchandise = canConfirmAndPayMerchandiseSelector(state);
  const canConfirmAndPayMerchandiseWithManualCapture = canConfirmAndPayMerchandiseWithManualCaptureSelector(state);
  const canConfirmAndPayMerchandiseWithPos = canConfirmAndPayMerchandiseWithPosSelector(state);
  const note = getMerchandiseNoteSelector(state);
  const isEditable = isMerchandiseInvoiceEditableSelector(state);
  const canPayForMerchandise = canPayForMerchandiseSelector(state);
  const canDiscardInvoice = canDiscardInvoiceSelector(state);

  return {
    showBuyMerchandiseModal,
    isLoadingBuyMerchandiseModal,
    merchandise,
    invoice,
    error,
    isLoadingInvoice,
    coupons,
    selectedCoupon,
    isLoadingBuyMerchandiseButton,
    defaultPaymentMethodDisplayText,
    success,
    canConfirmAndPayMerchandise,
    canConfirmAndPayMerchandiseWithManualCapture,
    stripe,
    setupIntent,
    note,
    isEditable,
    canPayForMerchandise,
    shouldShowConfirmActionInvoiceModal: showConfirmActionInvoiceModal,
    canDiscardInvoice,
    canConfirmAndPayMerchandiseWithPos,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchandiseModal);
