import { UserProfile } from 'fitbeat.gym-core';
import { IUserInfo } from 'fitbeat.models';
import { Gym } from 'fitbeat.models';
import moment from 'moment';
import React, { Component } from 'react';
import { getEmailLink, getEmailToSMSViaGmailLink } from '../../util/helpers';
import { getProgramTypeTranslation, getSkillLevelMapping } from '../config';
import MemberDetailsSingleField, {
  ISingleField,
} from './MemberDetailsSingleField';

interface IProps {
  memberDetails: UserProfile;
  userAccountInfo: IUserInfo;
  currentGym: Gym;
}

export default class MemberSummaryDetails extends Component<IProps> {
  public render() {
    const { memberDetails, userAccountInfo, currentGym } = this.props;
    const mappedData = this.mapDataToSummaryDetails(memberDetails, userAccountInfo, currentGym);
    const emailLink = getEmailLink(currentGym, memberDetails);
    return (
      <div className='member-summary-details-container'>
        <div className='summary-email-text-container'>
          <a
            target='mygmailtab'
            href={emailLink}
            className='summary-email-text'>{memberDetails.email}</a>
        </div>
        {mappedData.map((singleData: ISingleField) => {
          return (
            <MemberDetailsSingleField
              field={{
                label: singleData.label,
                value: singleData.value,
                urlLink: singleData.urlLink,
              }}
            />
          );
        })}
      </div>
    );
  }

  private mapDataToSummaryDetails = (
    memberDetails: UserProfile,
    userAccountInfo: IUserInfo,
    gym: Gym): ISingleField[] => {
    const dateValue = moment(memberDetails.userSignupDate).format('ddd, DD MMM YYYY');
    const emailLink = memberDetails.mobileNumber ? getEmailToSMSViaGmailLink(gym, memberDetails) : undefined;
    return [
      { label: 'Signup Date', value: dateValue },
      {
        label: 'Mobile Number',
        value: memberDetails.mobileNumber,
        urlLink: emailLink,
      },
      { label: 'Plan Description', value: userAccountInfo.planDescription },
      {
        label: 'Skill Level',
        value: getSkillLevelMapping(memberDetails.skillLevel),
      },
      {
        label: 'Fitness Goal',
        value: getProgramTypeTranslation(memberDetails.longTermGoal),
      },
    ];
  }
}
