import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import { connect } from 'react-redux';
import { IAppStore } from '../rootReducer';
import { getFollowUpDate, removeFollowUpDate, saveFollowUpDate } from './actions';

interface IProps {
  uid: string;
  followUpDateLoading: boolean;
  followUpDateMessage: string;
  memberFollowUpDate: Date | null;
  getFollowUpDate(uid: string): void;
  removeFollowUpDate(uid: string): void;
  saveFollowUpDate(uid: string, followUpDate: string): void;
}

interface IState {
  focused: boolean;
  selectedDate: string;
  dateHandlerVisible: boolean;
}

class MemberFollowUpDate extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      focused: false,
      selectedDate: props.memberFollowUpDate,
      dateHandlerVisible: false,
    };
  }

  public setFocus = (focused: boolean) => {
    this.setState({ focused });
  }

  public componentDidMount = async () => {
    const { memberFollowUpDate } = this.props;
    const dateToUse = this.state.selectedDate || memberFollowUpDate;
    this.props.getFollowUpDate(this.props.uid);
    if (!dateToUse && this.state.focused ) {
        this.setState({dateHandlerVisible: true});
    }
  }

  public saveFollowUpDate = (date: string) => {
    this.props.saveFollowUpDate(this.props.uid, date);
  }

  public setUpFollowUpDate = () => {
    this.setState({ dateHandlerVisible: true });
  }

  public clearFollowUpDate = () => {
    this.setState({ selectedDate: '', dateHandlerVisible: false });
    this.props.removeFollowUpDate(this.props.uid);
  }

  public handleOnDateChange = (selectedDate: Moment) => {
    if (selectedDate) {
      const dateToSave = selectedDate.utc().format();
      this.setState({ selectedDate: dateToSave });
      this.saveFollowUpDate(dateToSave);
    }
  }

  public handleOnFocusChange  = () => {
    const { memberFollowUpDate } = this.props;
    const { selectedDate, focused } = this.state;
    const dateToUse = selectedDate || memberFollowUpDate;
    if (!dateToUse && focused)  {
      this.setFocus(false);
      this.setState({ dateHandlerVisible: false });
      return;
    }
    this.setFocus(!focused);
  }

  public render() {
    const { followUpDateLoading, followUpDateMessage, memberFollowUpDate } =
      this.props;
    const dateToUse = this.state.selectedDate || memberFollowUpDate;
    const displayDate = dateToUse ? moment(dateToUse) : null;
    const followUpDateHasPassed = dateToUse ? moment(dateToUse).isBefore(moment()) : false;

    return (
      <div className='comment-section-container'>
        {!displayDate && (
          <button
            className='jr-btn-default-outlined'
            style={{
              width: '100%',
              marginTop: 15,
              marginBottom: 15,
            }}
            onClick={this.setUpFollowUpDate}>
            Set Follow Up Date
          </button>
        )}
        {(displayDate || this.state.dateHandlerVisible) && !followUpDateLoading ? (
          <div>
            <h1 className='comments-heading'>Follow up date:</h1>
            <div className='follow-up-date-container'>
              <div className='follow-up-date-and-icon-container'>
                {followUpDateHasPassed &&
                  <img
                  className='btnImg refreshIconContainer'
                  src='resources/images/notifications-circle.svg'
                  />
                }
                <SingleDatePicker
                  date={displayDate}
                  onDateChange={this.handleOnDateChange}
                  focused={this.state.focused ||
                    (this.state.dateHandlerVisible && !displayDate)}
                  noBorder={true}
                  openDirection={'up'}
                  displayFormat='YYYY-MM-DD'
                  onFocusChange={this.handleOnFocusChange}
                  id='member-follow-up-date-picker'
                  block={true}
                  small={true}
                  daySize={40}
                  hideKeyboardShortcutsPanel={true}
                  appendToBody={true}
                  numberOfMonths={1}
                  readOnly={true}
                />
              </div>
              <button
                className='jr-btn-default'
                onClick={this.clearFollowUpDate}>
                Clear
              </button>
            </div>
          </div>
        ) : null}
        {followUpDateMessage && <p>{followUpDateMessage}</p>}
      </div>
    );
  }
}

const mapStateToProps = (state: IAppStore) => {
  const { followUpDateLoading, followUpDateMessage } = state.members.view;
  const { memberFollowUpDate } = state.members.data;

  return { followUpDateLoading, followUpDateMessage, memberFollowUpDate };
};

const mapDispatchToProps = { saveFollowUpDate, getFollowUpDate, removeFollowUpDate };

export default connect(mapStateToProps, mapDispatchToProps)(MemberFollowUpDate);
