import { IMemberDetailsData } from 'fitbeat.gym-core';
import {
  Gym,
  IAccountComment,
  IMemberColumnData,
  IOrganisationConfig,
  IStripeCouponWrapper,
  IUserWorkoutSessionMemberReport,
  MembersReportData, ReportType,
  UserAccountDetail,
} from 'fitbeat.models';
import { Filter, SortingRule } from 'react-table';
import { IWorkoutAggregateFilter } from './actions';
import { availableMemberDataAccessors, buildColumnsFromAccessors } from './filters/members';

export interface IMemberColumn {
  Header: any;
  accessor?: string;
  used: boolean;
  filterMethod?: any;
  filterAll?: boolean;
  Filter?: any;
  filterable?: boolean;
  width?: number;
  Cell?: any;
  filterRender?: any;
  sortable?: boolean;
}

export interface IFilterDataFromMembersData {
  sources: string[];
  mediums: string[];
  campaigns: string[];
}

export const convertDataToView = (data: IMemberColumnData, filterData: IFilterDataFromMembersData, currentGym: Gym) => {
  if (!data.accessor) {
    throw new Error('No accessor');
  }
  const columns = buildColumnsFromAccessors(data.accessor, filterData, currentGym);
  columns.used = data.used;
  columns.sortable = data.sortable;
  columns.width = data.width;
  return columns;
};

export const convertDataToViews = (
  data: IMemberColumnData[],
  filterData: IFilterDataFromMembersData,
  currentGym: Gym) => {
  return data.map((col: IMemberColumnData) => convertDataToView(col, filterData, currentGym));
};

export interface IMemberAccountData {
  userAccountDetail?: UserAccountDetail;
}

export interface IMembersData {
  memberReportData: MembersReportData[];
  memberDetailsData: IMemberDetailsData[];
  activeReport: MembersReportData;
  memberAccountData?: UserAccountDetail;
  memberComments: IAccountComment[];
  memberCommentOnEdit: string;
  memberFollowUpDate: Date | null;
  lastViewedMemberReport: string;
  lastViewedWorkoutsReport: string;
  dashboardMemberReportData: MembersReportData[];
  lastDashboardActiveReport: string;
  activeDashboardActiveReport: MembersReportData;
  filterDataFromMembersData: IFilterDataFromMembersData;
  allWorkouts: IUserWorkoutSessionMemberReport[];
  offerCoupons: IStripeCouponWrapper[] | null;
  organisationConfig?: IOrganisationConfig;
}
export interface IMembersState {
  data: IMembersData;
  view: IMemberUIView;
}

export interface IMemberUIView {
  error: null | string;
  isFetching: boolean;
  isUploadingAvatar: boolean;
  sortOrder: SortingRule[];
  filtered: Filter[];
  initialLoadDone: boolean;
  showSaveReportModal: boolean;
  showConfirmationModal: boolean;
  isNewReport: boolean;
  isClone: boolean;
  isDeleteConfirmation: boolean;
  selectedRows: any[];
  tempColumnStore: IMemberColumnData[];
  tempReportNameStore: string;
  totalInvoiceOutstandingAmount: number;
  showEditMemberDetails: boolean;
  isSaving: boolean;
  memberCommentUpdateEnabled: boolean;
  memberCommentServiceMessage: string;
  followUpDateLoading: boolean;
  followUpDateMessage: string;
  specialOfferModalVisible: boolean;
  offerCouponsLoading: boolean;
  specialOfferSaving: boolean;
  specialOfferFailureMessage?: string;
  workoutAggregateFilter?: IWorkoutAggregateFilter;
}

export type MessageStatus = 'error' | 'success';

const initialMembersReportState = {
  reportId: 'initialState',
  reportName: 'Members Database *',
  dateCreated: new Date(),
  reportType: ReportType.members,
  memberColumnData: [...availableMemberDataAccessors],
  sortOrder: [],
  filters: [],
  selected: true,
};

export const initialState: IMembersState = {
  data: {
    memberReportData: [],
    memberDetailsData: [],
    memberComments: [],
    memberCommentOnEdit: '',
    memberFollowUpDate: null,
    activeReport: initialMembersReportState,
    lastViewedMemberReport: 'initialState',
    lastViewedWorkoutsReport: 'initialState',
    lastDashboardActiveReport: 'initialState',
    activeDashboardActiveReport: initialMembersReportState,
    dashboardMemberReportData: [],
    allWorkouts: [],
    organisationConfig: null,
    filterDataFromMembersData: {
      campaigns: [],
      mediums: [],
      sources: [],
    },
    offerCoupons: null,
  },
  view: {
    error: null,
    isFetching: false,
    sortOrder: [
      {
        id: 'userSignupDate',
        desc: true,
      },
    ],
    filtered: [],
    initialLoadDone: false,
    showSaveReportModal: false,
    showConfirmationModal: false,
    isNewReport: true,
    isClone: false,
    isDeleteConfirmation: false,
    selectedRows: [],
    tempColumnStore: [],
    tempReportNameStore: '',
    showEditMemberDetails: false,
    isSaving: false,
    memberCommentUpdateEnabled: false,
    memberCommentServiceMessage: '',
    followUpDateLoading: false,
    followUpDateMessage: '',
    isUploadingAvatar: false,
    totalInvoiceOutstandingAmount: 0,
    specialOfferModalVisible: false,
    offerCouponsLoading: false,
    specialOfferSaving: false,
    workoutAggregateFilter: undefined,
  },
};

export interface ISortColumn {
  oldIndex: number;
  newIndex: number;
}
