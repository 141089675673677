import { StrengthRatioForMuscleGroups } from 'fitbeat.models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';
import MemberDetailsSingleField from './MemberDetailsSingleField';

interface IProps {
  strengthRatioForMuscleGroups?: StrengthRatioForMuscleGroups;
}
class StrengthRatioForMuscleGroupsDetails extends Component<IProps> {
  public render() {
    const { strengthRatioForMuscleGroups } = this.props;
    return <div>
        <div className='plan-end-date-headings-container'>
            <h3 className='member-details-section-sub-header'>Strength Profile</h3>
        </div>
        <div>
          {strengthRatioForMuscleGroups && Object.keys(strengthRatioForMuscleGroups).map((keyName, index) => (
            <MemberDetailsSingleField
              field={{
                label: keyName,
                value: Number(strengthRatioForMuscleGroups[keyName].toFixed(2)),
              }}
              key={index}
            />))}
        </div>
    </div>;
  }
}

const mapStateToProps = (state: IAppStore, props: any) => {
  const { members } = state;
  const { memberAccountData } = members.data;
  const memberDetail = memberAccountData && memberAccountData.userInfo.profile;
  const strengthRatioForMuscleGroups = memberDetail?.strengthRatioForMuscleGroups;
  return {
    memberDetail,
    strengthRatioForMuscleGroups,
  };
};

export default connect(mapStateToProps, null)(StrengthRatioForMuscleGroupsDetails);
