import { UserProfile } from 'fitbeat.gym-core';
import {
  IPhaseActionInfo,
  IStripeSchedulePhase,
  IUserInfo,
  UserAccountScheduleActionInfo,
} from 'fitbeat.models';
import moment from 'moment';
import React, { Component } from 'react';
import ReactTable from 'react-table';
import { MemberTableHeader } from './memberTableHeader';
import { PhaseControlsDropdown } from './PhaseControlsDropdown';

interface IProps {
  memberAccountShedule?: UserAccountScheduleActionInfo;
  userAccountInfo: IUserInfo;
  userProfile: UserProfile;
}

export const subscriptionScheduleColumnData = [
  {
    Header: () => <MemberTableHeader showIcons={false} header='Plan' />,
    accessor: 'plan',
    width: 220,
  },
  {
    Header: () => <MemberTableHeader showIcons={false} header='Starts' />,
    accessor: 'startDate',
    width: 100,
  },
  {
    Header: () => <MemberTableHeader showIcons={false} header='Ends' />,
    accessor: 'endDate',
    width: 100,
  },
  {
    Header: () => <MemberTableHeader showIcons={false} header='' />,
    accessor: 'actions',
    width: 100,
    Cell: ({original}: any) => {
      if (original.freezeInfo?.enabled || original.freeWeeksInfo?.enabled) {
        return <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
          <PhaseControlsDropdown subscriptionSchedulePhase={original}></PhaseControlsDropdown>
        </div>;
      }

      return undefined;
    },
  },
];

export interface ISubscriptionScheduleTableData {
  uid: string;
  plan: string;
  startDate: string;
  endDate: string;
  freeWeekInfo?: IPhaseActionInfo;
  freezeInfo?: IPhaseActionInfo;
}

class SubscriptionSchedule extends Component<IProps, any> {
  public reactTable: any;
  constructor(props: IProps) {
    super(props);
    this.reactTable = React.createRef();
  }

  public render() {
    let data: ISubscriptionScheduleTableData[] = [];
    const { userAccountInfo, memberAccountShedule, userProfile } = this.props;
    const subscriptionSchedule = memberAccountShedule?.subscriptionSchedule;

    if (subscriptionSchedule) {
      data = subscriptionSchedule.phases.map((phase: IStripeSchedulePhase) => {
      let endDate = 'Never';
      if (!phase.isInfinite && phase.end_date) {
        endDate = moment(phase.end_date).format('D MMM [‘]YY');
      }

      return {
          uid: userAccountInfo.profile.uid,
          plan: phase.plans[0].description,
          startDate: moment(phase.start_date).format('D MMM [‘]YY'),
          endDate,
          freeWeekInfo: phase.freeWeekPhaseInfo,
          freezeInfo: phase.freezePhaseInfo,
        } as ISubscriptionScheduleTableData;
      });
    }

    if (!subscriptionSchedule && userProfile.currentPlanStatus === 'active') {
      data = [{
        uid: userAccountInfo.profile.uid,
        plan: userAccountInfo.planDescription || '',
        startDate: userAccountInfo.contractStart ?
          moment(userAccountInfo.contractStart).format('D MMM [‘]YY') :
          '',
        endDate: userAccountInfo.cancelAt ?
          moment(userAccountInfo.cancelAt).format('D MMM [‘]YY') :
          'Never',
        freeWeekInfo: memberAccountShedule?.freeWeekSubscriptionInfo,
        freezeInfo: memberAccountShedule?.freeWeekSubscriptionInfo,
      }];
    }

    return (
      <div style={{flex: 'fit-content'}}>
        <ReactTable
          minRows={0}
          className='subscriptionScheduleTable'
          sortable={false}
          filterable={false}
          showPagination={false}
          showFilters={false}
          ref={this.reactTable}
          loadingText='Loading...'
          data={data}
          noDataText={'No subscription schedule'}
          columns={subscriptionScheduleColumnData}
        />
      </div>
    );
  }
}

export default SubscriptionSchedule;
