import { ICancelSubscriptionResponse, UserAccountScheduleActionInfo } from 'fitbeat.models';
import moment from 'moment';
import React from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { apiConfig } from '../../app/apiConfig';
import { MembersService } from '../../members/membersService';
import { IAppStore } from '../../rootReducer';
import {
  cancelSubscription,
  toggleCancelSubscriptionModal,
  updateCancellationFee,
} from '../actions';

interface IProps {
  uid: string;
  error: string | null;
  isFetching: boolean;
  showCancelSubscriptionModal: boolean;
  memberAccountShedule: UserAccountScheduleActionInfo;
  cancelSubscriptionDetail?: ICancelSubscriptionResponse;
  toggleCancelSubscriptionModal: () => void;
  cancelSubscription: (uid: string, penaltyAmount: number, isImmediateCancel: boolean) => void;
  updateCancellationFee: (amount: any) => void;
}

class CancelSubscrtionModal extends React.Component<IProps, {}> {
  constructor(props: any) {
    super(props);
  }

  public closeModal = () => {
    this.props.toggleCancelSubscriptionModal();
  }

  public cancelSubscription = (isImmediateCancel: boolean) => {
    const { uid, memberAccountShedule } = this.props;
    const defaultPenaltyAmount: any = memberAccountShedule && memberAccountShedule.cancelSubscriptionInfo
      ? memberAccountShedule.cancelSubscriptionInfo.defaultPenaltyAmount
      : 0;

    this.props.cancelSubscription(uid, parseFloat(defaultPenaltyAmount), isImmediateCancel);
  }

  public render() {
    const { memberAccountShedule, cancelSubscriptionDetail } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.showCancelSubscriptionModal}
          className='modal-content'
          overlayClassName='modal-overlay'
          ariaHideApp={false}
          contentLabel='Columns'>
          <div className='column-label content-margin'>
            <h2
              className='column-header'>
              {`Cancel Subscription`}
            </h2>
            <button
              className='modal-close-button'
              onClick={this.closeModal}>
              X
            </button>
          </div>
          {memberAccountShedule && memberAccountShedule.cancelSubscriptionInfo &&
            <div>
              <p className='cancel-message'>{`${memberAccountShedule.cancelSubscriptionInfo.message}`}</p>
              <div>
                <h4 style={{ display: 'inline-block', marginRight: 5 }}>Cancellation will take place on:</h4>
                <h4 style={{ display: 'inline-block' }}>
                  {moment(memberAccountShedule.cancelSubscriptionInfo.date).format('YYYY-MM-DD')}</h4>
              </div>
              <InputGroup style={{ width: '80%' }}>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>Cancellation penalty amount:</InputGroupText>
                </InputGroupAddon>
                <Input
                  disabled={!memberAccountShedule.cancelSubscriptionInfo.enabledCancellationFeeOverride}
                  value={memberAccountShedule.cancelSubscriptionInfo.defaultPenaltyAmount}
                  placeholder='cancellation penalty'
                  onChange={(e) => this.props.updateCancellationFee(e.target.value)}
                />
              </InputGroup>
            </div>
          }
          {cancelSubscriptionDetail &&
            <div style={{ textAlign: 'center' }}>
              <p style={{ paddingTop: '20px' }}>{
                `Cancel date: ${moment(cancelSubscriptionDetail.cancelDate).format('YYYY-MM-DD')}.` +
                ` Penalty amount: $${cancelSubscriptionDetail.penaltyAmount}`
              }</p>
            </div>
          }
          {this.props.error &&
            <div style={{ textAlign: 'left', paddingTop: '20px' }}>
              <p style={{ color: 'red' }}>{this.props.error}</p>
            </div>
          }
          {this.props.isFetching
            ?
            <div style={{ textAlign: 'center' }}>
              <Loader
                type='ThreeDots'
                color='#FF621D'
                height={50}
                width={50}
                timeout={3000}
              />
            </div>
            : cancelSubscriptionDetail ?
              <div style={{ display: 'inline-block' }}>
                <button
                  className='jr-btn-default'
                  style={{ marginTop: 10 }}
                  onClick={this.closeModal}>
                  Close
                </button>
              </div>
              :
              <div style={{ display: 'inline-block' }}>
                <div style={{ float: 'right', display: 'inline-block' }}>
                  <button
                    className='jr-btn-default'
                    style={{ marginTop: 10 }}
                    onClick={this.closeModal}>
                    No, Do Not Cancel
                </button>
                </div>
                <div style={{ float: 'left', display: 'inline-block' }}>
                  <button
                    className='jr-btn-default'
                    style={{ marginTop: 10, marginRight: 10 }}
                    onClick={() => this.cancelSubscription(false)}>
                    Yes, Schedule Cancellation
                </button>

                <button
                    className='jr-btn-default'
                    style={{ marginTop: 10 }}
                    onClick={() => this.cancelSubscription(true)}>
                    Yes, Cancel Immediately
                </button>

                </div>
              </div>
          }
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  const membersService = new MembersService(apiConfig);

  return {
    toggleCancelSubscriptionModal: () => dispatch(toggleCancelSubscriptionModal()),
    updateCancellationFee: (cancellationFee: number) => dispatch(updateCancellationFee(cancellationFee)),
    cancelSubscription: (uid: string, penaltyAmount: number, isImmediateCancel: boolean) =>
      dispatch(cancelSubscription(membersService, uid, penaltyAmount, isImmediateCancel)),
  };
};

const mapStateToProps = (state: IAppStore) => {
  const { accountActions } = state;
  const { showCancelSubscriptionModal, isLoadingCancelSubscription, cancelSubscriptionError } = accountActions.view;
  const { memberAccountShedule, cancelSubscriptionDetail } = accountActions.data;
  return {
    showCancelSubscriptionModal,
    isFetching: isLoadingCancelSubscription,
    error: cancelSubscriptionError,
    memberAccountShedule,
    cancelSubscriptionDetail,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelSubscrtionModal);
