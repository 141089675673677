import React, { useRef } from 'react';

interface IProps {
  competitionType: string;
  teams: string[];
  newTeamName: string;
  handleDeleteTeam: (index: number) => void;
  handleTeamNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddTeam: () => void;
}

const LeaderboardConfigTeams: React.FC<IProps> = ({
  competitionType,
  teams,
  newTeamName,
  handleDeleteTeam,
  handleTeamNameChange,
  handleAddTeam,
}) => {
  const originalTeamsRef = useRef<string[]>(teams);

  if (competitionType !== 'inter-team') {
    return null;
  }

  return (
    <div style={styles.formColumn}>
      <h3 style={styles.columnHeader} className='leaderboard-create-headers'>
        Teams
      </h3>
      {teams.map((team, index) => (
        <div key={index} style={styles.teamRow}>
          <span>{team}</span>
          {!originalTeamsRef.current.includes(team) && (
            <button
              type='button'
              onClick={() => handleDeleteTeam(index)}
              style={styles.deleteButton}
              className='leaderboard-create-headers'
            >
              X
            </button>
          )}
        </div>
      ))}
      <input
        type='text'
        value={newTeamName}
        onChange={handleTeamNameChange}
        placeholder='Enter team name'
        style={styles.inputField}
      />
      <button type='button' onClick={handleAddTeam} className='jr-btn-default'>
        Add Team
      </button>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  formColumn: {
    marginRight: '16px',
  },
  columnHeader: {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  teamRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  deleteButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  inputField: {
    width: '100%',
    padding: '8px',
    marginBottom: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
};

export default LeaderboardConfigTeams;
