import { Gym, ISpecialOfferDisplay, UserAccountDetail } from 'fitbeat.models';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { currentGymSelector } from '../../../adminSettings/selector';
import { IAppStore } from '../../../rootReducer';
import { SpecialOfferCard } from './SpecialOfferCard';
import { StandardMembershipCard } from './StandardMembershipCard';
import { subscriptionOfferComponentSelector } from './subscription-offers-selectors';

interface IProps {
  userAccountDetail?: UserAccountDetail;
  currentGym: Gym;
  componentToDisplay: SubscriptionOfferComponent;
  activeSpecialOffer?: ISpecialOfferDisplay;
  standardMembershipPlansLink?: string;
}

export type SubscriptionOfferComponent = 'StandardMembershipsCard' | 'SpecialOfferCard' | 'None';

const SubscriptionOfferMemberDetailsSection: FunctionComponent<IProps> = (props) => {
  switch (props.componentToDisplay) {
    case 'StandardMembershipsCard':
      return <StandardMembershipCard
        userAccountDetails={props.userAccountDetail!} currentGym={props.currentGym}
        standardMembershipPlansLink={props.standardMembershipPlansLink} />;
    case 'SpecialOfferCard':
      return <SpecialOfferCard
        userAccountDetails={props.userAccountDetail!}
        currentGym={props.currentGym}
        specialOffer={props.activeSpecialOffer!}
      />;
    default:
      return <></>;
  }
};

const mapStateToProps = (state: IAppStore): IProps => ({
  userAccountDetail: state.members.data.memberAccountData,
  activeSpecialOffer: state.members.data.memberAccountData?.userInfo?.activeSpecialOffer,
  standardMembershipPlansLink: state.members.data.memberAccountData?.userInfo?.standardMembershipPlansLink,
  currentGym: currentGymSelector(state),
  componentToDisplay: subscriptionOfferComponentSelector(state),
});

export default connect(mapStateToProps)(SubscriptionOfferMemberDetailsSection);
