import { IFreeWeekPhaseInfo, IFreezePhaseInfo, IPhaseActionInfo } from 'fitbeat.models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { toggleAddFreeWeeksModal, toggleAddPauseModal } from '../../accountActions/actions';
import Dropdown from '../../shared/components/Dropdown';
import { ISubscriptionScheduleTableData } from './SubscriptionSchedule';

interface IProps {
  addFreeze: (freezePhaseInfo: IPhaseActionInfo) => void;
  addFreeWeeks: (freeWeeksPhaseInfo: IFreeWeekPhaseInfo) => void;
  subscriptionSchedulePhase: ISubscriptionScheduleTableData;
}

enum Action {
  freeze,
  freeWeeks,
}

export const leadStatusDropdownValues = [
  {
    label: 'Add Freeze',
    value: Action.freeze,
  },
  {
    label: 'Add Free Days',
    value: Action.freeWeeks,
  }];

class PhaseControlsDropdownComponent extends Component<IProps> {
  public render() {
    return (
      <Dropdown
        isSearchable={false}
        placeholder=''
        options={leadStatusDropdownValues}
        menuPortalTarget={document.body}
        className={`select-container`}
        classNamePrefix={'select'}
        components={{
          Option: this.customOption,
          Control: this.customControl,
          DropdownIndicator: this.DropdownIndicator,
        }}
      />
    );
  }

  private ControlOnClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }

  private customControl = ({...props }) => {
    return (
      <div onClick={this.ControlOnClick}>
        <components.Control {...props}>
          {props.children}
        </components.Control>
      </div>);
  }

  private DropdownIndicator = ({...props }) => {
    return (
        <components.DropdownIndicator {...props}>
          <img
            src='resources/icons/ellipse.svg'
          />
        </components.DropdownIndicator>
    );
  }

  private customOption = (optionProps: any) => {
    const HandleOptionClick = () => {
      optionProps.selectOption(optionProps.data.value);

      const selectedOption = optionProps.data.value;

      if (selectedOption === Action.freeze) {
        this.props.addFreeze(this.props.subscriptionSchedulePhase.freezeInfo!);
      }

      if (selectedOption === Action.freeWeeks) {
        this.props.addFreeWeeks(this.props.subscriptionSchedulePhase.freeWeekInfo!);
      }
    };
    return (
      <div
        {...optionProps.data.innerProps}
        className={'phase-control-options'}
        onClick={HandleOptionClick}
      >
        <img
          src='resources/icons/add.svg'
        />
        <div className='phase-control-option-text'>{optionProps.data.label}</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addFreeWeeks: (freeWeeksPhaseInfo: IFreeWeekPhaseInfo) => dispatch(toggleAddFreeWeeksModal(freeWeeksPhaseInfo)),
    addFreeze: (freezePhaseInfo: IFreezePhaseInfo) => dispatch(toggleAddPauseModal(freezePhaseInfo)),
  };
};

export const PhaseControlsDropdown = connect(null, mapDispatchToProps)(PhaseControlsDropdownComponent);
