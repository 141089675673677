import { IClientSideLogger } from 'fitbeat.gym-core';

// TODO: this file is shared between zone screens and web-admin, it should move to a shared client-side lib
export class MultiLogger implements IClientSideLogger {
  constructor(private loggers: IClientSideLogger[]) {}

  public error(e: Error, customErrorMessage?: string): void {
    this.loggers.forEach((logger: IClientSideLogger) => logger.error(e, customErrorMessage));
  }

  public info(msg: string): void {
    this.loggers.forEach((logger: IClientSideLogger) => logger.info(msg));
  }

  public trace(msg: string): void {
    this.loggers.forEach((logger: IClientSideLogger) => logger.trace(msg));
  }

  public warn(msg: string): void {
    this.loggers.forEach((logger: IClientSideLogger) => logger.warn(msg));
  }
}
