import { IPrice, ISpecialOfferDisplay, IStripeCouponWrapper } from 'fitbeat.models';

export function calculateSubscriptionTotal(amount: number, coupon?: IStripeCouponWrapper): number {
  if (coupon?.percent_off) {
    const discount = amount * (coupon.percent_off / 100.0);
    return amount - discount;
  }

  if (coupon?.amount_off) {
    return amount - (coupon.amount_off / 100.0);
  }

  return amount;
}

interface ISubscriptionValues {
  subscriptionAmount: number;
  totalDiscount: number;
  totalPayable: number;
}

export function calculateSubscriptionValues(price?: IPrice, coupon?: IStripeCouponWrapper): ISubscriptionValues {
  if (!price) {
    return { subscriptionAmount: 0, totalDiscount: 0, totalPayable: 0 };
  }
  const subscriptionAmount = price.unitAmount / 100;
  const totalPayable = calculateSubscriptionTotal(subscriptionAmount, coupon);
  const totalDiscount = subscriptionAmount - totalPayable;
  return { subscriptionAmount, totalDiscount, totalPayable };
}

export function getCouponAmountOffDisplay(specialOffer: ISpecialOfferDisplay): string {
  if (specialOffer.couponPercentOff) {
    return specialOffer.couponPercentOff.toString();
  }
  if (specialOffer.couponAmountOff) {
    return (specialOffer.couponAmountOff / 100.0).toString();
  }
  return '';
}
