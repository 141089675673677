import { routerReducer } from 'react-router-redux';
import { Filter } from 'react-table';
import { combineReducers, Reducer } from 'redux';
import { accountActionsReducer, IMemberAccountActionsState } from './accountActions';
import { admingSettingsReducer, IAdminSettingsState} from './adminSettings';
import { ILeaderboardsState } from './leaderboards';
import leaderboardsSlice from './leaderboards/leaderboardsSlice';
import { IMembersState, membersReducer } from './members';
import Settings from './reducers/Settings';
import { IMemberStripeState, stripeReducer } from './stripe';

export interface IAppStore {
  members: IMembersState;
  stripe: IMemberStripeState;
  accountActions: IMemberAccountActionsState;
  adminSettings: IAdminSettingsState;
  // leaderboards: ILeaderboardsState;
  leaderboards: ILeaderboardsState;
  exportToCSV?: any[];
  filtered?: Filter[];
}

const reducers: Reducer<IAppStore> = combineReducers({
    routing: routerReducer,
    settings: Settings,
    members: membersReducer,
    dashboard: membersReducer,
    leaderboards: leaderboardsSlice,
    stripe: stripeReducer,
    accountActions: accountActionsReducer,
    adminSettings: admingSettingsReducer,
});

export default reducers;
