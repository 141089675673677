import { UserProfile } from 'fitbeat.gym-core';
import {
  Gym,
  UserAccountDetail,
  UserAccountScheduleActionInfo,
} from 'fitbeat.models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  toggleCancelSubscriptionModal, toggleStartSubscriptionModal,
} from '../../accountActions/actions';

import CancelPauseModal from '../../accountActions/components/cancelPauseModal';
import CancelSubscrtionModal from '../../accountActions/components/cancelSubscriptionModal';
import StartSubscriptionModal from '../../accountActions/components/startSubscriptionModal';
import { currentGymSelector } from '../../adminSettings/selector';
import { IAppStore } from '../../rootReducer';
import { toggleStripeKeyModal } from '../../stripe/actions';
import IntlMessages from '../../util/IntlMessages';
import { toggleEditMemberModal } from '../actions';
import {
  getMemberAddressDetailsSelector,
  getMemberPersonalDetailsSelector,
  getMemberSubscriptionDetailsSelector,
} from '../selector';
import EditMemberDetailsModal from './editMemberDetails';
import MemberDetailsSingleField, { ISingleField } from './MemberDetailsSingleField';
import StrengthRatioForMuscleGroupsDetails from './StrengthRatioForMuscleGroupsDetails';
import SpecialOfferModal from './subscriptionOffers/SpecialOfferModal';
import {
  createMembershipOfferPressed as createMembershipOfferPressedAction,
  editMembershipOfferPressed as editMembershipOfferPressedAction,
} from './subscriptionOffers/subscription-offers-actions';
import { subscriptionOfferComponentSelector } from './subscriptionOffers/subscription-offers-selectors';
import SubscriptionOfferMemberDetailsSection, {
  SubscriptionOfferComponent,
} from './subscriptionOffers/SubscriptionOfferMemberDetailsSection';

interface IProps {
  UID: string;
  stripeAccountId: string;
  canViewSubscriptionDetails: boolean;
  memberDetail: UserProfile;
  memberAccountData: UserAccountDetail;
  currentGym: Gym;
  accountActionsIsFetching: boolean;
  accountActionsError: string | null;
  accountActionsSuccessMessage: string | null;
  showStartSubscriptionModal: boolean;
  memberAddressDetails: { label: string, value: string }[];
  memberPersonalDetails: { leftList: { label: string, value: string }[],
    rightList: { label: string, value: string | undefined }[] };
  memberSubscriptionDetails: { label: string, value: string }[];
  memberAccountShedule: UserAccountScheduleActionInfo | undefined;
  subscriptionOfferDisplayed: SubscriptionOfferComponent;
  toggleStripeKeyModal(): void;
  toggleEditMemberModal(): void;
  toggleStartSubscriptionModal(): void;
  toggleCancelSubscriptionModal(): void;
  createMembershipOfferPressed(): void;
  editMembershipOfferPressed(): void;
}

class MemberDetailsOverview extends Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
  }

  public emailSmsHistory = () => {
    const userEmail = encodeURIComponent(this.props.memberDetail.email);
    const userMobileNumber = this.props.memberDetail.mobileNumber;
    const gymEmail = this.props.currentGym.email;
    window.open(
      `https://mail.google.com/mail/u/${gymEmail}/?tab=rm&ogbl#search/${userMobileNumber}+OR+${userEmail}`,
      'mygmailtab',
    );
  };

  public render() {
    const {
      canViewSubscriptionDetails,
      memberAccountData,
      memberDetail,
      memberSubscriptionDetails,
      memberPersonalDetails,
      memberAddressDetails,
      subscriptionOfferDisplayed,
      createMembershipOfferPressed,
      editMembershipOfferPressed,
    } = this.props;
    const userAccountInfo = memberAccountData && memberAccountData.userInfo;
    const hasStrengthProfile = memberDetail && userAccountInfo.profile.strengthRatioForMuscleGroups;
    return (
      <Container className='member-details-overview-container'>
        <Container className='subscription-details-container'>
          <Row className='member-details-overview-sub-heading-container'>
            <h2 className='member-details-section-heading-text'>
              Subscription Details
            </h2>
            <div className='account-actions-container'>
              {subscriptionOfferDisplayed === 'SpecialOfferCard'
                && (
                <button
                  className='account-action'
                  onClick={editMembershipOfferPressed}
                >
                  <IntlMessages id='member.editSpecialOffer' />
                </button>
                )}
              {subscriptionOfferDisplayed === 'StandardMembershipsCard'
                && (
                <button
                  className='account-action'
                  onClick={createMembershipOfferPressed}
                >
                  <IntlMessages id='member.createSpecialOffer' />
                </button>
                )}

              {!this.props.accountActionsIsFetching
                && this.props.memberAccountShedule
                && (
                <>
                  {this.props.memberAccountShedule.startSubscriptionInfo
                    && this.props.memberAccountShedule.startSubscriptionInfo
                      .enabled && (
                      <button
                        className='account-action'
                        onClick={this.props.toggleStartSubscriptionModal}
                      >
                        Start Subscription
                      </button>
                  )}
                  {this.props.memberAccountShedule.cancelSubscriptionInfo
                    && this.props.memberAccountShedule.cancelSubscriptionInfo
                      .enabled
                    && !userAccountInfo.cancelAt && (
                      <button
                        className='account-action'
                        onClick={this.props.toggleCancelSubscriptionModal}
                      >
                        Cancel Subscription
                      </button>
                  )}
                </>
                )}
              {!canViewSubscriptionDetails && (
              <button
                className='jr-btn-default'
                style={{ fontSize: '18px' }}
                onClick={() => window.open(`https://dashboard.stripe.com/customers/${memberDetail.stripeUserId}`)}
              >
                Stripe
                <img
                  className='newTabIcon'
                  src='resources/images/open-in-new-tab-icon-white.svg'
                  style={{
                    width: '25px', height: '20px', marginLeft: '5px', marginBottom: '2px',
                  }}
                />
              </button>
              )}
            </div>
          </Row>
          <Row className='member-details-overview-content-container'>
            <Col className='member-plan-details'>
              {memberSubscriptionDetails.map((singleData: ISingleField, index) => (
                <MemberDetailsSingleField
                  field={{
                    label: singleData.label,
                    value: singleData.value ? singleData.value : '-',
                  }}
                  key={index}
                />
              ))}
            </Col>
            <Col>
              <SubscriptionOfferMemberDetailsSection />
            </Col>
          </Row>
        </Container>
        <Container className='personal-details-container'>
          <Row className='member-details-overview-sub-heading-container'>
            <h2 className='member-details-section-heading-text'>
              Personal Details
            </h2>
            <div>
              <button
                onClick={this.emailSmsHistory}
                className='edit-member-details-icon-container'
              >
                <p className='edit-member-details-icon-text'>
                  Email and SMS history
                </p>
              </button>
              <button
                onClick={this.props.toggleEditMemberModal}
                className='edit-member-details-icon-container'
              >
                <p className='edit-member-details-icon-text'>
                  Edit Personal Details
                </p>
              </button>
            </div>
          </Row>
          <Row className='member-details-overview-content-container'>
            <Col>
              {memberPersonalDetails.leftList.map((singleData: ISingleField, index) => (
                <MemberDetailsSingleField
                  field={{
                    label: singleData.label,
                    value: singleData.value ? singleData.value : '-',
                  }}
                  key={index}
                />
              ))}
            </Col>
            <Col className='member-plan-details'>
              {memberPersonalDetails.rightList.map((singleData: ISingleField, index) => (
                <MemberDetailsSingleField
                  field={{
                    label: singleData.label,
                    value: singleData.value ? singleData.value : '-',
                    showQrCode: singleData.showQrCode,
                  }}
                  key={index}
                />
              ))}
            </Col>
          </Row>
          <Row className='member-details-overview-content-container'>
            {hasStrengthProfile && (
            <Col>
              <StrengthRatioForMuscleGroupsDetails />
            </Col>
            )}
            <Col />
          </Row>
          <Row className='member-details-overview-content-container'>
            <Col>
              <div className='plan-end-date-headings-container'>
                <h3 className='member-details-section-sub-header'>Address</h3>
              </div>
              {memberAddressDetails.map((singleData: ISingleField, index) => (
                <MemberDetailsSingleField
                  field={{
                    label: singleData.label,
                    value: singleData.value ? singleData.value : '-',
                  }}
                  key={index}
                />
              ))}
            </Col>
            <Col />
          </Row>
        </Container>
        <EditMemberDetailsModal
          memberDetail={memberDetail}
        />
        <CancelSubscrtionModal uid={this.props.UID} />
        {this.props.showStartSubscriptionModal
          && <StartSubscriptionModal uid={this.props.UID} />}
        <CancelPauseModal />
        <SpecialOfferModal />
        <Row />
      </Container>
    );
  }
}

const mapStateToProps = (state: IAppStore, props: any) => {
  const { accountActions, members } = state;
  const { canViewSubscriptionDetails } = state.adminSettings;
  const { memberAccountData } = members.data;
  const memberDetail = memberAccountData && memberAccountData.userInfo.profile;
  const {
    isFetching, error, successMessage, showStartSubscriptionModal,
  } = accountActions.view;
  const {
    memberAccountShedule,
  } = accountActions.data;
  const currentGym = currentGymSelector(state);
  const memberAddressDetails: { label: string, value: string }[] = getMemberAddressDetailsSelector(state);
  const memberPersonalDetails: { leftList: { label: string, value: string | undefined }[],
    rightList: ISingleField[] } = getMemberPersonalDetailsSelector(state);
  const memberSubscriptionDetails: {
    label: string,
    value: string | undefined,
  }[] = getMemberSubscriptionDetailsSelector(state, true);

  return {
    canViewSubscriptionDetails,
    memberDetail,
    memberAccountData,
    memberAccountShedule,
    currentGym,
    accountActionsIsFetching: isFetching,
    accountActionsError: error,
    accountActionsSuccessMessage: successMessage,
    showStartSubscriptionModal,
    memberAddressDetails,
    memberPersonalDetails,
    memberSubscriptionDetails,
    subscriptionOfferDisplayed: subscriptionOfferComponentSelector(state),
  };
};

const mapDispatchToProps = {
  toggleStripeKeyModal,
  toggleEditMemberModal,
  toggleStartSubscriptionModal,
  toggleCancelSubscriptionModal,
  createMembershipOfferPressed: createMembershipOfferPressedAction,
  editMembershipOfferPressed: editMembershipOfferPressedAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberDetailsOverview);
