import React from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';

interface IProps {
  showConfirmationModal: boolean;
  titles: {titleAction: string, questionAction: string};
  onClose: () => void;
  onCancel: () => void;
  onYesClick: () => void;
  isFetching: boolean;
  primaryActionText?: string;
  secondaryActionText?: string;
  error?: string | null;
}

class ConfirmationModal extends React.Component<IProps, any> {
  public render() {
    return (
      <div>
        <Modal
          isOpen={this.props.showConfirmationModal}
          className='modal-content'
          overlayClassName='modal-overlay'
          ariaHideApp={false}
          contentLabel='Columns'>
          <React.Fragment>
            <div className='column-label content-margin'>
              <h2
                className='column-header'>
                {`${this.props.titles.titleAction}`}
              </h2>
              <button
                className='modal-close-button'
                onClick={this.props.onClose}>
                X
              </button>
            </div>
            <div style={{ textAlign: 'center' }}>
              <label>{`Are you sure you want to ${this.props.titles.questionAction}?`}
              </label>
            </div>
            {this.props.isFetching ?
              <div style={{ textAlign: 'center' }}>
                <Loader
                  type='ThreeDots'
                  color='#FF621D'
                  height={50}
                  width={50}
                  timeout={3000}
                />
              </div> :
              <div>
                {this.props.error && (
                    <div style={{ textAlign: 'right' }}>
                      <p className='error'>{this.props.error}</p>
                    </div>
                  )}
                <div className='confirmation-modal-actions' style={{ textAlign: 'center' }}>
                  <button className='jr-btn-default primary-button'
                          onClick={this.props.onCancel}>{this.props.primaryActionText || 'No'}</button>
                  <button className='jr-btn-default secondary-button'
                          onClick={this.props.onYesClick}>{this.props.secondaryActionText || 'Yes'}</button>
                </div>
              </div>}
          </React.Fragment>
        </Modal>
      </div>
    );
  }
}

export default ConfirmationModal;
