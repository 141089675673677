import {
  IPrice,
  ISpecialOfferDisplay,
  IStripeCouponWrapper,
} from 'fitbeat.models';
import { createSelector } from 'reselect';
import { IAppStore } from '../../../rootReducer';
import {
  mapCouponToSelectOption,
  mapPriceToSelectOption,
} from '../../../shared/helpers/formatHelper';
import { SubscriptionOfferComponent } from './SubscriptionOfferMemberDetailsSection';

const getActiveSpecialOffer = (state: IAppStore): ISpecialOfferDisplay | undefined => {
  return state.members.data.memberAccountData?.userInfo.activeSpecialOffer;
};

const getSpecialOfferEnabled = (state: IAppStore): boolean => {
  return !!state.members.data.memberAccountData?.userInfo.specialOfferEnabled;
};

export const subscriptionOfferComponentSelector = createSelector(
  [
    getActiveSpecialOffer,
    getSpecialOfferEnabled,
  ],
  (activeSpecialOffer, specialOfferEnabled): SubscriptionOfferComponent => {
    if (specialOfferEnabled && activeSpecialOffer) {
      return 'SpecialOfferCard';
    }
    if (specialOfferEnabled) {
      return 'StandardMembershipsCard';
    }
    return 'None';
  },
);

export interface IPriceSelectOption {
  label: string;
  value: IPrice;
}

export interface ICouponSelectOption {
  label: string;
  value: IStripeCouponWrapper;
}

export const planOptionsSelector = createSelector(
  [
    (state: IAppStore) => state.members.data.memberAccountData?.userInfo.validPlansForSubscription,
  ],
  (validPlansForSubscription): IPriceSelectOption[] | undefined => {
    return validPlansForSubscription?.map((plan: IPrice) => mapPriceToSelectOption(plan));
  },
);

export const emptyCouponOption: ICouponSelectOption = mapCouponToSelectOption({
  id: '',
  name: 'No coupon',
  percent_off: 0,
  amount_off: 0,
  duration: 'forever',
  valid: true,
  currency: '',
  metadata: {},
});

export const couponOptionsSelector = createSelector(
  [
    (state: IAppStore) => state.members.data.offerCoupons,
  ],
  (coupons): ICouponSelectOption[] => {
    if (coupons) {
      return [emptyCouponOption, ...coupons.map((coupon) => mapCouponToSelectOption(coupon))];
    }
    return [emptyCouponOption];
  },
);
