import { Gym } from 'fitbeat.models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getLeaderboardConfigs,
} from '../../leaderboards/leaderboardsSlice'
import { getAllData, getSavedReports } from '../../members/actions';
import { IAppStore } from '../../rootReducer';
import { currentGymSelector } from '../selector';

interface IProps {
  currentGym: Gym;
  excludedEmails: string;
  getAllData(excludedEmails: string, gymName: string): void;
  getSavedReports(): void;
  getLeaderboardConfigs(gymName: string): void;
}

class RefreshData extends Component<IProps> {
  public handleRefresh = () => {
    this.props.getSavedReports();
    this.props.getLeaderboardConfigs(this.props.currentGym.name);
    this.props.getAllData(
      this.props.excludedEmails,
      this.props.currentGym.name,
    );
  }

  public render() {
    return (
      <div className='refreshDataContainer'>
        <button className='jr-btn-no-border row-button' onClick={this.handleRefresh}>
          <img
            className='btnImg refreshIconContainer'
            src='resources/images/refresh-right-direction-icon.svg'
          />
          <p className='studio-website-text'>Refresh Data</p>
        </button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllData: (excludedEmails: string, gymName: string) => dispatch(getAllData(excludedEmails, gymName)),
    getSavedReports: () => dispatch(getSavedReports()),
    getLeaderboardConfigs: (gymName: string) => dispatch(getLeaderboardConfigs(gymName)),
  }
};

const mapStateToProps = (state: IAppStore) => {
  const { gyms } = state.adminSettings.data;
  const currentGym = currentGymSelector(state);
  return { currentGym, gyms };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefreshData);
