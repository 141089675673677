import React, { Component } from 'react';

interface IProps {
  tabTitle: string;
  isActiveTab: boolean;
  handleTabSelected: (tabTitle: string) => void;
}

export default class TabHeader extends Component<IProps> {
  public tabSelectionHandler = () => {
    this.props.handleTabSelected(this.props.tabTitle);
  }

  public render() {
    const { tabTitle, isActiveTab } = this.props;
    const isActive = isActiveTab ? 'active' : '';
    const className = `nav-item ${isActive} single-tab-header-container`;

    return (
      <div
        className={className}
        onClick={() => this.tabSelectionHandler()}
      >
        <p className='single-tab-header-text'>{tabTitle}</p>
      </div>
    );
  }
}
