import moment from 'moment';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';

class DatePickerFilter extends React.Component<any, any> {
    public to: any;
    constructor(props: any) {
        super(props);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.state = {
            from: this.props.startDate ? new Date(this.props.startDate)
                : undefined,
            to: this.props.endDate ? new Date(this.props.endDate)
                : undefined,
        };
    }

    public showFromMonth() {
        const { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
        }
    }
    public handleFromChange(from: any, modifiers: any, instance: any) {
        const fromDate = Date.parse(from);
        const rawFromDate = instance.getInput().value;
        const varName = this.props.type === 'dateOfBirth' ? 'invalidFromDOBDate' : 'invalidFromSignupDate';
        if (!rawFromDate || !isNaN(fromDate)) {
            this.setState({ from, [varName]: false });
            this.props.onChange({ customFilter: true, startDate: from, endDate: this.state.to });
        } else if (isNaN(rawFromDate)) {
            this.setState({ [varName]: true });
        }
    }

    public handleToChange(to: any, modifiers: any, instance: any) {
        const toDate = Date.parse(to);
        const rawToDate = instance.getInput().value;
        const varName = this.props.type === 'dateOfBirth' ? 'invalidToDOBDate' : 'invalidToSignupDate';
        if (!rawToDate || !isNaN(toDate)) {
            this.setState({ to, [varName]: false }, this.showFromMonth);
            this.props.onChange({ customFilter: true, startDate: this.state.from, endDate: to });
        } else if (isNaN(rawToDate)) {
            this.setState({ [varName]: true });
        }
    }
    public formatDate = (date: Date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        return formattedDate;
    }

    public componentDidUpdate(prevProps: any, prevState: any) {
        const newStartDate = this.props.startDate ? new Date(this.props.startDate)
            : undefined;
        const newEndDate = this.props.endDate ? new Date(this.props.endDate)
            : undefined;
        const isDifferentStartDate = this.formatDate(prevProps.startDate) !== this.formatDate(this.props.startDate);
        const isDifferentEndDate =  this.formatDate(prevProps.endDate) !== this.formatDate(this.props.endDate);
        if (isDifferentStartDate || isDifferentEndDate) {
            this.setState({
                from: newStartDate,
                to: newEndDate,
            });
        }
    }

    public render() {
        const showLeft = this.props.showLeft;
        const { from, to } = this.state;
        const maxDate = from && this.props.maxRangeDays ?
          moment(from).add(this.props.maxRangeDays, 'days').toDate() : undefined;
        const modifiers = { start: from, end: to };
        const hasCustomNavBar: any = {};
        if (this.props.navbarElement) {
            hasCustomNavBar.navbarElement = this.props.navbarElement;
        }
        return (
            <div className='InputFromTo'>
                <span className={`InputFromTo-from-${this.props.type}`}>
                    <DayPickerInput
                        classNames={{overlayWrapper: `DayPickerInput-OverlayWrapper`,
                          overlay: `DayPickerInput-Overlay ${showLeft ? 'left' : 'right'}`,
                          container: 'DayPickerInput'}}
                        value={from}
                        placeholder='YYYY-MM-DD'
                        format='LL'
                        formatDate={this.formatDate}
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { after: to},
                            toMonth: to,
                            modifiers,
                            numberOfMonths: 2,
                            ...hasCustomNavBar,
                            onDayClick: () => this.to.getInput().focus(),
                        }}
                        onDayChange={this.handleFromChange}
                    /></span>{' '}
        —{' '}
                <span className={`InputFromTo-to-${this.props.type}`}>
                    <DayPickerInput
                        classNames={{overlayWrapper: `DayPickerInput-OverlayWrapper`,
                        overlay: `DayPickerInput-Overlay ${showLeft ? 'left' : 'right'}`,
                        container: 'DayPickerInput'}}
                        ref={(el: any) => (this.to = el)}
                        value={to}
                        placeholder='YYYY-MM-DD'
                        format='LL'
                        formatDate={this.formatDate}
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { before: from, after: maxDate },
                            modifiers,
                            month: from,
                            fromMonth: from,
                            numberOfMonths: 2,
                            ...hasCustomNavBar,
                        }}
                        onDayChange={this.handleToChange}
                    />
                </span>
                <style>{`
.DayPicker-wrapper {
    display: flex;
    flex-direction: row;
}
.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: rgb(255,98,29) !important;
    color: #white !important;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .DayPicker-NavBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 130px;
  }
  .DayPickerInput-Overlay {
    width: auto;
    min-width: 550px;
  }
  .DayPicker-Months {
    min-width: 550px;
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -198px;
  }
  .InputFromTo .DayPickerInput input {
      width: 95px;
  }
  .InputFromTo-to-DOB .DayPickerInput input {
    width: 95px;
    border: ${this.state.invalidToDOBDate ? `1px solid red !important` : 'none'}
}
.DayPickerInput-Overlay.left  {
    right: 0;
    left: unset;
}
.InputFromTo-from-DOB .DayPickerInput input {
    width: 95px;
    border: ${this.state.invalidFromDOBDate ? `1px solid red !important` : 'none'}
}
.InputFromTo-to-signupDate .DayPickerInput input {
    width: 95px;
    border: ${this.state.invalidToSignupDate ? `1px solid red !important` : 'none'}
}
.InputFromTo-from-signupDate .DayPickerInput input {
    width: 95px;
    border: ${this.state.invalidFromSignupDate ? `1px solid red !important` : 'none'}
}
.InputFromTo-to-aggregateDate .DayPickerInput input {
    width: 95px;
    font-weight: 700 !important;
}
.InputFromTo-from-aggregateDate .DayPickerInput input {
    width: 95px;
    font-weight: 700 !important;
}
.DayPickerInput input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
    color: #474747;
    font-size: 14px !important;
    letter-spacing: 0;
    font-weight: 300 !important;
    line-height: 17px;
}
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: #FF621D;
    color: black;
  }
  .DayPicker-Day--today {
      color: #FF621D;
  }
`}</style>
            </div>
        );
    }
}
const mapDispatchToProps = {
};

const mapStateToProps = (state: IAppStore) => {
    const { members } = state;
    const { filtered } = members.view;
    const dateOfBirthDates = filtered.find((filter) => filter.id === 'dateOfBirth');
    return {
        filtered,
        dateOfBirthFilterDates: dateOfBirthDates ? dateOfBirthDates.value : {},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerFilter);
