import React, { Component } from 'react';
interface IProps {
    fullName: string;
    status: string;
    hideMembershipStatus?: boolean;
}
export default class MemberNameWithStatusColor extends Component<IProps> {
    public render() {
        const { fullName, status, hideMembershipStatus } = this.props;
        const memberMappedStatus = this.mapStatusToTextAndColor(status);
        const classNameContainer = `member-membership-status-container ${memberMappedStatus.classNameContainer}`;
        const classNameText = `member-membership-status-text ${memberMappedStatus.classNameText}`;
        return (
            <div className='member-details-name-and-status-top-container'>
                <h2 className='member-full-name'>{fullName}</h2>
                {!hideMembershipStatus &&
                    <div className={classNameContainer}>
                        <p className={classNameText}>{memberMappedStatus.statusText}</p>
                    </div>
                }
            </div>
    );
    }

    private mapStatusToTextAndColor = (status: string) => {
        switch (status) {
            case 'active':
                return {
                    statusText: 'ACTIVE MEMBERSHIP',
                    classNameContainer: 'active-container',
                    classNameText: 'active-status-text'};
            case 'canceled':
                return {
                    statusText: 'INACTIVE MEMBERSHIP',
                    classNameContainer: 'inactive-grey-container',
                    classNameText: 'inactive-grey-text'};
            case 'unpaid':
                return {
                    statusText: 'UNPAID',
                    classNameContainer: 'inactive-red-container',
                    classNameText: 'inactive-red-text'};
            case 'trialing':
                return {
                    statusText: 'TRIALING',
                    classNameContainer: 'active-container',
                    classNameText: 'active-status-text'};
            case 'noTimeSlots':
                return {
                    statusText: 'NO TIMESLOTS',
                    classNameContainer: 'inactive-orange-container',
                    classNameText: 'inactive-orange-text'};
            case 'past_due':
                return {
                    statusText: 'PAYMENT PAST DUE',
                    classNameContainer: 'inactive-red-container',
                    classNameText: 'inactive-red-text'};
            default:
                return {
                    statusText: 'INACTIVE MEMBERSHIP',
                    classNameContainer: 'inactive-grey-container',
                    classNameText: 'inactive-grey-text'};
        }
    }
}
