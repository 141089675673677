import { IClientSideLogger } from 'fitbeat.gym-core';
import { config } from '../../config';
import { createBugsnagLogger, initializeBugsnag, setActiveUser as setActiveUserOnBugsnag } from './bugsnag';
import { DebugLogger } from './symlinked-debugLogger';
import { MultiLogger } from './symlinked-multiLogger';

export { createReactErrorBoundry } from './bugsnag';

const { nameForLogging } = config.environmentDetails;

export function initializeLogging() {
  if (config.bugsnag.apiKey) {
    initializeBugsnag(config.bugsnag.apiKey);
  }
}

export function setUserDetails(userId: string | null) {
  if (config.bugsnag.apiKey) {
    setActiveUserOnBugsnag(userId);
  }
}

export function clearUserDetails() {
  if (config.bugsnag.apiKey) {
    setActiveUserOnBugsnag(null);
  }
}

export default function getLogger(name: string): IClientSideLogger {
  if (nameForLogging === 'tests') {
    return new MultiLogger([]);
  }

  const debug = new DebugLogger(name);
  // todo: maybe use a less verbose logger on staging and production
  const loggers: IClientSideLogger[] = [debug];
  if (config.bugsnag.apiKey) {
    debug.trace('creating Bugsnag logger');
    loggers.push(createBugsnagLogger());
  } else if (nameForLogging === 'staging' || nameForLogging === 'production') {
    debug.warn('Bugsnag apiKey is not set, cannot use Bugsnag!');
  }
  return new MultiLogger(loggers);
}
