import { ILeaderboardConfig } from 'fitbeat.models';
import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { SortableRow } from './ArrangeConfigOrderRow';

const Columns: React.FC<{ leaderboardConfigs: ILeaderboardConfig[] }> = ({
  leaderboardConfigs,
}) => (
  <div className='modal-content-columns-container leaderboard-drag-tiles-container'>
    {leaderboardConfigs.length === 0
      ? (
        <div />
      )
      : (
        leaderboardConfigs.map((singleLeaderboardConfig: ILeaderboardConfig, index: any) => (
          <SortableRow
            key={`item-${singleLeaderboardConfig.name}`}
            index={index}
            indexNumber={index}
            singleLeaderboardConfig={singleLeaderboardConfig}
          />
        ))
      )}
  </div>
);

export const SelectableLeaderboardConfigs = SortableContainer(Columns);
