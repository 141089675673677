import React from 'react';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';
import { memberAvatarUrlSelector } from '../selector';

class MemberAvatar extends React.Component<any, any> {
    public render() {
        return (
            <div>
                <div className='avatar' style={{ paddingLeft: 8 }}>
                    <img alt='Avatar' src={this.props.avatarUrl}
                        onError={this.addDefaultSrc}
                        style={{ width: 50, border: '1px solid grey', borderRadius: 4 }} />
                </div>
            </div>);
    }

    private addDefaultSrc(ev: any) {
        ev.target.src = `resources/images/user.svg`;
    }
}

const mapStateToProps = (state: IAppStore, ownProps: any) => {
    const { members } = state;
    const { isFetching } = members.view;
    return {
        isLoading: isFetching,
        avatarUrl: memberAvatarUrlSelector(state, ownProps.row.uid),
    };
};

export default connect(mapStateToProps)(MemberAvatar);
