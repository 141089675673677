import { getEnvironmentDetailsFromName } from 'fitbeat.models';

const environmentDetails = getEnvironmentDetailsFromName(process.env.ENVIRONMENT_NAME, process.env.NODE_ENV);

export const config = {
  environmentDetails,
  bugsnag: {
    apiKey: process.env.BUSINESS_TOOLS_BUGSNAG_KEY,
  },
  appVersion: process.env.APP_VERSION,
  stripe: {
    publishableKey: process.env.STRIPE_PUBLISHABLE_KEY
      || 'pk_test_51HH8LdAcaWctOtVHK1kvAEbqgkqBXHFjSC47FZAgDkoZWzlrlihSTYWOuCXhVF7QzadWGHVs9AN0UCKd670huTfH00bTW3Ugby',
  },
  foundationMembershipPlanId: 'price_1MYqSrLd8sv8uhTtlzlxpQ41',
  links: {
    eliteEcommercePage: process.env.ELITE_ECOMMERCE_PAGE || 'https://staging.fitillion.com/product/elite_starter/',
    webAppUrl: process.env.WEB_APP_URL || 'http://localhost:8000',
    terminalPower: 'https://stripe.com/docs/terminal/payments/setup-reader/bbpos-wisepos-e#power',
    terminalConnection:
        'https://stripe.com/docs/terminal/payments/setup-reader/bbpos-wisepos-e#connecting-to-the-internet',
    terminalTroubleShoot: 'https://stripe.com/docs/terminal/readers/bbpos-wisepos-e#troubleshooting',
    terminalSetup: 'https://stripe.com/docs/terminal/payments/setup-reader/bbpos-wisepos-e',
  },
};
