import React from 'react';
import { withRouter } from 'react-router-dom';
import Menu from 'shared/components/Header/Menu';
import GymDropDown from '../../../adminSettings/components/GymDropDown';
import OpenStudioWebsite from '../../../adminSettings/components/OpenStudioWebsite';
import LogoutButton from '../../../adminSettings/components/Logout';
import RefreshData from '../../../adminSettings/components/RefreshData';

class TopNav extends React.Component {
  render() {
    return (
      <div className='top-nav-container'>
        <div className='logo-gym-selection-container'>
          <img
            className='fitillion-biz-nav-logo'
            src='assets/images/Fitillion-logo-biztools.png'
          />
          <div className='nav-logo-dropdown-divider' />
          <GymDropDown />
        </div>
        <Menu />
        <div className='open-website-container'>
          <RefreshData />
          <OpenStudioWebsite />
          <LogoutButton />
        </div>
      </div>
    );
  }
}

export default withRouter(TopNav);

TopNav.defaultProps = {
  styleName: '',
};
