import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { SortableColumn } from './Column';

const Columns: React.FC<{columns: any, setColumnAsUsed: any, header: string, emptyMessage?: string}> = ({
  columns, setColumnAsUsed, header, emptyMessage,
  }) => {
  const validColumns = columns.filter((singleColumn: any) => singleColumn.accessor ? true : false);
  return (
    <div className='modal-content-columns-container'>
      <h3 className='section-header'>{header}</h3>
      {validColumns.length === 0 && emptyMessage
        ? (
          <p>{emptyMessage}</p>
        ) : (
          validColumns.map((column: any, index: any) => (<SortableColumn
              key={column.accessor}
              disabled={!column.used}
              setColumnAsUsed={setColumnAsUsed}
              column={column}
              index={index}
              />
          ))
      )}
    </div>
  );
};

export const SelectableColumns = SortableContainer(Columns);
