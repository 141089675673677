import { Gym } from 'fitbeat.models';
import { createSelector } from 'reselect';
import { IAppStore } from '../rootReducer';

export const currentGymSelector = createSelector(
  [
    (state: IAppStore) => state.adminSettings.data.selectedGymName,
    (state: IAppStore) => state.adminSettings.data.gyms,
  ],
  (selectedGymName: string, gyms: Gym[]): Gym => {
    const gym = gyms.find((g: Gym) => g.name === selectedGymName);
    if (!gym) {
      throw new Error(`Gym ${selectedGymName} not found`);
    }
    return gym;
  },
);
