import React, { FunctionComponent } from 'react';

type RightContainerValue = |
  {
    value: string;
    valueSymbol: string;
    date?: string
  } |
  {
    value?: string;
    valueSymbol?: string;
    date: string
  };

export interface ILineItem {
  heading: string;
  description?: string;
  rightContainerValue: RightContainerValue;
  borderBottom?: boolean;
}

export const SpecialOfferLineItem: FunctionComponent<ILineItem> = (
  {
    heading,
    description,
    rightContainerValue,
    borderBottom = true,
  },
) => {
  return (
    <div className='px-2'>
      <div className={`row no-gutters align-items-center p-2 border-box ${borderBottom && 'border-bottom'}`}>
        <div className='col-8'>
          <div className='body-copy-small bold'>
            {heading}
          </div>
          {description &&
            <div className='body-copy-small'>
              {description}
            </div>
          }
        </div>
        <div className='col-4 text-right body-copy-small'>
          {rightContainerValue.date &&
            rightContainerValue.date
          }
          {!rightContainerValue.date &&
            `${rightContainerValue.valueSymbol} ${rightContainerValue.value}`
          }
        </div>
      </div>
    </div>
  );
};
