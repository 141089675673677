import { OFFER_EXPIRES_DAYS_AFTER_BILLING_START } from 'fitbeat.gym-core';
import { AccountStatus } from 'fitbeat.models';
import moment from 'moment-timezone';

const MAX_FREE_DAYS = 31;

export const freeDayOptions = Array.from(Array(MAX_FREE_DAYS).keys())
  .map((num) => ({ label: num === 1 ? '1 day' : `${num} days`, value: num }));

export function calculateFreeDays(trialDays: number, billingStartDate: Date | null): number {
  if (!billingStartDate) {
    return 0;
  }
  const daysUntilBillingStart = moment(billingStartDate).diff(moment().startOf('day'), 'days');
  const freeDays = daysUntilBillingStart - trialDays;

  return Math.max(0, freeDays);
}

export function addDaysToStartOfToday(days: number): Date {
  return moment().startOf('day').add(days, 'days').toDate();
}

export function calculateExpirationDate(date: Date): Date {
  return moment(date).add(OFFER_EXPIRES_DAYS_AFTER_BILLING_START, 'days').toDate();
}

export function getDaysUntil(date?: number): number {
  if (!date) {
    return 0;
  }
  const startOfToday = moment().startOf('day');
  const startOfDate = moment(date).startOf('day');
  return startOfDate.diff(startOfToday, 'days');
}

export function getRemainingDaysOnCurrentSubscription(
  memberAccountStatus?: AccountStatus,
  currentSubscriptionCancelsOn?: number,
  currentSubscriptionPeriodEnd?: number,
): number {
  return memberAccountStatus === 'InStudioTrial' ?
    getDaysUntil(currentSubscriptionCancelsOn) : getDaysUntil(currentSubscriptionPeriodEnd);
}

export function initialValueForBillingStartDate(remainingDaysOnSubscription: number): Date {
  return moment().startOf('day').add(remainingDaysOnSubscription, 'days').toDate();
}

export function dateString(date: Date): string {
  return moment(date).format();
}
