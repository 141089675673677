import { ILeaderboardConfig, ILeaderboardDataViewModel, ILeaderboardInstance } from 'fitbeat.models';

export interface ILeaderboardsData {
  leaderboardConfigs: ILeaderboardConfig [];
  leaderboardInstances: ILeaderboardInstance[];
  selectedLeaderboardConfig: ILeaderboardConfig | null;
  selectedLeaderboardInstance: ILeaderboardInstance | null;
  selectedLeaderboardInstanceDetails: ILeaderboardDataViewModel | null;
  selectedLeaderboardTeam: string | null;
}
export interface ILeaderboardsState {
  data: ILeaderboardsData;
  view: IAdminSettingsUIView;
}

export interface IAdminSettingsUIView {
  errorMessage: null | string;
  isFetching: boolean;
  isFetchingInstanceDetails: boolean;
  showSortLeaderboardRankingModal: boolean;
  showCreateLeaderboardConfigModal: boolean;
  showUpdateLeaderboardConfigModal: boolean;
}

export const initialState: ILeaderboardsState = {
  data: {
    leaderboardConfigs: [],
    leaderboardInstances: [],
    selectedLeaderboardTeam: null,
    selectedLeaderboardConfig: null,
    selectedLeaderboardInstance: null,
    selectedLeaderboardInstanceDetails: null,
  },
  view: {
    errorMessage: null,
    isFetching: false,
    isFetchingInstanceDetails: false,
    showSortLeaderboardRankingModal: false,
    showCreateLeaderboardConfigModal: false,
    showUpdateLeaderboardConfigModal: false,
  },
};
