import { IMemberColumnData, MembersReportData } from 'fitbeat.models';
import React from 'react';
import { connect } from 'react-redux';
import { Filter, SortingRule } from 'react-table';
import { IAppStore } from '../../rootReducer';
import Dropdown from '../../shared/components/Dropdown';
import {
  toggleConfirmationModal,
  toggleIsReportClone,
  toggleReportModal,
  updateReport,
} from '../actions';
import { defaultReportId } from '../config';

interface IProps {
  reportId: string;
  activeReport: MembersReportData;
  isNewReport: boolean;
  reportName: string;
  filtered: Filter[];
  sortOrder: SortingRule[];
  memberTableColumns: IMemberColumnData[];
  toggleIsReportClone(): void;
  toggleReportModal(): void;
  updateReport(): void;
  toggleConfirmationModal(show: boolean): void;
}

class ReportActionsDropdown extends React.Component<IProps> {

  public createActionList = () => {
    const disableSaveAndDelete = this.props.reportId === defaultReportId;

    const save = {
      optionMethod: () => this.props.updateReport(),
      optionIcon: <img
        className='btnImg report-action-icon'
        src='resources/images/save-changes-icon.svg'
        style={{width: '19px', height: '19px'}}
      />,
      optionText: 'Save',
    };
    const saveAs = {
      optionMethod: () => this.props.toggleIsReportClone(),
      optionIcon: <img
        className='btnImg report-action-icon'
        src='resources/images/save-changes-icon.svg'
        style={{width: '19px', height: '19px'}}
      />,
      optionText: 'Save as Report',
      optionContainerCustomStyleClass: disableSaveAndDelete ? undefined : 'dropdown-separator',
    };
    const deleteReport = {
      optionMethod: () => this.props.toggleConfirmationModal(true),
      optionIcon: <img
        className='btnImg report-action-icon'
        src={'resources/images/delete-report-icon.svg'}
        style={{width: '21px', height: '19px'}}
      />,
      optionText: 'Delete',
    };

    if (disableSaveAndDelete) {
      return [saveAs];
    }
    return [save, saveAs, deleteReport];
  }

  public render() {
    return (
      <Dropdown
        options={this.createActionList()}
        value={{ value: 'Report Actions', label: 'Report Actions' }}
        placeholder='Select Report'
        className='report-action-select-container'
        classNamePrefix='report-action-select'
        isSearchable={false}
        components={{ Option: this.customOption }}
      />
    );
  }

  private customOption = (optionProps: any) => {
    const HandleOptionClick = () => {
      optionProps.selectOption(optionProps.data.optionText);
      optionProps.data.optionMethod();
    };
    return (
        <div
        {...optionProps.data.innerProps}
        className={`row jr-btn-no-border action-report-dropdown-option
          ${optionProps.data.optionContainerCustomStyleClass}`}
          onClick={HandleOptionClick}>
          {optionProps.data.optionIcon}
          <div className='report-action-option-text'>{optionProps.data.optionText}</div>
        </div>
    );
  }
}

const mapDispatchToProps = {
  toggleConfirmationModal,
  toggleReportModal,
  toggleIsReportClone,
  updateReport,
};

const mapStateToProps = (state: IAppStore) => {
  const { members } = state;
  const { activeReport } = members.data;
  const { memberColumnData, reportName, reportId } = members.data.activeReport;
  const { isNewReport } = members.view;
  const userProfiles = members.data.memberDetailsData.map((member) => member.userSettings);
  const filtered = members.view.filtered;
  const sortOrder = members.view.sortOrder;

  return {
    members: userProfiles,
    memberTableColumns: memberColumnData,
    reportName,
    filtered,
    sortOrder,
    isNewReport,
    reportId,
    activeReport,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportActionsDropdown);
