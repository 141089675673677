import {
  ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { ILeaderboardConfig, ILeaderboardDataViewModel, ILeaderboardInstance } from 'fitbeat.models';
import moment from 'moment';
import { apiConfig } from '../app/apiConfig';
import { MembersService } from '../members/membersService';
import { LeaderboardsServices } from './leaderboardsService';
import { ILeaderboardsState, initialState } from './storeTypes';

export const setupInitialLeaderboard = createAsyncThunk<{
  leaderboardConfigs: ILeaderboardConfig[],
  selectedLeaderboardConfig: ILeaderboardConfig | null,
  leaderboardInstances: ILeaderboardInstance[],
  selectedLeaderboardInstance: ILeaderboardInstance | null,
  selectedLeaderboardInstanceDetails: ILeaderboardDataViewModel | null,
}, string>(
  'leaderboard/setupInitialLeaderboard',
  async (gym: string) => {
    const sortedLeaderboardList: ILeaderboardConfig[] = await fetchLeaderboardConfigs(gym);
    if (sortedLeaderboardList.length) {
      const leaderboardInstances: {
        leaderboardConfigId: string;
        selectedLeaderboardInstance: ILeaderboardInstance;
        leaderboardInstances: ILeaderboardInstance[];
      } = await fetchLeaderboardInstances(gym, sortedLeaderboardList[0]._id);
      let instanceDetails = null;
      if (leaderboardInstances.leaderboardInstances[0]) {
        instanceDetails = await fetchGymLeaderboardInstanceDetails(
          gym,
          leaderboardInstances.leaderboardInstances[0],
        );
      }
      return {
        leaderboardConfigs: sortedLeaderboardList,
        selectedLeaderboardConfig: sortedLeaderboardList[0],
        leaderboardInstances: leaderboardInstances.leaderboardInstances,
        selectedLeaderboardInstance: leaderboardInstances.selectedLeaderboardInstance,
        selectedLeaderboardInstanceDetails: instanceDetails,
      };
    }
    return {
      leaderboardConfigs: [],
      selectedLeaderboardConfig: null,
      leaderboardInstances: [],
      selectedLeaderboardInstance: null,
      selectedLeaderboardInstanceDetails: null,
    };
  },
);

export const getLeaderboardConfigs = createAsyncThunk<ILeaderboardConfig[], string>(
  'leaderboard/fetchLeaderboardConfigs',
  async (gym: string) => fetchLeaderboardConfigs(gym),
);

export const getLeaderboardInstances = createAsyncThunk<{
  leaderboardConfigId: string,
  selectedLeaderboardInstance: ILeaderboardInstance,
  leaderboardInstances: ILeaderboardInstance[],
}, { gym: string, leaderboardConfigId: string }>(
  'leaderboard/fetchLeaderboardInstance',
  async (action: {
    gym: string,
    leaderboardConfigId: string,
  }) => fetchLeaderboardInstances(action.gym, action.leaderboardConfigId),
);

export const setSelectedInstance = createAsyncThunk<ILeaderboardDataViewModel | null, {
  gym: string,
  leaderboardInstance: ILeaderboardInstance,
}>('leaderboard/fetchInstanceDetails', async (action: {
  gym: string,
  leaderboardInstance: ILeaderboardInstance,
}) => {
  if (!action.leaderboardInstance) {
    return null;
  }
  return fetchGymLeaderboardInstanceDetails(action.gym, action.leaderboardInstance);
});

export const setSelectedTeam = createAsyncThunk<
{ teamName: string | undefined, leaderboardInstanceDetails: ILeaderboardDataViewModel | null },
{
  gym: string,
  leaderboardTeam: string | null,
  selectedLeaderboardInstance: ILeaderboardInstance,
}>('leaderboard/fetchLeaderboardTeamDetails', async (action: {
  gym: string,
  leaderboardTeam: string | null,
  selectedLeaderboardInstance: ILeaderboardInstance,
}) => {
  const teamName = action.leaderboardTeam || undefined;
  if (!action.selectedLeaderboardInstance) {
    return {
      teamName,
      leaderboardInstanceDetails: null,
    };
  }
  return fetchGymLeaderboardTeamInstanceDetails(
    action.gym,
    action.selectedLeaderboardInstance,
    teamName,
  );
});

export const updateUserTeamSelected = createAsyncThunk<
ILeaderboardDataViewModel | null,
{
  gym: string,
  leaderboardInstance: ILeaderboardInstance,
  uid: string, newTeam: string,
}>('leaderboard/updateUserTeam', async (action: {
  gym: string,
  leaderboardInstance: ILeaderboardInstance,
  uid: string, newTeam: string,
}) => {
  if (!action.leaderboardInstance.leaderboardId || !action.newTeam) {
    return null;
  }
  await userTeamSelected(
    action.gym,
    action.uid,
    action.leaderboardInstance.leaderboardId,
    action.newTeam,
  );
  return fetchGymLeaderboardInstanceDetails(action.gym, action.leaderboardInstance);
});

export const updateLeaderboardRankings = createAsyncThunk<ILeaderboardConfig[], {
  gym: string,
  leaderboardConfigs: ILeaderboardConfig[],
}>(
  'leaderboard/updateLeaderboardConfigsRanking',
  async (action: {
    gym: string,
    leaderboardConfigs: ILeaderboardConfig[],
  }) => {
    const updatedOrderConfigs = action.leaderboardConfigs.map((singleLeaderboard: ILeaderboardConfig, index) => {
      const leaderboardConfigToUpdate = { ...singleLeaderboard };
      const newOrderNumber = index + 1;
      leaderboardConfigToUpdate.order = newOrderNumber;
      return leaderboardConfigToUpdate;
    });
    const leaderboardServices = new LeaderboardsServices(apiConfig);
    const leaderboardList = await leaderboardServices.updateGymLeaderboardConfigRanking(
      action.gym,
      updatedOrderConfigs,
    );
    const sortedLeaderboardList = leaderboardList.sort((a, b) => a.order - b.order);
    return sortedLeaderboardList;
  },
);

export const createLeaderboardConfig = createAsyncThunk<void, {
  gym: string,
  leaderboardConfig: ILeaderboardConfig,
}>(
  'leaderboard/createLeaderboardConfig',
  async (action: {
    gym: string,
    leaderboardConfig: ILeaderboardConfig,
  }) => {
    const leaderboardServices = new LeaderboardsServices(apiConfig);
    await leaderboardServices.createGymLeaderboard(
      action.gym,
      action.leaderboardConfig,
    );
  },
);

export const updateLeaderboardConfig = createAsyncThunk<void, {
  gym: string,
  leaderboardConfig: ILeaderboardConfig,
}>(
  'leaderboard/updateLeaderboardConfig',
  async (action: {
    gym: string,
    leaderboardConfig: ILeaderboardConfig,
  }) => {
    const leaderboardServices = new LeaderboardsServices(apiConfig);
    await leaderboardServices.updateGymLeaderboard(
      action.gym,
      action.leaderboardConfig,
    );
  },
);

export const leaderboardsSlice = createSlice({
  name: 'leaderboard-slice',
  initialState,
  reducers: {
    toggleLeaderboardRankingModal: (state: ILeaderboardsState, action: PayloadAction<boolean>) => {
      state.view.showSortLeaderboardRankingModal = action.payload;
    },
    toggleCreateLeaderboardConfigModal: (state: ILeaderboardsState, action: PayloadAction<boolean>) => {
      state.view.showCreateLeaderboardConfigModal = action.payload;
    },
    toggleUpdateLeaderboardConfigModal: (state: ILeaderboardsState, action: PayloadAction<boolean>) => {
      state.view.showUpdateLeaderboardConfigModal = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ILeaderboardsState>) => {
    builder
      .addCase(getLeaderboardConfigs.pending, (state) => {
        state.view.isFetching = true;
        state.view.isFetchingInstanceDetails = true;
        state.view.errorMessage = null;
        state.data.leaderboardConfigs = [];
        state.data.leaderboardInstances = [];
        state.data.selectedLeaderboardTeam = null;
        state.data.selectedLeaderboardConfig = null;
        state.data.selectedLeaderboardInstance = null;
        state.data.selectedLeaderboardInstanceDetails = null;
      })
      .addCase(getLeaderboardConfigs.fulfilled, (state, action: PayloadAction<ILeaderboardConfig[]>) => {
        const { payload } = action;
        const leaderboardConfigs = !payload ? [] : payload;
        state.data.leaderboardConfigs = leaderboardConfigs;
        state.view.isFetching = false;
        state.view.isFetchingInstanceDetails = false;
        state.view.errorMessage = null;
      })
      .addCase(getLeaderboardConfigs.rejected, (state) => {
        state.view.errorMessage = 'Failed to load leaderboards';
        state.view.isFetching = false;
        state.view.isFetchingInstanceDetails = false;
      });

    builder
      .addCase(getLeaderboardInstances.pending, (state) => {
        state.view.isFetching = true;
      })
      .addCase(getLeaderboardInstances.fulfilled, (state, action: PayloadAction<{
        leaderboardConfigId: string;
        selectedLeaderboardInstance: ILeaderboardInstance,
        leaderboardInstances: ILeaderboardInstance[];
      }>) => {
        const { selectedLeaderboardInstance, leaderboardInstances, leaderboardConfigId } = action.payload;
        const validLeaderboardInstances = !leaderboardInstances ? [] : leaderboardInstances;
        state.data.leaderboardInstances = validLeaderboardInstances;
        const selectedLeaderboard = state.data.leaderboardConfigs.find((singleItem) => (
          singleItem._id === leaderboardConfigId
        ));

        state.data.selectedLeaderboardConfig = selectedLeaderboard || null;
        state.data.selectedLeaderboardInstance = selectedLeaderboardInstance;
        state.data.selectedLeaderboardInstanceDetails = null;
        state.data.selectedLeaderboardTeam = null;
        state.view.isFetching = false;
        state.view.errorMessage = null;
      })
      .addCase(getLeaderboardInstances.rejected, (state) => {
        state.view.isFetching = false;
        state.view.errorMessage = 'Failed to load leaderboard Details';
      });

    builder
      .addCase(setSelectedInstance.pending, (state) => {
        state.view.isFetchingInstanceDetails = true;
        state.view.errorMessage = null;
      })
      .addCase(setSelectedInstance.fulfilled, (state, action: PayloadAction<ILeaderboardDataViewModel | null>) => {
        state.data.selectedLeaderboardInstanceDetails = action.payload;
        state.view.isFetchingInstanceDetails = false;
      })
      .addCase(setSelectedInstance.rejected, (state) => {
        state.view.errorMessage = 'Failed to load leaderboards details';
        state.view.isFetchingInstanceDetails = false;
      });

    builder
      .addCase(updateUserTeamSelected.pending, (state) => {
        state.view.isFetchingInstanceDetails = true;
        state.view.errorMessage = null;
      })
      .addCase(updateUserTeamSelected.fulfilled, (state, action: PayloadAction<ILeaderboardDataViewModel | null>) => {
        state.data.selectedLeaderboardInstanceDetails = action.payload;
        state.data.selectedLeaderboardTeam = null;
        state.view.isFetchingInstanceDetails = false;
      })
      .addCase(updateUserTeamSelected.rejected, (state) => {
        state.view.errorMessage = 'Failed to update user team';
        state.view.isFetchingInstanceDetails = false;
      });

    builder
      .addCase(setSelectedTeam.pending, (state) => {
        state.view.isFetchingInstanceDetails = true;
        state.view.errorMessage = null;
      })
      .addCase(setSelectedTeam.fulfilled, (state, action: PayloadAction<{
        teamName: string | undefined,
        leaderboardInstanceDetails: ILeaderboardDataViewModel | null,
      }>) => {
        state.data.selectedLeaderboardInstanceDetails = action.payload.leaderboardInstanceDetails;
        state.data.selectedLeaderboardTeam = action.payload.teamName || null;
        state.view.isFetchingInstanceDetails = false;
      })
      .addCase(setSelectedTeam.rejected, (state) => {
        state.view.errorMessage = 'Failed to load leaderboards team details';
        state.view.isFetchingInstanceDetails = false;
      });

    builder
      .addCase(updateLeaderboardRankings.pending, (state) => {
        state.view.isFetching = true;
        state.view.isFetchingInstanceDetails = true;
        state.view.errorMessage = null;
      })
      .addCase(updateLeaderboardRankings.fulfilled, (state, action: PayloadAction<ILeaderboardConfig[]>) => {
        const { payload } = action;
        const leaderboardConfigs = !payload ? [] : payload;
        state.data.leaderboardConfigs = leaderboardConfigs;
        state.view.isFetching = false;
        state.view.isFetchingInstanceDetails = false;
        state.view.showSortLeaderboardRankingModal = false;
        state.view.errorMessage = null;
      })
      .addCase(updateLeaderboardRankings.rejected, (state) => {
        state.view.errorMessage = 'Failed to update leaderboard rankings';
        state.view.isFetching = false;
        state.view.isFetchingInstanceDetails = false;
      });

    builder
      .addCase(setupInitialLeaderboard.pending, (state) => {
        state.view.isFetching = true;
        state.view.isFetchingInstanceDetails = true;
        state.view.errorMessage = null;
      })
      .addCase(setupInitialLeaderboard.fulfilled, (state, action: PayloadAction<{
        leaderboardConfigs: ILeaderboardConfig[],
        selectedLeaderboardConfig: ILeaderboardConfig | null,
        leaderboardInstances: ILeaderboardInstance[],
        selectedLeaderboardInstance: ILeaderboardInstance | null,
        selectedLeaderboardInstanceDetails: ILeaderboardDataViewModel | null,
      }>) => {
        state.data.leaderboardConfigs = action.payload.leaderboardConfigs;
        state.data.leaderboardInstances = action.payload.leaderboardInstances;
        state.data.selectedLeaderboardConfig = action.payload.selectedLeaderboardConfig;
        state.data.selectedLeaderboardInstance = action.payload.selectedLeaderboardInstance;
        state.data.selectedLeaderboardInstanceDetails = action.payload.selectedLeaderboardInstanceDetails;
        state.view.isFetching = false;
        state.view.isFetchingInstanceDetails = false;
        state.view.showSortLeaderboardRankingModal = false;
        state.view.errorMessage = null;
      })
      .addCase(setupInitialLeaderboard.rejected, (state) => {
        state.view.errorMessage = 'Failed to load leaderboards';
        state.view.isFetching = false;
        state.view.isFetchingInstanceDetails = false;
      });
  },
});

export const {
  toggleLeaderboardRankingModal,
  toggleCreateLeaderboardConfigModal,
  toggleUpdateLeaderboardConfigModal,
} = leaderboardsSlice.actions;

export default leaderboardsSlice.reducer;

async function fetchGymLeaderboardTeamInstanceDetails(
  gym: string,
  selectedLeaderboardInstance: ILeaderboardInstance,
  teamName: string | undefined,
) {
  const leaderboardServices = new LeaderboardsServices(apiConfig);
  const endDate = selectedLeaderboardInstance.endDate === 'undefined'
    ? moment().add(10, 'years').toISOString()
    : selectedLeaderboardInstance.endDate;
  const leaderboardInstanceDetails = await leaderboardServices.getGymLeaderboardInstanceData(
    gym,
    selectedLeaderboardInstance.leaderboardId,
    selectedLeaderboardInstance.startDate,
    endDate,
    teamName,
  );
  return { teamName, leaderboardInstanceDetails };
}

async function fetchGymLeaderboardInstanceDetails(gym: string, leaderboardInstance: ILeaderboardInstance) {
  const leaderboardServices = new LeaderboardsServices(apiConfig);
  const endDate = leaderboardInstance.endDate === 'undefined'
    ? moment().add(10, 'years').toISOString()
    : leaderboardInstance.endDate;
  const leaderboardInstanceDetails = await leaderboardServices.getGymLeaderboardInstanceData(
    gym,
    leaderboardInstance.leaderboardId,
    leaderboardInstance.startDate,
    endDate,
  );
  return leaderboardInstanceDetails;
}

async function userTeamSelected(
  gym: string,
  uid: string,
  leaderboardConfigId: string,
  newTeam: string,
) {
  const leaderboardServices = new LeaderboardsServices(apiConfig);
  await leaderboardServices.userLeaderboardTeamChange(gym, uid, leaderboardConfigId, newTeam);
}

async function fetchLeaderboardInstances(gym: string, leaderboardConfigId: string) {
  const leaderboardServices = new LeaderboardsServices(apiConfig);
  const leaderboardInstances = await leaderboardServices.getGymLeaderboardInstances(
    gym,
    leaderboardConfigId,
  );
  const dateSortedInstances = leaderboardInstances.sort((
    a: ILeaderboardInstance,
    b: ILeaderboardInstance,
  ) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
  return {
    leaderboardConfigId,
    selectedLeaderboardInstance: dateSortedInstances[0],
    leaderboardInstances: dateSortedInstances,
  };
}

async function fetchLeaderboardConfigs(gym: string) {
  const leaderboardServices = new LeaderboardsServices(apiConfig);
  const leaderboardList: ILeaderboardConfig[] = await leaderboardServices.getGymLeaderboardConfigs(gym);
  const sortedLeaderboardList = leaderboardList.sort((a, b) => a.order - b.order);
  return sortedLeaderboardList;
}
