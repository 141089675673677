import React from 'react';

export const previousButton = (props: any) => {
    return (
        <button {...props} className='jr-btn-default-pagination'>
        <img style={{ display: 'inline-block', paddingRight: 8 }} src='resources/images/previous-icon.svg'></img>
        Previous</button>
    );
  };

export const nextButton = (props: any) => {
    return (
        <button {...props} className='jr-btn-default-pagination'>Next
        <img style={{ display: 'inline-block', paddingLeft: 8 }} src='resources/images/next-icon.svg'></img>
        </button>
    );
  };
