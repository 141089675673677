import { Moment } from 'moment';
import { Dispatch } from 'redux';
import { ReduxHelpers } from '../util/reduxHelpers';
import { StripeService } from './stripeService';

export const TOGGLE_STRIPE_KEY_MODAL = 'TOGGLE_STRIPE_KEY_MODAL';
export const SET_STRIPE_KEY = 'SET_STRIPE_KEY';
export const SAVE_STRIPE_KEY_SUCCESS = 'SAVE_STRIPE_KEY_SUCCESS';
export const SAVE_STRIPE_KEY_FAILURE = 'SAVE_STRIPE_KEY_FAILURE';
export const TOGGLE_STRIPE_ACTIONS = 'TOGGLE_STRIPE_ACTIONS';
export const CLOSE_STRIPE_KEY_MODAL = 'CLOSE_STRIPE_KEY_MODAL';
export const GET_REQUIRED_STRIPE_DATA_REQUEST = 'GET_REQUIRED_STRIPE_DATA_REQUEST';
export const GET_REQUIRED_STRIPE_DATA_SUCCESS = 'GET_REQUIRED_STRIPE_DATA_SUCCESS';
export const GET_REQUIRED_STRIPE_DATA_FAILURE = 'GET_REQUIRED_STRIPE_DATA_FAILURE';
export const stripeStateTransformations = {};
export const TOGGLE_STRIPE_SUBSCRIPTIONS_MODAL = 'TOGGLE_STRIPE_SUBSCRIPTIONS_MODAL';
export const SET_SUBSCRIPTION_PHASE_END_DATE = 'SET_SUBSCRIPTION_PHASE_END_DATE';
export const SET_SUBSCRIPTION_PLAN = 'SET_SUBSCRIPTION_PLAN';
export const SET_SUBSCRIPTION_COUPON = 'SET_SUBSCRIPTION_COUPON';
export const ADD_NEW_PHASE = 'ADD_NEW_PHASE';
export const ADD_PAUSE_PHASES = 'ADD_PAUSE_PHASES';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const DELETE_PHASE = 'DELETE_PHASE';

const { makeApiRequestAction } = ReduxHelpers(stripeStateTransformations);
interface IGetStripePlansAndCustomerRequest {
  type: typeof GET_REQUIRED_STRIPE_DATA_REQUEST;
  payload: any[];
}
interface IGetStripePlansAndCustomerSuccess {
  type: typeof GET_REQUIRED_STRIPE_DATA_SUCCESS;
  payload: any;
}

interface ISetSchedulePhaseEndDate {
  type: typeof SET_SUBSCRIPTION_PHASE_END_DATE;
  payload: any;
}

interface IAddNewPhase {
  type: typeof ADD_NEW_PHASE;
  payload: any;
}

interface IAddPausePhases {
  type: typeof ADD_PAUSE_PHASES;
  payload: any;
}

interface IDeletePhase {
  type: typeof DELETE_PHASE;
  payload: any;
}

interface ISetError {
  type: typeof SET_ERROR_MESSAGE;
  payload: any;
}

interface ISetSchedulePlan {
  type: typeof SET_SUBSCRIPTION_PLAN;
  payload: any;
}

interface ISetScheduleCoupon {
  type: typeof SET_SUBSCRIPTION_COUPON;
  payload: any;
}

interface IGetStripePlansAndCustomerFailure {
  type: typeof GET_REQUIRED_STRIPE_DATA_FAILURE;
  payload: string;
}
interface IToggleStripeKeyModal {
  type: typeof TOGGLE_STRIPE_KEY_MODAL;
}

interface IToggleStripeSubscriptionsModal {
  type: typeof TOGGLE_STRIPE_SUBSCRIPTIONS_MODAL;
}
interface ICloseStripeKeyModal {
  type: typeof CLOSE_STRIPE_KEY_MODAL;
}

interface ISetStripeKeySuccess {
  type: typeof SET_STRIPE_KEY;
  payload: string;
}

interface ISaveStripeKeySuccess {
  type: typeof SAVE_STRIPE_KEY_SUCCESS;
  payload: string;
}

interface ISaveStripeKeyFailure {
  type: typeof SAVE_STRIPE_KEY_FAILURE;
  payload: string;
}

interface IToggleStripeActions {
  type: typeof TOGGLE_STRIPE_ACTIONS;
}

export type StripeActionTypes = IToggleStripeKeyModal | ISetStripeKeySuccess | ISaveStripeKeySuccess
  | ISaveStripeKeyFailure | IToggleStripeActions | ICloseStripeKeyModal | IGetStripePlansAndCustomerRequest |
  IGetStripePlansAndCustomerSuccess | IGetStripePlansAndCustomerFailure | IToggleStripeSubscriptionsModal |
  ISetSchedulePhaseEndDate | ISetSchedulePlan | ISetScheduleCoupon | IAddNewPhase |
  IAddPausePhases | ISetError | IDeletePhase;

export const toggleStripeKeyModal = (): IToggleStripeKeyModal => {
  return {
    type: TOGGLE_STRIPE_KEY_MODAL,
  };
};

export const toggleStripeSubscriptionsModal = (): IToggleStripeSubscriptionsModal => {
  return {
    type: TOGGLE_STRIPE_SUBSCRIPTIONS_MODAL,
  };
};

export const closeStripeKeyModal = (): ICloseStripeKeyModal => {
  return {
    type: CLOSE_STRIPE_KEY_MODAL,
  };
};

export const saveStripeKey = (stripeKey: string, stripeUserId: string) => {
  return async (dispatch: Dispatch<StripeActionTypes>) => {
    try {
      dispatch(saveStripeKeySuccess(stripeKey));
      dispatch(toggleStripeActions());
    } catch (error) {
      dispatch(saveStripeKeyFailure(error.message));
    }
  };
};

export const getRequiredStripeData = (stripeKey: string, stripeUserId: string) => {
  return async (dispatch: Dispatch<StripeActionTypes>) => {
    try {
      dispatch(getRequiredStripeDataRequest());
      const stripeService = new StripeService();
      const results = await Promise.all([
        stripeService.getPlans(stripeKey),
        stripeService.getCustomer(stripeKey, stripeUserId),
      ]);
      dispatch(getRequiredStripeDataSuccess(results[0], results[1]));
    } catch (error) {
      dispatch(getRequiredStripeDataFailure(error.message));
    }
  };
};

export const releaseStripeSchedule = makeApiRequestAction('REMOVE_STRIPE_SCHEDULE',
  async (stripeKey: string, stripeCustomerId: string, scheduleId: string, dispatch?: any) => {
    const stripeService = new StripeService();
    const scheduleResponse = await stripeService.releaseSchedule(stripeKey, scheduleId);
    dispatch(getRequiredStripeData(stripeKey, stripeCustomerId));
    if (scheduleResponse.error) {
      dispatch(setErrorMessage(scheduleResponse.error.message));
    }
    return {
      scheduleResponse,
    };
  },
  (draft: any, action: any) => {
    return draft;
  });

export const createStripeSchedule = makeApiRequestAction('CREATE_STRIPE_SCHEDULE',
  async (stripeKey: string, stripeCustomerId: string, schedule: any, dispatch?: any) => {
    const stripeService = new StripeService();
    const scheduleResponse = await stripeService.createSchedule(stripeKey, schedule);
    dispatch(getRequiredStripeData(stripeKey, stripeCustomerId));
    if (scheduleResponse.error) {
      dispatch(setErrorMessage(scheduleResponse.error.message));
    }
    return {
      scheduleResponse,
    };
  },
  (draft: any, action: any) => {
    return draft;
  });

export const updateStripeSchedule = makeApiRequestAction('UPDATE_STRIPE_SCHEDULE',
  async (stripeKey: string, stripeCustomerId: string, scheduleId: string, schedule: any, dispatch?: any) => {
      const stripeService = new StripeService();
      const scheduleUpdate = {
        phases: schedule.phases.map((phase: any, index: number) => {
          const couponThatIsUndefinedIfEmpty = phase.coupon != null && phase.coupon !== ''
            ? phase.coupon
            : undefined;
          return {
            plans: phase.plans.map((plan: any) => {
              return {
                plan: plan.plan,
              };
            }),
            start_date: index === 0 ? phase.start_date : undefined,
            end_date: phase.end_date,
            coupon: couponThatIsUndefinedIfEmpty,
          };
        }),
      };
      const scheduleResponse = await stripeService.updateSchedule(stripeKey, scheduleId, scheduleUpdate);
      dispatch(getRequiredStripeData(stripeKey, stripeCustomerId));
      if (scheduleResponse.error) {
        dispatch(setErrorMessage(scheduleResponse.error.message));
      }
      return {
        scheduleResponse,
      };
  },
  (draft: any, action: any) => {
    return draft;
  });

export const setStripeKey = (stripeKey: any): ISetStripeKeySuccess => {
  return {
    type: SET_STRIPE_KEY,
    payload: stripeKey.target.value,
  };
};

const saveStripeKeySuccess = (stripeKey: string): ISaveStripeKeySuccess => {
  return {
    type: SAVE_STRIPE_KEY_SUCCESS,
    payload: stripeKey,
  };
};

const saveStripeKeyFailure = (error: string): ISaveStripeKeyFailure => {
  return {
    type: SAVE_STRIPE_KEY_FAILURE,
    payload: error,
  };
};

export const toggleStripeActions = (): IToggleStripeActions => {
  return {
    type: TOGGLE_STRIPE_ACTIONS,
  };
};

const getRequiredStripeDataRequest = (): IGetStripePlansAndCustomerRequest => {
  return {
    type: GET_REQUIRED_STRIPE_DATA_REQUEST,
    payload: [],
  };
};

const getRequiredStripeDataSuccess = (plans: any[], customer: any): IGetStripePlansAndCustomerSuccess => {
  return {
    type: GET_REQUIRED_STRIPE_DATA_SUCCESS,
    payload: {
      plans,
      customer,
    },
  };
};

const getRequiredStripeDataFailure = (error: string): IGetStripePlansAndCustomerFailure => {
  return {
    type: GET_REQUIRED_STRIPE_DATA_FAILURE,
    payload: error,
  };
};

const setErrorMessage = (error: string): ISetError => {
  return {
    type: SET_ERROR_MESSAGE,
    payload: error,
  };
};

export const setSchedulePhaseEndDate = (newDate: Moment, subscriptionId: string, phaseIndex: number):
  ISetSchedulePhaseEndDate => {
  return {
    type: SET_SUBSCRIPTION_PHASE_END_DATE,
    payload: {
      newDate,
      subscriptionId,
      phaseIndex,
    },
  };
};

export const setSchedulePlan = (planId: string, subscriptionId: string, phaseIndex: number):
  ISetSchedulePlan => {
  return {
    type: SET_SUBSCRIPTION_PLAN,
    payload: {
      planId,
      subscriptionId,
      phaseIndex,
    },
  };
};

export const setScheduleCoupon = (couponId: string, subscriptionId: string, phaseIndex: number):
  ISetScheduleCoupon => {
  return {
    type: SET_SUBSCRIPTION_COUPON,
    payload: {
      couponId,
      subscriptionId,
      phaseIndex,
    },
  };
};

export const addNewPhase = (subscriptionId: string):
  IAddNewPhase => {
  return {
    type: ADD_NEW_PHASE,
    payload: subscriptionId,
  };
};

export const addPausePhases = (subscriptionId: string):
  IAddPausePhases => {
  return {
    type: ADD_PAUSE_PHASES,
    payload: subscriptionId,
  };
};

export const deletePhase = (subscriptionId: string, phaseIndex: number):
  IDeletePhase => {
  return {
    type: DELETE_PHASE,
    payload: {
      subscriptionId,
      phaseIndex,
    },
  };
};
