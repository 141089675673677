import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { exportDataToCSV } from '../../adminSettings/actions';
import { currentGymSelector } from '../../adminSettings/selector';
import { IAppStore } from '../../rootReducer';
import { Loading } from '../../shared/loading';
import {
  clearFilterData,
  clearReportTableFilters,
  getAllData,
  loadActiveReport,
  saveColumnWidth,
  setFilterData,
  setSortingOrder,
} from '../actions';
import {
  nextButton,
  previousButton,
} from '../components/reactTablePaginationButtons';
import { flattenDataForReport } from '../config';
import { membersColumnsSelector, savedReportColumnWidth } from '../selector';
import { MemberTableError } from './error';

export default class MemberList extends Component<any, any> {
  public reactTable: any;
  constructor(props: any) {
    super(props);
    this.reactTable = React.createRef();
    this.props.exportCSV(this.reactTable);
  }

  public componentDidMount = async () => {
    await this.props.exportCSV(this.reactTable);
    this.props.loadActiveReport(this.props.memberReportData, this.props.reportType);
  }

  public componentWillUnmount = () => {
    this.props.clearReportTableFilters();
  }

  public componentDidUpdate = async (prevProps: any) => {
    if (
      prevProps.report.reportId !== this.props.report.reportId ||
      prevProps.membersColumns !== this.props.membersColumns ||
      prevProps.sortOrder !== this.props.sortOrder ||
      prevProps.filtered !== this.props.filtered
    ) {
      await this.props.exportCSV(this.reactTable);
    }
  }

  public onFilterChange = (filtered: any) => {
    this.props.setFilterData(filtered);
    this.props.exportCSV(this.reactTable);
  }

  public render() {
    const {
      isError,
      isLoading,
      excludedEmails,
      currentGym,
      sortOrder,
      filtered,
      memberDetailsData,
      allWorkouts,
      report,
      isDashboardReport = false,
    } = this.props;
    const rows = flattenDataForReport(
      memberDetailsData,
      allWorkouts,
      report);
    return (
      <div>
        {!isDashboardReport && <h2 className='total-rows-header'>
          TOTAL ROWS: {this.reactTable.current?.state?.sortedData?.length || 0}
        </h2>}
        {isError ? (
          <MemberTableError
            reload={() => this.props.reload(
              excludedEmails,
              currentGym.accountId,
            )}
          />
        ) : isLoading ? (
          <div className={'loader-container'}>
            <Loading />
          </div>
        ) : (
          <div>
            <ReactTable
              className='clickable-row'
              ref={this.reactTable}
              minRows={0}
              resizable={true}
              onResizedChange={this.props.saveColumnWidth}
              resized={this.props.savedColumnWidth}
              showPageSizeOptions={true}
              defaultPageSize={10}
              filtered={filtered}
              filterable
              data={rows}
              loadingText={'Loading...'}
              noDataText={'No data found'}
              columns={this.props.membersColumns}
              defaultFilterMethod={(singleFilter: any, row: any) =>
                String(row[singleFilter.id]) === singleFilter.value
              }
              defaultSorted={sortOrder}
              PreviousComponent={previousButton}
              NextComponent={nextButton}
              onFilteredChange={(reportFilter) => this.onFilterChange(reportFilter)}
              TrGroupComponent={(props) => {
                return props.uid ? (<a
                  href={`/#/app/memberDetails/${props.uid}`}
                  className='rt-tr-group'
                  role='rowgroup'>{props.children}
                </a>): (<div className='rt-tr-group disabled-click'>
                    {props.children}
                </div>);
              }}
              getTrGroupProps={(_: any, props: any) => ({ uid: props.original.uid })}
              onSortedChange={(sorted) => this.props.setSortingOrder(sorted)}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IAppStore, ownProps: any) => {
  const { members, adminSettings } = state;
  const {
    isFetching,
    error,
    sortOrder,
    filtered,
    selectedRows,
  } = members.view;
  const { exportData, enableDataExport } = adminSettings.data;
  const { memberReportData, memberDetailsData, allWorkouts } = members.data;
  const { excludedEmails } = adminSettings.data;
  const currentGym = currentGymSelector(state);
  const filtersToUse = filtered.length > 0 ? filtered : ownProps.report.filters;
  return {
    savedColumnWidth: savedReportColumnWidth(ownProps.report),
    isLoading: isFetching,
    isError: !!error,
    sortOrder,
    exportData,
    filtered: filtersToUse,
    enableDataExport,
    selectedRows,
    excludedEmails,
    currentGym,
    membersColumns: membersColumnsSelector(state, ownProps.report, currentGym),
    memberDetailsData,
    memberReportData,
    allWorkouts,
  };
};

const mapDispatchToProps = {
  reload: getAllData,
  exportCSV: exportDataToCSV,
  clearFilters: clearFilterData,
  setFilterData,
  setSortingOrder,
  saveColumnWidth,
  clearReportTableFilters,
  loadActiveReport,
};

export const MemberListTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MemberList));
