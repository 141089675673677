import { apiConfig } from '../app/apiConfig';
import { GymResolver } from '../util/GymResolver';
import { clearUserDetails as clearUserDetailsOnLogging } from '../util/logging';

export function logout() {
  apiConfig.LOCAL_SERVER_USERNAME = '';
  apiConfig.LOCAL_SERVER_PASSWORD = '';
  localStorage.clear();
  clearUserDetailsOnLogging();
  GymResolver.clearGyms();
  window.location.replace('/');
}
