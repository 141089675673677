import React from 'react';
import 'react-table/react-table.css';
import { MemberListTable } from './memberListTable';

export class List extends React.Component<any, any> {
  public render() {
    return (
      <MemberListTable
        report={this.props.report}
        reportType={this.props.reportType}
      />
    );
  }
}
