import {
  ICreateSpecialOfferParams,
  IPrice,
  ISpecialOfferDisplay,
  IStripeCouponWrapper,
  ProductCategory,
} from 'fitbeat.models';
import { AccountActionsService } from '../../../accountActions/accountActionsService';
import { currentGymSelector } from '../../../adminSettings/selector';
import { apiConfig } from '../../../app/apiConfig';
import { IAppStore } from '../../../rootReducer';
import { MembersService } from '../../membersService';

export const types = {
  EDIT_MEMBERSHIP_OFFER_PRESSED: 'EDIT_MEMBERSHIP_OFFER_PRESSED',
  CREATE_MEMBERSHIP_OFFER_PRESSED: 'CREATE_MEMBERSHIP_OFFER_PRESSED',
  CLOSE_MEMBERSHIP_OFFER_MODAL_PRESSED: 'CLOSE_MEMBERSHIP_OFFER_MODAL_PRESSED',
  CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_REQUEST: 'CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_REQUEST',
  CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_SUCCESS: 'CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_SUCCESS',
  CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_FAILURE: 'CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_FAILURE',
  UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_REQUEST: 'UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_REQUEST',
  UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_SUCCESS: 'UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_SUCCESS',
  UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_FAILURE: 'UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_FAILURE',

  OFFER_PLAN_SELECTED_REQUEST: 'OFFER_PLAN_SELECTED_REQUEST',
  OFFER_PLAN_SELECTED_SUCCESS: 'OFFER_PLAN_SELECTED_SUCCESS',
  OFFER_PLAN_SELECTED_FAILURE: 'OFFER_PLAN_SELECTED_FAILURE',
};

const membersService = new MembersService(apiConfig);

interface IEditMembershipOfferPressed {
  type: typeof types.EDIT_MEMBERSHIP_OFFER_PRESSED;
}

export const editMembershipOfferPressed = (): IEditMembershipOfferPressed => ({
  type: types.EDIT_MEMBERSHIP_OFFER_PRESSED,
});

interface ICreateMembershipOfferPressed {
  type: typeof types.CREATE_MEMBERSHIP_OFFER_PRESSED;
}

export const createMembershipOfferPressed = (): ICreateMembershipOfferPressed => ({
  type: types.CREATE_MEMBERSHIP_OFFER_PRESSED,
});

interface ICloseMembershipOfferModalPressed {
  type: typeof types.CLOSE_MEMBERSHIP_OFFER_MODAL_PRESSED;
}

export const closeMembershipOfferModalPressed = (): ICreateMembershipOfferPressed => ({
  type: types.CLOSE_MEMBERSHIP_OFFER_MODAL_PRESSED,
});

interface ICreateMembershipOfferInModalPressedRequest {
  type: typeof types.CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_REQUEST;
}

export interface ICreateMembershipOfferInModalPressedSuccess {
  type: typeof types.CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_SUCCESS;
  payload: {
    specialOffer: ISpecialOfferDisplay;
  };
}

interface ICreateMembershipOfferInModalPressedFailure {
  type: typeof types.CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_FAILURE;
}

export const createMembershipOfferInModalPressed = (specialOfferToCreate: ICreateSpecialOfferParams) => {
  return async (
    dispatch: (
      action:
        | ICreateMembershipOfferInModalPressedRequest
        | ICreateMembershipOfferInModalPressedSuccess
        | ICreateMembershipOfferInModalPressedFailure,
    ) => void,
    getState: () => IAppStore,
    ) => {
    try {
      dispatch({ type: types.CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_REQUEST });
      const state = getState();
      const userId = state.members.data.memberAccountData?.userInfo.profile.uid;
      if (!userId) {
        throw new Error('Current user not found');
      }
      const specialOffer = await membersService.createSpecialOffer(userId, specialOfferToCreate);
      dispatch({ type: types.CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_SUCCESS, payload: { specialOffer } });
    } catch {
      dispatch({ type: types.CREATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_FAILURE });
    }
  };
};

interface IUpdateMembershipOfferInModalPressedRequest {
  type: typeof types.UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_REQUEST;
}

export interface IUpdateMembershipOfferInModalPressedSuccess {
  type: typeof types.UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_SUCCESS;
  payload: {
    specialOffer: ISpecialOfferDisplay;
  };
}

interface IUpdateMembershipOfferInModalPressedFailure {
  type: typeof types.UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_FAILURE;
}

export const updateMembershipOfferInModalPressed = (
  specialOfferId: string,
  specialOfferToUpdate: ICreateSpecialOfferParams,
  ) => {
  return async (
    dispatch: (
      action:
        | IUpdateMembershipOfferInModalPressedRequest
        | IUpdateMembershipOfferInModalPressedSuccess
        | IUpdateMembershipOfferInModalPressedFailure,
    ) => void,
    getState: () => IAppStore,
  ) => {
    try {
      dispatch({ type: types.UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_REQUEST });
      const state = getState();
      const userId = state.members.data.memberAccountData?.userInfo.profile.uid;
      if (!userId) {
        throw new Error('Current user not found');
      }

      const specialOffer = await membersService.updateSpecialOffer(
        userId,
        specialOfferId,
        specialOfferToUpdate,
      );
      dispatch({ type: types.UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_SUCCESS, payload: { specialOffer } });
    } catch {
      dispatch({ type: types.UPDATE_MEMBERSHIP_OFFER_IN_MODAL_PRESSED_FAILURE });
    }
  };
};

interface IOfferPlanSelectedRequest {
  type: typeof types.OFFER_PLAN_SELECTED_REQUEST;
}

export interface IOfferPlanSelectedSuccess {
  type: typeof types.OFFER_PLAN_SELECTED_SUCCESS;
  payload: {
    coupons: IStripeCouponWrapper[];
  };
}

interface IOfferPlanSelectedFailure {
  type: typeof types.OFFER_PLAN_SELECTED_FAILURE;
}

export const specialOfferPlanSelected = (
  plan: IPrice,
) => {
  return async (
    dispatch: (
      action:
        | IOfferPlanSelectedRequest
        | IOfferPlanSelectedSuccess
        | IOfferPlanSelectedFailure,
    ) => void,
    getState: () => IAppStore,
  ) => {
    try {
      dispatch({ type: types.OFFER_PLAN_SELECTED_REQUEST });
      const state = getState();
      const currentGym = currentGymSelector(state);
      const userId = state.members.data.memberAccountData?.userInfo.profile.uid;
      if (!userId) {
        throw new Error('Current user not found');
      }
      const accountActionService = new AccountActionsService(apiConfig);
      const coupons = await accountActionService.getCoupons(
        currentGym.name,
        userId,
        plan.priceId,
        [ProductCategory.Services],
      );
      dispatch({ type: types.OFFER_PLAN_SELECTED_SUCCESS, payload: { coupons } });
    } catch {
      dispatch({ type: types.OFFER_PLAN_SELECTED_FAILURE });
    }
  };
};

export type SpecialOfferActions =
  | IEditMembershipOfferPressed
  | ICreateMembershipOfferPressed
  | ICloseMembershipOfferModalPressed
  | ICreateMembershipOfferInModalPressedRequest
  | ICreateMembershipOfferInModalPressedSuccess
  | ICreateMembershipOfferInModalPressedFailure
  | IUpdateMembershipOfferInModalPressedRequest
  | IUpdateMembershipOfferInModalPressedSuccess
  | IUpdateMembershipOfferInModalPressedFailure
  | IOfferPlanSelectedRequest
  | IOfferPlanSelectedSuccess
  | IOfferPlanSelectedFailure;
