import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';
import { getDatesForPredefinedDayFilter } from '../filters/filterHelpers';
import DatePickerFilter from './datePickerComponent';

class DatePickerPreDefinedDays extends Component<any> {
  public render() {
    const { type } = this.props;
    const options = [
      { label: 'All Dates', value: { operation: 'clearFilter' }},
      { label: 'Last 7 days', value: { days: 7, operation: 'lastDays' } },
      { label: 'Last 14 days', value: { days: 14, operation: 'lastDays' } },
      { label: 'Last 30 days', value: { days: 30, operation: 'lastDays' } },
      {
        label: 'Next 7 days',
        value: { days: 7, operation: 'nextDays' },
      },
      {
        label: 'Next 14 days',
        value: { days: 14, operation: 'nextDays' },
      },
      {
        label: 'Next 30 days',
        value: { days: 30, operation: 'nextDays' },
      },
      { label: 'Custom', value: { operation: 'customFilter' }},
    ];

    const currentSelected = this.props[type].customFilter
      ? { label: 'Custom' }
      : options.find((item) => {
          return (
            item.value && item.value.operation && item.value.days &&
            (item.value.days === this.props[type][item.value.operation])
          );
        });

    const predefinedDaysAsOptions = (givenProps: any) => <div>
        <div>
          <span onClick={() => givenProps.onPreviousClick()}
            role='button' aria-label='Previous Month' className='DayPicker-NavButton DayPicker-NavButton--prev'></span>
          <span onClick={() => givenProps.onNextClick()}
            role='button' aria-label='Next Month' className='DayPicker-NavButton DayPicker-NavButton--next'></span>
        </div>
        <div className='predefine-day-range-container'>
          {options.map((singleOption) => {
            const matchCurrentSelected =
            currentSelected?.label === singleOption.label;
            return (
              <p
                onClick={() => this.props.onChange(singleOption.value.days ? {
                    [singleOption.value.operation]: singleOption.value.days,
                    label: singleOption.label,
                    customFilter: false,
                  }: {
                    [singleOption.value.operation]: true,
                    label: singleOption.label,
                    startDate: this.props[type].startDate,
                    endDate: this.props[type].endDate,
                   })
                }
                style={{
                  paddingLeft: 10,
                  fontWeight: matchCurrentSelected ? 'bold' : 'normal',
                  color: matchCurrentSelected ? '#FF621D' : 'black',
                }}>
                  {singleOption.label}
                </p>
            );
          })}
        </div>
      </div>;
    return (
      <div style={{ display: 'flex' }}>
        <DatePickerFilter
          startDate={this.props[type].startDate}
          endDate={this.props[type].endDate}
          onChange={this.props.onChange}
          type={this.props.type}
          navbarElement={predefinedDaysAsOptions}
          placeHolderFormat={this.props.placeHolderFormat}
          showLeft={this.props.showLeft}
          maxRangeDays={this.props.maxRangeDays}
        />
      </div>
    );
  }
}
const mapDispatchToProps = {};

const mapStateToProps = (state: IAppStore, props: any) => {
  const { members } = state;
  const custom = !!props.custom;
  const { filtered } = members.view;
  const filterDates = filtered.find((filter) => filter.id === props.type);
  let filterData = filterDates?.value;
  let predefinedDayFilterDate = getDatesForPredefinedDayFilter(filterData);
  if (predefinedDayFilterDate) {
    filterData = {
    ...filterData,
    ...predefinedDayFilterDate,
    };
  }

  if (!custom) {
    return {
      filtered,
      [props.type]: filterData ? filterData : {},
    };
  }

  predefinedDayFilterDate = getDatesForPredefinedDayFilter(props.custom);
  filterData = {
    ...props.custom,
    ...predefinedDayFilterDate,
  };
  return {
    [props.type]: filterData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DatePickerPreDefinedDays);
