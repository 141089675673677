import { AccountStatus } from 'fitbeat.models';
import moment from 'moment-timezone';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { IAppStore } from '../../../rootReducer';
import Dropdown from '../../../shared/components/Dropdown';
import {
  addDaysToStartOfToday,
  calculateExpirationDate,
  calculateFreeDays,
  freeDayOptions,
  getRemainingDaysOnCurrentSubscription,
  initialValueForBillingStartDate,
} from './billing-start-date-helpers';

interface IProps {
  parentProps: {
    selectedBillingStartDate: Date | null;
    setSelectedBillingStartDate: (date: Date) => void;
  };
  currentSubscriptionCancelsOn?: number;
  currentSubscriptionPeriodEnd?: number;
  memberAccountStatus?: AccountStatus;
}

interface IFreeDayOption {
  value: number;
  label: string;
}

const BillingStartDateSelect: FunctionComponent<IProps> = (
  {
    parentProps: {
      selectedBillingStartDate,
      setSelectedBillingStartDate,
    },
    currentSubscriptionPeriodEnd,
    currentSubscriptionCancelsOn,
    memberAccountStatus,
  },
) => {

  useEffect(() => {
    if (!selectedBillingStartDate) {
      const initialBillingStartDate = initialValueForBillingStartDate(remainingDaysOnSubscription);
      setSelectedBillingStartDate(initialBillingStartDate);
    }
  }, []);

  const freeDaySelectChanged = (option: IFreeDayOption) => {
    const daysToAdd = remainingDaysOnSubscription + option.value;
    setSelectedBillingStartDate(addDaysToStartOfToday(daysToAdd));
  };

  const remainingDaysOnSubscription = getRemainingDaysOnCurrentSubscription(
    memberAccountStatus,
    currentSubscriptionCancelsOn,
    currentSubscriptionPeriodEnd,
  );

  const freeDays = calculateFreeDays(remainingDaysOnSubscription, selectedBillingStartDate);
  const selectedFreeDayOption = freeDayOptions.find((option) => option.value === freeDays);

  return (
    <div className='border-top'>
      <div className='mt-4'>
        <div className='heading bold mb-2'>Add free days</div>
        <div className='row'>
          <div className='col-4'>
            <Dropdown
              onChange={freeDaySelectChanged}
              options={freeDayOptions}
              value={selectedFreeDayOption}
              placeholder={'Select a free days'}
              className='start-subscription select-container'
              classNamePrefix='plan select'
              isSearchable={true}
              menuPortalTarget={document.body}
            />
          </div>
          <div className='col-8 text-right'>
            {selectedBillingStartDate &&
              <p className='mb-0'>
                <span className='heading'>Billing starts (on or after): </span>
                <span className='heading bold'>
                {moment(selectedBillingStartDate).format('DD MMM YYYY')}
                </span>
              </p>
            }
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='mt-1 col-6'>
          {memberAccountStatus === 'InStudioTrial' &&
            <p className='body-copy-reg'>
              <strong>{remainingDaysOnSubscription} {remainingDaysOnSubscription === 1 ? 'day' : 'days'} </strong>
              left on current trial (ends {moment(currentSubscriptionCancelsOn).format('DD MMM')})
            </p>
          }
          {memberAccountStatus === 'StudioStandardSubscription' &&
            <p>
              <strong>{remainingDaysOnSubscription} {remainingDaysOnSubscription === 1 ? 'day' : 'days'} </strong>
              until current subscription period end (period ends {moment(currentSubscriptionPeriodEnd)
              .format('DD MMM')})
            </p>
          }
        </div>
      </div>
      {selectedBillingStartDate &&
        <p className='mb-4 text-right'>
          <span className='heading'>Offer expires on: </span>
          <span className='heading bold'>
          {moment(calculateExpirationDate(selectedBillingStartDate)).format('DD MMM YYYY')}
          </span>
        </p>
      }
    </div>
  );
};

const mapStateToProps = (state: IAppStore) => ({
  currentSubscriptionCancelsOn: state.members.data.memberAccountData?.userInfo.cancelAt,
  currentSubscriptionPeriodEnd: state.members.data.memberAccountData?.userInfo.currentSubscriptionPeriodEnd,
  memberAccountStatus: state.members.data.memberAccountData?.userInfo.currentAccountStatus,
});

export default connect(mapStateToProps)(BillingStartDateSelect);
