import * as React from 'react';
import { CSSProperties } from 'react';
import { logout } from '../auth';

export const Error = ({ containerStyle, errorMessage }: { containerStyle?: CSSProperties, errorMessage?: string }) => (
  <div style={containerStyle} className='errorMessageContainer'>
    <h1>
      {errorMessage || 'Something has gone wrong.'}
    </h1>
    <div className='error-buttons-container'>
      <button onClick={() => window.location.reload()} className='jr-btn-default'>Reload</button>
      <button onClick={logout} className='jr-btn-default'>Logout</button>
    </div>
  </div>
);
