import matchSorter, { rankings } from 'match-sorter';
import moment from 'moment/moment';

export const filterMethodContainsOrDoesntContain = (filter: any, rows: any, key: string) => {
  const value = filter.value;
  const notTextSymbol = '-'; // tried to follow google https://ahrefs.com/blog/google-advanced-search-operators/
  if (typeof value === 'string' && value.length > 1 && value.charAt(0) === notTextSymbol) {
    const valueExludingExclamation = value.substring(1);
    const valuesToExclude
      = matchSorter(rows, valueExludingExclamation, { threshold: rankings.CONTAINS, keys: [key] });
    return rows.filter((item: any) => !valuesToExclude.includes(item));
  }

  return matchSorter(rows, filter.value, { threshold: rankings.CONTAINS, keys: [key] });
};

export const filterMethodOnStringArray = (filter: any, rows: any) => {
  const temp = matchSorter(rows, filter);
  return temp.length >= 1;
};

export const exactTextMatchFilter = (filters: any, row: any) => {
  let hasFoundMatch = false;
  const isEmpty = !row[filters.id];
  for (const filter of filters.value) {
    if (filter === 'Unassigned' && isEmpty) {
      hasFoundMatch = true;
    }
    if (filter === row[filters.id]) {
      hasFoundMatch = true;
    }
  }
  return hasFoundMatch;
};

export const getDatesForPredefinedDayFilter = (filter: any) => {
  if (filter?.clearFilter) {
    return {
      startDate: undefined,
      endDate: undefined,
    };
  }
  if (filter?.customFilter && !filter?.nextDays && !filter?.lastDays) {
    if ((filter.startDate && filter.endDate) || (!filter.startDate && !filter.endDate)) {
      const startDate = filter.startDate ? moment(filter.startDate) : moment();
      const endDate = filter.endDate ? moment(filter.endDate) : moment();
      return {
        startDate: startDate.startOf('day').toDate(),
        endDate: endDate.endOf('day').toDate(),
      };
    }
  }
  if (filter?.nextDays && !filter?.lastDays && !filter.customFilter) {
    return {
      startDate: moment().startOf('day').toDate(),
      endDate: moment().add(filter.nextDays, 'day').endOf('day').toDate(),
    };
  }
  if (filter?.lastDays && !filter?.nextDays && !filter?.customFilter) {
    return {
      startDate: moment().subtract(filter.lastDays, 'day').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
    };
  }
  return;
};
