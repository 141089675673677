import {
  Gym,
  IPrice,
  ISubscriptionPaymentSummary,
} from 'fitbeat.models';
import React, { FunctionComponent, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { currentGymSelector } from '../../../adminSettings/selector';
import { IAppStore } from '../../../rootReducer';
import Dropdown from '../../../shared/components/Dropdown';
import { getFormattedAmount } from '../../../shared/helpers/formatHelper';
import { specialOfferPlanSelected } from '../subscriptionOffers/subscription-offers-actions';
import {
  couponOptionsSelector, emptyCouponOption,
  ICouponSelectOption,
  IPriceSelectOption,
  planOptionsSelector,
} from '../subscriptionOffers/subscription-offers-selectors';
import { calculateSubscriptionValues } from './plan-coupon-select-helper-functions';

interface IProps {
  parentProps: {
    selectedPriceId: string;
    setSelectedPriceId: (priceId: string) => void;
    selectedCouponId: string | undefined;
    setSelectedCouponId: (couponId: string) => void;
  };

  planOptions: IPriceSelectOption[] | undefined;
  paymentSummary: ISubscriptionPaymentSummary | undefined;
  currentGym: Gym;
  uid: string;
  initializeCoupons: (price: IPrice) => void;
  couponOptions: ICouponSelectOption[];
  couponsLoading: boolean;
}

const PlanAndCouponSelect: FunctionComponent<IProps> = (
  {
    parentProps: {
      selectedPriceId,
      setSelectedPriceId,
      selectedCouponId,
      setSelectedCouponId,
    },

    planOptions,
    initializeCoupons,
    couponOptions,
    couponsLoading,
  },
) => {

  useEffect(() => {
    const selectedOption = planOptions?.find((option) =>
      option.value?.priceId === selectedPriceId);
    if (selectedOption) {
      initializeCoupons(selectedOption.value);
    }
  }, []);

  const planSelectChanged = (option: IPriceSelectOption) => {
    setSelectedPriceId(option.value.priceId);
    initializeCoupons(option.value);
  };

  const couponSelectChanged = (option: ICouponSelectOption) => {
    setSelectedCouponId(option.value.id);
  };

  if (!planOptions) {
    return <></>;
  }

  const selectedPriceOption = planOptions?.find((option) =>
    option.value.priceId === selectedPriceId);

  const selectedCouponOption = couponOptions?.find((option) =>
    option.value.id === selectedCouponId);

  const subscriptionTotals = calculateSubscriptionValues(selectedPriceOption?.value, selectedCouponOption?.value);

  return (
    <div>

      <div className='my-4'>
        <div className='heading bold mb-2'>Plan</div>
        <div className='start-subscription-item-total-container'>
          <Dropdown
            onChange={planSelectChanged}
            options={planOptions}
            value={selectedPriceOption || -1}
            placeholder={'Select a plan'}
            className='start-subscription select-container'
            classNamePrefix='plan select'
            isSearchable={true}
            menuPortalTarget={document.body}
          />
          {selectedPriceOption?.value.unitAmount &&
            <div className='total-text-container heading bold'>
              {getFormattedAmount(
                subscriptionTotals.subscriptionAmount, selectedPriceOption?.value.currency)}
            </div>
          }
        </div>
      </div>

      <div className='mb-4'>
        {couponsLoading &&
          <Loader
            type='ThreeDots'
            color='#FF621D'
            height={50}
            width={50}
          />
        }
        {!couponsLoading && selectedPriceOption &&
          <>
            <div className='heading bold mb-2'>Coupon</div>
            <div className='start-subscription-item-total-container'>
              <Dropdown
                onChange={couponSelectChanged}
                options={couponOptions}
                value={selectedCouponOption || emptyCouponOption}
                placeholder={'Select a coupon'}
                className='start-subscription select-container'
                classNamePrefix='coupon select'
                isSearchable={true}
                menuPortalTarget={document.body}
              />
              <div className='total-text-container heading bold '>
                -{getFormattedAmount(subscriptionTotals.totalDiscount, selectedPriceOption.value.currency)}
              </div>
            </div>
          </>
        }
      </div>

      {selectedPriceOption && selectedPriceOption.value.recurring &&
        <div className='d-flex justify-content-between mb-4'>
          <div className='heading bold mb-2'>
            Total per {selectedPriceOption.value.recurring.interval}
          </div>
          <div className='heading bold mb-2'>
            {getFormattedAmount(subscriptionTotals.totalPayable, selectedPriceOption.value.currency)}
          </div>
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state: IAppStore): Partial<IProps> => ({
  planOptions: planOptionsSelector(state),
  currentGym: currentGymSelector(state),
  couponOptions: couponOptionsSelector(state),
  uid: state.members.data.memberAccountData?.userInfo.profile.uid,
  couponsLoading: state.members.view.offerCouponsLoading,
});

const mapDispatchToProps = {
  initializeCoupons: specialOfferPlanSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanAndCouponSelect);
