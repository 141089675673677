import * as React from 'react';

export const MemberTableError = ({ reload }: { reload: () => void }) => {
  return (<div style={{ paddingTop: 50 }}>
    <p style={{ display: 'inline-block' }}>
      Something has gone wrong.
    </p>
    <button onClick={reload} style={{ display: 'inline-block' }}>Reload?</button>
  </div>);
};
