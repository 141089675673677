import { Stripe, StripeElements, StripePaymentElementOptions } from '@stripe/stripe-js';
import { UserProfile } from 'fitbeat.gym-core';
import {
  IPaymentSetupData,
  IPrice,
  IStripeCouponWrapper,
  ISubscriptionPaymentSummary,
  UserAccountDetail,
  UserAccountScheduleActionInfo,
} from 'fitbeat.models';
import React from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { currentGymSelector } from '../../adminSettings/selector';
import { apiConfig } from '../../app/apiConfig';
import { defaultPaymentMethodDisplayTextSelector } from '../../members';
import ManualCardCapture from '../../members/components/ManualCardCapture';
import Pos from '../../members/components/POS';
import Success from '../../members/components/Success';
import { MembersService } from '../../members/membersService';
import { IAppStore } from '../../rootReducer';
import Checkbox from '../../shared/components/Checkbox';
import Dropdown from '../../shared/components/Dropdown';
import TabDisplay from '../../shared/components/TabDisplay/TabDisplay';
import { getCouponDescription, getFormattedAmount, getPlanDescription } from '../../shared/helpers/formatHelper';
import { AccountActionsService } from '../accountActionsService';
import {
  applyCouponCheckedOnStartSubscription,
  closeStartSubscriptionModal,
  consentCheckedOnStartSubscription,
  couponSelectedOnStartSubscription,
  loadStartSubscriptionModal, loadStripePaymentData,
  planSelectedOnStartSubscription,
  startManualCaptureSubscriptionOnStartSubscription, StartSubscriptionWithPOS,
  subscribeToPlan,
} from '../actions';
import {
  canStartSubscriptionWithDefaultPaymentMethodSelector,
  canStartSubscriptionWithNewPaymentMethodSelector, canStartSubscriptionWithPosSelector,
  getCustomerCouponIfApplicableSelector, getStartSubscriptionCurrency,
  getStartSubscriptionPaymentSummarySelector, getStartSubscriptionPlanDetailsProvidedSelector,
} from '../selector';
import { IStartSubscription } from '../storeTypes';

interface IProps {
  startSubscription: IStartSubscription;
  uid: string;
  accountId: string;
  isLoadingStartSubscription: boolean;
  showStartSubscriptionModal: boolean;
  startSubscriptionError: string | null;
  allowedStartSubscriptionPlans: IPrice[];
  memberAccountShedule: UserAccountScheduleActionInfo;
  startManualCaptureSubscription: (
    uid: string,
    stripe: Stripe | null,
    elements: StripeElements | null,
  ) => void;
  subscribeToPlan: (uid: string) => void;
  handlePlanSelect: (uid: string, plan: IPrice) => void;
  handleCouponSelect: (uid: string, coupon: IStripeCouponWrapper) => void;
  loadStartSubscriptionModal: (uid: string) => void;
  closeModal: () => void;
  handleConsentCheck: () => void;
  handleApplyCouponCheck: (uid: string) => void;
  memberAccountData: UserAccountDetail;
  canStartSubscriptionWithDefaultPaymentMethod: boolean;
  canStartSubscriptionWithNewPaymentMethod: boolean;
  defaultPaymentMethodDisplayText: string | undefined;
  paymentSummary: ISubscriptionPaymentSummary | undefined;
  customerCouponToShow: IStripeCouponWrapper | undefined;
  currency: string | undefined;
  canGiveConsent: boolean;
  successMessage: string | null;
  setupIntent: IPaymentSetupData | undefined;
  loadPaymentMethodData: (uid: string) => void;
  subscribeWithPOS: (uid: string) => void;
  canStartSubscriptionWithPos: boolean;
  showStartSubscriptionSuccessModal: boolean;
}

class StartSubscriptionModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public componentDidMount() {
    this.props.loadStartSubscriptionModal(this.props.uid);
  }

  public handleManualCaptureSubmit = (stripe: Stripe | null, elements: any | null) => {
    const { uid, startManualCaptureSubscription} = this.props;

    startManualCaptureSubscription(
      uid,
      stripe,
      elements,
    );
  }

  public  generateTabDisplay = ():
    {tabTitle: string, tabContents: JSX.Element | undefined}[] => {

    const paymentOptions: StripePaymentElementOptions = {
      terms: {
        auBecsDebit: 'never',
        card: 'never',
      },
    };

    return [
      {
        tabTitle: 'Default Payment Method',
        tabContents:
          <div className='default-payment-method-container'>
            <div className='default-payment-method-text-container'>
                <p className='default-payment-method-text'>
                  {this.props.defaultPaymentMethodDisplayText ??
                    'No default payment method'}</p>
            </div>
            {
              this.renderStartSubscriptionActions(
                () => this.props.subscribeToPlan(this.props.uid),
                this.props.isLoadingStartSubscription,
                this.props.canStartSubscriptionWithDefaultPaymentMethod,
                this.props.successMessage || undefined,
                this.props.startSubscriptionError,
                'Start Subscription',
              )
            }
          </div>,
      },
      {
        tabTitle: 'Card Reader',
        tabContents:
          <div>
            <Pos
              uid={this.props.uid}
              actionText={'Capture Card & Start Subscription'}
              actions={() => this.renderStartSubscriptionActions(
                () => this.props.subscribeWithPOS(this.props.uid),
                this.props.isLoadingStartSubscription,
                this.props.canStartSubscriptionWithPos,
                this.props.successMessage || undefined,
                this.props.startSubscriptionError,
                'Capture Card & Start Subscription',
              )}/>
          </div>,
      },
      {
        tabTitle: 'Manual Capture',
        tabContents:
        <div>
          <ManualCardCapture paymentOptions={paymentOptions}
                             stripe={this.props.startSubscription.stripe}
                             setupIntent={this.props.setupIntent}
                             loadPaymentData={this.loadPaymentData}
                             actions={(stripe: Stripe | null, elements: StripeElements | null) =>
                               this.renderStartSubscriptionActions(
                                 () => this.handleManualCaptureSubmit(stripe, elements),
                                 this.props.isLoadingStartSubscription,
                                 this.props.canStartSubscriptionWithNewPaymentMethod,
                                 this.props.successMessage || undefined,
                                 this.props.startSubscriptionError,
                                 'Start Subscription',
                               )}
          />
        </div>,
      },
    ];
  }

  public render() {
      const {
        startSubscription,
        startSubscription: { paymentSummary },
        memberAccountData: { userInfo },
        currency,
        canGiveConsent,
        startSubscriptionError,
        isLoadingStartSubscription,
        showStartSubscriptionModal,
        closeModal,
        handlePlanSelect,
        handleApplyCouponCheck,
        showStartSubscriptionSuccessModal,
      } = this.props;
      const userProfile = userInfo.profile as UserProfile;
      const couponOptions: { label: string, value: IStripeCouponWrapper | undefined }[]
        = startSubscription.couponsForPlan.map((coupon: IStripeCouponWrapper) => {
        return {
          label: getCouponDescription(coupon),
          value: coupon,
        };
      });

      const planOptions: { label: string, value: IPrice | undefined }[]
        = this.props.allowedStartSubscriptionPlans.map((plan: IPrice) => {
        return {
          label: getPlanDescription(plan),
          value: plan,
        };
      });

      const shouldShowConsentForm = canGiveConsent && startSubscription.paymentSummary;

      return (
        <div className='start-subscription-container'>
          <Modal
            isOpen={showStartSubscriptionModal}
            className='modal-content'
            overlayClassName='modal-overlay'
            ariaHideApp={false}
          >
            {!showStartSubscriptionSuccessModal && <div style={{width: '100%'}}>
              <div className='d-flex justify-content-between overflow-scroll'>
              <h1>Start Subscription</h1>
              <button
                className='modal-close-button'
                onClick={closeModal}
              >
                X
              </button>
            </div>

            <div className='start-subscription-separator'></div>

            {this.props.memberAccountShedule.startSubscriptionInfo && (
              <div>
                <h2>Plan</h2>
                <div className='start-subscription-item-total-container'>
                  <Dropdown
                    onChange={(selected: { label: string; value: IPrice; }) =>
                      handlePlanSelect(this.props.uid, selected.value)}
                    options={planOptions}
                    value={startSubscription.plan ? {
                      label: getPlanDescription(startSubscription.plan),
                      value: startSubscription.plan,
                    } : -1}
                    placeholder={'Select a plan'}
                    className='start-subscription select-container'
                    classNamePrefix='plan select'
                    isSearchable={true}
                    menuPortalTarget={document.body}
                  />
                  {paymentSummary &&
                    <div className='total-text-container'>
                      <h1>
                        {getFormattedAmount(paymentSummary.amount, currency)}</h1>
                    </div>
                  }
                </div>
                {startSubscription.plan &&
                  <div>
                    <h2 className='start-subscription-coupon-header'>Coupon</h2>
                    <Checkbox
                      onClick={() => handleApplyCouponCheck(this.props.uid)}
                      isChecked={startSubscription.couponChecked}
                      labelText='Apply subscription coupon'
                      labelClass='start-subscription-checkbox-label'
                      disabled={
                        !startSubscription.plan ||
                        startSubscription.isLoadingCoupons ||
                        isLoadingStartSubscription
                      }
                    />
                    {this.props.customerCouponToShow && paymentSummary &&
                      <div className='start-subscription-customer-coupon-container'>
                        <p>Customer coupon: {getCouponDescription(this.props.customerCouponToShow)}.
                          Only applies if no subscription coupon.</p>
                        <div className='total-text-container'>
                          <h1>
                            -{getFormattedAmount(paymentSummary.amountOff,
                              currency)}</h1>
                        </div>
                      </div>
                    }
                    {startSubscription.couponChecked &&
                      <div className='start-subscription-item-total-container'>
                        <Dropdown
                          onChange={(selected: { label: string; value: IStripeCouponWrapper; }) =>
                            this.props.handleCouponSelect(this.props.uid, selected.value)}
                          options={couponOptions}
                          value={startSubscription.coupon ? {
                            label: getCouponDescription(startSubscription.coupon),
                            value: startSubscription.coupon,
                          } : -1}
                          placeholder={startSubscription.couponPlaceholder}
                          className='start-subscription select-container'
                          classNamePrefix='coupon select'
                          isSearchable={true}
                          isDisabled={!startSubscription.plan || startSubscription.isLoadingCoupons}
                          menuPortalTarget={document.body}
                        />
                        {paymentSummary &&
                          <div className='total-text-container'>
                            <h1>-{getFormattedAmount(paymentSummary.amountOff,
                              currency)}</h1>
                          </div>
                        }
                      </div>
                    }
                    {paymentSummary &&
                      <div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
                          <h1 style={{ width: '100%' }}>Total per {paymentSummary.interval} (while coupon applies)</h1>
                          <div className='total-text-container'>
                            <h1 style={{ justifyContent: 'end' }}>
                              {getFormattedAmount(Number(paymentSummary.total), currency)}</h1>
                          </div>
                        </div>
                        <p className='start-subscription-terms'>Subscription will start immediately and customer will be
                          charged the prorated amount for the rest of the {paymentSummary.interval}. </p>
                      </div>
                    }
                  </div>
                }

                {shouldShowConsentForm &&
                  <div>
                    <div className='start-subscription-separator'></div>

                    <div>
                      <h2>Member Consent</h2>
                      <Checkbox
                        labelClass='start-subscription-checkbox-label'
                        onClick={() => this.props.handleConsentCheck()}
                        isChecked={startSubscription.consentChecked}
                        labelText='The member has given written consent to start this plan.'
                      />
                    </div>
                  </div>
                }
                </div>
            )}

            <div className='start-subscription-separator'></div>
              {!startSubscription.stripe &&
                <div>
                  {startSubscriptionError ? (
                      <div style={{ textAlign: 'right' }}>
                        <p className='error'>{startSubscriptionError}</p>
                      </div>
                    ) :
                    <div style={{ textAlign: 'right' }}>
                      <Loader
                        type='ThreeDots'
                        color='#FF621D'
                        height={50}
                        width={50}
                      />
                    </div>
                  }
                </div>
              }
            {startSubscription.stripe &&
              <div>
                <h1>Payment method</h1>
                <div>
                  <TabDisplay
                    tabHeaderClass='nav payment-method-nav-tabs'
                    tabContentClass='tab-content-container'
                    tabItemsWithDisplay={this.generateTabDisplay()}
                  />
                </div>
              </div>
            }
            </div>}

          {showStartSubscriptionSuccessModal && <Success close={closeModal} header={'Subscription and payment' +
            ' successful!'} />}
          </Modal>
        </div>
      );
    }

  private loadPaymentData = () => {
    this.props.loadPaymentMethodData(this.props.uid);
  }

  private renderStartSubscriptionActions = (
    submit: () => void,
    isLoading: boolean,
    enabled: boolean,
    success: string | undefined,
    error: string | null,
    buttonText: string,
  ) => {
    return (
      <div>
        <div className='start-subscription-separator'></div>
        {success &&
          <div style={{ textAlign: 'right' }}>
            <p className='success'>{success}</p>
          </div>
        }
        {error && (
          <div style={{ textAlign: 'right' }}>
            <p className='error'>{error}</p>
          </div>
        )}
        {isLoading ? (
          <div style={{ textAlign: 'right', marginRight: 50 }}>
            <Loader
              type='ThreeDots'
              color='#FF621D'
              height={50}
              width={50}
            />
          </div>
        ) : (
          <div>
            <div style={{ textAlign: 'right' }}>
              <button
                className='jr-btn-default start-subscription'
                disabled={!enabled}
                onClick={submit}
              >{buttonText}</button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  const accountActionService = new AccountActionsService(apiConfig);
  const membersService = new MembersService(apiConfig);

  return {
    subscribeToPlan: (uid: string) =>
      dispatch(subscribeToPlan(accountActionService, membersService, uid)),
    startManualCaptureSubscription: (
      uid: string,
      stripe: Stripe | null,
      elements: StripeElements | null,
    ) =>
      dispatch(startManualCaptureSubscriptionOnStartSubscription(
        accountActionService,
        membersService,
        uid,
        stripe,
        elements,
      )),
    subscribeWithPOS: (uid: string) => dispatch(StartSubscriptionWithPOS(accountActionService, membersService, uid)),
    handlePlanSelect: (uid: string, plan: IPrice) =>
      dispatch(planSelectedOnStartSubscription(accountActionService, uid, plan)),
    handleCouponSelect: (uid: string, coupon: IStripeCouponWrapper) =>
      dispatch(couponSelectedOnStartSubscription(accountActionService, uid, coupon)),
    handleApplyCouponCheck: (uid: string) => dispatch(applyCouponCheckedOnStartSubscription(accountActionService, uid)),
    handleConsentCheck: () => dispatch(consentCheckedOnStartSubscription()),
    loadStartSubscriptionModal: (uid: string) =>
      dispatch(loadStartSubscriptionModal(accountActionService, uid)),
    closeModal: () => dispatch(closeStartSubscriptionModal()),
    loadPaymentMethodData: (uid: string) => dispatch(loadStripePaymentData(uid, accountActionService)),
  };
};

const mapStateToProps = (state: IAppStore) => {
  const { accountActions, members } = state;
  const {
    showStartSubscriptionModal,
    isLoadingStartSubscription,
    startSubscriptionError,
    successMessage,
    showStartSubscriptionSuccessModal,
  } = accountActions.view;

  const { setupIntent } = accountActions.data;

  const { memberAccountShedule, startSubscription } = accountActions.data;
  const { allowedStartSubscriptionPlans } = accountActions.view;
  const canStartSubscriptionWithNewPaymentMethod = canStartSubscriptionWithNewPaymentMethodSelector(state);
  const canStartSubscriptionWithDefaultPaymentMethod = canStartSubscriptionWithDefaultPaymentMethodSelector(state);
  const canStartSubscriptionWithPos = canStartSubscriptionWithPosSelector(state);
  const defaultPaymentMethodDisplayText = defaultPaymentMethodDisplayTextSelector(state);
  const { memberAccountData } = members.data;
  const accountId = currentGymSelector(state).accountId;
  const paymentSummary: ISubscriptionPaymentSummary | undefined = getStartSubscriptionPaymentSummarySelector(state);
  const customerCoupon: IStripeCouponWrapper | undefined = getCustomerCouponIfApplicableSelector(state);
  const currency = getStartSubscriptionCurrency(state);
  const canGiveConsent = getStartSubscriptionPlanDetailsProvidedSelector(state);

  return {
    showStartSubscriptionModal,
    showStartSubscriptionSuccessModal,
    isLoadingStartSubscription,
    startSubscriptionError,
    memberAccountShedule,
    allowedStartSubscriptionPlans,
    startSubscription,
    canStartSubscriptionWithDefaultPaymentMethod,
    canStartSubscriptionWithNewPaymentMethod,
    defaultPaymentMethodDisplayText,
    memberAccountData,
    accountId,
    paymentSummary,
    customerCouponToShow: customerCoupon,
    currency,
    canGiveConsent,
    successMessage,
    setupIntent,
    canStartSubscriptionWithPos,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartSubscriptionModal);
