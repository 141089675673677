import React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { IAppStore } from '../../rootReducer';
import { getEmailToSMSDomain } from '../../util/helpers';

import { currentGymSelector } from '../../adminSettings/selector';

import { Gym } from 'fitbeat.models';

// todo: setup up proper types for member details and reuse here
interface IPartialMemberDetailsForThisComponentOnly {
  Email: string;
  Name: string;
  'Mobile Number'?: string;
}

interface IProps {
  isSmsCopy: boolean;
  buttonText: string;
  idToUse: string;
  exportData?: IPartialMemberDetailsForThisComponentOnly[];
  currentGym: Gym;
}

interface IState {
  showCopiedTip: boolean;
}

class CopyReportSmsEmails extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showCopiedTip: false,
    };
  }

  public mapDataToCopy = () => {
    this.setState({ showCopiedTip: true });
    const toBeCoppiedUserList: string[] = [];
    this.props.exportData?.forEach((singleMemberDetails: IPartialMemberDetailsForThisComponentOnly) => {
      if (!this.props.isSmsCopy) {
        toBeCoppiedUserList.push(`${singleMemberDetails.Name} <${singleMemberDetails.Email}>`);
      }
      if (this.props.isSmsCopy && singleMemberDetails['Mobile Number']?.trim()) {
        const emailToSMSDomain = getEmailToSMSDomain(this.props.currentGym);
        toBeCoppiedUserList.push(
          `${singleMemberDetails.Name} <${singleMemberDetails['Mobile Number']}@${emailToSMSDomain}>`,
        );
      }
    });
    const mappedDataAsString = toBeCoppiedUserList.join(',');
    // todo: try to use TextCopyToClipBoard, or extract helper
    navigator.clipboard.writeText(mappedDataAsString)
      .catch((e) => { /* no-op, this should show/log error */});
    setTimeout(() => {
      this.setState({ showCopiedTip: false });
    }, 1000);
  }

  public render() {
    const { showCopiedTip } = this.state;
    const { buttonText, idToUse } = this.props;
    return (
      <div
        className='settings'
        style={{ display: 'inline-block' }}
        id={idToUse}>
        <button
          className='jr-btn-no-border black-color-text'
          onClick={this.mapDataToCopy}>
          <img
            className='btnImg showOrHideColumnIconContainer'
            src='resources/images/copy-icon-curved-edges.svg'
          />
          {buttonText}
        </button>
        <Tooltip placement={'top'} isOpen={showCopiedTip} target={idToUse}>
          Copied!
        </Tooltip>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppStore) => {
  const { adminSettings } = state;
  const { exportData } = adminSettings.data;
  const currentGym = currentGymSelector(state);
  return { exportData, currentGym};
};

export default connect(mapStateToProps, null)(CopyReportSmsEmails);
