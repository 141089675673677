import { BigBodyGroup, getWorkoutDuration, WorkoutType } from 'fitbeat.gym-core';
import { Equipment, Gym, GymTypes, RemovedReasonType } from 'fitbeat.models';
import moment from 'moment-timezone';
import React from 'react';
import { GymResolver } from '../../util/GymResolver';
import {
  aggregateFilterSelectHandler,
  cardioOrResistanceFilter,
  dataDefinedSelect,
  filterSkillLevel,
  ignoreProgramRecommendationsFilter,
  stringArrayToOptions,
  userWorkoutHistoryClassStatusFilter,
} from '../components/activeFilter';
import DatePickerPreDefinedDays from '../components/datePickerPreDefinedDays';
import GreaterThanLessThanFilter from '../components/greaterThanLessThanFilter';
import { MemberTableHeader } from '../components/memberTableHeader';
import { getCardioOrResistanceMapping, getSkillLevelMapping } from '../config';
import { filterMethodContainsOrDoesntContain, getDatesForPredefinedDayFilter } from './filterHelpers';

const formatToYYYYMMDD = (date: any): string =>
  moment(date).format('YYYY-MM-DD');

const getSlotTime = (workoutType: WorkoutType) => {
  return workoutType === WorkoutType.FullStudioWorkoutWithPreWarmupPause ? '48' : '28';
};

export const buildWorkoutConfigColumnsFromAccessor = (currentGym: Gym) => [
  {
    id: 'classDate',
    Header: () => <MemberTableHeader header='Class Date' />,
    accessor: 'classDate',
    used: true,
    headerClassName: 'container-overflow-visible',
    filterable: true,
    sortable: true,
    width: 220,
    filterMethod: (filter: any, row: any) => {
      let startDate = filter.value.startDate;
      let endDate = filter.value.endDate;
      const predefinedDayFilterDate = getDatesForPredefinedDayFilter(filter.value);
      if (predefinedDayFilterDate) {
        startDate = predefinedDayFilterDate.startDate;
        endDate = predefinedDayFilterDate.endDate;
      }
      const startTimeString = moment
        .tz(row._original.startTime, currentGym.timezone)
        .format('YYYY-MM-DD');
      const startTime = moment(startTimeString);

      if (
        startDate &&
        startTime.isBefore(formatToYYYYMMDD(startDate))
      ) {
        return false;
      }
      if (
        endDate &&
        startTime.isAfter(formatToYYYYMMDD(endDate))
      ) {
        return false;
      }
      return true;
    },
    Filter: ({ filter, onChange }: any) => <DatePickerPreDefinedDays
          filter={filter}
          onChange={onChange}
          type={'classDate'}
          />,
    Cell: ({ original }: any) =>
      moment.tz(original.startTime, currentGym.timezone).format('YYYY-MM-DD'),
  },
  {
    id: 'classTime',
    Header: () => <MemberTableHeader header='Class Time' />,
    accessor: 'classTime',
    used: true,
    headerClassName: 'container-overflow-visible',
    filterable: true,
    sortable: true,
    width: 120,
    filterMethod: (filter: any, row: any) => {
      const { startTimeOfFirstExercise } = row._original;
      return (
        moment
          .tz(startTimeOfFirstExercise, currentGym.timezone)
          .format('HH:mm')
          .indexOf(filter.value) !== -1
      );
    },
    Cell: ({ original }: any) =>
      moment
        .tz(original.startTimeOfFirstExercise, currentGym.timezone)
        .format('HH:mm'),
  },
  {
    id: 'minutesInWorkout',
    Header: () => <MemberTableHeader header='Minutes in workout' />,
    accessor: 'minutesInWorkout',
    used: true,
    filterMethod: (filter: any, row: any) => {
      const updatedRow = {...row};
      return aggregateFilterSelectHandler(filter, updatedRow);
    },
    Filter: ({ filter, onChange }: any) => <GreaterThanLessThanFilter
      onChange={onChange}
      columnId='minutesInWorkout'>
    </GreaterThanLessThanFilter>,
    headerClassName: 'container-overflow-visible',
    filterable: true,
    sortable: true,
    Cell: ({ original }: any) => {
      return getWorkoutActiveDurationForColumn(original);
    },
    width: 220,
  },
  {
    id: 'classDuration',
    Header: () => <MemberTableHeader header='Class Duration' />,
    accessor: 'classDuration',
    used: true,
    filterMethod: (filter: any, row: any) => {
      const updatedRow = {...row};
      updatedRow[filter.id] = getWorkoutDurationForColumn(row);
      return aggregateFilterSelectHandler(filter, updatedRow);
    },
    Filter: ({ filter, onChange }: any) => <GreaterThanLessThanFilter
      onChange={onChange}
      columnId='classDuration'>
    </GreaterThanLessThanFilter>,
    headerClassName: 'container-overflow-visible',
    filterable: true,
    sortable: true,
    Cell: ({ original }: any) => {
      return getWorkoutDurationForColumn(original);
    },
    width: 220,
  },
  {
    id: 'removedAt',
    Header: () => <MemberTableHeader header='Class State' />,
    used: true,
    Filter: ({ filter, onChange }: any) =>
      userWorkoutHistoryClassStatusFilter(filter, onChange),
    filterMethod: (filters: any, row: any) => {
      const { endTime, removedAt, startTime, removedReason } = row._original;
      const isFutureBooking = moment(endTime).isAfter(moment()) && !removedAt;
      const isCompleted = moment(endTime).isBefore(moment()) && !removedAt;
      const isNow = isFutureBooking && moment(startTime).isBefore(moment()) && !removedAt;
      if (filters.value.length === 4) {
        return true;
      }

      for (const filter of filters.value) {
        if (filter === 'current' && isNow) {
          return true;
        }

        if (filter === 'future' && isFutureBooking) {
          return true;
        }

        if (filter === 'completed' && isCompleted) {
          return true;
        }

        if (filter === 'missed' && removedAt &&
          (removedReason && removedReason ===  RemovedReasonType.Missed)) {
          return true;
        }

        if (filter === 'canceled' && removedAt &&
          (removedReason && removedReason ===  RemovedReasonType.Cancelled)) {
          return true;
        }
        if (filter === 'notCompleted'
          && removedAt
          && removedReason === RemovedReasonType.NotCompleted
        ) {
          return true;
        }
      }
      return false;
    },
    accessor: 'removedAt',
    headerClassName: 'container-overflow-visible',
    Cell: ({ original }: any) => {
      const isFutureBooking =
        moment(original.endTime).isAfter(moment()) && !original.removedAt;
      const isCompleted =
        moment(original.endTime).isBefore(moment()) && !original.removedAt;
      const isNow = isFutureBooking && moment(original.startTime).isBefore(moment()) && !original.removedAt;
      const isMissed = original.removedAt &&
        (original.removedReason && original.removedReason ===  RemovedReasonType.Missed);
      const isCancelled = original.removedAt &&
        (original.removedReason && original.removedReason ===  RemovedReasonType.Cancelled);
      const isNotCompleted = original.removedAt &&
        (original.removedReason && original.removedReason ===  RemovedReasonType.NotCompleted);
      if (isNow) {
        return 'Current';
      }
      if (isFutureBooking) {
        return 'Future';
      }
      if (isCompleted) {
        return 'Completed';
      }
      if (isMissed) {
        return 'Missed';
      }
      if (isCancelled) {
        return 'Canceled';
      }
      if (isNotCompleted) {
        return 'Not Completed';
      }
      if (original.removedAt && !original.removedReason) {
        return 'Canceled';
      }
      return '';
    },
    sortable: true,
    width: 100,
  },
  {
    id: 'gymName',
    Header: () => <MemberTableHeader header='Location' />,
    accessor: 'gymName',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'gymName'),
    Cell: ({ original }: any) => {
      return `${original.gymName || 'Unassigned'}`;
    },
    filterAll: true,
    filterable: true,
    width: 100,
    sortable: true,
  },
  {
    id: 'ignoreProgramRecommendations',
    Header: () => <MemberTableHeader header='Customised' />,
    accessor: 'ignoreProgramRecommendations',
    used: true,
    Filter: ({ filter, onChange }: any) => {
      return ignoreProgramRecommendationsFilter(filter, onChange);
    },
    headerClassName: 'container-overflow-visible',
    filterMethod: (filters: any, row: any) => {
      const { ignoreProgramRecommendations } = row._original;
      if (filters.value.length === 4) {
        return true;
      }

      for (const filter of filters.value) {
        if (filter === 'ignored' && ignoreProgramRecommendations) {
          return true;
        }
        if (filter === 'NotIgnored' && !ignoreProgramRecommendations) {
          return true;
        }
      }
      return false;
    },
    Cell: ({ original }: any) => {
      return original.settings?.ignoreProgramRecommendations ? 'Yes' : 'No';
    },
    filterable: true,
    sortable: true,
    width: 100,
  },
  {
    id: 'cardioOrResistance',
    Header: () => <MemberTableHeader header='Build/Burn/Balance' />,
    accessor: 'cardioOrResistance',
    used: true,
    Filter: ({ filter, onChange }: any) => {
      return cardioOrResistanceFilter(filter, onChange);
    },
    Cell: ({ original }: any) => {
      return getCardioOrResistanceMapping(original.settings?.cardioOrResistance);
    },
    filterMethod: (filters: any, row: any) => {
      const { settings } = row._original;
      if (filters.value.length === 4) {
        return true;
      }

      for (const filter of filters.value) {
        if (filter === settings?.cardioOrResistance) {
          return true;
        }
      }
      return false;
    },
    filterable: true,
    sortable: true,
    width: 80,
    headerClassName: 'container-overflow-visible',
  },
  {
    Header: () => <MemberTableHeader header='Workout Skill Level' />,
    accessor: 'workoutLevel',
    id: 'workoutLevel',
    used: true,
    Filter: ({ filter, onChange }: any) => filterSkillLevel(filter, onChange),
    filterMethod: (filters: any, row: any) => {
      const { settings } = row._original;
      if (filters.value.length === 4) {
        return true;
      }

      for (const filter of filters.value) {
        if (filter === settings?.level) {
          return true;
        }
      }
      return false;
    },
    Cell: ({ original }: any) => {
      return getSkillLevelMapping(original.settings?.level);
    },
    headerClassName: 'container-overflow-visible',
    filterable: true,
    sortable: true,
    width: 100,
  },
  {
    id: 'threeBodyGroups',
    Header: () => <MemberTableHeader header='Body Groups' />,
    accessor: 'threeBodyGroups',
    used: true,
    Filter: ({ filter, onChange }: any) =>
      dataDefinedSelect(filter, onChange, stringArrayToOptions([...Object.values(BigBodyGroup), 'Full Body'])),
    filterMethod: (filters: any, row: any) => {
      const { settings } = row._original;
      if (!filters.value.length) {
        return false;
      }
      if (filters.value.length === [...Object.values(BigBodyGroup), 'Full Body'].length) {
        return true;
      }
      if (filters.value.join('') === 'Full Body' && settings?.threeBodyGroups.length >= 3) {
        return true;
      }
      const threeBodyGroups = settings?.threeBodyGroups;
      if (filters.value && threeBodyGroups) {
        const sortedFilterValues = [...filters.value].sort().join('');
        const sortedSelectedValues = [...threeBodyGroups].sort().join('');

        const rowWithFilter = sortedFilterValues === sortedSelectedValues;
        if (rowWithFilter) {
          return true;
        }
      }
      return false;
    },
    Cell: ({ original }: any) => {
      return ` ${original?.settings?.threeBodyGroups.length >= 3 ?
        'Full Body' :
        original?.settings?.threeBodyGroups.join(', ')}`;
    },
    headerClassName: 'container-overflow-visible',
    filterable: true,
    sortable: true,
    width: 140,
  },
  {
    id: 'equipment',
    Header: () => <MemberTableHeader header='Equipment' />,
    accessor: 'equipment',
    used: true,
    Filter: ({ filter, onChange }: any) =>
      dataDefinedSelect(filter, onChange, stringArrayToOptions(Object.values(Equipment))),
    filterMethod: (filters: any, row: any) => {
      const { settings } = row._original;
      if (filters.value.length === Object.values(Equipment).length) {
        return true;
      }
      if (settings?.equipment) {
        for (const filter of filters.value) {
          const rowWithFilter = settings.equipment.find((item: any) => item === filter);
          if (rowWithFilter) {
            return true;
          }
        }
      }
      return false;
    },
    headerClassName: 'container-overflow-visible',
    Cell: ({ original }: any) => {
      return original?.settings?.equipment &&
        original.settings.equipment.length > 0
        ? `${original.settings.equipment.join(', ')}`
        : 'None';
    },
    sortable: true,
    filterable: true,
    width: 150,
  },
  {
    id: 'memberMainGym',
    Header: () => <MemberTableHeader header='Member of' />,
    accessor: 'memberMainGym',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'memberMainGym'),
    Cell: ({ original }: any) => {
      return `${original.memberMainGym || 'Unassigned'}`;
    },
    filterAll: true,
    filterable: true,
    width: 100,
    sortable: true,
  },
  {
    id: 'calories',
    Header: () => <MemberTableHeader header='Calories' />,
    accessor: 'calories',
    used: true,
    headerClassName: 'container-overflow-visible',
    filterMethod: (filters: any, row: any) => {
      const { performanceMetrics } = row._original;
      const matches = `${performanceMetrics?.calories}`.includes(filters.value);
      if (matches) {
        return true;
      }
      return false;
    },
    Cell: ({ original }: any) => {
      return `${
        original?.performanceMetrics?.calories >= 0
          ? original?.performanceMetrics?.calories
          : 'None'
      }`;
    },
    filterable: true,
    sortable: true,
    width: 100,
  },
  {
    id: 'load',
    Header: () => <MemberTableHeader header='Points' />,
    accessor: 'load',
    used: true,
    filterMethod: (filters: any, row: any) => {
      const { performanceMetrics } = row._original;
      const matches = `${performanceMetrics?.load}`.includes(filters.value);
      if (matches) {
        return true;
      }
      return false;
    },
    Cell: ({ original }: any) => {
      return `${
        original?.performanceMetrics?.load >= 0
          ? original?.performanceMetrics?.load
          : 'None'
      }`;
    },
    filterable: true,
    sortable: true,
    width: 100,
  },
  {
    id: 'averageEffort',
    Header: () => <MemberTableHeader header='AVG HR%' />,
    accessor: 'averageEffort',
    used: true,
    filterMethod: (filters: any, row: any) => {
      const { performanceMetrics } = row._original;
      const matches = `${performanceMetrics?.averageEffort}`.includes(filters.value);
      if (matches) {
        return true;
      }
      return false;
    },
    Cell: ({ original }: any) => {
      return `${
        original?.performanceMetrics?.averageEffort >= 0
          ? original?.performanceMetrics?.averageEffort
          : 'None'
      }`;
    },
    filterable: true,
    sortable: true,
    width: 100,
  },
  {
    id: 'workoutSelectionId',
    Header: () => <MemberTableHeader header='Workout Type' />,
    accessor: 'workoutSelectionId',
    used: true,
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'workoutSelectionId'),
    Cell: ({ original }: any) => (
      <div
        className='workout-id-cell-container'
        onClick={(e: React.MouseEvent) => {
          handleCopyIdEvent(original.workoutSelectionId, e);
        }}>
        <span className='workout-id-tooltip-text'>Click to copy</span>
        <span
          className='workout-id-tooltip-copied-text'
          id={original.workoutSelectionId}>
          Copied
        </span>
        {original.workoutSelectionId}
      </div>),
    filterAll: true,
    filterable: true,
    width: 120,
    sortable: true,
  },
  {
    id: 'uniqueReference',
    Header: () => <MemberTableHeader header='Workout Id' />,
    Cell: ({ original }: any) => (
      <div
        className='workout-id-cell-container'
        onClick={(e: React.MouseEvent) => {
          handleCopyIdEvent(original.uniqueReference, e);
        }}>
        <span className='workout-id-tooltip-text'>Click to copy</span>
        <span
          className='workout-id-tooltip-copied-text'
          id={original.uniqueReference}>
          Copied
        </span>
        {original.uniqueReference}
      </div>
    ),
    accessor: 'uniqueReference',
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'uniqueReference'),
    used: true,
    filterAll: true,
    filterable: true,
    sortable: false,
    width: 180,
  },
  {
    id: 'bookingSource',
    Header: () => <MemberTableHeader header='Booking Source' />,
    accessor: 'bookingSource',
    filterMethod: (filter: any, rows: any) =>
      filterMethodContainsOrDoesntContain(filter, rows, 'bookingSource'),
    used: true,
    filterAll: true,
    filterable: true,
    sortable: false,
    width: 180,
  },
];

function getWorkoutDurationForColumn(row: any) {
  let totalWorkoutDuration = 'None';
  const workoutTypeData = row.workoutType || row._original.workoutType;
  const gym = GymResolver.getByNameOrNull(row.gymName);
  if (gym?.type === GymTypes.Home) {
    totalWorkoutDuration = `${Math.floor(getWorkoutDuration(workoutTypeData).workoutDurationInMinutes)}`;
  } else {
    totalWorkoutDuration = getSlotTime(workoutTypeData);
  }
  return totalWorkoutDuration;
}

function getWorkoutActiveDurationForColumn(row: any) {
    if (row.removedAt || !row.checkedIn) {
        return 0;
    }

    const startTimeOfFirstExerciseInMs = new Date(row.startTimeOfFirstExercise).getTime();
    const endTimeOfWorkoutInMs = new Date(row.endTime).getTime();
    const additionalSecondsBeforeFirstExerciseInMs = ((row.additionalSecondsBeforeFirstExercise || 0) * 1000);

    const totalMinutes = Math.round(
      (endTimeOfWorkoutInMs - startTimeOfFirstExerciseInMs - additionalSecondsBeforeFirstExerciseInMs) / 60000,
    );

    return totalMinutes;
}

function handleCopyIdEvent(
  toCopyText: string,
  e: React.MouseEvent<Element, MouseEvent>,
) {
  // todo: try to use TextCopyToClipBoard, or extract helper
  navigator.clipboard.writeText(toCopyText)
    .catch((err) => { /* no-op. this should log/show error */ });
  const elementToUpdate = document.getElementById(toCopyText);
  if (elementToUpdate) {
    elementToUpdate.style.visibility = 'visible';
    setTimeout(() => {
      if (elementToUpdate) {
        elementToUpdate.style.visibility = 'hidden';
      }
    }, 1000);
  }
  e.preventDefault();
}
