import { IMemberDetailsData } from 'fitbeat.gym-core';

export interface IMemberStripeData {
    memberDetailsData: IMemberDetailsData[];
    stripeKey: string;
    plans: any[];
    pausePlansWithPaidFirst: any[];
    customer: any;
}
export interface IMemberStripeState {
    data: IMemberStripeData;
    view: IMemberStripeUIView;
}

export interface IMemberStripeUIView {
    error: null | string;
    isFetching: boolean;
    showStripeKeyModal: boolean;
    tempStripeKeyStore: string;
    showStripeActions: boolean;
    showSubscriptionsModal: boolean;
}

export function groupBy<T extends any, K extends keyof T>(array: T[], key: K | ((obj: T) => string)):
Record<string, T[]> {
    const keyFn = key instanceof Function ? key : (obj: T) => obj[key];
    return array.reduce(
      (objectsByKeyValue, obj) => {
        const value = keyFn(obj);
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      },
      {} as Record<string, T[]>,
    );
  }

export const initialState: IMemberStripeState = {
    data: {
        memberDetailsData: [],
        stripeKey: '',
        plans: [],
        pausePlansWithPaidFirst: [],
        customer: {},
    },
    view: {
        error: null,
        isFetching: false,
        showStripeKeyModal: false,
        tempStripeKeyStore: '',
        showStripeActions: false,
        showSubscriptionsModal: false,
    },
};
