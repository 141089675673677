import { ILeaderboardConfig } from 'fitbeat.models';
import React, { CSSProperties } from 'react';
import Checkbox from '../../shared/components/Checkbox';

interface IProps {
  formData: ILeaderboardConfig;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const LeaderboardConfigAdvancedOptions: React.FC<IProps> = ({ formData, handleCheckboxChange }) => (
  <div style={styles.formColumn}>
    <h3 style={styles.columnHeader} className='leaderboard-create-headers'>
      Advanced Options
    </h3>
    <div>
      <Checkbox
        onClick={() => handleCheckboxChange({
          target: {
            name: 'showInStudio',
            checked: !formData.showInStudio,
          },
        } as React.ChangeEvent<HTMLInputElement>)}
        isChecked={formData.showInStudio}
        labelText='Show in Studio'
        labelClass=''
      />
      <Checkbox
        onClick={() => handleCheckboxChange({
          target: {
            name: 'requireOptIn',
            checked: !formData.requireOptIn,
          },
        } as React.ChangeEvent<HTMLInputElement>)}
        isChecked={formData.requireOptIn}
        labelText='Require Opt In'
        labelClass=''
      />
      <div className={`${formData.metric === 'average-minutes' ? '' : 'hidden-option'}`}>
        <Checkbox
          onClick={() => handleCheckboxChange({
            target: {
              name: 'countZeroScores',
              checked: !formData.countZeroScores,
            },
          } as React.ChangeEvent<HTMLInputElement>)}
          isChecked={!!formData.countZeroScores}
          labelText='Non-Active Users Considered in Score Total'
          labelClass=''
        />
      </div>
    </div>
  </div>
);

const styles: { [key: string]: CSSProperties } = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  headerTitle: {
    flex: 1,
    margin: 0,
  },
  headerButton: {
    marginLeft: 'auto',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    fontSize: '1.5rem',
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '6px',
    gap: '20px',
  },
  formColumn: {
    marginRight: '16px',
  },
  inputLabel: {
    display: 'block',
    fontWeight: 'bold',
  },
  inputField: {
    width: '100%',
    padding: '8px',
    marginBottom: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  buttonRow: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  discardButton: {
    padding: '8px 16px',
    marginRight: '16px',
    border: '1px solid orange',
    borderRadius: '4px',
    background: 'none',
    color: 'orange',
    cursor: 'pointer',
  },
  createButton: {
    padding: '8px 16px',
    borderRadius: '4px',
    background: 'orange',
    color: 'white',
    cursor: 'pointer',
  },
  columnHeader: {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  teamRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  deleteButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  addButton: {
    padding: '8px 16px',
    borderRadius: '4px',
    background: 'orange',
    color: 'white',
    cursor: 'pointer',
    marginTop: '8px',
  },
};

export default LeaderboardConfigAdvancedOptions;
