import { CardioOrResistance, Level } from 'fitbeat.gym-core';
import { Gender, ProgramType, TimeSlotType } from 'fitbeat.models';
import React from 'react';
import MultiSelect from 'react-multi-select-component';
import { getCardioOrResistanceMapping, getSkillLevelMapping } from '../config';
import { leadStatusDropdownValues } from '../LeadStatusDropdown';

const getOnSelect = (onChange: any) => (option: any) => {
  const optionValue = option.map((o: any) => {
    return o.value;
  });
  onChange(optionValue);
};

const valuesToOptions = (options: any, filterValues: any) => {
  return options.filter((option: any) => {
    return filterValues.indexOf(option.value) !== -1;
  });
};

export const stringArrayToOptions = (values: string[]) => {
  let listToUse = values;
  if (values.length) {
    listToUse = values.sort((a, b) => {
      const valueA = a.toUpperCase(); // ignore upper and lowercase
      const valueB = b.toUpperCase(); // ignore upper and lowercase
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
  }
  return listToUse.map((s: string) => ({
    value: s,
    label: s,
  }));
};

export const filterStatus = (filter: any, onChange: any) => {
  const options = [
    { label: 'Active', value: 'active' },
    { label: 'Cancelled', value: 'canceled' },
    { label: 'Unassigned', value: 'Unassigned' },
    { label: 'Trialing', value: 'trialing' },
    { label: 'Unpaid', value: 'unpaid' },
    { label: 'Passed Due', value: 'past_due' },
  ];

  return (
      <MultiSelect
        options={options}
        value={filter ? valuesToOptions(options, filter.value) : options}
        onChange={getOnSelect(onChange)}
        labelledBy={'Select'}
        className='multi-select'
      />
  );
};

export const filterGender = (filter: any, onChange: any) => {
  const options = [
    { label: Gender.Female, value: Gender.Female },
    { label: Gender.Male, value: Gender.Male},
    { label: Gender.Other, value: Gender.Other},
    { label: 'Unassigned', value: 'Unassigned'},
  ];

  return (
    <MultiSelect
    options={options}
    value={filter ? valuesToOptions(options, filter.value) : options}
    onChange={getOnSelect(onChange)}
    labelledBy={'Select'}
  />);
};

export const dataDefinedSelect = (filter: any, onChange: any, options: {
  value: string,
  label: string,
}[]) => {
  return (
    <MultiSelect
    options={options}
    value={filter ? valuesToOptions(options, filter.value) : options}
    onChange={getOnSelect(onChange)}
    labelledBy={'Select'}
  />);
};

export const filterGoal = (filter: any, onChange: any) => {

  const options = [
    { label: ProgramType.NotSet, value: ProgramType.NotSet },
    { label: ProgramType.GetLeanAndFitGoal, value: ProgramType.GetLeanAndFitGoal },
    { label: ProgramType.GainMuscleAndStrengthGoal, value: ProgramType.GainMuscleAndStrengthGoal },
    { label: ProgramType.GetStrongAndFitGoal, value: ProgramType.GetStrongAndFitGoal },
    { label: ProgramType.MobilityAndTone, value: ProgramType.MobilityAndTone },
    { label: ProgramType.MuscleAndShred, value: ProgramType.MuscleAndShred },
    { label: 'Unassigned', value: 'Unassigned'},
  ];
  return (
    <MultiSelect
    options={options}
    value={filter ? valuesToOptions(options, filter.value) : options}
    onChange={getOnSelect(onChange)}
    labelledBy={'Select'}
  />);
};

export const filterTimeSlotType = (filter: any, onChange: any) => {

  const options = [
    { label: TimeSlotType.None, value: TimeSlotType.None },
    { label: TimeSlotType.Peak, value: TimeSlotType.Peak},
    { label: TimeSlotType.OffPeak, value: TimeSlotType.OffPeak},
    { label: TimeSlotType.AnyTime, value: TimeSlotType.AnyTime},
    { label: 'Unassigned', value: 'Unassigned'},
  ];

  return (
    <MultiSelect
    options={options}
    value={filter ? valuesToOptions(options, filter.value) : options}
    onChange={getOnSelect(onChange)}
    labelledBy={'Select'}
  />);
};

export const filterSkillLevel = (filter: any, onChange: any) => {
  const options = [
    { label: getSkillLevelMapping(Level.Beginner), value: Level.Beginner },
    { label: getSkillLevelMapping(Level.Basic), value: Level.Basic },
    { label: getSkillLevelMapping(Level.Intermediate), value: Level.Intermediate },
    { label: 'Unassigned', value: 'Unassigned'},
  ];

  return (
    <MultiSelect
    options={options}
    value={filter ? valuesToOptions(options, filter.value) : options}
    onChange={getOnSelect(onChange)}
    labelledBy={'Select'}
    className='multi-select'
  />);
};

export const cardioOrResistanceFilter = (filter: any, onChange: any) => {
  const options = [
    { label: getCardioOrResistanceMapping(CardioOrResistance.Cardio), value: CardioOrResistance.Cardio },
    { label: getCardioOrResistanceMapping(CardioOrResistance.Resistance), value: CardioOrResistance.Resistance },
    { label: getCardioOrResistanceMapping(CardioOrResistance.Balance), value: CardioOrResistance.Balance },
  ];

  return (
    <MultiSelect
    options={options}
    value={filter ? valuesToOptions(options, filter.value) : options}
    onChange={getOnSelect(onChange)}
    labelledBy={'Select'}
    className='multi-select'
  />);
};

export const userWorkoutHistoryClassStatusFilter = (filter: any, onChange: any) => {
  const options = [
    { label: 'Future', value: 'future'},
    { label: 'Completed', value: 'completed'},
    { label: 'Missed', value: 'missed'},
    { label: 'Canceled', value: 'canceled'},
    {label: 'Current', value: 'current'},
    {label: 'Not Completed', value: 'notCompleted'},
  ];

  return (
    <MultiSelect
      options={options}
      value={filter ? valuesToOptions(options, filter.value) : options}
      onChange={getOnSelect(onChange)}
      labelledBy={'Select'}
      className='multi-select'
  />);
};

export const classStatusFilter = (filter: any, onChange: any) => {
  const options = [
    { label: 'Upcoming', value: 'upcoming'},
    { label: 'Past', value: 'past'},
    { label: 'Current', value: 'current'},
  ];

  return (
    <MultiSelect
    options={options}
    value={filter ? valuesToOptions(options, filter.value) : options}
    onChange={getOnSelect(onChange)}
    labelledBy={'Select'}
  />);
};

export const followUpDueFilter = (filter: any, onChange: any) => {
  const options = [
    { label: 'Not due yet', value: 'notDueYet'},
    { label: 'Due Today', value: 'dueToday'},
    { label: 'Past due', value: 'pastDue'},
    { label: 'Unassigned', value: 'unassigned'},
  ];
  return (
    <MultiSelect
      options={options}
      value={filter ? valuesToOptions(options, filter.value) : options}
      onChange={getOnSelect(onChange)}
      labelledBy={'Select'}
    />);
};

export const filterIsNewUser = (filter: any, onChange: any) => {
  const options = [
    { label: 'Yes', value: 'isNewUser' },
    { label: 'No', value: 'isNotNewUser' },
  ];

  return (
    <MultiSelect
      options={options}
      value={filter ? valuesToOptions(options, filter.value) : options}
      onChange={getOnSelect(onChange)}
      labelledBy={'Select'}
  />);
};

export const ignoreProgramRecommendationsFilter = (filter: any, onChange: any) => {
  const options = [
    { label: 'Yes', value: 'ignored' },
    { label: 'No', value: 'NotIgnored' },
  ];

  return (
    <MultiSelect
      options={options}
      value={filter ? valuesToOptions(options, filter.value) : options}
      onChange={getOnSelect(onChange)}
      labelledBy={'Select'}
      className='multi-select'
  />);
};

export const filterRemovedAtWorkouts = (filter: any, onChange: any) => {
  const options = [
    { label: 'Removed', value: 'Removed' },
    { label: 'Attended', value: 'Attended' },
    { label: 'Upcomming', value: 'Upcomming' },
  ];

  return (
    <MultiSelect
      options={options}
      value={filter ? valuesToOptions(options, filter.value) : options}
      onChange={getOnSelect(onChange)}
      labelledBy={'Select'}
  />);
};

export const filterContinueSignup = (filter: any, onChange: any) => {
  const options = [
    { label: 'Has Link', value: 'Continue' },
    { label: 'No Link', value: 'None'},
  ];
  return (
    <MultiSelect
      options={options}
      value={filter ? valuesToOptions(options, filter.value) : options}
      onChange={getOnSelect(onChange)}
      labelledBy={'Select'}
  />);
};

export const filterPaymentStatus = (filter: any, onChange: any) => {
  const options = [
    { label: 'Active', value: 'active' },
    { label: 'Canceled', value: 'canceled'},
    { label: 'Trialing', value: 'trialing' },
    { label: 'Past Due', value: 'past_due'},
    { label: 'Unpaid', value: 'unpaid'},
    { label: 'Unassigned', value: 'Unassigned'},
  ];

  return (
      <MultiSelect
        options={options}
        value={filter ? valuesToOptions(options, filter.value) : options}
        onChange={getOnSelect(onChange)}
        labelledBy={'Select'}
        className='multi-select'
      />
  );
};

export const filterBooleanStatus = (filter: any, onChange: any) => {
  const options = [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false'},
    { label: 'Unassigned', value: 'Unassigned'},
  ];

  return (
      <MultiSelect
        options={options}
        value={filter ? valuesToOptions(options, filter.value) : options}
        onChange={getOnSelect(onChange)}
        labelledBy={'Select'}
        className='multi-select'
      />
  );
};

export const influencerFilters = (filter: any, onChange: any) => {
  const options = [
    { label: 'Streaming', value: 'streaming' },
    { label: 'Non Streaming', value: 'non-streaming'},
  ];

  return (
      <MultiSelect
        options={options}
        value={filter ? valuesToOptions(options, filter.value) : options}
        onChange={getOnSelect(onChange)}
        labelledBy={'Select'}
        className='multi-select'
      />
  );
};

export const invoiceStatusMap =
  new Map<string, string>([['open', 'unpaid (open)'], ['void', 'cancelled (void)'], ['draft', 'draft'],
    ['paid', 'paid']]);

export const invoiceStatusFilters = (filter: any, onChange: any) => {
  const options = [
    { label: invoiceStatusMap.get('draft') || '', value: 'draft' },
    { label: invoiceStatusMap.get('open') || '', value: 'open'},
    { label: invoiceStatusMap.get('void') || '', value: 'void'},
    { label: invoiceStatusMap.get('paid') || '', value: 'paid'},
  ];

  return (
    <MultiSelect
      options={options}
      value={filter ? valuesToOptions(options, filter.value) : options}
      onChange={getOnSelect(onChange)}
      labelledBy={'Select'}
      className='multi-select'
    />
  );
};

export const LeadStatusFilters = (filter: any, onChange: any) => {
  const options = [...leadStatusDropdownValues, { label: 'Unassigned', value: 'Unassigned'}];
  return (
      <MultiSelect
        options={options}
        value={filter ? valuesToOptions(options, filter.value) : options}
        onChange={getOnSelect(onChange)}
        labelledBy={'Select'}
        className='multi-select'
      />
  );
};

export const filterPaymentTableStatus = (filter: any, onChange: any) => {
  const options = [
    { label: 'Succeeded', value: 'succeeded' },
    { label: 'Pending', value: 'pending'},
    { label: 'Failed', value: 'failed' },
    { label: 'Unassigned', value: 'Unassigned'},
  ];

  return (
      <MultiSelect
        options={options}
        value={filter ? valuesToOptions(options, filter.value) : options}
        onChange={getOnSelect(onChange)}
        labelledBy={'Select'}
        className='multi-select'
      />
  );
};

export const filterInvoiceLink = (filter: any, onChange: any) => {
  const options = [
    { label: 'Has Link', value: 'Continue' },
    { label: 'No Link', value: 'None'},
  ];
  return (
    <MultiSelect
    options={options}
    value={filter ? valuesToOptions(options, filter.value) : options}
    onChange={getOnSelect(onChange)}
    labelledBy={'Select'}
  />);
};

export const aggregateFilterSelectHandler = (filter: any, row: any) => {
  if (filter.value.operator === undefined ||
    (!filter.value.operatorValue && filter.value.operatorValue !== 0)) {
    return true;
  }
  if (filter.value.operator === 'greaterThan') {
    const isGreaterThan = +row[filter.id] > +filter.value.operatorValue;
    return isGreaterThan;
  }
  if (filter.value.operator === 'lessThan') {
    const isLessThan = +row[filter.id] < +filter.value.operatorValue;
    return isLessThan;
  }
  if (filter.value.operator === 'equals') {
    const isEqual = +row[filter.id] === +filter.value.operatorValue;
    return isEqual;
  }
  return false;
};
