import { MembersReportData } from 'fitbeat.models';
import React from 'react';
import { connect } from 'react-redux';
import { Card, CardText, CardTitle } from 'reactstrap';
import { IAppStore } from '../../rootReducer';
import { loadDashboardActiveReport } from '../actions';
import { numberOfRowsInReportsSelector } from '../selector';

interface IOwnProps {
  report: MembersReportData;
}

interface IStoreProps {
  activeDashboardActiveReport: MembersReportData;
  numberOfRowsInReport: number;
}

interface IDispatchProps {
  loadDashboardActiveReport: (report: MembersReportData) => void;
}

class DashboardReportTileComponent extends React.Component<IOwnProps & IStoreProps & IDispatchProps> {
  public render() {
    const {
      report,
      activeDashboardActiveReport,
      numberOfRowsInReport,
    } = this.props;

    const isActive = report.reportId === activeDashboardActiveReport.reportId ? 'active' : '';
    const containerClass = `dashboard-report-card-container ${isActive}`;
    return (
      <Card
        className={containerClass}
        onClick={() => this.props.loadDashboardActiveReport(report)}
      >
        <div className='dashboard-report-card-title-container'>
          <CardTitle tag='p' className='dashboard-report-card-title'>
            {numberOfRowsInReport}
          </CardTitle>
        </div>
        <div className='dashboard-report-card-sub-title-container'>
          <CardText className='dashboard-report-card-sub-title'>
            {report.reportName}
          </CardText>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state: IAppStore, ownProps: IOwnProps): IStoreProps => {
  return {
    numberOfRowsInReport: numberOfRowsInReportsSelector(state).get(ownProps.report.reportId) ?? 0,
    activeDashboardActiveReport: state.members.data.activeDashboardActiveReport,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    loadDashboardActiveReport: (report) => dispatch(loadDashboardActiveReport(report)),
  };
};

export const DashboardReportTile =
  connect<IStoreProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(DashboardReportTileComponent);
