import { ILeaderboardConfig } from 'fitbeat.models';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import { IAppStore } from '../../rootReducer';
import {
  toggleLeaderboardRankingModal,
  updateLeaderboardRankings,
} from '../leaderboardsSlice';
import { SelectableLeaderboardConfigs } from './LeaderboardConfigArrangeOrder';

interface IProps {
  selectedGymName: string;
  leaderboardConfigs: ILeaderboardConfig[];
  showSortLeaderboardRankingModal: boolean;
  toggleLeaderboardRankingModal: (modalState: boolean) => void;
  updateLeaderboardRankings: (selectedGymName: string, leaderboardConfigs: ILeaderboardConfig[]) => void;
}

interface IState {
  showModal: boolean;
  enableRankSaving: boolean;
  leaderboardConfigs: ILeaderboardConfig[];
}

class ArrangeConfigOrder extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showModal: false,
      leaderboardConfigs: this.props.leaderboardConfigs,
      enableRankSaving: false,
    };
  }

  public componentDidMount() {
    this.setState({
      leaderboardConfigs: this.props.leaderboardConfigs,
    });
  }

  public getSnapshotBeforeUpdate(props: IProps, state: IState) {
    if (props.leaderboardConfigs.length && !this.state.leaderboardConfigs.length) {
      this.setState({
        leaderboardConfigs: props.leaderboardConfigs,
      });
    }
  }

  public componentWillUnmount() {
    this.setState({
      showModal: false,
      enableRankSaving: false,
    });
  }

  public render() {
    return (
      <div>
        <div
          className='jr-btn-no-border pointer'
          onClick={() => this.props.toggleLeaderboardRankingModal(true)}
        >
          Assign Rank
        </div>
        <Modal
          isOpen={this.props.showSortLeaderboardRankingModal}
          className='modal-content leaderboard-ranking-container'
          overlayClassName='modal-overlay'
          ariaHideApp={false}
          contentLabel='Columns'
        >
          <div
            className='column-label content-margin'
            style={{ height: '70px', marginTop: 10 }}
          >
            <div>
              <h2 className='column-header leaderboard-ranking-text'>
                Assign Rank
              </h2>
              <p className='column-header leaderboard-ranking-text text-transform-none'>
                The top ranked leaderboard applicable to a user will be featured on their home tab.
              </p>
              <p className='leaderboard-ranking-text text-transform-none'>
                Drag the leaderboards up or down to change it's rank and Apply.
              </p>
            </div>
            <button
              className='modal-close-button'
              onClick={() => this.props.toggleLeaderboardRankingModal(false)}
            >
              X
            </button>
          </div>
          <SelectableLeaderboardConfigs
            leaderboardConfigs={this.state.leaderboardConfigs}
            onSortEnd={this.onSortEnd}
          />
          <div className='float-right'>
            <button
              className='jr-btn-default'
              style={{ float: 'right', width: '20%', marginRight: 15 }}
              disabled={!this.state.enableRankSaving}
              onClick={() => {
                this.props.updateLeaderboardRankings(
                  this.props.selectedGymName,
                  this.state.leaderboardConfigs,
                );
              }}
            >
              Apply
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  private onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newArrayList = arrayMove(this.state.leaderboardConfigs, oldIndex, newIndex);
    this.setState(({ leaderboardConfigs }) => ({
      leaderboardConfigs: newArrayList,
    }));
    this.rankingHasChanged(newArrayList);
  };

  private rankingHasChanged = (rankedList: ILeaderboardConfig[]) => {
    const sortedList = rankedList.map((singleLeaderboard: ILeaderboardConfig) => singleLeaderboard._id).toString();
    const initialList = this.props.leaderboardConfigs.map((
      singleLeaderboard: ILeaderboardConfig,
    ) => singleLeaderboard._id).toString();
    const enableRankSaving = initialList !== sortedList;
    this.setState({ enableRankSaving });
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  toggleLeaderboardRankingModal: (modalState: boolean) => dispatch(toggleLeaderboardRankingModal(modalState)),
  updateLeaderboardRankings: (
    selectedGymName: string,
    leaderboardConfigs: ILeaderboardConfig[],
  ) => dispatch(updateLeaderboardRankings({
    gym: selectedGymName,
    leaderboardConfigs,
  })),
});

const mapStateToProps = (state: IAppStore) => {
  const { leaderboards, adminSettings } = state;
  const { selectedGymName } = adminSettings.data;

  const {
    leaderboardConfigs,
  } = leaderboards.data;
  const { showSortLeaderboardRankingModal } = leaderboards.view;
  return {
    selectedGymName,
    leaderboardConfigs,
    showSortLeaderboardRankingModal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArrangeConfigOrder);
