import { BugsnagStatic } from '@bugsnag/core';
import { IClientSideLogger } from 'fitbeat.gym-core';
import { InfoError } from './InfoError';

// TODO: this file is shared between zone screens and web-admin, it should move to a shared client-side lib
export class BugsnagLogger implements IClientSideLogger {
  constructor(private bugsnag: BugsnagStatic) {}

  public error(e: Error, customErrorMessage?: string) {
    if (!e) {
      this.error(new Error('Unknown error (falsy passed to logger.error)'), customErrorMessage);
      return;
    }

    this.bugsnag.notify(e, (event) => {
      event.severity = 'error';
      event.context = customErrorMessage;
    });
  }

  public info(msg: string) {
    this.bugsnag.notify(new InfoError(msg), (event) => {
      event.severity = 'info';
    });
  }

  public warn(msg: string) {
    this.bugsnag.notify(new Error(msg), (event) => {
      event.severity = 'warning';
    });
  }

  public trace(msg: string) {
    this.bugsnag.leaveBreadcrumb(msg);
  }
}
