import { BaseUserProfile, Gym } from 'fitbeat.models';

const messageBodyParam = (body?: string): string => (body ? `&body=${body}` : '');

const messageSubjectParam = (subject?: string): string => (subject ? `&su=${subject}` : '');

export const getEmailLink = (gym: Gym, member: BaseUserProfile, body?: string): string => {
  const encodedEmailWithAddition = member.email.replace(/\+/g, '%2b');
  const emailWithName = `${member.fullname} <${encodedEmailWithAddition}>`;
  const gymEmail = gym.email;
  return `https://mail.google.com/mail/u/${gymEmail}/?fs=1&to=${emailWithName}${messageBodyParam(body)}&tf=cm`;
};

export const getEmailToSMSViaGmailLink = (gym: Gym, member: BaseUserProfile, body?: string): string => {
  const emailToSMSDomain = getEmailToSMSDomain(gym);
  const emailLink = `https://mail.google.com/mail/u/${gym.email}`;
  const mobileNumber = member.mobileNumber.replace(/\+/g, '%2b');
  const formattedAddress = `${member.fullname} <${mobileNumber}@${emailToSMSDomain}>`;
  const params = `fs=1&to=${formattedAddress}&tf=cm${messageSubjectParam(body)}`;
  return `${emailLink}?${params}`;
};

export const buildSMSLinkForStandardPlans = (member: BaseUserProfile, plansLink: string, currentGym: Gym) => {
  const message = `Hey ${member.firstname}. Here is the link for the standard membership plans: ${plansLink}`;
  return getEmailToSMSViaGmailLink(currentGym, member, message);
};

export const buildSMSLinkForSpecialOffer = (member: BaseUserProfile, offerLink: string, currentGym: Gym) => {
  const message = `Hey ${member.firstname}. Here is the link for your special offer: ${offerLink}`;
  return getEmailToSMSViaGmailLink(currentGym, member, message);
};

export const getEmailToSMSDomain = (gym: Gym) => {
  // todo: make messagebird explicit integration ONLY instead of default
  let emailToSMSDomain = 'sms.messagebird.com';
  if (Gym.isIntegrationEnabled(gym, 'Textmagic')) {
    emailToSMSDomain = 'textmagic.com';
  } else if (Gym.isIntegrationEnabled(gym, 'MessageBird')) {
    emailToSMSDomain = 'sms.messagebird.com';
  }
  return emailToSMSDomain;
};
