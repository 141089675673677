import { Gym, IBasicAuthenticationUser } from 'fitbeat.models';
import getLogger from '../util/logging';

const logger = getLogger('zendesk');

function setZendeskPrefill(key: string, value: string, readOnly = true) {
  try {
    const zendesk = (window as any).zE;
    if (!zendesk) {
      logger.warn('zendesk is not available');
      return;
    }
    logger.trace(`set ${key}=${value}`);
    // https://fitbeat.zendesk.com/admin/objects-rules/tickets/ticket-fields
    zendesk('messenger:set', 'conversationFields', [{ id: key, value }]);;
  } catch (error) {
    logger.error(error, `zendesk messenger error setting ${key}=${value}`);
  }
}

export function updateZendeskWidget(gym: Gym) {
  setZendeskPrefill('19085935119506', gym.email || '');
  setZendeskPrefill('19086626949778', gym.name || '');
  setZendeskPrefill('19086736671762', gym.organisation || '');
  setZendeskPrefill('19086647939090', gym.timezone || '');
}

export function setupZendeskWidget(user: IBasicAuthenticationUser, selectedGym: Gym) {
  setZendeskPrefill('19085626342802', user.name);
  updateZendeskWidget(selectedGym);
}
