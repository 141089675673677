import { IInvoice, IInvoiceItem, IPrice, IStripeCouponWrapper, UserAccountScheduleActionInfo } from 'fitbeat.models';
import produce from 'immer';
import moment from 'moment';
import {
  AccountActionTypes,
  APPLY_COUPON_CHECKED_ON_START_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CHANGE_MERCHANDISE_NOTE, CLEANUP_TERMINAL,
  CLOSE_BUY_MERCHANDISE_MODAL,
  CLOSE_CONFIRM_SAVE_DRAFT_MODAL,
  CLOSE_START_SUBSCRIPTION_MODAL,
  CONNECT_TO_TERMINAL_READER_FAILURE,
  CONNECT_TO_TERMINAL_READER_REQUEST,
  CONNECT_TO_TERMINAL_READER_SUCCESS,
  CONSENT_CHECKED_ON_START_SUBSCRIPTION,
  COUPON_SELECTED_ON_START_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_FAILURE,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  FREE_WEEK_SUBSCRIPTION_FAILURE,
  FREE_WEEK_SUBSCRIPTION_REQUEST,
  FREE_WEEK_SUBSCRIPTION_SUCCESS,
  HANDLE_MERCHANDISE_DRAFT_INVOICE_FAILURE,
  HANDLE_MERCHANDISE_DRAFT_INVOICE_REQUEST,
  HANDLE_MERCHANDISE_DRAFT_INVOICE_SUCCESS,
  HANDLE_TERMINAL_DISCONNECT,
  LOAD_BUY_MERCHANDISE_MODAL_FAILURE,
  LOAD_BUY_MERCHANDISE_MODAL_REQUEST,
  LOAD_BUY_MERCHANDISE_MODAL_SUCCESS,
  LOAD_START_SUBSCRIPTION_MODAL_FAILURE,
  LOAD_START_SUBSCRIPTION_MODAL_REQUEST,
  LOAD_START_SUBSCRIPTION_MODAL_SUCCESS,
  LOAD_STRIPE_PAYMENT_DATA_FAILURE,
  LOAD_STRIPE_PAYMENT_DATA_SUCCESS,
  LOAD_TERMINAL_READERS,
  MEMBER_SCHEDULE_ACTION_DETAILS_FAILURE,
  MEMBER_SCHEDULE_ACTION_DETAILS_REQUEST,
  MEMBER_SCHEDULE_ACTION_DETAILS_SUCCESS,
  OPEN_CONFIRM_ACTION_INVOICE_MODAL,
  PAUSE_SUBSCRIPTION_FAILURE,
  PAUSE_SUBSCRIPTION_REQUEST,
  PAUSE_SUBSCRIPTION_SUCCESS,
  PAY_MERCHANDISE_FAILURE,
  PAY_MERCHANDISE_REQUEST,
  PAY_MERCHANDISE_SUCCESS,
  PLAN_SELECTED_ON_START_SUBSCRIPTION_FAILURE,
  PLAN_SELECTED_ON_START_SUBSCRIPTION_REQUEST,
  PLAN_SELECTED_ON_START_SUBSCRIPTION_SUCCESS,
  SELECT_MERCHANDISE_COUPON_FAILURE,
  SELECT_MERCHANDISE_COUPON_REQUEST,
  SELECT_MERCHANDISE_COUPON_SUCCESS,
  SET_EARLIEST_FREE_WEEK_END,
  SET_EARLIEST_PAUSE_END,
  SET_FREE_WEEK_END_DATE,
  SET_FREE_WEEK_PLAN_ID,
  SET_PAUSE_END_DATE,
  SET_PAUSE_PLAN_ID,
  SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_FAILURE,
  SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_REQUEST,
  SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_SUCCESS,
  SET_TERMINAL_STATUS,
  SETUP_TERMINAL_FAILURE,
  SETUP_TERMINAL_REQUEST,
  SETUP_TERMINAL_SUCCESS,
  TOGGLE_ADD_FREE_WEEKS_MODAL,
  TOGGLE_ADD_PAUSE_MODAL,
  TOGGLE_CANCEL_PAUSE_MODAL,
  TOGGLE_CANCEL_SUBSCRIPTION_MODAL,
  TOGGLE_START_SUBSCRIPTION_MODAL,
  UPDATE_CANCELLATION_FEE,
  UPDATE_MERCHANDISE_INVOICE_FAILURE,
  UPDATE_MERCHANDISE_INVOICE_REQUEST,
  UPDATE_MERCHANDISE_INVOICE_SUCCESS,
} from './actions';
import { IBuyMerchandise, IMemberAccountActionsState, initialState, IStartSubscription } from './storeTypes';

export const accountActionsReducer = produce((draft: IMemberAccountActionsState = initialState,
                                              action: AccountActionTypes):
IMemberAccountActionsState => {
  switch (action.type) {
    case TOGGLE_ADD_FREE_WEEKS_MODAL:
      draft.view.showAddFreeWeeksModal = !draft.view.showAddFreeWeeksModal;

      if (action.payload) {
        draft.view.addFreeWeekInfo = action.payload;
        draft.view.freeWeekStartDate = moment(action.payload.earliestStart);
        draft.view.freeWeekEndDate = moment(action.payload.earliestEnd);
      }
      return draft;
    case TOGGLE_ADD_PAUSE_MODAL:
      draft.view.showAddPauseModal = !draft.view.showAddPauseModal;

      if (action.payload) {
        draft.view.addFreezeInfo = action.payload;
        draft.view.pauseStartDate = moment(action.payload.earliestStart);
        draft.view.pauseEndDate = moment(action.payload.earliestEnd);
      }
      return draft;
    case TOGGLE_CANCEL_SUBSCRIPTION_MODAL:
      draft.view.showCancelSubscriptionModal = !draft.view.showCancelSubscriptionModal;
      draft.view.cancelSubscriptionError = null;
      draft.view.isLoadingCancelSubscription = false;
      draft.data.cancelSubscriptionDetail = undefined;
      return draft;
    case TOGGLE_START_SUBSCRIPTION_MODAL:
      draft.view.showStartSubscriptionModal = !draft.view.showStartSubscriptionModal;
      draft.view.startSubscriptionError = null;
      draft.view.isLoadingStartSubscription = false;
      return draft;
    case TOGGLE_CANCEL_PAUSE_MODAL:
      draft.view.showCancelPauseModal = !draft.view.showCancelPauseModal;
      return draft;
    case MEMBER_SCHEDULE_ACTION_DETAILS_REQUEST:
      draft.view.isFetching = true;
      draft.view.successMessage = '';
      draft.view.error = null;
      return draft;
    case MEMBER_SCHEDULE_ACTION_DETAILS_SUCCESS:
      handleAccountActions(draft, action.payload.memberAccountSchedule, action.payload.stripeAccountId);

      const workoutList = action.payload.workoutList;
      if (workoutList) {
        draft.data.memberWorkoutHistory = workoutList;
      }

      draft.view.isFetching = false;
      return draft;
    case MEMBER_SCHEDULE_ACTION_DETAILS_FAILURE:
      draft.view.error = action.payload;
      draft.view.isFetching = false;
      return draft;
    case SET_EARLIEST_PAUSE_END:
      draft.view.pauseStartDate = moment(action.payload);
      draft.view.pauseEndDate = moment(action.payload).add(1, 'days');
      return draft;
    case UPDATE_CANCELLATION_FEE:
      if (draft.data.memberAccountShedule && draft.data.memberAccountShedule.cancelSubscriptionInfo) {
        draft.data.memberAccountShedule.cancelSubscriptionInfo.defaultPenaltyAmount = action.payload;
      }
      return draft;
    case SET_PAUSE_PLAN_ID:
      draft.view.pausePlanId = action.payload;
      return draft;
    case SET_PAUSE_END_DATE:
      draft.view.pauseEndDate = action.payload;
      return draft;
    case CANCEL_SUBSCRIPTION_REQUEST:
      draft.view.isLoadingCancelSubscription = true;
      draft.data.cancelSubscriptionDetail = undefined;
      return draft;
    case CANCEL_SUBSCRIPTION_SUCCESS:
      draft.view.cancelSubscriptionError = null;
      draft.view.isLoadingCancelSubscription = false;
      draft.data.cancelSubscriptionDetail = action.payload;
      draft.view.successMessage = 'Cancel successful';
      return draft;
    case CANCEL_SUBSCRIPTION_FAILURE:
      draft.view.cancelSubscriptionError = action.payload;
      draft.view.isLoadingCancelSubscription = false;
      draft.data.cancelSubscriptionDetail = undefined;
      return draft;
    case PAUSE_SUBSCRIPTION_REQUEST:
      draft.view.isFetching = true;
      return draft;
    case PAUSE_SUBSCRIPTION_SUCCESS:
      draft.view.isFetching = false;
      draft.view.showAddPauseModal = false;
      draft.view.successMessage = 'Subscription paused.';
      draft.view.pauseEndDate = moment();
      draft.view.pauseStartDate = moment();
      draft.view.pauseEarliestEnd = moment();
      draft.view.pausePlanId = '';
      return draft;
    case PAUSE_SUBSCRIPTION_FAILURE:
      draft.view.error = action.payload;
      draft.view.isFetching = false;
    case FREE_WEEK_SUBSCRIPTION_REQUEST:
      draft.view.isFetching = true;
      return draft;
    case FREE_WEEK_SUBSCRIPTION_SUCCESS:
      draft.view.isFetching = false;
      draft.view.showAddFreeWeeksModal = false;
      draft.view.successMessage = 'Free week added to subscription.';
      draft.view.freeWeekEndDate = moment();
      draft.view.freeWeekStartDate = moment();
      draft.view.freeWeekEarliestEnd = moment();
      draft.view.freeWeekPlanId = '';
      return draft;
    case FREE_WEEK_SUBSCRIPTION_FAILURE:
      draft.view.error = action.payload;
      draft.view.isFetching = false;
      return draft;
    case SET_FREE_WEEK_PLAN_ID:
      draft.view.freeWeekPlanId = action.payload;
      return draft;
    case SET_EARLIEST_FREE_WEEK_END:
      draft.view.freeWeekStartDate = moment(action.payload);
      draft.view.freeWeekEndDate = moment(action.payload).add(1, 'days');
      return draft;
    case SET_FREE_WEEK_END_DATE:
      draft.view.freeWeekEndDate = action.payload;
      return draft;
    case CREATE_SUBSCRIPTION_REQUEST:
      draft.view.isLoadingStartSubscription = true;
      draft.view.startSubscriptionError = null;
      return draft;
    case CREATE_SUBSCRIPTION_SUCCESS:
      draft.view.showStartSubscriptionSuccessModal = true;
      draft.view.isLoadingStartSubscription = false;
      handleAccountActions(draft, action.payload.memberSchedule, action.payload.accountId);
      return draft;
    case CREATE_SUBSCRIPTION_FAILURE:
      draft.view.startSubscriptionError = action.payload.error;
      if (action.payload.createNewSetupIntent)  {
        draft.data.setupIntent = undefined;
      }
      draft.view.isLoadingStartSubscription = false;
      return draft;
    case PLAN_SELECTED_ON_START_SUBSCRIPTION_REQUEST:
      draft.view.isLoadingStartSubscription = true;
      draft.data.startSubscription.plan = action.payload;
      draft.data.startSubscription.plan = action.payload;
      draft.data.startSubscription.coupon = undefined;
      draft.data.startSubscription.isLoadingCoupons = true;
      draft.data.startSubscription.couponsForPlan = [];
      draft.data.startSubscription.couponPlaceholder = 'Loading';
      return draft;
    case PLAN_SELECTED_ON_START_SUBSCRIPTION_SUCCESS:
      draft.data.startSubscription.couponsForPlan = action.payload.coupons;
      draft.data.startSubscription.isLoadingCoupons = false;
      draft.data.startSubscription.couponPlaceholder = 'Select a coupon';
      draft.data.startSubscription.coupon = undefined;

      return draft;
    case PLAN_SELECTED_ON_START_SUBSCRIPTION_FAILURE:
      draft.data.startSubscription.isLoadingCoupons = false;
      return draft;
    case COUPON_SELECTED_ON_START_SUBSCRIPTION:
      draft.data.startSubscription.coupon = action.payload;
      return draft;
    case APPLY_COUPON_CHECKED_ON_START_SUBSCRIPTION:
      draft.data.startSubscription.couponChecked = !draft.data.startSubscription.couponChecked;
      draft.data.startSubscription.coupon = undefined;
      if (draft.data.startSubscription.couponChecked) {
        draft.data.startSubscription.couponPlaceholder = 'Select a coupon';
      }
      draft.data.startSubscription.paymentSummary = undefined;
      draft.view.isLoadingStartSubscription = false;
      return draft;
    case CONSENT_CHECKED_ON_START_SUBSCRIPTION:
      draft.data.startSubscription.consentChecked = !draft.data.startSubscription.consentChecked;
      return draft;
    case LOAD_START_SUBSCRIPTION_MODAL_REQUEST:
      draft.view.isLoadingStartSubscription = true;
      draft.data.setupIntent = undefined;
      draft.data.startSubscription = getDefaultStartSubscriptionState();
      return draft;
    case LOAD_START_SUBSCRIPTION_MODAL_SUCCESS:
      draft.view.isLoadingStartSubscription = false;
      draft.data.startSubscription.stripe = action.payload.stripe;
      draft.data.startSubscription.couponPlaceholder = 'First select a plan';
      return draft;
    case LOAD_START_SUBSCRIPTION_MODAL_FAILURE:
      draft.view.isLoadingStartSubscription = false;
      draft.view.startSubscriptionError = action.payload.error;
      return draft;
    case SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_REQUEST:
      draft.view.isLoadingStartSubscription = true;
      draft.data.startSubscription.paymentSummary = undefined;
      return draft;
    case SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_SUCCESS:
      draft.data.startSubscription.paymentSummary = action.payload;
      draft.view.isLoadingStartSubscription = false;
      draft.data.startSubscription.consentChecked = false;
      return draft;
    case SET_START_SUBSCRIPTION_PAYMENT_SUMMARY_FAILURE:
      draft.data.startSubscription.paymentSummary = undefined;
      draft.view.isLoadingStartSubscription = false;
      return draft;
    case CLOSE_START_SUBSCRIPTION_MODAL:
      draft.data.startSubscription = getDefaultStartSubscriptionState();
      draft.view.showStartSubscriptionModal = false;
      draft.view.showStartSubscriptionSuccessModal = false;
      draft.view.startSubscriptionError = null;
      draft.view.successMessage = null;
      draft.view.isLoadingStartSubscription = false;
      return draft;
    case LOAD_BUY_MERCHANDISE_MODAL_REQUEST:
      draft.data.setupIntent = undefined;
      draft.view.isLoadingBuyMerchandiseModal = true;
      draft.data.buyMerchandise.error = null;
      draft.view.showBuyMerchandiseModal = true;
      draft.data.buyMerchandise = getDefaultBuyMerchandiseState();
      return draft;
    case LOAD_BUY_MERCHANDISE_MODAL_SUCCESS:
      draft.view.isLoadingBuyMerchandiseModal = false;
      draft.data.buyMerchandise.merchandise = action.payload.merchandise;
      draft.data.buyMerchandise.coupons = action.payload.coupons;
      draft.data.buyMerchandise.stripe = action.payload.stripe;
      draft.data.buyMerchandise.selectedCoupon =
        draft.data.buyMerchandise.coupons
          .find((coupon: IStripeCouponWrapper) => coupon.name === action.payload.invoice?.couponCode);
      draft.data.buyMerchandise.invoice = action.payload.invoice;
      return draft;
    case LOAD_BUY_MERCHANDISE_MODAL_FAILURE:
      draft.view.isLoadingBuyMerchandiseModal = false;
      draft.view.isLoadingBuyMerchandiseModal = false;
      draft.data.buyMerchandise.error = action.payload;
      return draft;
    case CLOSE_BUY_MERCHANDISE_MODAL:
      if (action.payload.showConfirmActionInvoiceModal) {
        draft.data.buyMerchandise.showConfirmActionInvoiceModal = true;
      } else {
        draft.view.showBuyMerchandiseModal = false;
        draft.data.buyMerchandise = getDefaultBuyMerchandiseState();
      }

      return draft;
    case CLOSE_CONFIRM_SAVE_DRAFT_MODAL:
      draft.data.buyMerchandise.showConfirmActionInvoiceModal = false;
      return draft;
    case HANDLE_MERCHANDISE_DRAFT_INVOICE_REQUEST:
      draft.data.buyMerchandise = getDefaultBuyMerchandiseState();
      draft.view.showBuyMerchandiseModal = false;
      draft.view.isFetching = true;
      return draft;
    case HANDLE_MERCHANDISE_DRAFT_INVOICE_SUCCESS:
      draft.view.isFetching = false;
      return draft;
    case HANDLE_MERCHANDISE_DRAFT_INVOICE_FAILURE:
      draft.view.isFetching = false;
      draft.data.buyMerchandise.error = action.payload;
      return draft;
    case UPDATE_MERCHANDISE_INVOICE_REQUEST:
      draft.data.buyMerchandise.isLoadingInvoice = true;
      draft.data.buyMerchandise.invoice =
        updateInvoice(action.payload.invoiceItem, draft.data.buyMerchandise.invoice ??
          {invoiceLines: [], status: 'draft'} as any);
      return draft;
    case UPDATE_MERCHANDISE_INVOICE_SUCCESS:
      draft.data.buyMerchandise.isLoadingInvoice = false;
      draft.data.buyMerchandise.invoice = action.payload.invoice;
      return draft;
    case UPDATE_MERCHANDISE_INVOICE_FAILURE:
      draft.data.buyMerchandise.isLoadingInvoice = false;
      draft.data.buyMerchandise.error = action.payload;
      return draft;
    case SELECT_MERCHANDISE_COUPON_REQUEST:
      draft.data.buyMerchandise.isLoadingInvoice = true;
      draft.data.buyMerchandise.selectedCoupon = action.payload.coupon;
      return draft;
    case SELECT_MERCHANDISE_COUPON_SUCCESS:
      draft.data.buyMerchandise.invoice = action.payload.updatedInvoice;
      draft.data.buyMerchandise.isLoadingInvoice = false;
      return draft;
    case SELECT_MERCHANDISE_COUPON_FAILURE:
      draft.data.buyMerchandise.isLoadingInvoice = false;
      draft.data.buyMerchandise.error = action.payload;
      return draft;
    case PAY_MERCHANDISE_REQUEST:
      draft.data.buyMerchandise.isProcessingPayment = true;
      draft.data.buyMerchandise.error = null;
      return draft;
    case PAY_MERCHANDISE_SUCCESS:
      draft.data.buyMerchandise.invoice = action.payload.invoice;
      const status = draft.data.buyMerchandise.invoice.status;
      if (status === 'paid') {
        draft.data.buyMerchandise.showMerchandiseSuccessModal = true;
        return draft;
      }
      draft.data.buyMerchandise.error = `Failed to pay invoice .Try again. Status: (${status}).`;
      draft.data.buyMerchandise.isProcessingPayment = false;
      return draft;
    case PAY_MERCHANDISE_FAILURE:
      draft.data.buyMerchandise.error = action.payload.error || null;
      draft.data.buyMerchandise.isProcessingPayment = false;
      if (action.payload.invoice) {
        draft.data.buyMerchandise.invoice = action.payload.invoice;
      }
      if (action.payload.shouldCreateNewSetupIntent) {
        draft.data.setupIntent = undefined;
      }
      return draft;
    case LOAD_STRIPE_PAYMENT_DATA_SUCCESS:
      draft.data.setupIntent = action.payload.setupIntent;
      return draft;
    case LOAD_STRIPE_PAYMENT_DATA_FAILURE:
      draft.data.buyMerchandise.error = action.payload;
      return draft;
    case CHANGE_MERCHANDISE_NOTE:
      draft.data.buyMerchandise.note = action.payload;
      return draft;
    case OPEN_CONFIRM_ACTION_INVOICE_MODAL:
      draft.data.buyMerchandise.showConfirmActionInvoiceModal = true;
      return draft;
    case SETUP_TERMINAL_REQUEST:
      draft.view.isLoadingTerminal = true;
      draft.view.terminalError = null;
      return draft;
    case SETUP_TERMINAL_SUCCESS:
      draft.data.terminal = action.payload.terminal;
      draft.data.setupIntent = action.payload.setupIntent;
      draft.view.isLoadingTerminal = false;
      draft.data.connectedReader = action.payload.connectedReader;
      return draft;
    case SETUP_TERMINAL_FAILURE:
      draft.view.isLoadingTerminal = false;
      draft.view.terminalError = action.payload;
      draft.view.terminalStatus = 'disconnected';
      return draft;
    case CONNECT_TO_TERMINAL_READER_REQUEST:
      draft.view.terminalError = null;
      draft.view.isLoadingTerminal = true;
      return draft;
    case CONNECT_TO_TERMINAL_READER_SUCCESS:
      draft.view.isLoadingTerminal = false;
      return draft;
    case CONNECT_TO_TERMINAL_READER_FAILURE:
      draft.view.terminalStatus = 'disconnected';
      draft.view.isLoadingTerminal = false;
      draft.data.connectedReader = null;
      return draft;
    case LOAD_TERMINAL_READERS:
      draft.data.readers = action.payload;
      return draft;
    case SET_TERMINAL_STATUS:
      draft.view.terminalStatus = action.payload;
      if (action.payload === 'disconnected') {
        draft.data.connectedReader = null;
      }
      return draft;
    case HANDLE_TERMINAL_DISCONNECT:
      draft.data.connectedReader = null;
      draft.view.terminalStatus = 'disconnected';
      draft.view.terminalError = 'Terminal disconnected';
      return draft;
    case CLEANUP_TERMINAL:
      draft.data.terminal = null;
      draft.data.connectedReader = null;
      draft.data.readers = [];
      draft.view.terminalError = null;
      draft.view.isLoadingTerminal = false;
      draft.view.terminalStatus = 'disconnected';
      draft.data.connectedReader = null;
      return draft;
    default:
      return draft;
  }
});

const updateInvoice = (updatedInvoiceItem: IInvoiceItem, currentInvoice: IInvoice) => {
  if (!updatedInvoiceItem.id) {
    currentInvoice.invoiceLines.unshift(updatedInvoiceItem);
    return currentInvoice;
  }

  const index = currentInvoice.invoiceLines.findIndex((item: IInvoiceItem) => item.id === updatedInvoiceItem.id);

  if (updatedInvoiceItem.quantity === 0) {
    currentInvoice.invoiceLines.splice(index, 1);
    return currentInvoice;
  }

  currentInvoice.invoiceLines[index] = updatedInvoiceItem;
  return currentInvoice;
};

const handleAccountActions = (
  draft: IMemberAccountActionsState,
  accountSchedule: UserAccountScheduleActionInfo,
  accountId: string,
) => {
  draft.data.memberAccountShedule = accountSchedule;
  const allowedPausePlanIds: any[] = [];
  if (accountSchedule && accountSchedule.pauseSubscriptionInfo &&
    accountSchedule.pauseSubscriptionInfo.allowedPlanIds) {
    accountSchedule.pauseSubscriptionInfo.allowedPlanIds.forEach((plan: any) => {
      if (plan.accountId === accountId) {
        allowedPausePlanIds.push(plan);
      }
    });
  }

  draft.view.allowedPlanIds = allowedPausePlanIds;

  const allowedFreeWeekPlanIds: any[] = [];
  if (accountSchedule && accountSchedule.freeWeekSubscriptionInfo &&
    accountSchedule.freeWeekSubscriptionInfo.allowedPlanIds) {
    accountSchedule.freeWeekSubscriptionInfo.allowedPlanIds.forEach((plan: any) => {
      if (plan.accountId === accountId) {
        allowedFreeWeekPlanIds.push(plan);
      }
    });
  }
  draft.view.allowedFreeWeekPlanIds = allowedFreeWeekPlanIds;

  const allowedStartSubscriptionPlans: IPrice[] = [];
  if (accountSchedule && accountSchedule.startSubscriptionInfo &&
    accountSchedule.startSubscriptionInfo.allowedPlans) {
    accountSchedule.startSubscriptionInfo.allowedPlans.forEach(
      (plan: IPrice) => {
        if (plan.accountId === accountId && plan.description) {
          allowedStartSubscriptionPlans.push(plan);
        }
      });
  }

  draft.view.allowedStartSubscriptionPlans = allowedStartSubscriptionPlans;
};

const getDefaultStartSubscriptionState = (): IStartSubscription => {
  return {
    couponChecked: false,
    coupon: undefined,
    plan: undefined,
    stripe: null,
    couponPlaceholder: 'First select a plan',
    couponsForPlan: [],
    isLoadingCoupons: false,
    consentChecked: false,
    paymentSummary: undefined,
  };
};

const getDefaultBuyMerchandiseState = (): IBuyMerchandise => {
  return {
    merchandise: [],
    error: null,
    invoice: undefined,
    isLoadingInvoice: false,
    selectedCoupon: undefined,
    coupons: [],
    isProcessingPayment: false,
    success: undefined,
    stripe: null,
    note: undefined,
    showConfirmActionInvoiceModal: false,
    showMerchandiseSuccessModal: false,
  };
};
