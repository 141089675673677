import fetch from 'isomorphic-fetch';

export class StripeService {
  public static pauseProductNameContains = 'Pause';
  public async getCustomer(stripePrivateKey: string, customerId: string):
  Promise<any> {
    const response = await fetch(`https://api.stripe.com/v1/customers/` +
      `${customerId}?expand[]=subscriptions.data.schedule`, {
      headers: {
        authorization: `Bearer ${stripePrivateKey}`,
      },
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  public async getPlans(stripePrivateKey: string):
  Promise<any> {
    const response = await fetch(`https://api.stripe.com/v1/plans?expand[]=data.product&limit=100&active=true`, {
      headers: {
        authorization: `Bearer ${stripePrivateKey}`,
      },
    });
    const jsonResponse = await response.json();
    return jsonResponse.data;
  }

  public async createSchedule(stripePrivateKey: string, schedule: any): Promise<any> {
    const windowAsAny = window as any;
    const formData = windowAsAny.jsonToFormData(schedule);
    const response = await fetch(`https://api.stripe.com/v1/subscription_schedules`, {
      headers: {
        authorization: `Bearer ${stripePrivateKey}`,
      },
      method: 'POST',
      body: new URLSearchParams(formData),
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  public async updateSchedule(stripePrivateKey: string, scheduleId: string, schedule: any): Promise<any> {
    const windowAsAny = window as any;
    const formData = windowAsAny.jsonToFormData(schedule);
    const response = await fetch(`https://api.stripe.com/v1/subscription_schedules/${scheduleId}`, {
      headers: {
        authorization: `Bearer ${stripePrivateKey}`,
      },
      method: 'POST',
      body: new URLSearchParams(formData),
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  public async releaseSchedule(stripePrivateKey: string, scheduleId: string): Promise<any> {
    const response = await fetch(`https://api.stripe.com/v1/subscription_schedules/${scheduleId}/release`, {
      headers: {
        authorization: `Bearer ${stripePrivateKey}`,
      },
      method: 'POST',
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  }
}
