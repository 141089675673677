import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { AccountActionsService } from '../../accountActions/accountActionsService';
import { loadBuyMerchandiseModal } from '../../accountActions/actions';
import { apiConfig } from '../../app/apiConfig';
import { IAppStore } from '../../rootReducer';
import { merchandiseInvoiceColumnData } from '../filters/payments';
import { merchandiseInvoicesSelector } from '../selector';
import { nextButton, previousButton } from './reactTablePaginationButtons';

class MerchandiseInvoices extends Component<any, any> {
  public reactTable: any;
  constructor(props: any) {
    super(props);
    this.reactTable = React.createRef();
  }

  public render() {
    return (
      <ReactTable
        ref={this.reactTable}
        className='clickable-row'
        minRows={0}
        resizable={true}
        showPageSizeOptions={true}
        filterable
        data={this.props.invoices}
        loadingText='Loading...'
        loading={this.props.isFetching}
        noDataText={'No data found'}
        columns={merchandiseInvoiceColumnData}
        defaultPageSize={50}
        defaultFilterMethod={(filter: any, row: any) =>
          String(row[filter.id]) === filter.value
        }
        defaultSorted={this.props.sortOrder}
        PreviousComponent={previousButton}
        NextComponent={nextButton}
        TrGroupComponent={(props) => (
          <div
            onClick={() => this.props.openInvoice(this.props.uid, props.invoiceNo)}
            className='rt-tr-group'
            role='rowgroup'>{props.children}
          </div>
        )}
        getTrGroupProps={(_: any, props: any) => ({ invoiceNo: props.original.invoiceNo })}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  const accountActionService = new AccountActionsService(apiConfig);

  return {
    openInvoice: (uid: string, invoiceNo: string) =>
      dispatch(loadBuyMerchandiseModal(accountActionService, uid, invoiceNo)),
  };
};

const mapStateToProps = (state: IAppStore, props: any) => {
  const { accountActions, members } = state;
  const { error } = members.view;
  const { memberAccountShedule, memberWorkoutHistory } = accountActions.data;
  const { isFetching } = accountActions.view;
  const { memberAccountData } = members.data;
  const memberDetail = memberAccountData && memberAccountData.userInfo.profile;
  const invoices = merchandiseInvoicesSelector(state);

  return {
    isFetching,
    error,
    memberAccountShedule,
    invoices,
    memberDetail,
    memberWorkoutHistory,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchandiseInvoices);
