import React from 'react';
import Loader from 'react-loader-spinner';

export const Loading = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader
        type='Puff'
        color='#FF621D'
        height={100}
        width={100}
        timeout={3000}
      />
    </div>);
};
