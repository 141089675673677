import React from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { IAppStore } from '../../rootReducer';
import { toggleCancelPauseModal } from '..//actions';

class CancelPauseModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  public closeModal = () => {
    this.props.toggleCancelPauseModal();
  }

  public render() {
    return (
      <div>
        <Modal
          isOpen={this.props.showCancelPauseModal}
          className='modal-content'
          overlayClassName='modal-overlay'
          ariaHideApp={false}
          contentLabel='Columns'>
          <div className='column-label content-margin'>
            <h2
              className='column-header'>
              {`Cancel Freeze`}
            </h2>
            <button
              className='modal-close-button'
              onClick={this.closeModal}>
              X
                </button>
          </div>
          <div>
            <p>Are you sure you want to cancel your pause on your subscription?</p>
          </div>
          {this.props.error &&
            <div style={{ textAlign: 'right' }}>
              <p style={{ color: 'red' }}>{this.props.error}</p>
            </div>
          }
          {this.props.isFetching ?
            <div style={{ textAlign: 'right' }}>
              <Loader
                type='ThreeDots'
                color='#FF621D'
                height={50}
                width={50}
                timeout={3000}
              />
            </div> :
            <div style={{ display: 'inline-block' }}>
              <div style={{ float: 'right', display: 'inline-block' }}>
                <button className='jr-btn-default' style={{ marginTop: 10 }}>Yes, Cancel</button>
              </div>
              <div style={{ float: 'left', display: 'inline-block' }}>
                <button className='jr-btn-default' style={{ marginTop: 10 }}>No, Do Not Cancel</button>
              </div>
            </div>

          }
        </Modal>
      </div>
    );
  }
}
const mapDispatchToProps = {
    toggleCancelPauseModal,
};
const mapStateToProps = (state: IAppStore) => {
  const { accountActions } = state;
  const { showCancelPauseModal, isFetching, error } = accountActions.view;
  return {
    showCancelPauseModal,
    isFetching,
    error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelPauseModal);
