
import { Gym } from 'fitbeat.models';
import { IFilterDataFromMembersData } from '../storeTypes';
import { buildWorkoutConfigColumnsFromAccessor } from './workoutColumnConfigFilter';

export const buildWorkoutColumnsFromAccessor = (
  accessor: string,
  filterData: IFilterDataFromMembersData,
  currentGym: Gym): any => {
    const workoutColumns = buildWorkoutConfigColumnsFromAccessor(currentGym);
    const accessorFound = workoutColumns.find((singleColumn: any) => {
      return singleColumn.id === accessor;
    });
    if (accessorFound) {
      return accessorFound;
    }
    return {};
};

export const availableWorkoutAccessors = [
  {
    accessor: 'minutesInWorkout',
    used: true,
    sortable: true,
    width: 100,
  },
  {
    accessor: 'uniqueReference',
    used: true,
    sortable: true,
    width: 100,
  }, {
    accessor: 'classDate',
    used: true,
    sortable: true,
    width: 100,
  },  {
    accessor: 'equipment',
    used: true,
    sortable: true,
    width: 200,
  },
  {
    accessor: 'classTime',
    used: true,
    sortable: true,
    width: 100,
  }, {
    accessor: 'removedAt',
    used: true,
    sortable: true,
    width: 100,
  }, {
    accessor: 'workoutSelectionId',
    used: true,
    sortable: true,
    width: 100,
  }, {
    accessor: 'workoutLevel',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'threeBodyGroups',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'cardioOrResistance',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'ignoreProgramRecommendations',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'memberMainGym',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'gymName',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'calories',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'load',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'averageEffort',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'classDuration',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'bookingSource',
    used: true,
    sortable: true,
    width: 120,
  }];
