import { IInvoice } from 'fitbeat.models';
import * as React from 'react';
import { getFormattedAmount } from '../../shared/helpers/formatHelper';

export const invoiceTotal = (original: IInvoice) => {
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <p style={{margin: 0}}>{getFormattedAmount(original.total, original.currency)}</p>
    </div>
  );
};
