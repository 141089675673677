import React, { Component, ReactNode } from 'react';
import TabHeader from './TabHeader';

interface ITabListItem {
  tabTitle: string;
  tabContents: ReactNode;
}

interface IProps {
  tabItemsWithDisplay: ITabListItem[];
  onSelectTab?: (tab: string) => void;
  tabHeaderClass: string;
  tabContentClass: string;
}

interface IState {
  activeTab: string | null;
}

export default class TabDisplay extends Component<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      activeTab: null,
    };
  }

  public handleTabHeadersDisplay = () => {
    return this.props.tabItemsWithDisplay.map((singleTabHeaderTitle: any) => {
      const isActiveTab = singleTabHeaderTitle.tabTitle === this.state.activeTab;
      return <TabHeader
        tabTitle={singleTabHeaderTitle.tabTitle}
        handleTabSelected={this.handleTabSelection}
        isActiveTab={isActiveTab}
        />;
    });
  }

  public handleTabSelection = (tabName: string) => {
    this.setState({activeTab: tabName});
    if (this.props.onSelectTab) {
      this.props.onSelectTab(tabName);
    }
  }

  public handleTabContentDisplay = () => {
    const {tabItemsWithDisplay} = this.props;
    const contentSectionToDisplay = tabItemsWithDisplay.find((singleContentSection: any) => {
      return singleContentSection.tabTitle === this.state.activeTab;
    });
    return contentSectionToDisplay ? contentSectionToDisplay.tabContents : null;
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if(prevProps.tabItemsWithDisplay.length !== this.props.tabItemsWithDisplay.length) {
      const firstTabInList = this.props.tabItemsWithDisplay[0];
      this.handleTabSelection(firstTabInList.tabTitle);
    }
  }

  public componentDidMount() {
    const firstTabInList = this.props.tabItemsWithDisplay[0];
    this.handleTabSelection(firstTabInList.tabTitle);
  }

public render() {
    return (
      <div>
        <div className={this.props.tabHeaderClass}>
          {this.handleTabHeadersDisplay()}
        </div>
        <div className={this.props.tabContentClass}>
          {this.handleTabContentDisplay()}
        </div>
      </div>
    );
  }
}
