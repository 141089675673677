import { Dispatch } from 'redux';

import { IMemberDetailsData } from 'fitbeat.gym-core';
import {
  IAccountComment, IMemberColumnData,
  IOrganisationConfig,
  IUserWorkoutSessionMemberReport,
  LeadStatus, MemberReportErrors, MembersReportData, ReportType, UserAccountDetail,
} from 'fitbeat.models';
import Moment from 'moment';
import { Filter, SortingRule } from 'react-table';
import { ThunkAction } from 'redux-thunk';
import {
  ICreateSubscriptionSuccessAction,
  IHandleMerchandiseDraftInvoiceSuccessAction,
  IPayMerchandiseFailureAction,
  IPayMerchandiseSuccessAction,
} from '../accountActions/actions';
import { apiConfig } from '../app/apiConfig';
import { IAppStore } from '../rootReducer';
import getLogger from '../util/logging';
import { userAvatarSrc } from './config';
import { getDatesForPredefinedDayFilter } from './filters/filterHelpers';
import { MembersService } from './membersService';
import { IMembersState, ISortColumn } from './storeTypes';

export const GET_ALL_DATA_REQUEST = 'GET_ALL_DATA_REQUEST';
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_DATA_SUCCESS';
export const GET_ALL_DATA_FAILURE = 'GET_ALL_DATA_FAILURE';
export const WORKOUT_AGGREGATE_DATE_CHANGED = 'WORKOUT_AGGREGATE_DATE_CHANGED';
export const GET_MEMBERACCOUNT_REQUEST = 'GET_MEMBERS_REQUEST';
export const GET_MEMBERACCOUNT_SUCCESS = 'GET_MEMBERACCOUNT_SUCCESS';
export const GET_MEMBERACCOUNT_FAILURE = 'GET_MEMBERS_FAILURE';
export const TOGGLE_COLUMN_IS_USED = 'TOGGLE_COLUMN_IS_USED';
export const SORT_COLUMN = 'SORT_COLUMN';
export const CLEAR_FILTER_DATA = 'CLEAR_FILTER_DATA';
export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SAVE_REPORT_REQUEST = 'SAVE_REPORT_REQUEST';
export const SAVE_REPORT_SUCCESS = 'SAVE_REPORT_SUCCESS';
export const SAVE_REPORT_FAILURE = 'SAVE_REPORT_FAILURE';
export const SET_SORTING_ORDER = 'SET_SORTING_ORDER';
export const TOGGLE_SAVE_REPORT_MODAL = 'TOGGLE_SAVE_REPORT_MODAL';
export const CHANGE_REPORT_NAME = 'CHANGE_REPORT_NAME';
export const GET_DEFAULT_REPORTS = 'GET_DEFAULT_REPORTS';
export const GET_SAVED_REPORTS_REQUEST = 'GET_SAVED_REPORTS_REQUEST';
export const GET_SAVED_REPORTS_SUCCESS = 'GET_SAVED_REPORTS_SUCCESS';
export const GET_SAVED_REPORTS_FAILURE = 'GET_SAVED_REPORTS_FAILURE';
export const GET_ACTIVE_REPORT = 'GET_ACTIVE_REPORT';
export const GET_DASHBOARD_ACTIVE_REPORT = 'GET_DASHBOARD_ACTIVE_REPORT';
export const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST';
export const DASHBOARD_RETURN_TO_INITIAL_STATE = 'DASHBOARD_RETURN_TO_INITIAL_STATE';
export const RESET_TABLE_FILTERS = 'RESET_TABLE_FILTERS';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const RETURN_TO_INITIAL_STATE = 'RETURN_TO_INITIAL_STATE';
export const TOGGLE_CONFIRMATION_MODAL = 'TOGGLE_CONFIRMATION_MODAL';
export const TOGGLE_IS_CLONE = 'TOGGLE_IS_CLONE';
export const SELECT_TABLE_ROW = 'SELECT_TABLE_ROW';
export const SAVE_USED_COLUMNS = 'SAVE_USED_COLUMNS';
export const SAVE_COLUMN_WIDTH = 'SAVE_COLUMN_WIDTH';
export const RESET_TEMP_COLUMN_STORE = 'RESET_TEMP_COLUMN_STORE';
export const TOGGLE_EDIT_MEMBER_DETAILS_MODAL = 'TOGGLE_EDIT_MEMBER_DETAILS_MODAL';
export const UPDATE_MEMBER_DETAILS_REQUEST = 'UPDATE_MEMBER_DETAILS_REQUEST';
export const UPDATE_MEMBER_DETAILS_SUCCESS = 'UPDATE_MEMBER_DETAILS_SUCCESS';
export const UPDATE_MEMBER_DETAILS_FAILURE = 'UPDATE_MEMBER_DETAILS_FAILURE';
export const LOAD_FOLLOW_UP_DATE_ACTION = 'LOAD_FOLLOW_UP_DATE_ACTION';
export const SAVE_FOLLOW_UP_DATE_ACTION = 'SAVE_FOLLOW_UP_DATE_ACTION';
export const EDIT_FOLLOW_UP_DATE_ACTION = 'EDIT_FOLLOW_UP_DATE_ACTION';
export const FOLLOW_UP_DATE_SERVICE_SUCCESS_ACTION = 'FOLLOW_UP_DATE_SERVICE_SUCCESS_ACTION';
export const FOLLOW_UP_DATE_SERVICE_FAILURE_ACTION = 'FOLLOW_UP_DATE_SERVICE_FAILURE_ACTION';
export const LOAD_MEMBER_COMMENTS = 'LOAD_MEMBER_COMMENTS';
export const SAVE_MEMBER_COMMENTS_REQUEST = 'UPDATE_MEMBER_COMMENTS_REQUEST';
export const EDIT_MEMBER_COMMENTS = 'EDIT_MEMBER_COMMENTS';

export const MEMBER_COMMENTS_SERVICE_SUCCESS = 'MEMBER_COMMENTS_SERVICE_SUCCESS';
export const MEMBER_COMMENTS_SERVICE_FAILURE = 'MEMBER_COMMENTS_SERVICE_FAILURE';

export const MEMBER_LEAD_STATUS_UPDATE_REQUEST = 'MEMBER_LEAD_STATUS_UPDATE_REQUEST';
export const MEMBER_LEAD_STATUS_UPDATE_SUCCESS = 'MEMBER_LEAD_STATUS_UPDATE_SUCCESS';
export const MEMBER_LEAD_STATUS_UPDATE_FAILURE = 'MEMBER_LEAD_STATUS_UPDATE_FAILURE';

export const GET_INVOICES_DUE_AMOUNT = 'GET_INVOICES_DUE_AMOUNT';
export const MOVE_REPORT_DOWN = 'MOVE_REPORT_DOWN';
export const MOVE_REPORT_UP = 'MOVE_REPORT_UP';

export const UPLOAD_AVATAR_REQUEST = 'UPLOAD_AVATAR_REQUEST';
export const UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS';
export const UPLOAD_AVATAR_FAILURE = 'UPLOAD_AVATAR_FAILURE';

const logger = getLogger('members:actions');

interface IMoveReportDown {
  type: typeof MOVE_REPORT_DOWN;
  payload: string[];
}

interface IMoveReportUp {
  type: typeof MOVE_REPORT_UP;
  payload: string[];
}

interface IGetAllDataSuccess {
  type: typeof GET_ALL_DATA_SUCCESS;
  payload: {
    members: IMemberDetailsData[],
    workouts: IUserWorkoutSessionMemberReport[],
    reportToUpdate?: string,
    organisationConfig: IOrganisationConfig,
  };
}

interface IGetAllDataFailure {
  type: typeof GET_ALL_DATA_FAILURE;
  payload: string | null;
}

interface IGetAllDataRequest {
  type: typeof GET_ALL_DATA_REQUEST;
  payload: IWorkoutAggregateFilter | undefined;
}

export interface IWorkoutAggregateDateChanged {
  type: typeof WORKOUT_AGGREGATE_DATE_CHANGED;
  payload: IWorkoutAggregateFilter | undefined;
}

interface IToggleColumnIsUsed {
  type: typeof TOGGLE_COLUMN_IS_USED;
  payload: string | null;
}

interface ISortColumnArrangement {
  type: typeof SORT_COLUMN;
  payload: ISortColumn;
}

interface ISetFilterData {
  type: typeof SET_FILTER_DATA;
  payload: any[];
}

interface IClearFilterData {
  type: typeof CLEAR_FILTER_DATA;
  payload: any[];
}

interface ISetSortingOrder {
  type: typeof SET_SORTING_ORDER;
  payload: SortingRule[];
}

interface ISaveReportRequestAction {
  type: typeof SAVE_REPORT_REQUEST;
}

interface ISaveReportSuccessAction {
  type: typeof SAVE_REPORT_SUCCESS;
  payload: any;
}

interface ISaveReportFailureAction {
  type: typeof SAVE_REPORT_FAILURE;
  payload: string | null;
}

interface IToggleSaveReportModalAction {
  type: typeof TOGGLE_SAVE_REPORT_MODAL;
}

interface IToggleConfirmationModalAction {
  type: typeof TOGGLE_CONFIRMATION_MODAL;
  payload: boolean;
}

interface IChangeReportNameAction {
  type: typeof CHANGE_REPORT_NAME;
  payload: string;
}

interface IGetSavedReportsRequestAction {
  type: typeof GET_SAVED_REPORTS_REQUEST;
  payload: string;
}

interface ISavedReportsSuccessPayload {
  reports: MembersReportData[];
  dashboardReportIds: string[];
  lastViewedMemberReport: string;
  lastActiveDashboardReport: string;
  lastViewedWorkoutsReport: string;
}

interface IGetSavedReportsSuccessAction {
  type: typeof GET_SAVED_REPORTS_SUCCESS;
  payload: ISavedReportsSuccessPayload;
}

interface IGetSavedReportsFailureAction {
  type: typeof GET_SAVED_REPORTS_FAILURE;
  payload: string | null;
}

interface IGetActiveReportAction {
  type: typeof GET_ACTIVE_REPORT;
  payload: string;
}

interface IGetDashboardActiveReportAction {
  type: typeof GET_DASHBOARD_ACTIVE_REPORT;
  payload: string;
}

interface IDashboardReturnInitialStateAction {
  type: typeof DASHBOARD_RETURN_TO_INITIAL_STATE;
}

interface IReportTableResetFilters {
  type: typeof RESET_TABLE_FILTERS;
}

interface IDeleteReportRequestsAction {
  type: typeof DELETE_REPORT_REQUEST;
}

interface IDeleteReportSuccessAction {
  type: typeof DELETE_REPORT_SUCCESS;
  payload: string;
}

interface IReturnInitialStateAction {
  type: typeof RETURN_TO_INITIAL_STATE;
}

interface IToggleIsCloneAction {
  type: typeof TOGGLE_IS_CLONE;
}

interface ISelectTableRowAction {
  type: typeof SELECT_TABLE_ROW;
  payload: any;
}

interface ISaveColumns {
  type: typeof SAVE_USED_COLUMNS;
}

interface ISaveColumnWidth {
  type: typeof SAVE_COLUMN_WIDTH;
  payload: any;
}
interface IResetTempColumnStore {
  type: typeof RESET_TEMP_COLUMN_STORE;
}

interface IGetMemberAccountRequestAction {
  type: typeof GET_MEMBERACCOUNT_REQUEST;
  payload: string;
}

interface IGetMemberAccountSuccessAction {
  type: typeof GET_MEMBERACCOUNT_SUCCESS;
  payload: UserAccountDetail;
}

interface IGetMemberAccountFailureAction {
  type: typeof GET_MEMBERACCOUNT_FAILURE;
  payload: string | null;
}

interface IUpdateMemberDetailsRequestAction {
  type: typeof UPDATE_MEMBER_DETAILS_REQUEST;
  payload: string;
}

interface IUpdateMemberDetailsSuccessAction {
  type: typeof UPDATE_MEMBER_DETAILS_SUCCESS;
  payload: any;
}
interface IUpdateMemberDetailsFailureAction {
  type: typeof UPDATE_MEMBER_DETAILS_FAILURE;
  payload: string | null;
}
interface IUpdateMemberDetailsFailureAction {
  type: typeof UPDATE_MEMBER_DETAILS_FAILURE;
  payload: string | null;
}
interface IUpdateMemberDetailsFailureAction {
  type: typeof UPDATE_MEMBER_DETAILS_FAILURE;
  payload: string | null;
}
interface IUpdateMemberDetailsFailureAction {
  type: typeof UPDATE_MEMBER_DETAILS_FAILURE;
  payload: string | null;
}
interface IUpdateMemberDetailsFailureAction {
  type: typeof UPDATE_MEMBER_DETAILS_FAILURE;
  payload: string | null;
}

interface IToggleEditMemberDetailsAction {
  type: typeof TOGGLE_EDIT_MEMBER_DETAILS_MODAL;
}

interface ILoadFollowUpDateAction {
  type: typeof LOAD_FOLLOW_UP_DATE_ACTION;
}
interface ISaveFollowUpDateAction {
  type: typeof SAVE_FOLLOW_UP_DATE_ACTION;
}
interface IFollowUpDateServiceSuccessAction {
  type: typeof FOLLOW_UP_DATE_SERVICE_SUCCESS_ACTION;
  payload: Date | undefined;
}
interface IFollowUpDateServiceFailureAction {
  type: typeof FOLLOW_UP_DATE_SERVICE_FAILURE_ACTION;
  payload: string;
}
interface ILoadMemberCommentsAction {
  type: typeof LOAD_MEMBER_COMMENTS;
}
interface ISaveMemberCommentsAction {
  type: typeof SAVE_MEMBER_COMMENTS_REQUEST;
}

interface IEditMemberCommentsAction {
  type: typeof EDIT_MEMBER_COMMENTS;
  payload: string;
}
interface IMemberCommentsServiceSuccessAction {
  type: typeof MEMBER_COMMENTS_SERVICE_SUCCESS;
  payload: IAccountComment[];
}
interface IMemberCommentsServiceFailureAction {
  type: typeof MEMBER_COMMENTS_SERVICE_FAILURE;
  payload: string;
}

interface IMemberLeadStatusUpdate {
  type: typeof MEMBER_LEAD_STATUS_UPDATE_REQUEST
  | typeof MEMBER_LEAD_STATUS_UPDATE_SUCCESS | typeof MEMBER_LEAD_STATUS_UPDATE_FAILURE;
  payload: {
    leadStatus: LeadStatus,
    uid: string,
  };
}

interface IAvatarUpload {
  type: typeof UPLOAD_AVATAR_FAILURE | typeof UPLOAD_AVATAR_SUCCESS | typeof UPLOAD_AVATAR_REQUEST;
  payload: {
    uid: string;
  };
}

export type MemberActionTypes = IGetAllDataRequest | IGetAllDataSuccess | IGetAllDataFailure |
IEditMemberCommentsAction | ISaveMemberCommentsAction | ILoadMemberCommentsAction |
IMemberCommentsServiceSuccessAction | IMemberCommentsServiceFailureAction |
IToggleColumnIsUsed | ISortColumnArrangement | IClearFilterData | ISetFilterData |
ISaveReportRequestAction | ISaveReportSuccessAction | ISaveReportFailureAction | ISetSortingOrder |
IToggleSaveReportModalAction | IChangeReportNameAction | IGetSavedReportsRequestAction | IGetSavedReportsSuccessAction
| IGetSavedReportsFailureAction | IDeleteReportSuccessAction | IToggleIsCloneAction
| IReportTableResetFilters | IToggleConfirmationModalAction | ISelectTableRowAction | ISaveColumns
| IReturnInitialStateAction | IToggleConfirmationModalAction | ISelectTableRowAction |
ISaveColumnWidth | IResetTempColumnStore | IGetMemberAccountRequestAction | IGetMemberAccountSuccessAction |
IGetMemberAccountFailureAction | IToggleEditMemberDetailsAction | IUpdateMemberDetailsRequestAction |
ILoadFollowUpDateAction |
ISaveFollowUpDateAction |
IFollowUpDateServiceSuccessAction |
IFollowUpDateServiceFailureAction |
IUpdateMemberDetailsSuccessAction | IUpdateMemberDetailsFailureAction | IGetActiveReportAction
| IDeleteReportRequestsAction | IMoveReportDown | IMoveReportUp | ICreateSubscriptionSuccessAction |
IGetDashboardActiveReportAction | IDashboardReturnInitialStateAction | IMemberLeadStatusUpdate | IAvatarUpload |
IHandleMerchandiseDraftInvoiceSuccessAction | IPayMerchandiseSuccessAction |
IPayMerchandiseFailureAction | IWorkoutAggregateDateChanged;

export interface IWorkoutAggregateFilter {
  startDate?: Date;
  endDate?: Date;
  label?: string;
}

const getWorkoutAggregateFilter = (gymName: string): IWorkoutAggregateFilter | undefined => {
  const workoutAggregateFilter = localStorage.getItem(`${gymName}-workoutAggregateFilter`);
  const parsedWorkoutAggregateFilter = workoutAggregateFilter ? JSON.parse(workoutAggregateFilter) : undefined;
  const predefinedDateFilter = getDatesForPredefinedDayFilter(parsedWorkoutAggregateFilter);
  if (parsedWorkoutAggregateFilter) {
    return {
      ...parsedWorkoutAggregateFilter,
      startDate: predefinedDateFilter?.startDate ? new Date(predefinedDateFilter.startDate) : undefined,
      endDate: predefinedDateFilter?.endDate ? new Date(predefinedDateFilter.endDate) : undefined,
    };
  }

  return undefined;
};

export const getAllData = (
  emailExclusions: string,
  gymName: string,
  includeWorkouts = true,
): ThunkAction<void, IAppStore, unknown, MemberActionTypes> => async (dispatch, getState) => {
  try {
    logger.trace('getAllData');
    const workoutAggregateFilter = getWorkoutAggregateFilter(gymName);
    const state = getState();
    dispatch({
      type: GET_ALL_DATA_REQUEST,
      payload: workoutAggregateFilter,
    });
    const membersService = new MembersService(apiConfig);
    const workoutsDateRange = workoutAggregateFilter?.startDate ? {
      workoutsFromDate: workoutAggregateFilter.startDate,
      workoutsToDate: workoutAggregateFilter.endDate,
    } : undefined;

    // todo - we only really need members, and not even the member stats so
    // get the workout stats and workouts as stages rather
    const [organisationConfig, loadedMembers, workouts] = await Promise.all([
      membersService.getOrginisationConfig(gymName),
      membersService.getMembers({ emailExclusions, gymName }, workoutsDateRange),
      includeWorkouts
        ? membersService.getAllWorkoutsForGym(state.adminSettings.data.selectedGymName || '')
        : [],
    ]);

    logger.trace('getAllData success');
    dispatch({
      type: GET_ALL_DATA_SUCCESS,
      payload: {
        members: loadedMembers.map((singleMember: IMemberDetailsData) => {
          if (singleMember.userSettings) {
            singleMember.userSettings.userSignupDate = Moment(
              singleMember.userSettings.userSignupDate,
            ).format('YYYY-MM-DD');
          }
          singleMember.userWorkoutAggregate.lastWorkout = singleMember.userWorkoutAggregate.lastWorkout ? Moment(
            singleMember.userWorkoutAggregate.lastWorkout,
          ).format('YYYY-MM-DD') : '';
          singleMember.userWorkoutAggregate.firstWorkout = singleMember.userWorkoutAggregate.firstWorkout ? Moment(
            singleMember.userWorkoutAggregate.firstWorkout,
          ).format('YYYY-MM-DD') : '';
          if (singleMember.userAccount && singleMember.userAccount.paymentAmount) {
            const dollars = singleMember.userAccount.paymentAmount / 100;
            singleMember.userAccount.paymentAmount = Number(dollars);
          }
          if (singleMember.userAccount && singleMember.userAccount.accountBalance) {
            singleMember.userAccount.accountBalance = singleMember.userAccount.accountBalance / 100;
          }
          singleMember.userAvatarUrl = userAvatarSrc(singleMember.userSettings.uid);
          return singleMember;
        }),
        workouts: includeWorkouts ? workouts : state.members.data.allWorkouts,
        organisationConfig,
      },
    });
  } catch (error) {
    logger.error(error, 'getAllData failed');
    dispatch({
      type: GET_ALL_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const workoutAggregateDateChange = (data: IWorkoutAggregateFilter, gymName: string):
ThunkAction<void, IAppStore, unknown, MemberActionTypes> => async (dispatch, getState) => {
  const jsonString = JSON.stringify(data);
  localStorage.setItem(`${gymName}-workoutAggregateFilter`, jsonString);

  dispatch({
    type: WORKOUT_AGGREGATE_DATE_CHANGED,
    payload: data,
  });
};

export const getMemberAccount = (uid: string) => async (dispatch: Dispatch<MemberActionTypes>) => {
  try {
    logger.trace('getMemberAccount');
    dispatch(fetchMemberAccountDataRequest(uid));
    const membersService = new MembersService(apiConfig);
    const membersAccountInfo = await getMemberDetails(membersService, uid);

    if (membersAccountInfo) {
      dispatch(fetchMemberAccountDataSuccess(membersAccountInfo));
    }
  } catch (error) {
    logger.error(error, 'getMemberAccount failed');
    dispatch(fetchMemberAccountDataFailure('Issue fetching member details'));
  }
};

export const getMemberDetails = async (membersService: MembersService, uid: string) => {
  const membersAccountInfo = await membersService.getMemberDetails(uid);
  if (membersAccountInfo.invoices != null) {
    membersAccountInfo.invoices.map((invoice) => {
      invoice.created = Moment.unix(invoice.created).format('YYYY-MM-DD');
      const dollars = invoice.total / 100;
      invoice.total = Number(dollars);
      invoice.due = Number(invoice.due / 100);
      invoice.creditNoteTotal = Number(invoice.creditNoteTotal / 100);
    });
  }

  if (membersAccountInfo.payments != null) {
    membersAccountInfo.payments.map((payment) => {
      payment.created = Moment.unix(payment.created).format('YYYY-MM-DD');
      const dollars = payment.amount / 100;
      payment.amount = Number(dollars);
    });
  }

  return membersAccountInfo;
};

export const setFilterData = (filtered: any[]) => async (dispatch: Dispatch<MemberActionTypes>) => {
  dispatch(setFilteredData(filtered));
  return filtered;
};

export const setSortingOrder = (sorting: SortingRule[]) => async (dispatch: Dispatch<MemberActionTypes>) => {
  dispatch(setSortOrder(sorting));
  return sorting;
};

export const clearFilterData = () => async (dispatch: Dispatch<MemberActionTypes>) => {
  const clearFilter: any[] = [];
  dispatch(clearFilteredData(clearFilter));
};

export const saveReport = (
  reportName: string,
  memberColumnData: IMemberColumnData[],
  filters: Filter[],
  sortOrder: SortingRule[],
  reportType: ReportType,
) => async (dispatch: Dispatch<MemberActionTypes>) => {
  try {
    dispatch(saveReportRequest());
    const membersService = new MembersService(apiConfig);
    const reportDataToSave: MembersReportData = {
      reportId: Math.random().toString(36).substr(2, 20),
      reportName,
      dateCreated: new Date(),
      memberColumnData,
      filters,
      sortOrder,
      selected: true,
      reportType: reportType ?? ReportType.members,
    };
    const reportResult = await membersService.createMemberReport(reportDataToSave);
    if (reportResult === MemberReportErrors.nameExist) {
      throw new Error('A report with this name already exists');
    }
    dispatch(saveReportSuccess(reportDataToSave));
  } catch (error) {
    dispatch(saveReportFailure(error.message));
  }
};

export const updateReport = () => async (dispatch: Dispatch<MemberActionTypes>, getState: () => IAppStore) => {
  try {
    const { data, view } = getState().members;
    const { activeReport } = data;
    const { filtered } = view;
    dispatch(saveReportRequest());
    toggleSaveReportModal();

    const reportDataToSave: MembersReportData = {
      reportId: activeReport.reportId,
      reportName: activeReport.reportName,
      dateCreated: new Date(),
      memberColumnData: activeReport.memberColumnData,
      filters: filtered,
      sortOrder: view.sortOrder,
      selected: true,
      reportType: activeReport.reportType,
    };
    const membersService = new MembersService(apiConfig);
    await membersService.updateMemberReport(activeReport.reportId, reportDataToSave);
    dispatch(saveReportSuccess(reportDataToSave));
  } catch (error) {
    dispatch(saveReportFailure(error.message));
  }
};

export const toggleReportModal = () => async (dispatch: Dispatch<MemberActionTypes>) => {
  dispatch(toggleSaveReportModal());
};

export const toggleConfirmationModal = (isDelete: boolean) => async (dispatch: Dispatch<MemberActionTypes>) => {
  dispatch(toggleConfirmModal(isDelete));
};

export const toggleIsReportClone = () => async (dispatch: Dispatch<MemberActionTypes>) => {
  dispatch(toggleIsClone());
};

export const updateMemberDetails = (
  uid: string,
  updatedMemberDetails: any,
) => async (dispatch: Dispatch<MemberActionTypes>) => {
  try {
    dispatch(fetchUpdateMemberDetailsRequest());
    const membersService = new MembersService(apiConfig);
    const returnedUpdatedData = await membersService.updateMember(uid, updatedMemberDetails);
    dispatch(fetchUpdateMemberDetailsSuccess(returnedUpdatedData));
  } catch (error) {
    dispatch(fetchUpdateMemberDetailsFailure(error.message));
  }
};

export const loadMemberComments = (uid: string) => async (dispatch: Dispatch<MemberActionTypes>) => {
  try {
    dispatch(loadMemberCommentsRequest());
    const membersService = new MembersService(apiConfig);
    const comments = await membersService.getMemberComments(uid);
    dispatch(memberCommentsServiceSuccess(comments));
  } catch (error) {
    dispatch(memberCommentsServiceFailure(error.message));
  }
};

export const saveFollowUpDate = (
  uid: string,
  followUpDate: string,
) => async (dispatch: Dispatch<MemberActionTypes>, getState: () => IAppStore) => {
  try {
    dispatch(saveFollowUpDateRequest());
    const membersService = new MembersService(apiConfig);
    const results = await membersService.saveFollowUpDate(uid, followUpDate);
    dispatch(followUpDateServiceSuccess(results.updatedFollowUpDate));
  } catch (error) {
    dispatch(followUpDateServiceFailure(error.message));
  }
};

export const getFollowUpDate = (
  uid: string,
) => async (dispatch: Dispatch<MemberActionTypes>, getState: () => IAppStore) => {
  try {
    dispatch(loadFollowUpDateRequest());
    const membersService = new MembersService(apiConfig);
    const memberFollowUpDate = await membersService.getFollowUpDate(uid);
    dispatch(followUpDateServiceSuccess(memberFollowUpDate.followUpDate));
  } catch (error) {
    dispatch(followUpDateServiceFailure(error.message));
  }
};

export const removeFollowUpDate = (
  uid: string,
) => async (dispatch: Dispatch<MemberActionTypes>, getState: () => IAppStore) => {
  try {
    const membersService = new MembersService(apiConfig);
    await membersService.removeFollowUpDate(uid);
    dispatch(followUpDateServiceSuccess());
  } catch (error) {
    dispatch(followUpDateServiceFailure(error.message));
  }
};

export const saveMemberComments = (
  uid: string,
  newComment: string,
) => async (dispatch: Dispatch<MemberActionTypes>, getState: () => IAppStore) => {
  try {
    dispatch(saveMemberCommentsRequest());
    const membersService = new MembersService(apiConfig);
    const updatedComments = await membersService.updateComments(uid, newComment);
    dispatch(memberCommentsServiceSuccess(updatedComments));
  } catch (error) {
    dispatch(memberCommentsServiceFailure(error.message));
  }
};

export const editMemberComment = (changedComments: string) => async (dispatch: Dispatch<MemberActionTypes>) => {
  dispatch(editMemberCommentOnEdit(changedComments));
};

export const setReportName = (reportName: any) => async (dispatch: Dispatch<MemberActionTypes>) => {
  dispatch(changeReportName(reportName.target.value));
};

const getLastActiveOrStorageReport = (reports: MembersReportData[]) => {
  let lastViewedMemberReport = localStorage.getItem('lastViewedMemberReportId');
  let lastViewedWorkoutsReportId = localStorage.getItem('lastViewedWorkoutsReportId');
  let lastActiveDashboardReportFromStorage = localStorage.getItem('lastActiveDashboardReportId');
  if (!lastActiveDashboardReportFromStorage) {
    lastActiveDashboardReportFromStorage = 'initialState';
  }
  if (!lastViewedMemberReport) {
    lastViewedMemberReport = 'initialState';
  }
  if (!lastViewedWorkoutsReportId) {
    const workoutsFilteredReports: MembersReportData[] = reports
      .filter((singleReport: MembersReportData) => singleReport.reportType === ReportType.workouts);
    lastViewedWorkoutsReportId = workoutsFilteredReports[0].reportId;
  }

  return { lastViewedMemberReport, lastViewedWorkoutsReportId, lastActiveDashboardReportFromStorage };
};

export const loadActiveReport = (
  reports: MembersReportData[],
  reportType?: ReportType,
) => async (dispatch: Dispatch<MemberActionTypes>) => {
  const {
    lastViewedMemberReport,
    lastViewedWorkoutsReportId,
  } = getLastActiveOrStorageReport(reports);

  if (reportType) {
    const toBeActiveReportId = reportType === ReportType.workouts
      ? lastViewedWorkoutsReportId
      : lastViewedMemberReport;
    setActiveReport(toBeActiveReportId, reportType, dispatch);
  }
};

export const getSavedReports = (reportType?: ReportType) => async (dispatch: Dispatch<MemberActionTypes>) => {
  try {
    const gymname = 'Sydney CBD'; // TODO should come from store
    dispatch(fetchSavedReportsRequest(gymname));
    const membersService = new MembersService(apiConfig);
    const reports = await membersService.getAllMemberReports();
    const sortedReportsId = await membersService.getDashboardConfig();
    const dashboardReportIds = sortedReportsId;
    const {
      lastViewedMemberReport,
      lastViewedWorkoutsReportId,
      lastActiveDashboardReportFromStorage,
    } = getLastActiveOrStorageReport(reports);

    dispatch(fetchSavedReportsSuccess(
      reports,
      dashboardReportIds,
      lastViewedMemberReport,
      lastActiveDashboardReportFromStorage,
      lastViewedWorkoutsReportId,
    ));
    if (reportType) {
      const toBeActiveReportId = reportType === ReportType.workouts
        ? lastViewedWorkoutsReportId
        : lastViewedMemberReport;
      setActiveReport(toBeActiveReportId, reportType, dispatch);
    }
  } catch (error) {
    dispatch(fetchSavedReportsFailure(error.message));
  }
};

export const moveReportDownOrDown = (
  reportId: string,
  moveDown: boolean,
) => async (dispatch: Dispatch<MemberActionTypes>) => {
  try {
    const membersService = new MembersService(apiConfig);
    const reports = await membersService.updateReportOrderList(reportId, moveDown);
    if (moveDown) {
      dispatch(moveReportDown(reports));
    } else {
      dispatch(moveReportUp(reports));
    }
  } catch (error) {
    dispatch(fetchSavedReportsFailure(error.message));
  }
};

export const loadDashboardActiveReport = (report: any) => async (dispatch: Dispatch<MemberActionTypes>) => {
  const { reportId } = report;
  localStorage.setItem('lastActiveDashboardReportId', reportId);
  if (reportId === 'initialState') {
    dispatch(dashboardFetchInitialState());
  } else {
    dispatch(setDashboardActiveReport(reportId));
  }
};

export const clearReportTableFilters = () => async (dispatch: Dispatch<MemberActionTypes>) => {
  dispatch(resetReportTableFilters());
};

const setActiveReport = (reportId: string, reportType: ReportType, dispatch: any) => {
  if (reportType === ReportType.members) {
    localStorage.setItem('lastViewedMemberReportId', reportId);
  } else {
    localStorage.setItem('lastViewedWorkoutsReportId', reportId);
  }
  if (reportId === 'initialState') {
    dispatch(fetchInitialState());
  } else {
    dispatch(fetchActiveReport(reportId));
  }
};

export const handleActiveReportChange = (report: any) => async (dispatch: Dispatch<MemberActionTypes>) => {
  const { reportId } = report;
  setActiveReport(reportId, report.reportType, dispatch);
};

export const deleteReport = (reportId: any) => async (dispatch: Dispatch<MemberActionTypes>) => {
  try {
    dispatch(deleteReportRequest());
    const membersService = new MembersService(apiConfig);
    const removedReport = await membersService.deleteMemberReport(reportId);
    dispatch(deleteReportSuccess(removedReport.id));
  } catch (error) {
    dispatch(fetchSavedReportsFailure(error.message));
  }
};

export const resetToInitialState = (state: IMembersState) => async (dispatch: Dispatch<MemberActionTypes>) => {
  dispatch(fetchInitialState());
};

export const addToRowSelection = (row: any) => async (dispatch: Dispatch<MemberActionTypes>) => {
  dispatch(selectTableRow(row));
};

export const toggleEditMemberModal = () => async (dispatch: Dispatch<MemberActionTypes>) => {
  dispatch(toggleEditMemberDetailsModal());
};

export const leadStatusSelected = (
  uid: string,
  currentLeadStatus: LeadStatus,
  newLeadStatus: LeadStatus,
) => async (dispatch: Dispatch<IMemberLeadStatusUpdate>) => {
  try {
    dispatch({
      type: MEMBER_LEAD_STATUS_UPDATE_REQUEST,
      payload: {
        uid,
        leadStatus: newLeadStatus,
      },
    });

    const membersService = new MembersService(apiConfig);
    await membersService.updateLeadStatus(uid, newLeadStatus);

    dispatch({
      type: MEMBER_LEAD_STATUS_UPDATE_SUCCESS,
      payload: {
        uid,
        leadStatus: newLeadStatus,
      },
    });
  } catch (error) {
    dispatch({
      type: MEMBER_LEAD_STATUS_UPDATE_FAILURE,
      payload: {
        uid,
        leadStatus: currentLeadStatus,
      },
    });
  }
};

const clearFilteredData = (filtered: any[]): IClearFilterData => ({
  type: CLEAR_FILTER_DATA,
  payload: filtered,
});

const setFilteredData = (filtered: any[]): ISetFilterData => ({
  type: SET_FILTER_DATA,
  payload: filtered,
});

const setSortOrder = (sorted: SortingRule[]): ISetSortingOrder => ({
  type: SET_SORTING_ORDER,
  payload: sorted,
});

export const toggleMemberTableColumnIsUsed = (columnName: string): IToggleColumnIsUsed => ({
  type: TOGGLE_COLUMN_IS_USED,
  payload: columnName,
});

export const sortMemberTableColumn = (oldIndex: number, newIndex: number): ISortColumnArrangement => ({
  type: SORT_COLUMN,
  payload: {
    oldIndex,
    newIndex,
  },
});

const saveReportRequest = (): ISaveReportRequestAction => ({
  type: SAVE_REPORT_REQUEST,
});

export const saveReportSuccess = (report: MembersReportData): ISaveReportSuccessAction => ({
  type: SAVE_REPORT_SUCCESS,
  payload: {
    activeReport: report,
  },
});

export const moveReportDown = (reportIdList: string[]): IMoveReportDown => ({
  type: MOVE_REPORT_DOWN,
  payload: reportIdList,
});

export const moveReportUp = (reportIdList: string[]): IMoveReportUp => ({
  type: MOVE_REPORT_UP,
  payload: reportIdList,
});

const saveReportFailure = (error: string): ISaveReportFailureAction => ({
  type: SAVE_REPORT_FAILURE,
  payload: error,
});

const deleteReportRequest = (): IDeleteReportRequestsAction => ({
  type: DELETE_REPORT_REQUEST,
});

const deleteReportSuccess = (reportId: string): IDeleteReportSuccessAction => ({
  type: DELETE_REPORT_SUCCESS,
  payload: reportId,
});

const toggleSaveReportModal = (): IToggleSaveReportModalAction => ({
  type: TOGGLE_SAVE_REPORT_MODAL,
});

const toggleConfirmModal = (isDelete: boolean): IToggleConfirmationModalAction => ({
  type: TOGGLE_CONFIRMATION_MODAL,
  payload: isDelete,
});

const changeReportName = (reportName: string): IChangeReportNameAction => ({
  type: CHANGE_REPORT_NAME,
  payload: reportName,
});

const fetchSavedReportsRequest = (gymname: string): IGetSavedReportsRequestAction => ({
  type: GET_SAVED_REPORTS_REQUEST,
  payload: gymname,
});

const fetchSavedReportsSuccess = (
  reports: MembersReportData[],
  dashboardReportIds: string[],
  lastViewedMemberReport: string,
  lastActiveDashboardReport: string,
  lastViewedWorkoutsReport: string,
): IGetSavedReportsSuccessAction => ({
  type: GET_SAVED_REPORTS_SUCCESS,
  payload: {
    reports,
    lastViewedMemberReport,
    dashboardReportIds,
    lastActiveDashboardReport,
    lastViewedWorkoutsReport,
  },
});

const fetchSavedReportsFailure = (error: string): IGetSavedReportsFailureAction => ({
  type: GET_SAVED_REPORTS_FAILURE,
  payload: error,
});

const setDashboardActiveReport = (reportId: string): IGetDashboardActiveReportAction => ({
  type: GET_DASHBOARD_ACTIVE_REPORT,
  payload: reportId,
});

const fetchActiveReport = (reportId: string): IGetActiveReportAction => ({
  type: GET_ACTIVE_REPORT,
  payload: reportId,
});

const dashboardFetchInitialState = (): IDashboardReturnInitialStateAction => ({
  type: DASHBOARD_RETURN_TO_INITIAL_STATE,
});

const resetReportTableFilters = (): IReportTableResetFilters => ({
  type: RESET_TABLE_FILTERS,
});

const fetchInitialState = (): IReturnInitialStateAction => ({
  type: RETURN_TO_INITIAL_STATE,
});

const toggleIsClone = (): IToggleIsCloneAction => ({
  type: TOGGLE_IS_CLONE,
});

const selectTableRow = (member: any[]): ISelectTableRowAction => ({
  type: SELECT_TABLE_ROW,
  payload: member,
});

export const saveColumnUsed = (): ISaveColumns => ({
  type: SAVE_USED_COLUMNS,
});

export const saveColumnWidth = (resizeData: any): ISaveColumnWidth => ({
  type: SAVE_COLUMN_WIDTH,
  payload: resizeData,
});

export const resetTempColumnStore = (): IResetTempColumnStore => ({
  type: RESET_TEMP_COLUMN_STORE,
});

const fetchMemberAccountDataRequest = (gymname: string): IGetMemberAccountRequestAction => ({
  type: GET_MEMBERACCOUNT_REQUEST,
  payload: gymname,
});

export const fetchMemberAccountDataSuccess = (members: UserAccountDetail): IGetMemberAccountSuccessAction => ({
  type: GET_MEMBERACCOUNT_SUCCESS,
  payload: members,
});

const fetchMemberAccountDataFailure = (error: string): IGetMemberAccountFailureAction => ({
  type: GET_MEMBERACCOUNT_FAILURE,
  payload: error,
});

const toggleEditMemberDetailsModal = (): IToggleEditMemberDetailsAction => ({
  type: TOGGLE_EDIT_MEMBER_DETAILS_MODAL,
});

const fetchUpdateMemberDetailsRequest = (): IUpdateMemberDetailsRequestAction => ({
  type: UPDATE_MEMBER_DETAILS_REQUEST,
  payload: '',
});

const fetchUpdateMemberDetailsSuccess = (updatedMemberDetails: any): IUpdateMemberDetailsSuccessAction => ({
  type: UPDATE_MEMBER_DETAILS_SUCCESS,
  payload: updatedMemberDetails,
});

const fetchUpdateMemberDetailsFailure = (error: string): IUpdateMemberDetailsFailureAction => ({
  type: UPDATE_MEMBER_DETAILS_FAILURE,
  payload: error,
});

const loadFollowUpDateRequest = (): ILoadFollowUpDateAction => ({
  type: LOAD_FOLLOW_UP_DATE_ACTION,
});

const saveFollowUpDateRequest = (): ISaveFollowUpDateAction => ({
  type: SAVE_FOLLOW_UP_DATE_ACTION,
});

const followUpDateServiceSuccess = (followUpDate?: Date | undefined): IFollowUpDateServiceSuccessAction => ({
  type: FOLLOW_UP_DATE_SERVICE_SUCCESS_ACTION,
  payload: followUpDate,
});

const followUpDateServiceFailure = (error: string): IFollowUpDateServiceFailureAction => ({
  type: FOLLOW_UP_DATE_SERVICE_FAILURE_ACTION,
  payload: error,
});

const loadMemberCommentsRequest = (): ILoadMemberCommentsAction => ({
  type: LOAD_MEMBER_COMMENTS,
});

const saveMemberCommentsRequest = (): ISaveMemberCommentsAction => ({
  type: SAVE_MEMBER_COMMENTS_REQUEST,
});

const editMemberCommentOnEdit = (comment: string): IEditMemberCommentsAction => ({
  type: EDIT_MEMBER_COMMENTS,
  payload: comment,
});

const memberCommentsServiceSuccess = (comments: IAccountComment[]): IMemberCommentsServiceSuccessAction => ({
  type: MEMBER_COMMENTS_SERVICE_SUCCESS,
  payload: comments,
});

const memberCommentsServiceFailure = (error: string): IMemberCommentsServiceFailureAction => ({
  type: MEMBER_COMMENTS_SERVICE_FAILURE,
  payload: error,
});

export const uploadAvatar = (uid: string, base64Avatar: string) => {
  const membersService = new MembersService(apiConfig);
  return async (dispatch: Dispatch<MemberActionTypes>) => {
    try {
      dispatch({ type: UPLOAD_AVATAR_REQUEST });
      await membersService.uploadAvatar(uid, base64Avatar);
      dispatch({ type: UPLOAD_AVATAR_SUCCESS, payload: { uid } });
    } catch (error) {
      dispatch({ type: UPLOAD_AVATAR_FAILURE });
    }
  };
};
