import React, { Component } from 'react';
import QRCode from 'react-qr-code';

export interface ISingleField {
    label: string;
    value: string | number | undefined;
    urlLink?: string;
    showQrCode?: boolean;
}
interface IProps {
    field: ISingleField;
}
export default class MemberDetailsSingleField extends Component<IProps> {
  public handleLink = () => {
    const  { field: { urlLink, value } } = this.props;
    if (urlLink && value) {
      window.open(
        urlLink,
       '_blank');
    }
  }
  public render() {
    const { field: { label } } = this.props;
    return (
      <div className='member-details-single-field-container' onClick={this.handleLink}>
        <p className='member-details-single-field-label'>{label}</p>
        {this.renderValue()}
      </div>
    );
  }

  private renderValue = () => {
    const { field: { value, urlLink, showQrCode } } = this.props;
    const isLinkClass = urlLink ? 'is-link-text' : '';
    if (showQrCode && value) {
      return (
        <QRCode
          value={value}
          viewBox={`0 0 128 128`}
          size={128}
        />
      );
    }
    return (
      <p className={`member-details-single-field-value ${isLinkClass}`}>
        {value ? value : '-'}
      </p>
    );
  }
}
