import React, { Component } from 'react';
import Select from 'react-select';

interface IProps {
  onChange?: (selectedValue: any) => void;
  value?: any;
  options: any[];
  placeholder?: string;
  className?: string;
  classNamePrefix?: string;
  isSearchable?: boolean;
  components?: any;
  isDisabled?: boolean;
  styles?: React.CSSProperties;
  isLoading?: boolean;
  menuPortalTarget?: HTMLElement;
}

export default class Dropdown extends Component<IProps> {
  public render() {
    return (
      <Select
        {...this.props}
        styles={{
          control: ({...provided }) => ({
            ...provided,
            'zIndex': 101,
            'borderColor': 'none',
            'boxShadow': 'none',
            '&:hover': {
              borderColor: 'none',
              boxShadow: 'none',
            },
            '&:active': {
              borderColor: 'none',
              boxShadow: 'none',
            },
          }),
          menuPortal: (base: any) => ({...base, zIndex: 9999 }),
        }}
      />
    );
  }
}
